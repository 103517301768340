import {getErrorMessage} from '@app/common/utils'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text} from '@chakra-ui/react'
import type React from 'react'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'

type ModalProps = {
  isOpen: boolean
  onClose(): void
}

type FormData = {
  originPlayer: string
  targetPlayer: string
}

export function AdminSubscriptionTransferModal(props: ModalProps): React.JSX.Element {
  const methods = useForm<FormData>({
    defaultValues: {
      originPlayer: '',
      targetPlayer: '',
    },
  })

  async function onSubmit(data: any): Promise<void> {
    try {
      await api.post('admin/billing/transfer-subscription-credit', data)
      toast.success('Off you go! The active subscription has been transferred to subscription credit.')
      props.onClose()
    } catch (error) {
      methods.setError('originPlayer', {type: 'validate', message: getErrorMessage(error, 'originPlayer')})
      methods.setError('targetPlayer', {type: 'validate', message: getErrorMessage(error, 'targetPlayer')})
    }
  }

  return (
    <Modal
      footer={
        <ModalFooter gap="8px">
          <Button colorScheme="gray" onClick={props.onClose} variant="ghost">
            Cancel
          </Button>
          <Button isLoading={methods.formState.isSubmitting} onClick={methods.handleSubmit(onSubmit)}>
            Submit
          </Button>
        </ModalFooter>
      }
      header="Transfer Active Subscription"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <Text>Transfers an active subscription from player A (if present) to player B (as subscription credit).</Text>

        <FormControl isInvalid={Boolean(methods.formState.errors.originPlayer?.message)}>
          <FormLabel htmlFor="originPlayer">Origin Player</FormLabel>
          <Input
            autoFocus
            id="originPlayer"
            maxLength={32}
            minLength={2}
            placeholder="Origin Player"
            {...methods.register('originPlayer', {required: true})}
          />
          <FormErrorMessage>{methods.formState.errors.originPlayer?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(methods.formState.errors.targetPlayer?.message)}>
          <FormLabel htmlFor="targetPlayer">Target Player</FormLabel>
          <Input
            id="targetPlayer"
            maxLength={32}
            minLength={2}
            placeholder="Target Player"
            {...methods.register('targetPlayer', {required: true})}
          />
          <FormErrorMessage>{methods.formState.errors.targetPlayer?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>
    </Modal>
  )
}
