import {useCurrentUser} from '@app/common/api'
import {FormType} from '@app/common/constants'
import {useRequestsStore} from '@app/common/requests'
import {useOverlayStore} from '@app/common/stores'
import {isStaff} from '@app/common/utils'
import {AccountDiscordModal} from '@app/components/Account/AccountDiscordModal'
import {AccountDiscordSettingsModal} from '@app/components/Account/AccountDiscordSettingsModal'
import {AccountGuildInvitesModal} from '@app/components/Account/AccountGuildInvitesModal'
import {AccountOfflineMessagesModal} from '@app/components/Account/AccountOfflineMessagesModal'
import {AccountUpdateModal} from '@app/components/Account/AccountUpdateModal'
import {SecurityModal} from '@app/components/Account/SecurityModal'
import styles from '@app/components/App.module.css'
import {FriendModal} from '@app/components/Friend/FriendModal'
import {LogoutModal} from '@app/components/Modal/LogoutModal'
import {NavigationButton, NavigationButtonGrid} from '@app/components/Modal/NavigationButton'
import {MetaTags} from '@app/components/UI/MetaTags'
import {Box, Grid, Heading, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightIcon,
  ClipboardDocumentCheckIcon,
  EnvelopeIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/solid'
import type React from 'react'
import {FaDiscord, FaRobot} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import {useMediaQuery} from 'usehooks-ts'

type Item = {
  icon: React.ReactNode
  title: string
  onClick(): void
}

function StepOption({icon, title, onClick}: Item): React.JSX.Element {
  return (
    <NavigationButton
      _focusVisible={{bgColor: '#ea580c'}}
      _hover={{bgColor: '#ea580c'}}
      bgColor="#f97316"
      leftIcon={icon}
      onClick={onClick}
      rightIcon={<ArrowRightIcon height={24} width={24} />}
      title={title}
    />
  )
}

function StepOptionGrid({icon, title, onClick}: Item): React.JSX.Element {
  return (
    <NavigationButtonGrid
      _focusVisible={{bgColor: '#ea580c'}}
      _hover={{bgColor: '#ea580c'}}
      bgColor="#f97316"
      icon={icon}
      onClick={onClick}
      title={title}
    />
  )
}

function Items({items}: {items: Item[]}): React.JSX.Element {
  const isGrid = useMediaQuery('(min-width: 479px)')
  if (isGrid) {
    return (
      <Grid gap="10px" templateColumns="repeat(2, 1fr)">
        {items.map((item, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: this is fine
          <StepOptionGrid key={index} {...item} />
        ))}
      </Grid>
    )
  }

  return (
    <>
      {items.map((item, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: this is fine
        <StepOption key={index} {...item} />
      ))}
    </>
  )
}

export default function Account(): React.JSX.Element | null {
  const {data: user} = useCurrentUser()
  const navigate = useRequestsStore(state => state.navigate)
  const routerNavigate = useNavigate()
  const setRequestsOpen = useOverlayStore(state => state.setRequestsOpen)
  const setPunishmentPlayer = useOverlayStore(state => state.setPunishmentPlayer)

  const discordModal = useDisclosure()
  const discordSettingsModal = useDisclosure()
  const friendModal = useDisclosure()
  const guildInvitesModal = useDisclosure()
  const logoutModal = useDisclosure()
  const offlineMessagesModal = useDisclosure()
  const securityModal = useDisclosure()
  const updateModal = useDisclosure()

  if (!user) return null

  const items = [
    {
      icon: <UserIcon height={24} width={24} />,
      title: 'Edit Profile',
      onClick: () => updateModal.onOpen(),
    },
    ...(isStaff(user)
      ? [
          {
            icon: <LockClosedIcon height={24} width={24} />,
            title: 'Security Settings',
            onClick: () => securityModal.onOpen(),
          },
        ]
      : []),
    {
      icon: <UsersIcon height={24} width={24} />,
      title: 'Manage Friends',
      onClick: () => friendModal.onOpen(),
    },
    {
      icon: <ShieldCheckIcon height={24} width={24} />,
      title: 'Punishments',
      onClick: () => setPunishmentPlayer(user.name),
    },
    {
      icon: <ClipboardDocumentCheckIcon height={24} width={24} />,
      title: 'My Requests',
      onClick: () => {
        navigate(FormType.SUBMISSIONS_SCREEN)
        setRequestsOpen(true)
      },
    },
    {
      icon: <FaDiscord size={24} />,
      title: 'Link Discord Account',
      onClick: () => discordModal.onOpen(),
    },
    {
      icon: <FaDiscord size={24} />,
      title: 'Discord Sync Settings',
      onClick: () => discordSettingsModal.onOpen(),
    },
    {
      icon: <FaRobot size={24} />,
      title: 'API Integrations',
      onClick: () => routerNavigate('/auth/applications'),
    },
    {
      icon: <EnvelopeIcon height={24} width={24} />,
      title: 'Offline Messages',
      onClick: () => offlineMessagesModal.onOpen(),
    },
    {
      icon: <UsersIcon height={24} width={24} />,
      title: 'Guild Invites',
      onClick: () => guildInvitesModal.onOpen(),
    },
    {
      icon: <ArrowLeftOnRectangleIcon height={24} width={24} />,
      title: 'Log Out',
      onClick: () => logoutModal.onOpen(),
    },
  ]

  return (
    <>
      <MetaTags title="Account Center" />
      <AccountDiscordModal {...discordModal} />
      <AccountDiscordSettingsModal {...discordSettingsModal} />
      <AccountGuildInvitesModal {...guildInvitesModal} />
      <AccountOfflineMessagesModal {...offlineMessagesModal} />
      <AccountUpdateModal user={user} {...updateModal} />
      <FriendModal {...friendModal} />
      <LogoutModal {...logoutModal} />
      <SecurityModal {...securityModal} />

      <Stack borderWidth={1} className={styles.backdrop} gap="8px" my={4} p={8} rounded="2xl" textAlign="center">
        <Box>
          <Heading fontSize="2xl">Account Center</Heading>
          <Text fontSize="md" mt={2}>
            Manage your NetherGames account.
          </Text>
        </Box>

        <Stack gap="2px">
          <Items items={items} />
        </Stack>
      </Stack>
    </>
  )
}
