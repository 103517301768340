import {useAPIStaffChatLogQuery, useCurrentUser} from '@app/common/api'
import {ChatLogContexts} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {isAdmin} from '@app/common/utils'
import {ChatlogEntry} from '@app/components/Modal/ChatLogEntry'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Heading, Input, Stack, Text} from '@chakra-ui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import type {GroupBase} from 'chakra-react-select'
import {Select} from 'chakra-react-select'
import React from 'react'

const CHAT_LOG_TYPES = Object.entries(ChatLogContexts).map(([, value], idx) => {
  return {
    label: value as string,
    value: Object.values(ChatLogContexts)[idx] as ChatLogContexts,
  }
})

const ADMIN_CONTEXTS: Set<ChatLogContexts> = new Set([
  ChatLogContexts.STAFF,
  ChatLogContexts.MODERATION,
  ChatLogContexts.ADMIN,
  ChatLogContexts.TRAINEE,
])

const CHAT_LOG_NONADMIN_TYPES = CHAT_LOG_TYPES.filter(l => !ADMIN_CONTEXTS.has(l.value))

interface ChatLogTypeOption {
  label: string
  value: ChatLogContexts
}

export default function StaffChatLogsModal(): React.JSX.Element {
  const [chatLogsOpen, setAdminChatLogsOpen] = useOverlayStore(state => [state.chatLogsOpen, state.setChatLogsOpen])

  return (
    <Modal header={'Chat logs'} isOpen={chatLogsOpen != null} onClose={() => setAdminChatLogsOpen(null)}>
      <StaffChatLogsModalContent />
    </Modal>
  )
}

function StaffChatLogsModalContent(): React.JSX.Element {
  const {chatLogsOpen, setChatLogContexts, chatLogsContexts} = useOverlayStore(state => ({
    chatLogsOpen: state.chatLogsOpen,
    setChatLogContexts: state.setChatLogContexts,
    chatLogsContexts: state.chatLogsContexts,
  }))
  const {data: user} = useCurrentUser()

  const [contentSearch, setContentSearch] = React.useState<string | null>(null)
  const [limit, setLimit] = React.useState<number>(100)
  const {data: messages} = useAPIStaffChatLogQuery(chatLogsOpen as string, {
    context_search: chatLogsContexts,
    content_search: contentSearch,
    page: 0,
    page_size: 0,
    limit: limit,
  })

  return (
    <ModalBody as={Stack} gap="8px" h="full" overflowY="auto">
      <Stack>
        <Stack>
          <SmallHeading>Message content search</SmallHeading>
          <Input
            onChange={event => setContentSearch(event.target.value)}
            placeholder="Message Content (fuzzy)"
            value={contentSearch ?? undefined}
          />
        </Stack>
        <Stack>
          <SmallHeading>Chat Context</SmallHeading>
          <Select<ChatLogTypeOption, false, GroupBase<ChatLogTypeOption>>
            className="react-select"
            classNamePrefix="react-select"
            defaultValue={[]}
            isClearable
            menuPortalTarget={document.body}
            onChange={option => {
              if (!option) {
                setChatLogContexts([])
                return
              }

              if (chatLogsContexts.includes(option.value))
                setChatLogContexts(chatLogsContexts.filter(value => value !== option.value))
              else setChatLogContexts([...chatLogsContexts, option.value])
            }}
            options={isAdmin(user) ? CHAT_LOG_TYPES : CHAT_LOG_NONADMIN_TYPES}
            selectedOptionStyle="check"
            size="sm"
            value={(isAdmin(user) ? CHAT_LOG_TYPES : CHAT_LOG_NONADMIN_TYPES).filter(option =>
              chatLogsContexts.includes(option.value),
            )}
          />
        </Stack>

        <Stack>
          <SmallHeading>Search limit (number)</SmallHeading>
          <Input
            onChange={event => setLimit(Number.parseInt(event.target.value))}
            placeholder="Search limit (number)"
            type={'number'}
            value={contentSearch ?? undefined}
          />
        </Stack>
      </Stack>

      {messages ? (
        messages.length === 0 && (
          <Stack align="center" h="full" justify="center" py={16} textAlign="center">
            <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
            <Heading fontSize="2xl" fontWeight="bold">
              *cricket noises*
            </Heading>
            <Text color="whiteAlpha.800" fontSize="md">
              No chat messages found matching your criteria
            </Text>
          </Stack>
        )
      ) : (
        <Spinner />
      )}

      <Stack spacing="8px">
        <SmallHeading>Chat Messages — {messages?.length ?? 0}</SmallHeading>
        <Stack spacing="16px">
          {messages?.map(message => (
            <ChatlogEntry chatLogEntry={message} key={message.timestamp} />
          ))}
        </Stack>
      </Stack>
    </ModalBody>
  )
}
