import React from 'react'

export const KEYBOARD_MODE_CLASSNAME = 'keyboardMode'
export const MOUSE_MODE_CLASSNAME = 'mouseMode'

function onKeyDown(event: KeyboardEvent): void {
  if (event.metaKey || event.ctrlKey || event.altKey || event.getModifierState?.(event.key)) return
  document.documentElement.classList.add(KEYBOARD_MODE_CLASSNAME)
  document.documentElement.classList.remove(MOUSE_MODE_CLASSNAME)
}

function onMouseDown(): void {
  document.documentElement.classList.add(MOUSE_MODE_CLASSNAME)
  document.documentElement.classList.remove(KEYBOARD_MODE_CLASSNAME)
}

export function useKeyboardMode(): void {
  React.useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('mousedown', onMouseDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('mousedown', onMouseDown)
    }
  }, [])
}
