import {DEFAULT_BACKGROUND_DAY, DEFAULT_BACKGROUND_NIGHT} from '@app/common/constants'
import {usePortalNagbar, usePromoBackground} from '@app/common/experiments'
import {Header} from '@app/components/Home/Header'
import styles from '@app/components/Home/Layout.module.css'
import {Flex, Stack, Text} from '@chakra-ui/react'
import {XMarkIcon} from '@heroicons/react/24/solid'
import React from 'react'
import {Outlet} from 'react-router-dom'

function useIsNight(): boolean {
  const [isNight, setIsNight] = React.useState(false)
  const currentHour = new Date().getHours()
  React.useEffect(() => {
    setIsNight(currentHour >= 20 || currentHour < 8)
  }, [currentHour])

  return isNight
}

function useBackground(): string {
  const isNight = useIsNight()
  const promoBackground = usePromoBackground()
  return promoBackground ?? (isNight ? DEFAULT_BACKGROUND_NIGHT : DEFAULT_BACKGROUND_DAY)
}

function Nagbar({children, onDismiss}: {children: React.ReactNode; onDismiss?(): void}): React.JSX.Element {
  return (
    <Flex
      align="center"
      bgColor="#f97316"
      boxShadow="lg"
      fontSize="sm"
      fontWeight="semibold"
      justify="center"
      letterSpacing="tighter"
      pos="relative"
      px={4}
      py={2}
      roundedBottom="md"
    >
      <Text>{children}</Text>
      {onDismiss && (
        <XMarkIcon
          height={20}
          onClick={onDismiss}
          role="button"
          style={{cursor: 'pointer', position: 'absolute', right: 8, top: 8}}
          width={20}
        />
      )}
    </Flex>
  )
}

export function Layout({hasHorizontalGutter = true}): React.JSX.Element {
  const background = useBackground()
  const nagbar = usePortalNagbar()

  return (
    <div
      className={styles.background}
      style={{'--background-image': `url(/backgrounds/${background})`} as React.CSSProperties}
    >
      <Flex direction="column">
        <Header />

        {nagbar && <Nagbar>{nagbar}</Nagbar>}

        <Stack
          align="center"
          justify="center"
          minH={nagbar ? 'calc(100vh - 4rem - 2.5rem)' : 'calc(100vh - 4rem)'}
          px={hasHorizontalGutter ? '4' : '0'}
          py="4"
        >
          <Outlet />
        </Stack>
      </Flex>
    </div>
  )
}
