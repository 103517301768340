import b32 from 'thirty-two'

function getRandomBytes(size = 10): Uint8Array {
  return crypto.getRandomValues(new Uint8Array(size))
}

function encodeTotpKey(bin: Uint8Array): string {
  const base32 = b32.encode(bin).toString('utf8').replaceAll('=', '')
  return base32
    .toLowerCase()
    .replaceAll(/(?<tmp>\w{4})/g, '$<tmp> ')
    .trim()
}

export function generateTotpSecret(): string {
  return encodeTotpKey(getRandomBytes())
}

export function encodeTotpSecret(secret: string): string {
  return secret.replaceAll(/[\s._-]+/g, '').toUpperCase()
}

export function encodeTotpSecretAsUrl(accountName: string, secret: string, issuer = 'NetherGames'): string {
  return `otpauth://totp/${encodeURI(issuer)}:${encodeURI(accountName)}?secret=${encodeTotpSecret(
    secret,
  )}&issuer=${encodeURIComponent(issuer)}`
}
