import {FactionRole, useCurrentUser, useFaction, usePunishmentsBulk} from '@app/common/api'
import {numberFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {getAvatarUrl, isAdmin} from '@app/common/utils'
import appStyles from '@app/components/App.module.css'
import {FactionUpdateModal} from '@app/components/Faction/FactionUpdateModal'
import {MemberList} from '@app/components/Member/MemberList'
import playerStyles from '@app/components/Player/Player.module.css'
import ErrorScreen from '@app/components/UI/ErrorScreen'
import {MetaTags} from '@app/components/UI/MetaTags'
import {api} from '@app/hooks/useApi'
import {EditIcon} from '@chakra-ui/icons'
import {Button, Divider, Heading, IconButton, Link, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import clsx from 'clsx'
import React from 'react'
import toast from 'react-hot-toast'
import {useParams} from 'react-router-dom'

export default function Faction(): React.JSX.Element | null {
  const {faction} = useParams()
  const {status, data, error} = useFaction(faction!)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isRandomizing, setIsRandomizing] = React.useState(false)
  const {data: punishments} = usePunishmentsBulk(data?.xuids ?? [])
  const {data: user} = useCurrentUser()
  const queryClient = useQueryClient()
  const setSplashScreenOpen = useOverlayStore(state => state.setSplashScreenOpen)
  const updateModal = useDisclosure()
  const updateRef = React.useRef()
  React.useEffect(() => {
    setSplashScreenOpen(status === 'pending')
  }, [setSplashScreenOpen, status])

  if (status === 'error') return <ErrorScreen payload={error} />
  if (!data) return null

  function getCurrentRole(xuid: string): FactionRole | null {
    if (!data) return null
    if (data.leader && data.leader.xuid === xuid) return FactionRole.LEADER
    if (data.officers.some(value => value.xuid === xuid)) return FactionRole.OFFICER
    if (data.members.some(value => value.xuid === xuid)) return FactionRole.MEMBER
    return null
  }

  const currentRole = user ? getCurrentRole(user.xuid) : null
  const shouldAllowUpdate = import.meta.env.DEV || currentRole === FactionRole.LEADER

  return (
    <>
      <MetaTags image={getAvatarUrl(data.leader.skinHash)} title={data.name} />

      <Stack
        align="center"
        borderWidth={1}
        className={clsx(appStyles.card, appStyles.backdrop, playerStyles.main)}
        direction="column"
        my={4}
        overflow="visible"
        p={4}
        rounded="2xl"
        spacing={2}
      >
        <Stack direction="row">
          {shouldAllowUpdate && (
            <>
              <IconButton
                aria-label="Edit"
                colorScheme="gray"
                icon={<EditIcon />}
                onClick={() => updateModal.onOpen()}
                pos="absolute"
                ref={updateRef as any}
                right={4}
                size="sm"
                top={4}
              />
              <FactionUpdateModal buttonRef={updateRef} faction={data} {...updateModal} />
            </>
          )}

          {isAdmin(user) && (
            <Button
              colorScheme="red"
              isLoading={isDeleting}
              onClick={async () => {
                try {
                  setIsDeleting(true)
                  await api.delete(`/admin/factions/${data.name}`)
                  await queryClient.invalidateQueries({queryKey: ['factions', data.name]})
                } catch {
                  toast.error('Something went wrong while deleting the faction. Please try again later.')
                } finally {
                  setIsDeleting(false)
                }
              }}
              size={['xs', 'sm']}
            >
              Delete Faction (Admin Only)
            </Button>
          )}

          {isAdmin(user) && (
            <Button
              colorScheme="red"
              isLoading={isRandomizing}
              onClick={async () => {
                try {
                  setIsRandomizing(true)
                  await api.post(`/admin/factions/${data.name}/randomize-name`)
                  await queryClient.invalidateQueries({queryKey: ['factions', data.name]})
                } catch {
                  toast.error('Something went wrong while randomizing the faction name. Please try again later.')
                } finally {
                  setIsRandomizing(false)
                }
              }}
              size={['xs', 'sm']}
            >
              Randomize Name (Admin Only)
            </Button>
          )}
        </Stack>

        <Stack align="center" direction="row">
          <Heading fontSize="2xl" fontWeight="bold">
            {data.name}
          </Heading>
        </Stack>

        <Stack align="center" direction={['column', 'row']} fontSize="16px" gap={['0px', '8px']} justify="center">
          <div>
            Strength: <strong>{numberFormatter.format(data.strength)}</strong>
          </div>

          <div>
            Balance: <strong>{numberFormatter.format(data.balance)}</strong>
          </div>

          <div>
            Members: <strong>{1 + data.officers.length + data.members.length}</strong>/<strong>25</strong>
          </div>
        </Stack>

        <Divider mb="0.5rem!important" mt="1rem!important" />
        <Stack align="center" direction="column" justify="center" mb="1.25rem!important" px={1} spacing={2} w="full">
          <Heading fontSize="lg">MOTD</Heading>
          {data.motd ? (
            <Text className={playerStyles.bio} fontSize="md">
              {data.motd}
            </Text>
          ) : (
            <Text color="gray.400" fontSize="md">
              {data.name} does not have a MOTD yet.
            </Text>
          )}
        </Stack>

        {data.allies.length > 0 && (
          <>
            <Divider mb="0.5rem!important" mt="1rem!important" />
            <Stack
              align="center"
              direction="column"
              justify="center"
              mb="1.25rem!important"
              px={1}
              spacing={2}
              w="full"
            >
              <Heading fontSize="lg">Allies — {data.allies.length}</Heading>
              <Text fontSize="md">
                {data.allies
                  .map<React.ReactNode>(ally => (
                    <Link color="orange.300" href={`/faction/${ally}`} isExternal key={ally}>
                      {ally}
                    </Link>
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr])}
              </Text>
            </Stack>
          </>
        )}

        {data.leader && (
          <>
            <Divider mb="0.5rem!important" mt="1rem!important" />
            <Stack
              align="center"
              direction="column"
              justify="center"
              mb="1.25rem!important"
              px={1}
              spacing={2}
              w="full"
            >
              <Heading fontSize="lg">Leader</Heading>
              <MemberList
                currentRole={null}
                entity={data}
                members={[data.leader]}
                punishments={punishments ?? {}}
                role={FactionRole.LEADER}
                type="faction"
              />
            </Stack>
          </>
        )}

        {data.officers.length > 0 && (
          <>
            <Divider mb="0.5rem!important" mt="1rem!important" />
            <Stack
              align="center"
              direction="column"
              justify="center"
              mb="1.25rem!important"
              px={1}
              spacing={2}
              w="full"
            >
              <Heading fontSize="lg">Officers — {data.officers.length}</Heading>
              <MemberList
                currentRole={currentRole}
                entity={data}
                members={data.officers}
                punishments={punishments ?? {}}
                role={FactionRole.OFFICER}
                type="faction"
              />
            </Stack>
          </>
        )}

        {data.members.length > 0 && (
          <>
            <Divider mb="0.5rem!important" mt="1rem!important" />
            <Stack
              align="center"
              direction="column"
              justify="center"
              mb="1.25rem!important"
              px={1}
              spacing={2}
              w="full"
            >
              <Heading fontSize="lg">Members — {data.members.length}</Heading>
              <MemberList
                currentRole={currentRole}
                entity={data}
                members={data.members}
                punishments={punishments ?? {}}
                role={FactionRole.MEMBER}
                type="faction"
              />
            </Stack>
          </>
        )}
      </Stack>
    </>
  )
}
