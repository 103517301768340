import styles from '@app/components/App.module.css'
import {Box, Button, Heading, Link, Stack, Text} from '@chakra-ui/react'
import {ExclamationTriangleIcon} from '@heroicons/react/24/solid'
import type {AxiosError} from 'axios'
import type React from 'react'
import {Link as RouterLink} from 'react-router-dom'

function isAxiosError(payload: unknown): payload is AxiosError {
  return Boolean((payload as AxiosError).response)
}

export default function ErrorScreen({payload}: {payload: any}): React.JSX.Element {
  const error = isAxiosError(payload) ? (payload.response as any).data : payload
  return (
    <Box borderWidth={1} className={styles.backdrop} letterSpacing="tight" p={8} rounded="2xl" textAlign="center">
      <Stack align="center" justify="center" maxW="lg" mb={5} spacing="4">
        <ExclamationTriangleIcon color="var(--chakra-colors-orange-200)" height={72} width={72} />
        <Heading fontSize="2xl">{error?.message ?? 'Technical Difficulties'}</Heading>
        <Text fontSize="lg" mt={4}>
          {error?.status === 404 ? (
            "This is not the page you're looking for."
          ) : (
            <>
              Please try again later, or{' '}
              <Link color="orange.300" href="https://ngmc.co/request" isExternal>
                chat with us
              </Link>{' '}
              if the error remains.
            </>
          )}
        </Text>
      </Stack>

      <RouterLink to="/">
        <Button>Return Home</Button>
      </RouterLink>
    </Box>
  )
}
