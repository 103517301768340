import {useCurrentUser} from '@app/common/api'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, FormLabel, Stack, Switch} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import toast from 'react-hot-toast'

type ModalProps = {
  isOpen: boolean
  onClose(): void
}

export function AccountDiscordSettingsModal(props: ModalProps): React.JSX.Element | null {
  const queryClient = useQueryClient()
  const {data: user} = useCurrentUser()
  const [visibility, setVisibility] = React.useState(true)
  const [shouldResetNickname, setShouldResetNickname] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  if (!user) return null

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              await api[visibility ? 'put' : 'delete']('users/@me/discord-visibility')
              await api[shouldResetNickname ? 'delete' : 'put']('users/@me/discord-nickname')
              await queryClient.invalidateQueries({queryKey: ['user']})
              toast.success('Discord settings updated successfully!')
              setIsLoading(false)
              props.onClose()
            }}
          >
            Save
          </Button>
        </ModalFooter>
      }
      header="Discord Sync Settings"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody fontSize="md">
        <Stack align="center" direction="row" justify="center">
          <FormLabel flex="1" htmlFor="discordVisibility" mb={0}>
            Discord Visibility
          </FormLabel>
          <Switch
            id="discordVisibility"
            isChecked={visibility}
            onChange={event => setVisibility(event.currentTarget.checked)}
          />
        </Stack>
        <Stack align="center" direction="row" justify="center" mt={2}>
          <FormLabel flex="1" htmlFor="nick" mb={0}>
            {shouldResetNickname ? (
              <>Reset Discord Nickname (Default)</>
            ) : (
              <>
                Set Discord Nickname to <strong>{user.name}</strong>
              </>
            )}
          </FormLabel>
          <Switch
            id="nick"
            isChecked={shouldResetNickname}
            onChange={event => setShouldResetNickname(event.currentTarget.checked)}
          />
        </Stack>
      </ModalBody>
    </Modal>
  )
}
