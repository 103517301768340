import {Text} from '@chakra-ui/react'
import type React from 'react'

export function SmallHeading({children}: {children: React.ReactNode}): React.JSX.Element {
  return (
    <Text
      as="h5"
      color="whiteAlpha.700"
      fontSize="sm"
      fontWeight="bold"
      letterSpacing="tight"
      textTransform="uppercase"
      userSelect="none"
    >
      {children}
    </Text>
  )
}
