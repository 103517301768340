import {API_HOST} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import type {Axios, AxiosInstance} from 'axios'
import axios from 'axios'
import React from 'react'

function createAPIClient({version = 1} = {}): AxiosInstance {
  const instance = axios.create({baseURL: `${API_HOST}/v${version}`})
  instance.interceptors.request.use(
    config => {
      const token = window.localStorage.getItem('token')
      if (token) config.headers.set('Authorization', token)
      return config
    },
    async error => {
      throw error
    },
  )
  instance.interceptors.response.use(
    response => {
      return response
    },
    async error => {
      console.error(error)
      if (axios.isAxiosError(error) && error.response?.data.code === 30_058) {
        useOverlayStore.getState().setMfaVerifyOpen(true)
      }

      throw error
    },
  )

  return instance
}

export const api = createAPIClient()
export const apiV2 = createAPIClient({version: 2})

export function useBlockingAPI(): Axios {
  const api = React.useMemo(createAPIClient, [])
  const setSplashScreenOpen = useOverlayStore(state => state.setSplashScreenOpen)
  api.interceptors.request.use(
    config => {
      setSplashScreenOpen(true)
      return config
    },
    async error => {
      setSplashScreenOpen(false)
      throw error
    },
  )
  api.interceptors.response.use(
    response => {
      setSplashScreenOpen(false)
      return response
    },
    async error => {
      setSplashScreenOpen(false)
      throw error
    },
  )
  return api
}
