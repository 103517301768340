import {useOfflineMessages} from '@app/common/api'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Heading, List, ListItem, Stack, Text} from '@chakra-ui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import type React from 'react'

type ModalProps = {
  buttonRef?: any
  isOpen: boolean
  onClose(): void
}

export function AccountOfflineMessagesModal(props: ModalProps): React.JSX.Element | null {
  const {data, status} = useOfflineMessages(props.isOpen)
  return (
    <Modal finalFocusRef={props.buttonRef} header="Offline Messages" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalBody fontSize="md">
        {status === 'pending' || !data ? (
          <Spinner />
        ) : data.length === 0 ? (
          <Stack align="center" h="full" justify="center" py={16} textAlign="center">
            <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
            <Heading fontSize="2xl" fontWeight="bold">
              *cricket noises*
            </Heading>
            <Text color="whiteAlpha.800" fontSize="md">
              No one has sent you any messages while you were offline.
            </Text>
          </Stack>
        ) : (
          <List spacing={2} userSelect="text">
            {data.map(message => (
              <ListItem fontSize="md" key={message.id}>
                {message.content}
              </ListItem>
            ))}
          </List>
        )}
      </ModalBody>
    </Modal>
  )
}
