import type {APIFormRequirementResponse, APIFormSubmissionResponse, APIPlayer} from '@app/common/api'
import {PlayerFlags, useAPIForm, useAPIFormSubmissions, useCurrentUser, useYoutubeMetadata} from '@app/common/api'
import {
  APPLICATION_FORM_TYPES,
  FormRequirementType,
  FormRequirementTypeToString,
  FormSubmissionStatus,
  FormSubmissionStatusToBg,
  FormSubmissionStatusToDescription,
  FormSubmissionStatusToString,
  FormType,
  FormTypeToString,
  FormTypeToTitle,
  INVALID_FORM_BODY,
  INVALID_FORM_TYPES,
  NOOP,
  SOMETHING_WENT_WRONG,
  XBOX_REDIRECT_URI,
  YOUTUBE_REQUIREMENT_TYPES,
  dateFormatter,
} from '@app/common/constants'
import {COUNTRIES} from '@app/common/countries'
import {
  useBuildCompetition,
  useCollageClips,
  useGuildIcons,
  useLinkedAccountsAppeal,
  usePauseFormSubmissions,
} from '@app/common/experiments'
import {useRequestsStore} from '@app/common/requests'
import {
  useCountryCodeStore,
  useDelayedFormsStore,
  useDraftStore,
  useOverlayStore,
  useSettingsStore,
} from '@app/common/stores'
import {createPopup, getAvatarUrl, hasFlag} from '@app/common/utils'
import {Indicator} from '@app/components/Indicator/Indicator'
import {FormField, FormFieldPreview} from '@app/components/Modal/FormInputs'
import {FormTypeToIcon} from '@app/components/Modal/FormTypeIcons'
import {HoldUp} from '@app/components/Modal/HoldUp'
import {InventoryBackup} from '@app/components/Modal/InventoryBackup'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {NavigationButton, NavigationButtonGrid} from '@app/components/Modal/NavigationButton'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {SequenceAnimation} from '@app/components/Sequencer/SequenceAnimation'
import {Sequencer} from '@app/components/Sequencer/Sequencer'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {useReplaceState} from '@app/hooks/useReplaceState'
import {Avatar, Box, Button, Checkbox, Flex, Grid, Heading, Link, Stack, Text} from '@chakra-ui/react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import axios from 'axios'
import {formatDistanceToNow} from 'date-fns'
import React from 'react'
import {FormProvider, useForm, useFormContext} from 'react-hook-form'
import {toast} from 'react-hot-toast'
import {FaDiscord, FaInfoCircle, FaXbox, FaYoutube} from 'react-icons/fa'
import {useInterval, useMediaQuery} from 'usehooks-ts'

export default function RequestsModal(): React.JSX.Element {
  const formType = useRequestsStore(state => state.formType)
  const methods = useForm({mode: 'onSubmit', reValidateMode: 'onSubmit'})
  const [requestsOpen, setRequestsOpen] = useOverlayStore(state => [state.requestsOpen, state.setRequestsOpen])

  return (
    <FormProvider {...methods}>
      <Modal
        footer={<RequestsModalFooter />}
        header={FormTypeToString[formType]}
        isOpen={requestsOpen}
        onClose={() => {
          setRequestsOpen(false)
          window.sessionStorage.reload = undefined
        }}
      >
        <Sequencer>
          <RequestsModalScreen />
        </Sequencer>
      </Modal>
    </FormProvider>
  )
}

function RequestsModalScreen(): React.JSX.Element {
  const formType = useRequestsStore(state => state.formType)
  const isMobile = useMediaQuery('(max-width: 768px)')
  useReplaceState(formType === FormType.WELCOME_SCREEN ? '/request' : `/request/${formType}`)

  // FIXME: Get rid of this hack -- on mobile all text inputs are broken unless a programmatic reload is done (kill me)
  React.useEffect(() => {
    if (!isMobile || window.sessionStorage.reload) return
    window.sessionStorage.reload = true
    window.location.reload()
    return () => {
      window.sessionStorage.reload = undefined
    }
  }, [isMobile])

  switch (formType) {
    case FormType.WELCOME_SCREEN: {
      return <Welcome />
    }

    case FormType.APPEAL_SCREEN: {
      return <Appeal />
    }

    case FormType.STAFF_APPLICATION_SCREEN: {
      return <ApplyStaff />
    }

    case FormType.BUG_REPORT_SCREEN: {
      return <BugReport />
    }

    case FormType.OTHER_HELP_SCREEN: {
      return <OtherHelp />
    }

    case FormType.FEEDBACK_SCREEN: {
      return <Feedback />
    }

    case FormType.FINISH_SCREEN: {
      return <Finish />
    }

    case FormType.SUBMISSIONS_SCREEN: {
      return <Submissions />
    }

    case FormType.PREVIEW_SCREEN: {
      return <Preview />
    }

    default: {
      return <FormRenderer />
    }
  }
}

function FooterSkeleton({
  onBack,
  onPrimary,
  primaryText = 'Next',
  disabled = false,
  disabledReason,
  submitting = false,
}: {
  onBack(): void
  onPrimary?(): void
  primaryText?: string
  disabled?: boolean
  disabledReason?: string
  submitting?: boolean
}): React.JSX.Element {
  return (
    <ModalFooter justifyContent="space-between" pt={3}>
      <Button colorScheme="gray" leftIcon={<ArrowLeftIcon height={16} width={16} />} onClick={onBack} variant="outline">
        Back
      </Button>
      {onPrimary && (
        <Tooltip label={disabledReason} padding={8} shouldShow={disabled && Boolean(disabledReason)}>
          <span>
            <Button colorScheme="orange" isDisabled={disabled} isLoading={submitting} ml={3} onClick={onPrimary}>
              {primaryText}
            </Button>
          </span>
        </Tooltip>
      )}
    </ModalFooter>
  )
}

function RequestsModalFooter(): React.JSX.Element | null {
  const bypassFormRequirements = useSettingsStore(state => state.bypassFormRequirements)
  const methods = useFormContext()
  const requests = useRequestsStore()
  const {data: formData} = useAPIForm(requests.formType)
  const setDraft = useDraftStore(state => state.setDraft)

  async function onSubmit(data: any): Promise<void> {
    try {
      await api.post(`/forms/${requests.formType}/submissions`, {
        fields: formData?.data.fields.map(field => ({...field, value: data[field.name]})),
      })
      setDraft(requests.formType, null)
      requests.navigate(FormType.FINISH_SCREEN)
    } catch (error) {
      if (axios.isAxiosError(error))
        if (error.response?.status === 400 && error.response?.data.code === INVALID_FORM_BODY)
          for (const issue of error.response?.data.errors ?? []) {
            if (issue.code === 'custom') methods.setError(issue.path[0], {message: issue.message})
          }
        else toast.error(error.response?.data.message ?? SOMETHING_WENT_WRONG)
      else toast.error(SOMETHING_WENT_WRONG)
    }
  }

  switch (requests.formType) {
    case FormType.WELCOME_SCREEN: {
      return null
    }

    case FormType.APPEAL_SCREEN:
    case FormType.STAFF_APPLICATION_SCREEN:
    case FormType.BUG_REPORT_SCREEN:
    case FormType.OTHER_HELP_SCREEN:
    case FormType.FEEDBACK_SCREEN:
    case FormType.SUBMISSIONS_SCREEN:
    case FormType.PREVIEW_SCREEN: {
      return <FooterSkeleton onBack={() => requests.back()} />
    }

    case FormType.FINISH_SCREEN: {
      return (
        <FooterSkeleton
          onBack={() => requests.back()}
          onPrimary={() => requests.navigate(FormType.SUBMISSIONS_SCREEN)}
          primaryText="Submissions"
        />
      )
    }

    default: {
      return requests.hasDismissedRequirements ? (
        <FooterSkeleton
          disabled={
            !requests.hasAgreedToTerms ||
            (APPLICATION_FORM_TYPES.has(requests.formType) && !requests.hasAgreedToPlagiarism)
          }
          disabledReason={
            requests.hasAgreedToTerms
              ? 'You must confirm this application is your original work to submit this request.'
              : 'You must agree to the Terms of Service and Privacy Policy to submit this request.'
          }
          onBack={() => requests.back()}
          onPrimary={methods.handleSubmit(onSubmit)}
          primaryText="Submit"
          submitting={methods.formState.isSubmitting}
        />
      ) : (
        <FooterSkeleton
          disabled={bypassFormRequirements ? false : !formData?.canDismissRequirements}
          disabledReason={formData ? 'You must meet all requirements to continue.' : 'Loading...'}
          onBack={() => requests.back()}
          onPrimary={() => requests.setHasDismissedRequirements(true)}
          primaryText="Continue"
        />
      )
    }
  }
}

const YOUTUBE_SYNC_MSG = 'Sync by pressing "Link YouTube Account."'

function FormRequirement({requirement}: {requirement: APIFormRequirementResponse}): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const {data: youtubeMetadata} = useYoutubeMetadata(YOUTUBE_REQUIREMENT_TYPES.has(requirement.type))
  const ipCountry = useCountryCodeStore(state => state.countryCode)

  function getTooltipMessage(): string {
    switch (requirement.type) {
      case FormRequirementType.YOUTUBE_SUBSCRIBER_COUNT: {
        return `You have ${youtubeMetadata?.subscriberCount ?? 0} subscribers. ${YOUTUBE_SYNC_MSG}`
      }

      case FormRequirementType.YOUTUBE_AVG_VIEW_COUNT: {
        return `Your average view count is ${youtubeMetadata?.avgViewCount ?? 0}. ${YOUTUBE_SYNC_MSG}`
      }

      case FormRequirementType.YOUTUBE_VIDEO_COUNT: {
        return `You have ${youtubeMetadata?.videoCount ?? 0} videos. ${YOUTUBE_SYNC_MSG}`
      }

      case FormRequirementType.CURRENT_OR_FORMER_STAFF: {
        if (!user) return ''
        return user.staff
          ? 'You are currently a staff member.'
          : hasFlag(user.flags, PlayerFlags.STAFF_ALUMNI)
            ? 'You are a former staff member.'
            : 'If you are a former staff member, contact an admin for approval.'
      }

      case FormRequirementType.IP_COUNTRY_RESTRICTED: {
        const label = COUNTRIES.find(country => country.value === ipCountry)!.label
        return requirement.value
          ? `${label} is a known restricted country.`
          : `${label} is not a known restricted country. Choose "Chat with us" if you believe it should be added.`
      }

      default: {
        return ''
      }
    }
  }

  const tooltipMessage = getTooltipMessage()
  return (
    <Stack
      align="center"
      as="li"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      letterSpacing="tight"
      px="3"
      py="2"
      rounded="lg"
      spacing="1.5"
    >
      <Box
        as={requirement.value ? CheckCircleIcon : XCircleIcon}
        boxSize="24px"
        color={requirement.value ? 'green.300' : 'red.300'}
        minW="24px"
      />
      <Stack spacing="0">
        <Text fontSize="md" fontWeight="semibold">
          {FormRequirementTypeToString[requirement.type]}
        </Text>
        {requirement.expiresAt && new Date(requirement.expiresAt) > new Date() && (
          <Text color="whiteAlpha.600" fontSize="sm" fontWeight="semibold" letterSpacing="tight">
            Try again on {dateFormatter.format(new Date(requirement.expiresAt!))} (in{' '}
            {formatDistanceToNow(new Date(requirement.expiresAt!))})
          </Text>
        )}
      </Stack>
      {tooltipMessage && (
        <Tooltip label={tooltipMessage}>
          <QuestionMarkCircleIcon height={24} width={24} />
        </Tooltip>
      )}
    </Stack>
  )
}

function FormRenderer(): React.JSX.Element {
  const requests = useRequestsStore()
  const {data: formData} = useAPIForm(requests.formType)
  const {data: user} = useCurrentUser()
  const {data: youtubeMetadata} = useYoutubeMetadata(formData?.isYoutubeRequired)
  const drafts = useDraftStore(state => state.drafts)
  const isFormDelayed = useDelayedFormsStore(state => state.isFormDelayed)
  const methods = useFormContext()
  const reset = useDraftStore(state => state.reset)
  const setDraft = useDraftStore(state => state.setDraft)

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  React.useEffect(() => {
    methods.reset(drafts[requests.formType] ?? {})
  }, [requests.formType])

  useInterval(() => {
    setDraft(requests.formType, methods.getValues())
  }, 1000)

  React.useEffect(() => {
    if (requests.hasDismissedRequirements) return
    if (!formData?.requirements) return
    if (formData.requirements.length === 0) {
      requests.setHasDismissedRequirements(true)
    }
  }, [requests.hasDismissedRequirements, formData?.requirements, requests])

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  React.useEffect(() => {
    if (!user) requests.navigate(FormType.WELCOME_SCREEN)
  }, [user])

  if (!formData)
    return (
      <SequenceAnimation key={requests.formType}>
        <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
          <Spinner />
        </ModalBody>
      </SequenceAnimation>
    )

  if (!requests.hasDismissedRequirements)
    return (
      <SequenceAnimation key={requests.formType}>
        <ModalBody as={Stack} gap="4px">
          {(formData.data.type === FormType.FACTIONS_ROLLBACK || formData.data.type === FormType.SKYBLOCK_ROLLBACK) && (
            <HoldUp>
              If approved, your backup items are added to your rollback storage (/rollback). Dropped items are removed
              from the server. You must be offline for the rollback to be performed.
            </HoldUp>
          )}

          {formData.data.type === FormType.LINKED_ACCOUNTS_APPEAL && (
            <HoldUp>
              We are experiencing a high volume of requests. Linked account appeals may take up to 30 days to be
              processed. Your patience is appreciated.
            </HoldUp>
          )}

          {(isFormDelayed(requests.formType) && formData.data.type !== FormType.LINKED_ACCOUNTS_APPEAL) ||
            formData.data.type === FormType.BUILDER_APPLICATION ||
            (formData.data.type === FormType.DEVELOPER_APPLICATION && (
              <HoldUp>
                We are experiencing a high volume of requests. It may take longer than usual to process your request.
                Your patience is appreciated.
              </HoldUp>
            ))}

          {formData.data.type === FormType.ACCOUNT_DELETION && (
            <HoldUp>
              This deletion request will be automatically processed in 14 days and after this point will be permanent
              and irreversible. You can cancel this request by contacting our staff team on Modmail through our Discord
              server at ngmc.co/discord before the request processes. An email will be sent to your Microsoft email
              address confirming you wish to delete your account.
            </HoldUp>
          )}

          <Text fontSize="md">
            You are {formData.canDismissRequirements ? '' : 'not '}eligible to submit a request!
          </Text>

          <Stack as="ul" gap="2px">
            {formData.requirements.map(requirement => (
              <FormRequirement key={requirement.type} requirement={requirement} />
            ))}
          </Stack>

          {formData.isConnectionRequired && (
            <Stack gap="4px">
              <SmallHeading>Prerequisites</SmallHeading>
              {formData.isDiscordRequired && (
                <>
                  <NavigationButton
                    bgColor="#5865f2"
                    leftIcon={<FaDiscord size={24} />}
                    onClick={async () =>
                      createPopup(async () => {
                        const {data} = await api.post<{url: string}>('/oauth/discord/authorize')
                        return data.url
                      })
                    }
                    rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
                    title="Link Discord Account"
                  />
                  {user?.discordTag ? (
                    <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
                      You are currently linked to <strong>{user.discordTag}</strong>.
                    </Text>
                  ) : (
                    <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
                      You have not linked a Discord account.
                    </Text>
                  )}
                </>
              )}

              {formData.isYoutubeRequired && (
                <>
                  <NavigationButton
                    bgColor="#c4302b"
                    leftIcon={<FaYoutube size={24} />}
                    onClick={async () =>
                      createPopup(async () => {
                        const {data} = await api.post<{url: string}>('/oauth/youtube/authorize')
                        return data.url
                      })
                    }
                    rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
                    title="Link YouTube Account"
                  />
                  {youtubeMetadata ? (
                    <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
                      You are currently linked to{' '}
                      <Link
                        color="orange.300"
                        fontWeight="bold"
                        href={`https://youtube.com/channel/${youtubeMetadata.channelId}`}
                        isExternal
                      >
                        this YouTube channel
                      </Link>
                      .
                    </Text>
                  ) : (
                    <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
                      You have not linked a YouTube account.
                    </Text>
                  )}
                  {formData.data.type === FormType.PARTNER_APPLICATION && (
                    <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
                      Only required if you're applying for a YouTube channel.
                    </Text>
                  )}
                </>
              )}
            </Stack>
          )}
        </ModalBody>
      </SequenceAnimation>
    )

  return (
    <SequenceAnimation key={requests.formType}>
      <ModalBody as={Stack} gap="8px">
        <Text color="whiteAlpha.800" fontSize="sm">
          Your form data is automatically saved in this browser, so you can come back to it later.{' '}
          <Link
            color="orange.300"
            fontWeight="bold"
            onClick={() => {
              reset()
              window.location.reload()
            }}
          >
            Clear all saved drafts in this browser?
          </Link>
        </Text>

        {formData.data.type === FormType.ACCOUNT_DELETION && (
          <HoldUp>
            This deletion request will be automatically processed in 14 days. You can cancel this request by contacting
            our staff team on Modmail through our Discord server at ngmc.co/discord. An email has also been sent to your
            Microsoft email address.
          </HoldUp>
        )}

        {(formData.data.type === FormType.COLLAGE_CLIPS || formData.data.type === FormType.BUILDING_COMPETITION) && (
          <HoldUp>
            Would you like to edit your previous submission? Send in a new one, and it will be silently overwritten.
          </HoldUp>
        )}

        {formData.data.type === FormType.DISCORD_REPORT && (
          <HoldUp>
            If you believe the user is violating the Discord Terms of Service or Community Guidelines, right-click the
            message and choose "Report."
          </HoldUp>
        )}

        {(formData.data.type === FormType.FACTIONS_ROLLBACK || formData.data.type === FormType.SKYBLOCK_ROLLBACK) && (
          <InventoryBackup formType={formData.data.type} inventoryId={methods.watch('rollback_inventory_id')} />
        )}

        <Stack gap="4px">
          {formData.data.fields.map(field => (
            <FormField control={methods.control} field={field} key={field.name} />
          ))}
        </Stack>

        <Checkbox
          isChecked={requests.hasAgreedToTerms}
          onChange={event => requests.setHasAgreedToTerms(event.target.checked)}
          size="lg"
        >
          <Text as="span" fontSize="sm" fontWeight="semibold" letterSpacing="tight">
            I agree to the{' '}
            <Link color="orange.300" href="https://ngmc.co/terms" isExternal>
              NetherGames Terms of Service
            </Link>{' '}
            and{' '}
            <Link color="orange.300" href="https://ngmc.co/privacy" isExternal>
              Privacy Policy
            </Link>
            , and certify that this form is submitted in good faith.
          </Text>
        </Checkbox>

        {APPLICATION_FORM_TYPES.has(formData.data.type) && (
          <Checkbox
            isChecked={requests.hasAgreedToPlagiarism}
            onChange={event => requests.setHasAgreedToPlagiarism(event.target.checked)}
            size="lg"
          >
            <Text as="span" fontSize="sm" fontWeight="semibold" letterSpacing="tight">
              I certify that this application does not contain AI-generated content or otherwise plagiarism.
            </Text>
          </Checkbox>
        )}
      </ModalBody>
    </SequenceAnimation>
  )
}

const SELF_CLOSE_FORM_TYPES = new Set([
  FormType.SERVER_BAN_APPEAL,
  FormType.LIVE_CHAT_BAN_APPEAL,
  FormType.DISCORD_BAN_APPEAL,
  FormType.MODMAIL_BAN_APPEAL,
  FormType.SERVER_MUTE_APPEAL,
  FormType.PLAYER_REPORT,
  FormType.FACTIONS_ROLLBACK,
  FormType.DISCORD_REPORT,
  FormType.GUILD_ICON_APPROVAL,
  FormType.SKYBLOCK_ROLLBACK,
])

function Preview(): React.JSX.Element | null {
  const [preview, navigate] = useRequestsStore(state => [state.preview, state.navigate])
  const [isClosing, setIsClosing] = React.useState(false)

  React.useEffect(() => {
    if (!preview) navigate(FormType.WELCOME_SCREEN)
  }, [preview, navigate])

  if (!preview) return null
  return (
    <SequenceAnimation key={FormType.PREVIEW_SCREEN}>
      <ModalBody as={Stack} gap="8px">
        <Text color="whiteAlpha.800" fontSize="md">
          {FormSubmissionStatusToDescription[preview.status]}
        </Text>

        {(preview.formType === FormType.COLLAGE_CLIPS || preview.formType === FormType.BUILDING_COMPETITION) && (
          <HoldUp>
            Would you like to edit your submission? Send in a new one, and it will be silently overwritten.
          </HoldUp>
        )}

        {preview.status === FormSubmissionStatus.PENDING && SELF_CLOSE_FORM_TYPES.has(preview.formType) && (
          <Stack gap="4px">
            <Stack spacing="4px">
              <SmallHeading>Something wrong?</SmallHeading>
              <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
                You can always close this form and submit a new one.
              </Text>
            </Stack>
            <Button
              colorScheme="red"
              isLoading={isClosing}
              onClick={async () => {
                setIsClosing(true)
                try {
                  await api.post(`/form-submissions/${preview.id}/close`)
                } catch {
                  toast.error('Something went wrong. Please try again later.')
                }

                setIsClosing(false)
                navigate(FormType.WELCOME_SCREEN)
              }}
              size="sm"
            >
              Close
            </Button>
          </Stack>
        )}

        {preview.reason && (
          <Stack gap="4px">
            <SmallHeading>Review Message</SmallHeading>
            <Box bg="#f97316" fontSize="sm" gap="4px" p={4} rounded="md">
              <Text fontSize="sm" letterSpacing="tight" overflow="hidden" userSelect="text" whiteSpace="break-spaces">
                {preview.reason.trim()}
              </Text>
            </Box>
          </Stack>
        )}

        {(preview.formType === FormType.FACTIONS_ROLLBACK || preview.formType === FormType.SKYBLOCK_ROLLBACK) && (
          <InventoryBackup
            formType={preview.formType}
            inventoryId={preview.fields.find(field => field.name === 'rollback_inventory_id')?.value as string}
          />
        )}

        <Stack gap="4px">
          {preview.fields.map(field => (
            <FormFieldPreview field={field} key={field.name} />
          ))}
        </Stack>
      </ModalBody>
    </SequenceAnimation>
  )
}

function JoinDiscordButton(): React.JSX.Element {
  return (
    <NavigationButton
      bgColor="#5865f2"
      leftIcon={<FaDiscord size={24} />}
      onClick={() => window.open('https://discord.gg/ng', '_blank')}
      rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
      title="Join our Discord server"
    />
  )
}

function usePaused(formType: FormType): string {
  const {data: user} = useCurrentUser()
  const pausedFormTypes = usePauseFormSubmissions()

  if (formType === FormType.SUBMISSIONS_SCREEN && !user)
    return 'You must be logged in to view your previous submissions.'

  if (INVALID_FORM_TYPES.has(formType)) return ''
  if (!user) return 'You must be logged in to submit a request.'

  if (
    formType !== FormType.SUBMISSIONS_SCREEN &&
    formType !== FormType.ACCOUNT_DELETION &&
    hasFlag(user.flags, PlayerFlags.SPAMMER)
  )
    return 'You are flagged for spam and may not submit more requests.'

  if (APPLICATION_FORM_TYPES.has(formType) && hasFlag(user.flags, PlayerFlags.APPLICATION_SPAMMER))
    return 'You are flagged for spam and may not submit more applications.'

  if (pausedFormTypes.includes(formType)) return 'This form is currently not accepting submissions.'

  return ''
}

function StepOption({formType}: {formType: FormType}): React.JSX.Element {
  const navigate = useRequestsStore(state => state.navigate)
  const LeftIcon = FormTypeToIcon[formType]
  const pausedReason = usePaused(formType)

  const button = (
    <NavigationButton
      _focusVisible={{bgColor: pausedReason ? 'gray.600' : '#ea580c'}}
      _hover={{bgColor: pausedReason ? 'gray.600' : '#ea580c'}}
      aria-disabled={Boolean(pausedReason)}
      bgColor={pausedReason ? 'gray.600' : '#f97316'}
      cursor={pausedReason ? 'not-allowed' : 'pointer'}
      leftIcon={LeftIcon && <LeftIcon height={24} size={24} width={24} />}
      onClick={pausedReason ? NOOP : () => navigate(formType)}
      opacity={pausedReason ? 0.85 : 1}
      rightIcon={<ArrowRightIcon height={24} width={24} />}
      title={FormTypeToTitle[formType]}
    />
  )

  return pausedReason ? (
    <Tooltip label={pausedReason}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  )
}

function StepOptionGrid({formType}: {formType: FormType}): React.JSX.Element {
  const navigate = useRequestsStore(state => state.navigate)
  const LeftIcon = FormTypeToIcon[formType]
  const pausedReason = usePaused(formType)

  const button = (
    <NavigationButtonGrid
      _focusVisible={{bgColor: pausedReason ? 'gray.600' : '#ea580c'}}
      _hover={{bgColor: pausedReason ? 'gray.600' : '#ea580c'}}
      aria-disabled={Boolean(pausedReason)}
      bgColor={pausedReason ? 'gray.600' : '#f97316'}
      cursor={pausedReason ? 'not-allowed' : 'pointer'}
      icon={LeftIcon && <LeftIcon height={24} size={24} width={24} />}
      onClick={pausedReason ? NOOP : () => navigate(formType)}
      opacity={pausedReason ? 0.85 : 1}
      title={FormTypeToTitle[formType]}
    />
  )

  return pausedReason ? (
    <Tooltip label={pausedReason}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  )
}

function WelcomeSteps(): React.JSX.Element {
  const guildIconsEnabled = useGuildIcons()
  const collageClipsEnabled = useCollageClips()
  const buildingCompetitionEnabled = useBuildCompetition()

  return (
    <>
      <StepOption formType={FormType.BUG_REPORT_SCREEN} />
      <StepOption formType={FormType.FEEDBACK_SCREEN} />
      <StepOption formType={FormType.APPEAL_SCREEN} />
      <StepOption formType={FormType.STAFF_APPLICATION_SCREEN} />
      <StepOption formType={FormType.PARTNER_APPLICATION} />
      <StepOption formType={FormType.YOUTUBE_APPLICATION} />
      <StepOption formType={FormType.PLAYER_REPORT} />
      <StepOption formType={FormType.DISCORD_REPORT} />
      <StepOption formType={FormType.FACTIONS_ROLLBACK} />
      <StepOption formType={FormType.SKYBLOCK_ROLLBACK} />
      <StepOption formType={FormType.BILLING_ISSUE} />
      <StepOption formType={FormType.BUG_BOUNTY} />
      <StepOption formType={FormType.VPN_ALLOWLIST} />
      <StepOption formType={FormType.INTEGRITY_REPORT} />
      <StepOption formType={FormType.FACTION_REPORT} />
      <StepOption formType={FormType.GUILD_REPORT} />
      {guildIconsEnabled && <StepOption formType={FormType.GUILD_ICON_APPROVAL} />}
      {collageClipsEnabled && <StepOption formType={FormType.COLLAGE_CLIPS} />}
      {buildingCompetitionEnabled && <StepOption formType={FormType.BUILDING_COMPETITION} />}
      <StepOption formType={FormType.ACCOUNT_DELETION} />
      <StepOption formType={FormType.OTHER_HELP_SCREEN} />
    </>
  )
}

function WelcomeStepsGrid(): React.JSX.Element {
  const guildIconsEnabled = useGuildIcons()
  const collageClipsEnabled = useCollageClips()
  const buildingCompetitionEnabled = useBuildCompetition()
  return (
    <Grid gap="10px" templateColumns="repeat(2, 1fr)">
      <StepOptionGrid formType={FormType.BUG_REPORT_SCREEN} />
      <StepOptionGrid formType={FormType.FEEDBACK_SCREEN} />
      <StepOptionGrid formType={FormType.APPEAL_SCREEN} />
      <StepOptionGrid formType={FormType.STAFF_APPLICATION_SCREEN} />
      <StepOptionGrid formType={FormType.PARTNER_APPLICATION} />
      <StepOptionGrid formType={FormType.YOUTUBE_APPLICATION} />
      <StepOptionGrid formType={FormType.PLAYER_REPORT} />
      <StepOptionGrid formType={FormType.DISCORD_REPORT} />
      <StepOptionGrid formType={FormType.FACTIONS_ROLLBACK} />
      <StepOptionGrid formType={FormType.SKYBLOCK_ROLLBACK} />
      <StepOptionGrid formType={FormType.BILLING_ISSUE} />
      <StepOptionGrid formType={FormType.BUG_BOUNTY} />
      <StepOptionGrid formType={FormType.VPN_ALLOWLIST} />
      <StepOptionGrid formType={FormType.INTEGRITY_REPORT} />
      <StepOptionGrid formType={FormType.FACTION_REPORT} />
      <StepOptionGrid formType={FormType.GUILD_REPORT} />
      {guildIconsEnabled && <StepOptionGrid formType={FormType.GUILD_ICON_APPROVAL} />}
      {collageClipsEnabled && <StepOptionGrid formType={FormType.COLLAGE_CLIPS} />}
      {buildingCompetitionEnabled && <StepOptionGrid formType={FormType.BUILDING_COMPETITION} />}
      <StepOptionGrid formType={FormType.ACCOUNT_DELETION} />
      <StepOptionGrid formType={FormType.OTHER_HELP_SCREEN} />
    </Grid>
  )
}

// const CRISP_URL = 'https://go.crisp.chat/chat/embed/?website_id=ac15f3f7-12a4-4eac-a670-b20fa788c429'
const SUPPORT_URL = 'https://support.nethergames.org'

function WelcomeBody(): React.JSX.Element {
  const isGrid = useMediaQuery('(min-width: 479px)')
  return (
    <Stack gap="8px">
      <Stack gap="4px">
        <SmallHeading>Your Enquiries</SmallHeading>
        <StepOption formType={FormType.SUBMISSIONS_SCREEN} />
      </Stack>

      <Stack gap="4px">
        <SmallHeading>New Enquiry</SmallHeading>
        {isGrid ? <WelcomeStepsGrid /> : <WelcomeSteps />}
      </Stack>
    </Stack>
  )
}

function Welcome(): React.JSX.Element {
  const {data: user} = useCurrentUser()
  return (
    <SequenceAnimation key={FormType.WELCOME_SCREEN}>
      <ModalBody as={Stack} color="whiteAlpha.800" fontSize="md" gap="8px" letterSpacing="tight" mb={1}>
        {user ? (
          <Text>
            Welcome, {user.name}! Please select the type of enquiry you would like to submit to our staff team.
          </Text>
        ) : (
          <>
            <Stack gap="4px">
              <Text>Welcome! You must be logged in to submit an enquiry.</Text>
              <NavigationButton
                bgColor="#0e7a0d"
                leftIcon={<FaXbox size={24} />}
                onClick={async () =>
                  createPopup(async () => {
                    const {data} = await api.post<{url: string}>('/oauth/xbox/authorize', {
                      redirect_uri: XBOX_REDIRECT_URI,
                    })
                    return data.url
                  })
                }
                rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
                title="Link Xbox Account"
              />
            </Stack>
            {/* <Stack gap="4px">
              <SmallHeading>Don't want to log in?</SmallHeading>
              <NavigationButton
                bgColor="#5865f2"
                leftIcon={<FaDiscord size={24} />}
                onClick={() => window.open('https://discord.gg/ng', '_blank')}
                rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
                title="Message us on Discord (avg 2 hour response)"
              />
              <NavigationButton
                bgColor="#f97316"
                leftIcon={<EnvelopeIcon height={24} width={24} />}
                onClick={() => {
                  window.open('mailto:help@nethergames.org?subject=My%20Support%20Request')
                }}
                rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
                title="Send us an email (avg 5 day response)"
              />
            </Stack> */}
          </>
        )}

        <Stack gap="8px">
          <WelcomeBody />
          <Stack gap="4px">
            {/* <SmallHeading>Not finding what you are looking for?</SmallHeading> */}
            {/* <NavigationButton
              bgColor="#5865f2"
              leftIcon={<FaDiscord size={24} />}
              onClick={() => window.open('https://discord.gg/ng', '_blank')}
              rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
              title="Contact staff via Discord"
            /> */}
            <NavigationButton
              bgColor="#1972f5"
              leftIcon={<FaInfoCircle size={24} />}
              onClick={() => window.open(SUPPORT_URL, '_blank')}
              rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
              title="Visit our helpdesk"
            />
          </Stack>
        </Stack>
      </ModalBody>
    </SequenceAnimation>
  )
}

function AppealSteps(): React.JSX.Element {
  const linkedAccountsAppealEnabled = useLinkedAccountsAppeal()
  return (
    <>
      <StepOption formType={FormType.SERVER_BAN_APPEAL} />
      <StepOption formType={FormType.SERVER_MUTE_APPEAL} />
      <StepOption formType={FormType.DISCORD_BAN_APPEAL} />
      <StepOption formType={FormType.MODMAIL_BAN_APPEAL} />
      {/* <StepOption formType={FormType.LIVE_CHAT_BAN_APPEAL} /> */}
      {linkedAccountsAppealEnabled && <StepOption formType={FormType.LINKED_ACCOUNTS_APPEAL} />}
    </>
  )
}

function AppealStepsGrid(): React.JSX.Element {
  const linkedAccountsAppealEnabled = useLinkedAccountsAppeal()
  return (
    <Grid gap="10px" templateColumns="repeat(2, 1fr)">
      <StepOptionGrid formType={FormType.SERVER_BAN_APPEAL} />
      <StepOptionGrid formType={FormType.SERVER_MUTE_APPEAL} />
      <StepOptionGrid formType={FormType.DISCORD_BAN_APPEAL} />
      <StepOptionGrid formType={FormType.MODMAIL_BAN_APPEAL} />
      {/* <StepOptionGrid formType={FormType.LIVE_CHAT_BAN_APPEAL} /> */}
      {linkedAccountsAppealEnabled && <StepOptionGrid formType={FormType.LINKED_ACCOUNTS_APPEAL} />}
    </Grid>
  )
}

function Appeal(): React.JSX.Element {
  const isGrid = useMediaQuery('(min-width: 479px)')
  return (
    <SequenceAnimation key={FormType.APPEAL_SCREEN}>
      <ModalBody as={Stack} gap="8px">
        <Text color="whiteAlpha.800" fontSize="md">
          Choose the type of appeal you would like to submit.
        </Text>
        <Stack gap="4px">{isGrid ? <AppealStepsGrid /> : <AppealSteps />}</Stack>
      </ModalBody>
    </SequenceAnimation>
  )
}

function ApplyStaffSteps(): React.JSX.Element {
  return (
    <>
      <StepOption formType={FormType.TRAINEE_APPLICATION} />
      <StepOption formType={FormType.BUILDER_APPLICATION} />
      {/* <StepOption formType={FormType.DESIGNER_APPLICATION} /> */}
      <StepOption formType={FormType.DEVELOPER_APPLICATION} />
      <StepOption formType={FormType.DISCORD_MOD_APPLICATION} />
      <StepOption formType={FormType.SUPPORT_REP_APPLICATION} />
      <StepOption formType={FormType.GAME_DESIGNER_APPLICATION} />
    </>
  )
}

function ApplyStaffStepsGrid(): React.JSX.Element {
  return (
    <Grid gap="10px" templateColumns="repeat(2, 1fr)">
      <StepOptionGrid formType={FormType.TRAINEE_APPLICATION} />
      <StepOptionGrid formType={FormType.BUILDER_APPLICATION} />
      {/*       <StepOptionGrid formType={FormType.DESIGNER_APPLICATION} /> */}
      <StepOptionGrid formType={FormType.DEVELOPER_APPLICATION} />
      <StepOptionGrid formType={FormType.DISCORD_MOD_APPLICATION} />
      <StepOptionGrid formType={FormType.SUPPORT_REP_APPLICATION} />
      <StepOptionGrid formType={FormType.GAME_DESIGNER_APPLICATION} />
    </Grid>
  )
}

function ApplyStaff(): React.JSX.Element {
  const isGrid = useMediaQuery('(min-width: 479px)')
  return (
    <SequenceAnimation key={FormType.STAFF_APPLICATION_SCREEN}>
      <ModalBody as={Stack} gap="8px">
        <Text color="whiteAlpha.800" fontSize="md">
          Choose the staff position you would like to apply for. See{' '}
          <Link color="orange.300" fontWeight="semibold" href="https://ngmc.co/jobs" isExternal>
            our jobs page
          </Link>{' '}
          for more information.
        </Text>
        <Stack gap="4px">{isGrid ? <ApplyStaffStepsGrid /> : <ApplyStaffSteps />}</Stack>
      </ModalBody>
    </SequenceAnimation>
  )
}

function BugReport(): React.JSX.Element {
  return (
    <SequenceAnimation key={FormType.BUG_REPORT_SCREEN}>
      <ModalBody as={Stack} gap="8px">
        <Text color="whiteAlpha.800" fontSize="md">
          We only accept bug reports through the #bugs channel in our Discord server. Use the /bug command in #bots to
          submit a bug report.
        </Text>
        <JoinDiscordButton />
      </ModalBody>
    </SequenceAnimation>
  )
}

function Feedback(): React.JSX.Element {
  return (
    <SequenceAnimation key={FormType.FEEDBACK_SCREEN}>
      <ModalBody as={Stack} gap="8px">
        <Text color="whiteAlpha.800" fontSize="md">
          We only accept suggestions through the #feedback channel in our Discord server.
        </Text>
        <JoinDiscordButton />
      </ModalBody>
    </SequenceAnimation>
  )
}

function OtherHelp(): React.JSX.Element {
  const {data: user} = useCurrentUser()
  return (
    <SequenceAnimation key={FormType.OTHER_HELP_SCREEN}>
      <ModalBody as={Stack} gap="8px">
        {user ? (
          <>
            <Text color="whiteAlpha.800" fontSize="md">
              Can't find a category to solve your issue? You can message us via #support on our Discord server.
            </Text>
            <NavigationButton
              bgColor="#5865f2"
              leftIcon={<FaDiscord size={24} />}
              onClick={() => window.open('https://discord.gg/ng', '_blank')}
              rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
              title="Message us on Discord"
            />
          </>
        ) : (
          <>
            <Text color="whiteAlpha.800" fontSize="md">
              If you don't have an account and need support, you can message us via #support on our Discord server.
            </Text>
            <NavigationButton
              bgColor="#5865f2"
              leftIcon={<FaDiscord size={24} />}
              onClick={() => window.open('https://discord.gg/ng', '_blank')}
              rightIcon={<ArrowTopRightOnSquareIcon height={24} width={24} />}
              title="Message us on Discord"
            />
            <Text color="whiteAlpha.800" fontSize="sm">
              Don't have Discord? Click
              <a href="mailto:help@nethergames.org?subject=My%20Support%20Request" rel="noreferrer" target="_blank">
                {' '}
                here{' '}
              </a>
              to send us an email.
            </Text>
          </>
        )}
      </ModalBody>
    </SequenceAnimation>
  )
}

function Finish(): React.JSX.Element | null {
  const {data: user} = useCurrentUser()
  if (!user) return null

  return (
    <SequenceAnimation key={FormType.FINISH_SCREEN}>
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16} textAlign="center">
        <ShieldCheckIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
        <Heading fontSize="2xl" fontWeight="bold">
          Thank you!
        </Heading>
        <Text color="whiteAlpha.800" fontSize="md">
          A staff member will get back to you within 7 days.
        </Text>
        <Text color="whiteAlpha.800" fontSize="md">
          We will notify you at <strong>{user.email}</strong>, and you can always check the Submissions tab manually.
        </Text>
      </ModalBody>
    </SequenceAnimation>
  )
}

function Submission({
  submission,
  player,
  reviewer,
  onClick,
}: {
  submission: APIFormSubmissionResponse
  player?: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>
  reviewer?: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>
  onClick(): void
}): React.JSX.Element {
  const LeftIcon = FormTypeToIcon[submission.formType]
  const isSimilars = 'score' in submission

  return (
    <Stack
      align="center"
      bg={isSimilars ? 'gray.800' : FormSubmissionStatusToBg[submission.status]}
      boxShadow="lg"
      direction="row"
      justify="space-between"
      onClick={onClick}
      p={4}
      pos="relative"
      role="button"
      rounded="lg"
      tabIndex={0}
      transition="ease-in-out 200ms"
    >
      <Stack align="center" direction="row" spacing="12px">
        <Flex flex="1 0 auto">{LeftIcon && <LeftIcon height={24} size={24} width={24} />}</Flex>

        <Stack spacing="1">
          <Stack align="center" direction="row" spacing="1.5">
            <Heading isTruncated size="sm">
              {FormTypeToString[submission.formType]}
            </Heading>
            <Indicator variant="inverted">{FormSubmissionStatusToString[submission.status]}</Indicator>
          </Stack>

          <Stack spacing="0">
            <Text color="whiteAlpha.800" fontSize="sm">
              {dateFormatter.format(new Date(submission.reviewedAt ?? submission.timestamp))}
            </Text>
          </Stack>

          {player && (
            <Stack align="center" direction="row" spacing="1.5">
              <Avatar name={player.name} size="xs" src={getAvatarUrl(player.skinHash)} />
              <Text color="whiteAlpha.800" fontSize="sm" fontWeight="semibold">
                {player.name}
              </Text>
            </Stack>
          )}

          {reviewer && player?.xuid !== reviewer.xuid && (
            <Stack align="center" direction="row" spacing="1.5">
              <Avatar name={reviewer.name} size="xs" src={getAvatarUrl(reviewer.skinHash)} />
              <Text color="whiteAlpha.800" fontSize="sm" fontWeight="semibold">
                {reviewer.name}
              </Text>
              <Indicator variant="inverted">Reviewer</Indicator>
            </Stack>
          )}
        </Stack>
      </Stack>

      {isSimilars && (
        <Box
          bg={submission.score! >= 50 ? 'red.500' : 'yellow.300'}
          color={submission.score! >= 50 ? 'white' : 'gray.800'}
          fontSize="sm"
          fontWeight="extrabold"
          p={1}
          pos="absolute"
          right={-2}
          rounded="lg"
          top={-2}
        >
          {submission.score}%
        </Box>
      )}

      <ArrowRightIcon height={24} width={24} />
    </Stack>
  )
}

function Submissions(): React.JSX.Element {
  const [navigate, setPreview] = useRequestsStore(state => [state.navigate, state.setPreview])
  const {data: submissions} = useAPIFormSubmissions()
  if (!submissions)
    return (
      <SequenceAnimation key={FormType.SUBMISSIONS_SCREEN}>
        <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
          <Spinner />
        </ModalBody>
      </SequenceAnimation>
    )

  if (submissions.length === 0)
    return (
      <SequenceAnimation key={FormType.SUBMISSIONS_SCREEN}>
        <ModalBody align="center" as={Stack} h="full" justify="center" my={16} textAlign="center">
          <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
          <Heading fontSize="2xl" fontWeight="bold">
            *cricket noises*
          </Heading>
          <Text color="whiteAlpha.800" fontSize="md">
            You have not submitted any requests yet.
          </Text>
        </ModalBody>
      </SequenceAnimation>
    )

  return (
    <SequenceAnimation key={FormType.SUBMISSIONS_SCREEN}>
      <ModalBody as={Stack} gap="8px">
        <Text color="whiteAlpha.800" fontSize="md">
          These are your previously submitted requests.
        </Text>
        <Stack gap="4px">
          {submissions.map(submission => (
            <Submission
              key={submission.id}
              onClick={() => {
                setPreview(submission)
                navigate(FormType.PREVIEW_SCREEN)
              }}
              submission={submission}
            />
          ))}
        </Stack>
      </ModalBody>
    </SequenceAnimation>
  )
}
