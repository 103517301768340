import type {PunishmentProperties} from '@app/common/api'
import {PlayerPunishmentIndicator} from '@app/components/Member/MemberList'
import {Heading, Stack} from '@chakra-ui/react'
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/solid'
import type React from 'react'
import {Link as RouterLink} from 'react-router-dom'

export function PlayerInfo({
  player,
  isDark = false,
  punishment,
}: {
  player: string
  isDark?: boolean
  punishment?: PunishmentProperties | undefined
}): React.JSX.Element {
  return (
    <Stack
      align="center"
      as={RouterLink}
      bgColor={isDark ? 'gray.800' : 'gray.700'}
      boxShadow="lg"
      direction="row"
      justify="space-between"
      p="16px"
      rounded="lg"
      target="_blank"
      to={`/player/${player}`}
    >
      <Stack align="center" direction="row" spacing="8px">
        <Heading fontSize="md">{player}</Heading>
        {punishment && <PlayerPunishmentIndicator {...punishment} iconSize={16} />}
      </Stack>
      <ArrowTopRightOnSquareIcon height={24} width={24} />
    </Stack>
  )
}
