import styles from '@app/components/Search/SearchBar.module.css'
import {useSearch} from '@app/hooks/useSearch'
import {SearchIcon} from '@chakra-ui/icons'
import {FormControl, FormErrorMessage, Input, InputGroup, InputLeftElement} from '@chakra-ui/react'
import clsx from 'clsx'
import React from 'react'
import toast from 'react-hot-toast'

type SearchBarProps = {
  variant: 'headerMobile' | 'main'
  onSuccessfulSearch?(): void
  isOverlay?: boolean
}

export function SearchBar(props: SearchBarProps): React.JSX.Element {
  const search = useSearch(props.onSuccessfulSearch)

  React.useEffect(() => {
    if (props.variant !== 'headerMobile') return
    if (!search.formState.errors.name?.message) return
    if (typeof search.formState.errors.name.message !== 'string') return
    toast.error(search.formState.errors.name.message.toString(), {id: 'search_error', position: 'bottom-center'})
  }, [props.variant, search.formState])

  return (
    <form
      className={clsx(styles.container, {
        [styles.main as string]: props.variant === 'main',
        [styles.headerMobile as string]: props.variant === 'headerMobile',
      })}
      onSubmit={search.handleSubmit(search.onSubmit as any)}
    >
      <FormControl isInvalid={Boolean(search.formState.errors.name)}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" fontSize={['sm', 'md']} />
          </InputLeftElement>

          <Input
            autoComplete="off"
            bgColor={props.isOverlay ? 'gray.800' : 'black'}
            fontSize={['sm', 'md']}
            maxLength={255}
            minW="full"
            placeholder="Enter a Player, Guild or Faction..."
            rounded={props.isOverlay ? 'xl' : 'full'}
            type="text"
            {...search.register('name', {
              required: "Please enter something at least. I can't guess what you want!",
            })}
          />
        </InputGroup>

        {props.variant === 'main' && (
          <FormErrorMessage>{search.formState.errors.name?.message?.toString()}</FormErrorMessage>
        )}
      </FormControl>
    </form>
  )
}
