import {FactionRole, queryClient} from '@app/common/api'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button} from '@chakra-ui/react'
import React from 'react'

type ModalProps = {
  isOpen: boolean
  onClose(): void
  type: 'faction' | 'guild'
  name: string
  xuid: string

  player: string
  role: FactionRole | null
}

function getTitle({player, role}: {player: string; role: FactionRole | null}): string {
  switch (role) {
    case FactionRole.LEADER: {
      return `Transfer Leadership to '${player}'`
    }

    case FactionRole.OFFICER: {
      return `Promote '${player}' to Officer`
    }

    case FactionRole.MEMBER: {
      return `Demote '${player}' to Member`
    }

    case null: {
      return `Kick '${player}'`
    }
  }
}

function getDescription({
  player,
  role,
  type,
}: {
  player: string
  role: FactionRole | null
  type: 'faction' | 'guild'
}): string {
  switch (role) {
    case FactionRole.LEADER: {
      return `Are you sure you want to transfer leadership to '${player}'?`
    }

    case FactionRole.OFFICER: {
      return `Are you sure you want to promote '${player}' to officer?`
    }

    case FactionRole.MEMBER: {
      return `Are you sure you want to demote '${player}' to member?`
    }

    case null: {
      return `Are you sure you want to kick '${player}' from this ${type}?`
    }
  }
}

export function MemberUpdateModal(props: ModalProps): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)

  async function onSubmit(event: any): Promise<void> {
    event.preventDefault()
    setIsLoading(true)

    await (props.role == null
      ? api.delete(`/${props.type}s/${props.name}/members/${props.xuid}`)
      : api.patch(`/${props.type}s/${props.name}/members/${props.xuid}`, {role: props.role}))
    await queryClient.invalidateQueries({queryKey: [`${props.type}s`, props.name]})
    setIsLoading(false)
    props.onClose()
  }

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button colorScheme="gray" onClick={props.onClose} variant="ghost">
            Cancel
          </Button>
          <Button colorScheme="red" isLoading={isLoading} ml={3} onClick={async event => onSubmit(event)}>
            Submit
          </Button>
        </ModalFooter>
      }
      header={getTitle(props)}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody fontSize="md">{getDescription(props)}</ModalBody>
    </Modal>
  )
}
