import type {APIApplication} from '@app/common/api'
import {getErrorMessage} from '@app/common/utils'
import {HoldUp} from '@app/components/Modal/HoldUp'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Textarea} from '@app/components/UI/Textarea'
import {api} from '@app/hooks/useApi'
import {Button, FormControl, FormErrorMessage, FormLabel, Input} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {useForm} from 'react-hook-form'
import {toast} from 'react-hot-toast'

type ModalProps = {
  isOpen: boolean
  onClose(): void
}

export function ApplicationCreateModal(props: ModalProps): React.JSX.Element {
  const queryClient = useQueryClient()
  const [characterCount, setCharacterCount] = React.useState(0)
  const {formState, handleSubmit, register, setError, watch, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {name: '', description: ''},
  })

  React.useEffect(() => {
    if (!props.isOpen) reset({name: '', description: ''})
  }, [props.isOpen, reset])
  React.useEffect(() => {
    const subscription = watch(value => setCharacterCount(value.description?.length ?? 0))
    return () => subscription.unsubscribe()
  }, [watch])

  async function onSubmit(data: {name: string; description: string}): Promise<void> {
    try {
      const {data: application} = await api.post<APIApplication>('applications', data)
      await queryClient.invalidateQueries({queryKey: ['applications']})
      toast.success(`Created the ${application.name} integration`, {id: 'application_created'})
      props.onClose()
    } catch (error) {
      setError('name', {type: 'validate', message: getErrorMessage(error, 'name')})
      setError('description', {type: 'validate', message: getErrorMessage(error, 'description')})
    }
  }

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button isLoading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
            Create
          </Button>
        </ModalFooter>
      }
      header="Create API Integration"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody fontSize="md">
        <HoldUp>This is for API integrations only, you cannot use it for support tickets or other purposes.</HoldUp>

        <FormControl isInvalid={Boolean(formState.errors.name?.message)}>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input
            autoFocus
            id="name"
            maxLength={32}
            minLength={2}
            placeholder="My Awesome Integration"
            {...register('name', {required: true})}
          />
          <FormErrorMessage>{formState.errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(formState.errors.description?.message)} mt={4}>
          <FormLabel htmlFor="description">Description</FormLabel>
          <Textarea
            characterCount={characterCount}
            id="description"
            maxLength={255}
            placeholder="Please describe how your integration uses our API in a few sentences."
            {...register('description', {required: true})}
          />
          <FormErrorMessage>{formState.errors.description?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>
    </Modal>
  )
}
