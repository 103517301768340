import type {PunishmentReason} from '@app/common/api'
import {useAdminListPunishmentReasons} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {Indicator} from '@app/components/Indicator/Indicator'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {PencilSquareIcon, PlusIcon, TrashIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {toast} from 'react-hot-toast'

export default function AdminPunishmentReasonsModal(): React.JSX.Element {
  const [adminPunishmentReasonsOpen, setAdminPunishmentReasonsOpen] = useOverlayStore(state => [
    state.adminPunishmentReasonsOpen,
    state.setAdminPunishmentReasonsOpen,
  ])

  return (
    <Modal
      header="Punishment Reasons"
      isOpen={adminPunishmentReasonsOpen as boolean}
      onClose={() => setAdminPunishmentReasonsOpen(false)}
    >
      <AdminPunishmentReasonsModalContent />
    </Modal>
  )
}

function AdminPunishmentReasonsModalContent(): React.JSX.Element {
  const createModal = useDisclosure()
  const {data: punishmentReasons} = useAdminListPunishmentReasons()
  const mappedReasons: {[key: string]: PunishmentReason[]} = {}

  if (punishmentReasons)
    for (const reason of punishmentReasons) {
      if (mappedReasons && mappedReasons[reason.category] !== undefined) {
        mappedReasons[reason.category]!.push(reason)
      } else {
        mappedReasons[reason.category] = [reason]
      }
    }
  return (
    <ModalBody as={Stack} gap="8px">
      <ReasonCreateEditModal {...createModal} editingPrefill={null} />
      <Button
        colorScheme="orange"
        leftIcon={<PlusIcon height={16} width={16} />}
        onClick={() => createModal.onOpen()}
        size="sm"
      >
        Create Punishment Reason
      </Button>
      {Object.entries(mappedReasons).map(([category, categories]) => (
        <ReasonGroupRenderer key={`category-${category}`} name={category} reasons={categories} />
      ))}
    </ModalBody>
  )
}

type ReasonType = 'BAN' | 'MUTE'

function ReasonCreateEditModal({
  isOpen,
  onClose,
  editingPrefill,
}: {
  isOpen: boolean
  onClose(): void
  editingPrefill: PunishmentReason | null
}): React.JSX.Element {
  const queryClient = useQueryClient()
  const [type, setType] = React.useState<ReasonType>(editingPrefill ? editingPrefill.type : 'BAN')
  const [points, setPoints] = React.useState(editingPrefill ? editingPrefill.points : 0)
  const [adminOnly, setAdminOnly] = React.useState(editingPrefill ? editingPrefill.adminOnly : false)
  const [internalOnly, setInternalOnly] = React.useState(editingPrefill ? editingPrefill.internalOnly : false)
  const [hasRollback, setHasRollback] = React.useState(editingPrefill ? editingPrefill.hasRollback : false)
  const [nonAppealable, setNonAppealable] = React.useState(editingPrefill ? editingPrefill.nonAppealable : false)
  const [flagRecentEvidence, setFlagRecentEvidence] = React.useState(
    editingPrefill ? editingPrefill.flagRecentEvidence : false,
  )

  const [category, setCategory] = React.useState(editingPrefill ? editingPrefill.category : '')
  const [name, setName] = React.useState(editingPrefill ? editingPrefill.name : '')
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!category || !name}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await (editingPrefill
                  ? api.patch('/admin/punishment-reasons', {
                      name,
                      category,
                      type,
                      points,
                      adminOnly,
                      internalOnly,
                      hasRollback,
                      nonAppealable,
                      flagRecentEvidence,
                    })
                  : api.put('/admin/punishment-reasons', {
                      name,
                      category,
                      type,
                      points,
                      adminOnly,
                      internalOnly,
                      hasRollback,
                      nonAppealable,
                      flagRecentEvidence,
                    }))

                await queryClient.invalidateQueries({queryKey: ['admin', 'list', 'punishmentReasons']})
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            {editingPrefill ? 'Update' : 'Create'}
          </Button>
        </ModalFooter>
      }
      header={`${editingPrefill ? 'Update' : 'Create'} Punishment Reason`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup onChange={value => setType(value as ReasonType)} value={type.toString()}>
            <Stack>
              {['BAN', 'MUTE'].map(key => (
                <Radio key={key} value={key}>
                  {key}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input onChange={event => setName(event.target.value)} placeholder="KillAura" value={name} />
        </FormControl>

        <FormControl>
          <FormLabel>Category</FormLabel>
          <Input onChange={event => setCategory(event.target.value)} placeholder="Cheating" value={category} />
        </FormControl>

        <FormControl>
          <FormLabel>Punishment Points</FormLabel>
          <Input onChange={event => setPoints(Number.parseInt(event.target.value))} type="number" value={points} />
        </FormControl>

        <FormControl>
          <FormLabel>Admin Only</FormLabel>
          <Checkbox isChecked={adminOnly} onChange={event => setAdminOnly(event.target.checked)}>
            Yes
          </Checkbox>
          <FormLabel>Internal Only</FormLabel>
          <Checkbox isChecked={internalOnly} onChange={event => setInternalOnly(event.target.checked)}>
            Yes
          </Checkbox>
          <FormLabel>Has Rollback</FormLabel>
          <Checkbox isChecked={hasRollback} onChange={event => setHasRollback(event.target.checked)}>
            Yes
          </Checkbox>
          <FormLabel>Is not appealable</FormLabel>
          <Checkbox isChecked={nonAppealable} onChange={event => setNonAppealable(event.target.checked)}>
            Yes
          </Checkbox>
          <FormLabel>Flag Recent Evidence</FormLabel>
          <Checkbox isChecked={flagRecentEvidence} onChange={event => setFlagRecentEvidence(event.target.checked)}>
            Yes
          </Checkbox>
        </FormControl>
      </ModalBody>
    </Modal>
  )
}

function ReasonRenderer({reason}: {reason: PunishmentReason}): React.JSX.Element {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const queryClient = useQueryClient()
  const updateModal = useDisclosure()

  return (
    <Stack
      align="center"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
    >
      <ReasonCreateEditModal {...updateModal} editingPrefill={reason} />

      <Stack align="center" direction="row" flex="1" spacing="16px">
        <Stack align={'1'}>
          <Stack align="center" direction="row" spacing="1.5">
            <Heading isTruncated size="sm">
              {reason.name}
            </Heading>
            <Indicator variant="inverted">{reason.type}</Indicator>
          </Stack>
          <Text fontWeight="light">
            {reason.points} Points{reason.hasRollback ? ' | Rollback Enabled' : ''}
            {reason.adminOnly ? ' | Admin Only' : ''}
            {reason.internalOnly ? ' | Internals Only' : ''}{' '}
          </Text>
        </Stack>
      </Stack>

      <Stack direction="row" spacing="1.5">
        <Tooltip label="Delete Reason">
          <IconButton
            aria-label="Delete Reason"
            colorScheme="red"
            icon={<TrashIcon height={16} width={16} />}
            isLoading={isDeleting}
            onClick={async () => {
              try {
                setIsDeleting(true)
                await api.delete('/admin/punishment-reasons', {
                  params: {
                    name: reason.name,
                  },
                })
                await queryClient.invalidateQueries({queryKey: ['admin', 'list', 'punishmentReasons']})
              } catch {
                toast.error('Failed to delete reason')
              } finally {
                setIsDeleting(false)
              }
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>

        <Tooltip label="Update Reason">
          <IconButton
            aria-label="Update Reason"
            colorScheme="orange"
            icon={<PencilSquareIcon height={16} width={16} />}
            onClick={() => updateModal.onOpen()}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}

function ReasonGroupRenderer({name, reasons}: {name: string; reasons: PunishmentReason[]}) {
  return (
    <Stack spacing="8px">
      <SmallHeading>
        {name} — {reasons?.length ?? 0}
      </SmallHeading>
      {reasons.map(reason => (
        <ReasonRenderer key={`category-${name}`} reason={reason} />
      ))}
    </Stack>
  )
}
