import type {APIFormSubmissionResponse} from '@app/common/api'
import {FormType} from '@app/common/constants'
import {devtools} from 'zustand/middleware'
import {createWithEqualityFn as create} from 'zustand/traditional'

export enum Direction {
  BACK = -1,
  DEFAULT = 0,
  NEXT = 1,
}

const CategoryToFormTypes: Record<FormType.APPEAL_SCREEN | FormType.STAFF_APPLICATION_SCREEN, FormType[]> = {
  [FormType.APPEAL_SCREEN]: [
    FormType.SERVER_BAN_APPEAL,
    FormType.LIVE_CHAT_BAN_APPEAL,
    FormType.DISCORD_BAN_APPEAL,
    FormType.MODMAIL_BAN_APPEAL,
    FormType.SERVER_MUTE_APPEAL,
    FormType.LINKED_ACCOUNTS_APPEAL,
  ],
  [FormType.STAFF_APPLICATION_SCREEN]: [
    FormType.BUILDER_APPLICATION,
    FormType.DESIGNER_APPLICATION,
    FormType.DEVELOPER_APPLICATION,
    FormType.DISCORD_MOD_APPLICATION,
    FormType.SUPPORT_REP_APPLICATION,
    FormType.GAME_DESIGNER_APPLICATION,
    FormType.TRAINEE_APPLICATION,
  ],
}

const FormTypeToCategory: Record<FormType, FormType> = {
  [FormType.SERVER_BAN_APPEAL]: FormType.APPEAL_SCREEN,
  [FormType.LIVE_CHAT_BAN_APPEAL]: FormType.APPEAL_SCREEN,
  [FormType.DISCORD_BAN_APPEAL]: FormType.APPEAL_SCREEN,
  [FormType.MODMAIL_BAN_APPEAL]: FormType.APPEAL_SCREEN,
  [FormType.SERVER_MUTE_APPEAL]: FormType.APPEAL_SCREEN,
  [FormType.LINKED_ACCOUNTS_APPEAL]: FormType.APPEAL_SCREEN,
  [FormType.BUILDER_APPLICATION]: FormType.STAFF_APPLICATION_SCREEN,
  [FormType.DESIGNER_APPLICATION]: FormType.STAFF_APPLICATION_SCREEN,
  [FormType.DEVELOPER_APPLICATION]: FormType.STAFF_APPLICATION_SCREEN,
  [FormType.DISCORD_MOD_APPLICATION]: FormType.STAFF_APPLICATION_SCREEN,
  [FormType.SUPPORT_REP_APPLICATION]: FormType.STAFF_APPLICATION_SCREEN,
  [FormType.GAME_DESIGNER_APPLICATION]: FormType.STAFF_APPLICATION_SCREEN,
  [FormType.TRAINEE_APPLICATION]: FormType.STAFF_APPLICATION_SCREEN,
  [FormType.PARTNER_APPLICATION]: FormType.WELCOME_SCREEN,
  [FormType.YOUTUBE_APPLICATION]: FormType.WELCOME_SCREEN,
  [FormType.PLAYER_REPORT]: FormType.WELCOME_SCREEN,
  [FormType.FACTIONS_ROLLBACK]: FormType.WELCOME_SCREEN,
  [FormType.BILLING_ISSUE]: FormType.WELCOME_SCREEN,
  [FormType.BUG_BOUNTY]: FormType.WELCOME_SCREEN,
  [FormType.DISCORD_REPORT]: FormType.WELCOME_SCREEN,
  [FormType.VPN_ALLOWLIST]: FormType.WELCOME_SCREEN,
  [FormType.GUILD_ICON_APPROVAL]: FormType.WELCOME_SCREEN,
  [FormType.ACCOUNT_DELETION]: FormType.WELCOME_SCREEN,
  [FormType.COLLAGE_CLIPS]: FormType.WELCOME_SCREEN,
  [FormType.SKYBLOCK_ROLLBACK]: FormType.WELCOME_SCREEN,
  [FormType.BUILDING_COMPETITION]: FormType.WELCOME_SCREEN,
  [FormType.INTEGRITY_REPORT]: FormType.WELCOME_SCREEN,
  [FormType.FACTION_REPORT]: FormType.WELCOME_SCREEN,
  [FormType.GUILD_REPORT]: FormType.WELCOME_SCREEN,

  // invalid types
  [FormType.WELCOME_SCREEN]: FormType.WELCOME_SCREEN,
  [FormType.APPEAL_SCREEN]: FormType.WELCOME_SCREEN,
  [FormType.STAFF_APPLICATION_SCREEN]: FormType.WELCOME_SCREEN,
  [FormType.BUG_REPORT_SCREEN]: FormType.WELCOME_SCREEN,
  [FormType.OTHER_HELP_SCREEN]: FormType.WELCOME_SCREEN,
  [FormType.FEEDBACK_SCREEN]: FormType.WELCOME_SCREEN,
  [FormType.FINISH_SCREEN]: FormType.WELCOME_SCREEN,
  [FormType.SUBMISSIONS_SCREEN]: FormType.WELCOME_SCREEN,
  [FormType.PREVIEW_SCREEN]: FormType.SUBMISSIONS_SCREEN,
}

type RequestsStore = {
  direction: Direction
  formType: FormType
  hasAgreedToPlagiarism: boolean
  hasAgreedToTerms: boolean
  hasDismissedRequirements: boolean
  preview: APIFormSubmissionResponse | null

  back(): void
  navigate(step: FormType): void
  setHasAgreedToPlagiarism(value: boolean): void
  setHasAgreedToTerms(value: boolean): void
  setHasDismissedRequirements(value: boolean): void
  setPreview(value: APIFormSubmissionResponse | null): void
}

export const useRequestsStore = create<RequestsStore>()(
  devtools(
    (set, get) => ({
      direction: Direction.DEFAULT,
      formType: FormType.WELCOME_SCREEN,
      hasAgreedToPlagiarism: false,
      hasAgreedToTerms: false,
      hasDismissedRequirements: false,
      preview: null,

      back: () => {
        set(requests => ({...requests, direction: Direction.BACK}))
        const currentFormType = get().formType as keyof typeof CategoryToFormTypes
        const currentIndex = CategoryToFormTypes[currentFormType]?.indexOf(currentFormType)

        if (currentIndex == null) {
          const currentCategory = FormTypeToCategory[currentFormType]
          get().navigate(currentCategory)
          return
        }

        const previousIndex = currentIndex - 1
        const previousStep = CategoryToFormTypes[currentFormType][previousIndex]
        get().navigate(previousStep ?? FormType.WELCOME_SCREEN)
      },

      navigate: (step: FormType) => {
        set(requests => ({
          ...requests,
          direction: step > requests.formType ? Direction.NEXT : Direction.BACK,
          formType: step,
          hasAgreedToTerms: false,
          hasDismissedRequirements: false,
        }))
      },

      setHasAgreedToPlagiarism: (value: boolean) => set(requests => ({...requests, hasAgreedToPlagiarism: value})),
      setHasAgreedToTerms: (value: boolean) => set(requests => ({...requests, hasAgreedToTerms: value})),
      setHasDismissedRequirements: (value: boolean) =>
        set(requests => ({...requests, hasDismissedRequirements: value})),
      setPreview: (value: APIFormSubmissionResponse | null) => set(requests => ({...requests, preview: value})),
    }),
    {name: 'RequestsStore'},
  ),
)
