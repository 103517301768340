import type {BackupCode} from '@app/common/api'
import {useCurrentUser} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {encodeTotpSecretAsUrl, generateTotpSecret} from '@app/components/Account/MFA/MFAUtils'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, Divider, Flex, Heading, Input, Link, Stack, Text} from '@chakra-ui/react'
import {ShieldCheckIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import {QRCodeSVG} from 'qrcode.react'
import React from 'react'
import {toast} from 'react-hot-toast'
import {SiAuthy} from 'react-icons/si'

export default function MFAEnable(): React.JSX.Element {
  const [mfaEnableOpen, setMfaEnableOpen, mfaEnableForceOpen] = useOverlayStore(state => [
    state.mfaEnableOpen,
    state.setMfaEnableOpen,
    state.mfaEnableForceOpen,
  ])
  return (
    <Modal
      forceOpen={mfaEnableForceOpen}
      header="Enable Two-Factor Auth"
      isOpen={mfaEnableOpen}
      onClose={() => setMfaEnableOpen(false)}
    >
      <MFAEnableContent />
    </Modal>
  )
}

function MFAEnableContent(): React.JSX.Element | null {
  const {data: user} = useCurrentUser()
  const [code, setCode] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const queryClient = useQueryClient()
  const setMfaBackupCodes = useOverlayStore(state => state.setMfaBackupCodes)
  const setMfaEnableOpen = useOverlayStore(state => state.setMfaEnableOpen)
  const totpSecret = React.useMemo(() => generateTotpSecret(), [])

  if (!user) return null
  const totpUrl = encodeTotpSecretAsUrl(user.email, totpSecret)

  return (
    <Stack as={ModalBody} direction="column" fontSize="md" py={5} spacing={4}>
      <Stack align="center" direction={['column', 'row']}>
        <Flex align="center" flex="0 0 140px" justify="center" mr="10px">
          <SiAuthy color="#ff5e52" size={64} />
        </Flex>

        <Stack direction="column" spacing={1}>
          <Heading as="h3" color="whiteAlpha.700" fontSize="sm" letterSpacing="tight" textTransform="uppercase">
            Download an authenticator app
          </Heading>

          <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
            Download{' '}
            <Link color="orange.300" href="https://authy.com/download" isExternal>
              Authy
            </Link>{' '}
            for your phone, tablet, or computer. Make sure to{' '}
            <Link color="orange.300" href="https://support.authy.com/hc/articles/115001750008" isExternal>
              enable backups
            </Link>
            .
          </Text>
        </Stack>
      </Stack>

      <Divider />

      <Stack align="center" direction={['column', 'row']}>
        <Flex align="center" flex="0 0 140px" justify="center" mr="10px">
          <QRCodeSVG
            style={{
              backgroundColor: 'white',
              borderRadius: 4,
              height: 128,
              padding: 8,
              width: 128,
            }}
            value={totpUrl}
          />
        </Flex>

        <Stack direction="column" spacing={4}>
          <Stack direction="column" spacing={1}>
            <Heading as="h3" color="whiteAlpha.700" fontSize="sm" letterSpacing="tight" textTransform="uppercase">
              Scan the QR code
            </Heading>

            <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
              Open the authenticator app and scan the image to the left using your phone's camera.
            </Text>
          </Stack>

          <Stack direction="column" spacing={1}>
            <Heading as="h3" color="whiteAlpha.700" fontSize="sm" letterSpacing="tight" textTransform="uppercase">
              2FA Key (Manual Entry)
            </Heading>

            <Text fontFamily="mono" fontSize="md" letterSpacing="tight" userSelect="text">
              {totpSecret}
            </Text>
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <Stack align="center" direction={['column', 'row']} justify="space-between" pb={[8, 0]}>
        <Flex align="center" flex="0 0 140px" justify="center" mr="10px">
          <ShieldCheckIcon color="var(--chakra-colors-orange-200)" height={64} width={64} />
        </Flex>

        <Stack direction="column" spacing={3}>
          <Stack direction="column" spacing={1}>
            <Heading as="h3" color="whiteAlpha.700" fontSize="sm" letterSpacing="tight" textTransform="uppercase">
              Log in with your code
            </Heading>

            <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
              Enter the 6-digit verification code generated.
            </Text>
          </Stack>

          <Stack align="center" direction={['column', 'row']} spacing={2}>
            <Input
              autoComplete="one-time-code"
              maxLength={7}
              onChange={event => setCode(event.target.value)}
              placeholder="000 000"
              size="md"
              type="text"
              value={code}
            />
            <Button
              colorScheme="orange"
              fontSize="sm"
              isDisabled={code.length === 0}
              isLoading={isLoading}
              onClick={async () => {
                setIsLoading(true)
                try {
                  const {data} = await api.post<{token: string; backup_codes: BackupCode[]}>(
                    '/users/@me/mfa/totp/enable',
                    {code, secret: totpSecret.replaceAll(/\s/g, '').toUpperCase()},
                  )
                  window.localStorage.setItem('token', data.token)
                  await queryClient.invalidateQueries({queryKey: ['user']})
                  setMfaEnableOpen(false)
                  setMfaBackupCodes(data.backup_codes)
                } catch (error) {
                  if (axios.isAxiosError(error))
                    toast.error(error.response?.data.message ?? 'Something went wrong, please try again later.')
                }

                setIsLoading(false)
              }}
              size="md"
              type="submit"
            >
              Activate
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
