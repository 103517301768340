import type {APIFormSubmissionResponse, APIPlayer} from '@app/common/api'
import {
  FormSubmissionStatusToBg,
  FormSubmissionStatusToString,
  FormTypeToString,
  dateFormatter,
} from '@app/common/constants'
import {getAvatarUrl} from '@app/common/utils'
import {Indicator} from '@app/components/Indicator/Indicator'
import {FormTypeToIcon} from '@app/components/Modal/FormTypeIcons'
import {Avatar, Box, Flex, Heading, Stack, Text} from '@chakra-ui/react'
import {ArrowRightIcon} from '@heroicons/react/24/solid'
import type React from 'react'

export function Submission({
  submission,
  player,
  reviewer,
  onClick,
}: {
  submission: APIFormSubmissionResponse
  player?: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>
  reviewer?: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>
  onClick(): void
}): React.JSX.Element {
  const LeftIcon = FormTypeToIcon[submission.formType]
  const isSimilars = 'score' in submission

  return (
    <Stack
      align="center"
      bg={isSimilars ? 'gray.800' : FormSubmissionStatusToBg[submission.status]}
      boxShadow="lg"
      direction="row"
      justify="space-between"
      onClick={onClick}
      p={4}
      pos="relative"
      role="button"
      rounded="lg"
      tabIndex={0}
      transition="ease-in-out 200ms"
    >
      <Stack align="center" direction="row" spacing="12px">
        <Flex flex="1 0 auto">{LeftIcon && <LeftIcon height={24} size={24} width={24} />}</Flex>

        <Stack spacing="1">
          <Stack align="center" direction="row" spacing="1.5">
            <Heading isTruncated size="sm">
              {FormTypeToString[submission.formType]}
            </Heading>
            <Indicator variant="inverted">{FormSubmissionStatusToString[submission.status]}</Indicator>
          </Stack>

          <Stack spacing="0">
            <Text color="whiteAlpha.800" fontSize="sm">
              {dateFormatter.format(new Date(submission.reviewedAt ?? submission.timestamp))}
            </Text>
          </Stack>

          {player && (
            <Stack align="center" direction="row" spacing="1.5">
              <Avatar name={player.name} size="xs" src={getAvatarUrl(player.skinHash)} />
              <Text color="whiteAlpha.800" fontSize="sm" fontWeight="semibold">
                {player.name}
              </Text>
            </Stack>
          )}

          {reviewer && player?.xuid !== reviewer.xuid && (
            <Stack align="center" direction="row" spacing="1.5">
              <Avatar name={reviewer.name} size="xs" src={getAvatarUrl(reviewer.skinHash)} />
              <Text color="whiteAlpha.800" fontSize="sm" fontWeight="semibold">
                {reviewer.name}
              </Text>
              <Indicator variant="inverted">Reviewer</Indicator>
            </Stack>
          )}
        </Stack>
      </Stack>

      {isSimilars && (
        <Box
          bg={submission.score! >= 50 ? 'red.500' : 'yellow.300'}
          color={submission.score! >= 50 ? 'white' : 'gray.800'}
          fontSize="sm"
          fontWeight="extrabold"
          p={1}
          pos="absolute"
          right={-2}
          rounded="lg"
          top={-2}
        >
          {submission.score}%
        </Box>
      )}

      <ArrowRightIcon height={24} width={24} />
    </Stack>
  )
}
