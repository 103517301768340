import type {WebauthnAuthenticator} from '@app/common/api'
import {useCurrentUser, useWebauthnAuthenticators} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {MFADisable} from '@app/components/Account/MFA/MFADisable'
import {WebAuthnRegister} from '@app/components/Account/WebAuthn/WebAuthnRegister'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {Box, Button, Divider, Heading, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {LockClosedIcon, LockOpenIcon} from '@heroicons/react/24/solid'
import type {PublicKeyCredentialCreationOptionsJSON} from '@simplewebauthn/types'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {toast} from 'react-hot-toast'

type ModalProps = {
  isOpen: boolean
  onClose(): void
}

export function SecurityModal(props: ModalProps): React.JSX.Element | null {
  const {data: user} = useCurrentUser()
  const {data: authenticators} = useWebauthnAuthenticators()
  const [credentialOptions, setCredentialOptions] = React.useState<PublicKeyCredentialCreationOptionsJSON | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const mfaDisable = useDisclosure()
  const setMfaEnableOpen = useOverlayStore(state => state.setMfaEnableOpen)
  const webauthn = useDisclosure()

  if (!user) return null
  return (
    <>
      <MFADisable {...mfaDisable} />
      <WebAuthnRegister {...webauthn} credentialOptions={credentialOptions} />

      <Modal header="Security Settings" isOpen={props.isOpen} onClose={props.onClose}>
        <Stack as={ModalBody} direction="column" fontSize="md" spacing={4}>
          <Stack direction="column" spacing={2}>
            <Stack align="center" direction="row" spacing={1}>
              {user.mfaEnabled ? (
                <LockClosedIcon color="var(--chakra-colors-green-300)" height={16} width={16} />
              ) : (
                <LockOpenIcon color="var(--chakra-colors-red-300)" height={16} width={16} />
              )}
              <Heading as="h3" color={user.mfaEnabled ? 'green.300' : 'red.300'} fontSize="md" letterSpacing="tight">
                Two-Factor Authentication {user.mfaEnabled ? 'Enabled' : 'Disabled'}
              </Heading>
            </Stack>

            <Text color="whiteAlpha.800" fontSize="md" letterSpacing="tight">
              Two-factor authentication (2FA) adds an additional layer of security to your account by requiring a second
              method of verification when logging in.
            </Text>
          </Stack>

          <Box>
            <Button
              colorScheme={user.mfaEnabled ? 'red' : 'orange'}
              onClick={user.mfaEnabled ? mfaDisable.onOpen : () => setMfaEnableOpen(true)}
              size="sm"
              variant={user.mfaEnabled ? 'outline' : 'solid'}
              w="full"
            >
              {user.mfaEnabled ? 'Disable' : 'Enable'} Two-Factor Auth
            </Button>
          </Box>

          <Divider />

          <Stack direction="column" spacing={2}>
            <Heading as="h3" fontSize="md" letterSpacing="tight">
              Security Keys
            </Heading>

            <Text color="whiteAlpha.800" fontSize="md" letterSpacing="tight">
              Add an additional layer of protection to your account by requiring access to physical device or biometric
              sensor.
            </Text>
          </Stack>

          {authenticators?.length ? (
            <Stack direction="column" spacing={2}>
              <Heading as="h3" fontSize="md" letterSpacing="tight">
                Registered Security Keys
              </Heading>

              <Text color="whiteAlpha.800" fontSize="md" letterSpacing="tight">
                The following security keys are registered to your account. You can remove them at any time.
              </Text>

              <Stack direction="column" spacing={2}>
                {authenticators.map(authenticator => (
                  <WebauthnAuthenticatorRenderer authenticator={authenticator} key={authenticator.id} />
                ))}
              </Stack>
            </Stack>
          ) : null}

          <Tooltip
            label="You must enable two-factor authentication before you can register a security key."
            shouldShow={!user.mfaEnabled}
          >
            <Box>
              <Button
                colorScheme="orange"
                isDisabled={!user.mfaEnabled}
                isLoading={isLoading}
                onClick={async () => {
                  setIsLoading(true)
                  try {
                    const {data} = await api.post('/users/@me/mfa/webauthn/credentials')
                    setCredentialOptions(data)
                    webauthn.onOpen()
                  } catch {
                    toast.error('Something went wrong! Please try again later.')
                  }

                  setIsLoading(false)
                }}
                size="sm"
                w="full"
              >
                Register Security Key
              </Button>
            </Box>
          </Tooltip>
        </Stack>
      </Modal>
    </>
  )
}

function WebauthnAuthenticatorRenderer({authenticator}: {authenticator: WebauthnAuthenticator}): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const queryClient = useQueryClient()

  return (
    <Stack
      align="center"
      bgColor="gray.800"
      borderRadius="md"
      direction="row"
      justify="space-between"
      p={4}
      spacing={2}
    >
      <Text color="whiteAlpha.800" fontSize="md" letterSpacing="tight">
        {authenticator.name}
      </Text>

      <Button
        colorScheme="red"
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true)
          try {
            await api.delete(`/users/@me/mfa/webauthn/credentials/${authenticator.id}`)
            await queryClient.invalidateQueries({queryKey: ['user', 'webauthn']})
          } catch {
            toast.error('Something went wrong! Please try again later.')
          }

          setIsLoading(false)
        }}
        size="sm"
        variant="outline"
      >
        Remove
      </Button>
    </Stack>
  )
}
