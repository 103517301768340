import type {APIUser} from '@app/common/api'
import {PlayerFlags} from '@app/common/api'
import {
  APIPermissions,
  CDN_HOST,
  CREW_PLUS_RANKS,
  MOD_PLUS_RANKS,
  MinecraftColor,
  Period,
  PeriodToString,
  Rank,
  STAFF_RANKS,
  SUPPORT_PLUS_RANKS,
  Tier,
  TierToCreditsThreshold,
} from '@app/common/constants'
import {getXpToReachLevel} from '@nethergames/utils'
import axios from 'axios'
import type React from 'react'

const DEFAULT_SKIN_HASH = 'def463341f256af9656a2eebea910968'

export function getSkinUrl(skinHash: string | null): string {
  return `${CDN_HOST}/skins/${skinHash ?? DEFAULT_SKIN_HASH}/full.png`
}

export function getAvatarUrl(skinHash: string | null): string {
  return `${CDN_HOST}/skins/${skinHash ?? DEFAULT_SKIN_HASH}/head.png`
}

export function getErrorMessage(error: unknown, field: string): string {
  if (!axios.isAxiosError(error)) return ''
  const data = (error as any).response?.data
  if (!data || !('errors' in data)) return 'Something went wrong, please try again later.'
  const err = data.errors?.find((error: any) => error.path?.[0] === field)
  return err?.message ?? ''
}

export function flattenObject(obj: any): Record<string, any> {
  const flattened: Record<string, any> = {}

  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === 'object' && obj[key] != null) Object.assign(flattened, flattenObject(obj[key]))
    else flattened[key] = obj[key]
  }

  return flattened
}

export function hasFlag(flags: number, flag: number): boolean {
  return (flags & flag) === flag
}

export function formatMinutes(inputMinutes: number): string {
  let ms = inputMinutes * 60 * 1000
  const weeks = Math.floor(ms / (1000 * 60 * 60 * 24 * 7))
  ms %= 1000 * 60 * 60 * 24 * 7
  const days = Math.floor(ms / (1000 * 60 * 60 * 24))
  ms %= 1000 * 60 * 60 * 24
  const hours = Math.floor(ms / (1000 * 60 * 60))
  ms %= 1000 * 60 * 60
  const minutes = Math.floor(ms / (1000 * 60))

  return `${weeks}W ${days}D ${hours}H ${minutes}M`
}

export function getKdr(kills: number, deaths: number): number {
  return deaths === 0 ? kills : Math.floor((kills / deaths) * 100) / 100
}

export async function createPopup(getUrl: () => Promise<string>): Promise<void> {
  const windowRef = window.open(undefined, '_blank')
  if (!windowRef) return
  windowRef.location = await getUrl()
}

export function getCreditsToNextTier(credits: number): number {
  if (credits >= TierToCreditsThreshold[Tier.DIAMOND]) return 0
  return Object.values(TierToCreditsThreshold).find(threshold => credits < threshold)! - credits
}

export function getPeriodOptions(includeYearly = true): React.JSX.Element[] {
  return Object.entries(PeriodToString)
    .filter(([period]) => (includeYearly ? true : period !== Period.YEARLY))
    .map(([period, label]) => (
      <option key={period} value={period}>
        {label}
      </option>
    ))
}

export function getValidColorsForGuildLevel(level: number): MinecraftColor[] {
  if (level < 50) return [MinecraftColor.GRAY]
  if (level < 150) return [MinecraftColor.GRAY, MinecraftColor.DARK_AQUA]
  if (level < 300) return [MinecraftColor.GRAY, MinecraftColor.DARK_AQUA, MinecraftColor.YELLOW]
  if (level < 500)
    return [MinecraftColor.GRAY, MinecraftColor.DARK_AQUA, MinecraftColor.YELLOW, MinecraftColor.DARK_GREEN]
  if (level < 750)
    return [
      MinecraftColor.GRAY,
      MinecraftColor.DARK_AQUA,
      MinecraftColor.YELLOW,
      MinecraftColor.DARK_GREEN,
      MinecraftColor.LIGHT_PURPLE,
    ]
  return [
    MinecraftColor.GRAY,
    MinecraftColor.DARK_AQUA,
    MinecraftColor.YELLOW,
    MinecraftColor.DARK_GREEN,
    MinecraftColor.LIGHT_PURPLE,
    MinecraftColor.GOLD,
  ]
}

export function getXpToNextLevelColor(xp: number): [xpRemaining: number, nextLevel: number] {
  if (xp < getXpToReachLevel(25)) return [getXpToReachLevel(25) - xp, 25]
  if (xp < getXpToReachLevel(50)) return [getXpToReachLevel(50) - xp, 50]
  if (xp < getXpToReachLevel(75)) return [getXpToReachLevel(75) - xp, 75]
  if (xp < getXpToReachLevel(100)) return [getXpToReachLevel(100) - xp, 100]
  if (xp < getXpToReachLevel(125)) return [getXpToReachLevel(125) - xp, 125]
  if (xp < getXpToReachLevel(150)) return [getXpToReachLevel(150) - xp, 150]
  if (xp < getXpToReachLevel(175)) return [getXpToReachLevel(175) - xp, 175]
  if (xp < getXpToReachLevel(200)) return [getXpToReachLevel(200) - xp, 200]
  if (xp < getXpToReachLevel(300)) return [getXpToReachLevel(300) - xp, 300]
  if (xp < getXpToReachLevel(400)) return [getXpToReachLevel(400) - xp, 400]
  if (xp < getXpToReachLevel(500)) return [getXpToReachLevel(500) - xp, 500]
  if (xp < getXpToReachLevel(600)) return [getXpToReachLevel(600) - xp, 600]
  if (xp < getXpToReachLevel(700)) return [getXpToReachLevel(700) - xp, 700]
  if (xp < getXpToReachLevel(800)) return [getXpToReachLevel(800) - xp, 800]
  if (xp < getXpToReachLevel(900)) return [getXpToReachLevel(900) - xp, 900]
  if (xp < getXpToReachLevel(1000)) return [getXpToReachLevel(1000) - xp, 1000]
  return [0, 1000]
}

export function getFormattedLevel(level: number): React.ReactNode {
  const levelCharacters = [...level.toString()]
  if (level < 25) return <span style={{color: MinecraftColor.WHITE}}>{level}</span>
  if (level < 50) return <span style={{color: MinecraftColor.GRAY}}>{level}</span>
  if (level < 75) return <span style={{color: MinecraftColor.YELLOW}}>{level}</span>
  if (level < 100) return <span style={{color: MinecraftColor.GREEN}}>{level}</span>
  if (level < 125) return <span style={{color: MinecraftColor.AQUA}}>{level}</span>
  if (level < 150) return <span style={{color: MinecraftColor.BLUE}}>{level}</span>
  if (level < 175) return <span style={{color: MinecraftColor.RED}}>{level}</span>
  if (level < 200) return <span style={{color: MinecraftColor.LIGHT_PURPLE}}>{level}</span>
  if (level < 300)
    return (
      <>
        <span style={{color: MinecraftColor.AQUA}}>{levelCharacters[0]}</span>
        <span style={{color: MinecraftColor.WHITE}}>{levelCharacters[1]}</span>
        <span style={{color: MinecraftColor.AQUA}}>{levelCharacters[2]}</span>
      </>
    )
  if (level < 400)
    return (
      <>
        <span style={{color: MinecraftColor.GOLD}}>{levelCharacters[0]}</span>
        <span style={{color: MinecraftColor.YELLOW}}>{levelCharacters[1]}</span>
        <span style={{color: MinecraftColor.GOLD}}>{levelCharacters[2]}</span>
      </>
    )
  if (level < 500)
    return (
      <>
        <span style={{color: MinecraftColor.DARK_AQUA}}>{levelCharacters[0]}</span>
        <span style={{color: MinecraftColor.AQUA}}>{levelCharacters[1]}</span>
        <span style={{color: MinecraftColor.DARK_AQUA}}>{levelCharacters[2]}</span>
      </>
    )
  if (level < 600)
    return (
      <>
        <span style={{color: MinecraftColor.DARK_GREEN}}>{levelCharacters[0]}</span>
        <span style={{color: MinecraftColor.GREEN}}>{levelCharacters[1]}</span>
        <span style={{color: MinecraftColor.DARK_GREEN}}>{levelCharacters[2]}</span>
      </>
    )
  if (level < 700)
    return (
      <>
        <span style={{color: MinecraftColor.DARK_BLUE}}>{levelCharacters[0]}</span>
        <span style={{color: MinecraftColor.BLUE}}>{levelCharacters[1]}</span>
        <span style={{color: MinecraftColor.DARK_BLUE}}>{levelCharacters[2]}</span>
      </>
    )
  if (level < 800)
    return (
      <>
        <span style={{color: MinecraftColor.DARK_RED}}>{levelCharacters[0]}</span>
        <span style={{color: MinecraftColor.RED}}>{levelCharacters[1]}</span>
        <span style={{color: MinecraftColor.DARK_RED}}>{levelCharacters[2]}</span>
      </>
    )
  if (level < 900)
    return (
      <>
        <span style={{color: MinecraftColor.DARK_PURPLE}}>{levelCharacters[0]}</span>
        <span style={{color: MinecraftColor.LIGHT_PURPLE}}>{levelCharacters[1]}</span>
        <span style={{color: MinecraftColor.DARK_PURPLE}}>{levelCharacters[2]}</span>
      </>
    )
  if (level < 1000)
    return (
      <>
        <span style={{color: MinecraftColor.DARK_GRAY}}>{levelCharacters[0]}</span>
        <span style={{color: MinecraftColor.GRAY}}>{levelCharacters[1]}</span>
        <span style={{color: MinecraftColor.DARK_GRAY}}>{levelCharacters[2]}</span>
      </>
    )
  return (
    <>
      <span style={{color: MinecraftColor.RED}}>{levelCharacters[0]}</span>
      <span style={{color: MinecraftColor.GOLD}}>{levelCharacters[1]}</span>
      <span style={{color: MinecraftColor.GREEN}}>{levelCharacters[2]}</span>
      <span style={{color: MinecraftColor.LIGHT_PURPLE}}>{levelCharacters[3]}</span>
    </>
  )
}

export function formatBitfield(bitfield: Record<any, any>): Record<string, number> {
  return Object.fromEntries(Object.entries(bitfield).filter(([key, value]) => Number.isNaN(Number(key)) && value !== 0))
}

export function intersect<T>(a: Set<T>, b: Set<T>): boolean {
  return new Set([...a].filter(x => b.has(x))).size > 0
}

export function canViewLinkedAccounts(user?: APIUser): boolean {
  if (!user) return false
  const ranks = new Set(user.ranks)
  return intersect(ranks, CREW_PLUS_RANKS) || isAdmin(user)
}

export function canViewPunishmentStaffDetails(user?: APIUser): boolean {
  if (!user) return false
  const ranks = new Set(user.ranks)
  const hasRequiredRank = intersect(ranks, CREW_PLUS_RANKS) || intersect(ranks, SUPPORT_PLUS_RANKS)
  const hasRequiredPermission = hasFlag(user.apiPermissions, APIPermissions.MANAGE_APPEALS)
  return hasRequiredRank || hasRequiredPermission
}

export function canViewChatLogs(user?: APIUser): boolean {
  if (!user) return false
  const ranks = new Set(user.ranks)
  return intersect(ranks, MOD_PLUS_RANKS)
}

export function isStaff(user?: Pick<APIUser, 'flags' | 'ranks'>): boolean {
  if (!user) return false
  return intersect(new Set(user.ranks), STAFF_RANKS) || hasFlag(user.flags, PlayerFlags.PSEUDO_ADMIN)
}

export function isAdmin(user?: Pick<APIUser, 'flags' | 'ranks'>): boolean {
  return (user?.ranks.includes(Rank.ADMIN) ?? false) || hasFlag(user?.flags ?? 0, PlayerFlags.PSEUDO_ADMIN)
}

export function isDev(user?: Pick<APIUser, 'flags' | 'ranks'>): boolean {
  return isAdmin(user) || (user?.ranks.includes(Rank.DEVELOPER) ?? false)
}

export function censorIpAddress(ip: string): string {
  return ip.replace(/(?:\.\d{1,3}){2}$/, '.***.***')
}
