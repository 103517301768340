import {useOverlayStore} from '@app/common/stores'
import Home from '@app/components/Home/Home'
import React from 'react'
import {useParams} from 'react-router-dom'

export default function RequestReviewModalOpener(): React.JSX.Element {
  const {submissionId} = useParams() as {submissionId: string}
  const setRequestId = useOverlayStore(state => state.setRequestId)

  React.useEffect(() => {
    setRequestId(submissionId)
  }, [setRequestId, submissionId])

  return <Home />
}
