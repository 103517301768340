import {useOverlayStore} from '@app/common/stores'
import React from 'react'
import {tinykeys} from 'tinykeys'

export default function KeybindManager(): null {
  const [settingsOpen, quickSearchOpen, setSettingsOpen, setQuickSearchOpen] = useOverlayStore(state => [
    state.settingsOpen,
    state.quickSearchOpen,
    state.setSettingsOpen,
    state.setQuickSearchOpen,
  ])
  React.useEffect(() => {
    const unsubscribe = tinykeys(window, {
      '$mod+,': event => {
        event.preventDefault()
        setSettingsOpen(!settingsOpen)
      },
      '$mod+k': event => {
        event.preventDefault()
        setQuickSearchOpen(!quickSearchOpen)
      },
    })
    return () => unsubscribe()
  }, [quickSearchOpen, setQuickSearchOpen, setSettingsOpen, settingsOpen])

  return null
}
