import {Heading, Stack, Text} from '@chakra-ui/react'
import {ExclamationTriangleIcon} from '@heroicons/react/24/solid'
import type React from 'react'

export function HoldUp({children}: {children: React.ReactNode}): React.JSX.Element {
  return (
    <Stack bg="#f97316" boxShadow="lg" my={1} p={3} rounded="lg" spacing={1}>
      <Stack align="center" direction="row" spacing={1}>
        <ExclamationTriangleIcon height={24} width={24} />
        <Heading letterSpacing="tight" size="md">
          Hold up!
        </Heading>
      </Stack>
      <Text fontSize="md" letterSpacing="tight">
        {children}
      </Text>
    </Stack>
  )
}
