import {useCurrentUser, usePlayerPunishments} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {canViewLinkedAccounts, canViewPunishmentStaffDetails} from '@app/common/utils'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Punishment} from '@app/components/Modal/Punishment'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Button, Checkbox, Heading, Stack, Text} from '@chakra-ui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import React from 'react'

export default function PlayerPunishmentsModal(): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const [player, punishmentIds, setPlayer, setAdminAltsPlayer] = useOverlayStore(state => [
    state.punishmentPlayer,
    state.punishmentIds,
    state.setPunishmentPlayer,
    state.setAdminAltsPlayer,
  ])
  return (
    <Modal
      footer={
        canViewLinkedAccounts(user) ? (
          <ModalFooter>
            <Button colorScheme="orange" onClick={() => setAdminAltsPlayer(player)}>
              Linked Accounts
            </Button>
          </ModalFooter>
        ) : undefined
      }
      header={player ? `Punishments for '${player}'` : 'Player Punishments'}
      isOpen={Boolean(player)}
      onClose={() => setPlayer(null)}
    >
      <PlayerPunishmentsModalContent player={player!} punishmentIds={punishmentIds} />
    </Modal>
  )
}

export function PlayerPunishmentsModalContent({
  player,
  punishmentIds,
}: {
  player: string
  punishmentIds: string[]
}): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const {data: punishments} = usePlayerPunishments(player, canViewPunishmentStaffDetails(user))
  const [shouldFilter, setShouldFilter] = React.useState(punishmentIds.length > 0)
  const [shouldShowAlts, setShouldShowAlts] = React.useState(punishmentIds.length > 0)

  if (!punishments)
    return (
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
        <Spinner />
      </ModalBody>
    )

  const basePunishments = punishments.punishments.filter(punishment => !punishment.alt || shouldShowAlts)
  const filteredPunishments =
    !shouldFilter || punishmentIds.length === 0
      ? basePunishments
      : basePunishments.filter(punishment => punishmentIds.includes(punishment.id))

  return (
    <ModalBody as={Stack} gap="8px" mb={1}>
      <Stack gap="4px">
        <Checkbox isChecked={shouldShowAlts} onChange={event => setShouldShowAlts(event.target.checked)}>
          Show alt punishments
        </Checkbox>
      </Stack>

      {punishmentIds.length > 0 && (
        <Stack gap="4px">
          {shouldFilter && (
            <Text color="red.300" fontSize="sm" fontWeight="semibold" letterSpacing="tight" userSelect="text">
              Only displaying the following punishment{punishmentIds.length > 1 ? 's' : ''}: "
              {punishmentIds.join('" , "')}"
            </Text>
          )}

          <Checkbox isChecked={shouldFilter} onChange={event => setShouldFilter(event.target.checked)}>
            Filter by punishments
          </Checkbox>
        </Stack>
      )}

      {filteredPunishments.length === 0 ? (
        <Stack align="center" h="full" justify="center" py={16} textAlign="center">
          <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
          <Heading fontSize="2xl" fontWeight="bold">
            *cricket noises*
          </Heading>
          <Text color="whiteAlpha.800" fontSize="md">
            No punishments found. This player is probably a saint.
          </Text>
        </Stack>
      ) : (
        <Stack align="center" gap="8px">
          {filteredPunishments.map(punishment => (
            <Punishment key={punishment.id} punishment={punishment} />
          ))}
        </Stack>
      )}
    </ModalBody>
  )
}
