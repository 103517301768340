import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Button} from '@chakra-ui/react'
import type React from 'react'

export default function LinkWarningModal(): React.JSX.Element {
  const [externalLinkUrl, setExternalLinkUrl] = useOverlayStore(state => [
    state.externalLinkUrl,
    state.setExternalLinkUrl,
  ])
  return (
    <Modal
      footer={
        <ModalFooter>
          <Button colorScheme="gray" onClick={() => setExternalLinkUrl(null)}>
            Cancel
          </Button>
          <Button
            colorScheme="orange"
            ml={3}
            onClick={() => {
              window.open(externalLinkUrl!, '_blank')
              setExternalLinkUrl(null)
            }}
          >
            Yep!
          </Button>
        </ModalFooter>
      }
      header="Hold Up"
      isOpen={Boolean(externalLinkUrl)}
      onClose={() => setExternalLinkUrl(null)}
    >
      <ModalBody fontSize="md">
        This link will take you to <strong>{externalLinkUrl}</strong>. Are you sure you want to go there?
      </ModalBody>
    </Modal>
  )
}
