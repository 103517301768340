import {queryClient} from '@app/common/api'
import {AlertDialog} from '@app/components/Modal/AlertDialog'
import {api} from '@app/hooks/useApi'
import React from 'react'
import toast from 'react-hot-toast'

type ModalProps = {
  isOpen: boolean
  onClose(): void
  player: string
}

export function FriendRemoveModal(props: ModalProps): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <AlertDialog
      content={
        <>
          Are you sure you want to permanently remove <strong>{props.player}</strong> from your friends?
        </>
      }
      isLoading={isLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPrimary={async () => {
        setIsLoading(true)
        try {
          await api.delete(`users/@me/relationships/${props.player}`)
          await queryClient.invalidateQueries({queryKey: ['user', 'relationships']})
        } catch {
          toast.error('Something went wrong! Please try again later.')
        }

        setIsLoading(false)
        props.onClose()
      }}
      primary="Remove Friend"
      title={`Remove '${props.player}'`}
    />
  )
}
