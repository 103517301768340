import type {APIRelationship} from '@app/common/api'
import {usePlayerRelationships} from '@app/common/api'
import {RelationshipPlayer} from '@app/components/Friend/FriendModal'
import {FriendRemoveModal} from '@app/components/Friend/FriendRemoveModal'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {Flex, Heading, IconButton, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {QuestionMarkCircleIcon, XMarkIcon} from '@heroicons/react/24/solid'
import type React from 'react'

type ModalProps = {
  buttonRef?: any
  isOpen: boolean
  onClose(): void
  player: string
}

export function PlayerFriendsModal(props: ModalProps): React.JSX.Element | null {
  const {data: relationships} = usePlayerRelationships(props.player)
  return (
    <Modal
      finalFocusRef={props.buttonRef}
      header={`Mutual Friends — ${relationships?.length ?? 0}`}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      {relationships ? (
        <ModalBody as={Stack} direction="column" gap="8px">
          <Stack direction="column" fontSize="sm" gap="4px">
            {relationships.map(relationship => (
              <PlayerEntry key={relationship.player} onClose={props.onClose} relationship={relationship} />
            ))}
          </Stack>
          {relationships.length === 0 && (
            <Stack align="center" h="full" justify="center" py={16} textAlign="center">
              <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
              <Heading fontSize="2xl" fontWeight="bold">
                *cricket noises*
              </Heading>
              <Text color="whiteAlpha.800" fontSize="md">
                You have no mutual friends with this player.
              </Text>
            </Stack>
          )}
        </ModalBody>
      ) : (
        <ModalBody my={16}>
          <Spinner />
        </ModalBody>
      )}
    </Modal>
  )
}

function PlayerEntry({relationship, onClose}: {relationship: APIRelationship; onClose(): void}): React.JSX.Element {
  const friendRemoveModal = useDisclosure()
  return (
    <Flex justify="space-between">
      <RelationshipPlayer onClose={onClose} relationship={relationship} />
      <FriendRemoveModal player={relationship.player} {...friendRemoveModal} />
      <Tooltip label="Remove Friend">
        <IconButton
          aria-label="Remove Friend"
          colorScheme="red"
          icon={<XMarkIcon height={16} width={16} />}
          onClick={() => friendRemoveModal.onOpen()}
          rounded="full"
        />
      </Tooltip>
    </Flex>
  )
}
