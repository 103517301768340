import {useTierSummary} from '@app/common/api'
import {numberFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Stack, Text} from '@chakra-ui/react'
import type React from 'react'

export default function TierSummaryModal(): React.JSX.Element {
  const [summaryOpen, setSummaryOpen] = useOverlayStore(state => [state.tierSummaryOpen, state.setTierSummaryOpen])

  return (
    <Modal header="Tier Summary" isOpen={summaryOpen} onClose={() => setSummaryOpen(false)}>
      <TierSummaryModalContent />
    </Modal>
  )
}

function TierSummaryModalContent(): React.JSX.Element {
  const {data: tiers} = useTierSummary()
  return (
    <ModalBody as={Stack} fontSize="md" gap="4px" userSelect="text">
      {Object.entries(tiers ?? {}).map(([tier, count]) => (
        <Stack align="center" direction="row" key={tier}>
          <Text flex="1" fontSize="sm" mb={0}>
            {tier}
          </Text>
          <Text flex="1" fontSize="sm" mb={0} textAlign="right">
            {numberFormatter.format(count)}
          </Text>
        </Stack>
      ))}
    </ModalBody>
  )
}
