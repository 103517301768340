import {API_HOST} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Button, Divider, Input, Stack, Text} from '@chakra-ui/react'
import {startAuthentication} from '@simplewebauthn/browser'
import type {PublicKeyCredentialRequestOptionsJSON} from '@simplewebauthn/types'
import {useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import React from 'react'
import toast from 'react-hot-toast'
import {useLocation, useSearchParams} from 'react-router-dom'

export default function MFAVerify(): React.JSX.Element {
  const [mfaVerifyOpen, setMfaVerifyOpen] = useOverlayStore(state => [state.mfaVerifyOpen, state.setMfaVerifyOpen])
  return (
    <Modal
      forceOpen={mfaVerifyOpen}
      header="Two-Factor Auth"
      isOpen={mfaVerifyOpen}
      onClose={() => setMfaVerifyOpen(false)}
    >
      <MFAVerifyContent />
    </Modal>
  )
}

function MFAVerifyContent(): React.JSX.Element {
  const [code, setCode] = React.useState('')
  const [isTotpLoading, setIsTotpLoading] = React.useState(false)
  const [isWebauthnLoading, setIsWebauthnLoading] = React.useState(false)
  const [searchParams] = useSearchParams()
  const [webauthnOptions, setWebauthnOptions] = React.useState<PublicKeyCredentialRequestOptionsJSON | null>(null)
  const location = useLocation()
  const queryClient = useQueryClient()
  const redirectTo = searchParams.get('redirect_to')
  const setMfaVerifyOpen = useOverlayStore(state => state.setMfaVerifyOpen)
  const token = location.hash.slice(1)
  const headers = {Authorization: token || window.localStorage.getItem('token')}

  async function verifyTotp() {
    setIsTotpLoading(true)
    try {
      await axios.post(`${API_HOST}/v1/auth/mfa/totp`, {code}, {headers})
      if (redirectTo) {
        window.location.href = redirectTo
      } else {
        await queryClient.invalidateQueries({queryKey: ['user']})
        setMfaVerifyOpen(false)
      }
    } catch (error) {
      if (axios.isAxiosError(error))
        toast.error(error.response?.data.message ?? 'Something went wrong, please try again later.')
    }

    setIsTotpLoading(false)
  }

  async function verifyWebauthn(webauthnOptions: PublicKeyCredentialRequestOptionsJSON) {
    setIsWebauthnLoading(true)
    try {
      const response = await startAuthentication({optionsJSON: webauthnOptions})
      await axios.post(`${API_HOST}/v1/auth/mfa/webauthn`, response, {headers})
      if (redirectTo) {
        window.location.href = redirectTo
      } else {
        await queryClient.invalidateQueries({queryKey: ['user']})
        setMfaVerifyOpen(false)
      }
    } catch (error) {
      if (axios.isAxiosError(error))
        toast.error(error.response?.data.message ?? 'Something went wrong, please try again later.')
    }

    setIsWebauthnLoading(false)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  React.useEffect(() => {
    ;(async () => {
      try {
        const {data} = await axios.get(`${API_HOST}/v1/users/@me/mfa/webauthn/options`, {headers})
        setWebauthnOptions(data)
      } catch {
        /* noop */
      }
    })()
  }, [])

  return (
    <Stack as={ModalBody} fontSize="md" spacing={4}>
      <Stack direction="column" spacing={3}>
        <Text color="whiteAlpha.800" fontSize="md" letterSpacing="tight">
          Enter the 6-digit verification code generated. You may also use a backup code if you've lost your device.
        </Text>

        <Stack align="center" direction="row" spacing={2}>
          <Input
            autoComplete="one-time-code"
            onChange={event => setCode(event.target.value)}
            placeholder="000 000"
            size="md"
            type="text"
            value={code}
          />
          <Button
            colorScheme="orange"
            fontSize="sm"
            isDisabled={code.length === 0}
            isLoading={isTotpLoading}
            onClick={async () => verifyTotp()}
            size="md"
            type="submit"
          >
            Verify
          </Button>
        </Stack>
      </Stack>

      {webauthnOptions && (
        <>
          <Divider />
          <Stack direction="column" spacing={3}>
            <Text color="whiteAlpha.800" fontSize="md" letterSpacing="tight">
              You can also use your security key to verify your identity.
            </Text>
            <Button
              colorScheme="orange"
              isLoading={isWebauthnLoading}
              onClick={async () => verifyWebauthn(webauthnOptions)}
              size="sm"
              type="submit"
            >
              Verify with Security Key
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  )
}
