import * as styles from '@app/components/Indicator/Indicator.css'
import type React from 'react'

export function Indicator({
  children,
  variant,
}: {
  children: React.ReactNode
  variant: keyof typeof styles.indicator
}): React.JSX.Element {
  return <div className={styles.indicator[variant]}>{children}</div>
}
