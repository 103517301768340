import type {APIPlayer} from '@app/common/api'
import styles from '@app/components/App.module.css'
import {MetaTags} from '@app/components/UI/MetaTags'
import {api} from '@app/hooks/useApi'
import {Box, Button, FormControl, FormErrorMessage, Heading, Input, InputGroup, Link, Text} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import type React from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

export default function Vote(): React.JSX.Element {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const {formState, handleSubmit, register, setError} = useForm({mode: 'onSubmit', reValidateMode: 'onSubmit'})

  async function onSubmit({player}: {player: string}): Promise<void> {
    try {
      const {data} = await api.get<APIPlayer>(`/players/${player}`)
      queryClient.setQueryData<APIPlayer>(['player', player], data)
      navigate(`/vote/${player}`)
    } catch (error) {
      if (axios.isAxiosError(error))
        setError('player', {type: 'validate', message: (error as any).response?.data.message})
    }
  }

  return (
    <>
      <MetaTags title="Vote Status" />
      <Box borderWidth={1} className={styles.backdrop} p={4} rounded="2xl" w={['unset', '22.5rem']}>
        <Heading mb={1} size="lg">
          Check Vote Status
        </Heading>

        <Text fontSize="sm" mb={3}>
          You can also{' '}
          <Link color="orange.300" href="https://minecraftpocket-servers.com/server/36864/vote" isExternal>
            vote now
          </Link>
          .
        </Text>

        <form onSubmit={handleSubmit(onSubmit as any)}>
          <FormControl isInvalid={Boolean(formState.errors.player)}>
            <InputGroup>
              <Input
                autoComplete="off"
                autoFocus
                maxLength={255}
                placeholder="Xbox Gamertag"
                size="md"
                type="text"
                w="full"
                {...register('player', {required: true})}
              />

              <Button isLoading={formState.isSubmitting} ml={2} size="md" type="submit">
                Check
              </Button>
            </InputGroup>

            <FormErrorMessage>{formState.errors.player?.message?.toString()}</FormErrorMessage>
          </FormControl>
        </form>
      </Box>
    </>
  )
}
