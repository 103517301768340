import {useCurrentUser, useLeaderboard, usePunishmentsBulk, useTournament} from '@app/common/api'
import type {GameMode, LeaderboardColumn, LeaderboardSortBy, Rank} from '@app/common/constants'
import {
  GameModeToString,
  LeaderboardColumnToString,
  LeaderboardSortByToString,
  LeaderboardType,
  LeaderboardTypeToString,
  Period,
  PeriodToString,
  RankToColorString,
  dateFormatter,
  numberFormatter,
} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {getPeriodOptions, isAdmin} from '@app/common/utils'
import appStyles from '@app/components/App.module.css'
import {Avatar} from '@app/components/Avatar/Avatar'
import dropdownStyles from '@app/components/Dropdown/Dropdown.module.css'
import {PlayerHoverCard} from '@app/components/Player/PlayerHoverCard'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import ErrorScreen from '@app/components/UI/ErrorScreen'
import {MetaTags} from '@app/components/UI/MetaTags'
import {InfoIcon, SearchIcon} from '@chakra-ui/icons'
import {Button, Flex, Heading, Input, InputGroup, InputLeftElement, Link, Select, Stack, Text} from '@chakra-ui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import {Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, makeStyles} from '@material-ui/core'
import clsx from 'clsx'
import {formatDistance, getDaysInMonth, subMonths} from 'date-fns'
import _ from 'lodash'
import {matchSorter} from 'match-sorter'
import React from 'react'
import {Link as RouterLink, useParams, useSearchParams} from 'react-router-dom'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    '@media (min-width: 720px)': {paddingLeft: '8rem', paddingRight: '8rem'},
    '@media (min-width: 1300px)': {paddingLeft: '16rem', paddingRight: '16rem'},
    '@media (min-width: 1800px)': {paddingLeft: '32rem', paddingRight: '32rem'},
  },
  root: {
    backdropFilter: 'blur(32px)',
    backgroundColor: 'var(--bg)',
    borderRadius: '16px',
    boxShadow: 'none',
    padding: '1rem',
    width: '100%',
  },
  tableWrapper: {overflow: 'auto', marginBottom: '8px'},
}))

const COLUMNS = [
  {id: 'rank', label: 'Rank', align: 'left'},
  {id: 'name', label: 'Name', align: 'left'},
  {id: 'gameMode', label: 'Game Mode', align: 'left'},
  {id: 'mapName', label: 'Map Name', align: 'left'},
  {id: 'leader', label: 'Leader', align: 'left'},
  {id: 'nickname', label: 'Nickname', align: 'left'},
  {id: 'player', label: 'Player', align: 'left'},
  {id: 'members', label: 'Members', align: 'left'},
  {id: 'bank', label: 'Bank', align: 'right'},
  {id: 'balance', label: 'Balance', align: 'right'},
  {id: 'bounty', label: 'Bounty', align: 'right'},
  {id: 'killStreak', label: 'Kill Streak', align: 'right'},
  {id: 'money', label: 'Money', align: 'right'},
  {id: 'credits', label: 'Credits', align: 'right'},
  {id: 'crateKeys', label: 'Crate Keys', align: 'right'},
  {id: 'deaths', label: 'Deaths', align: 'right'},
  {id: 'killsUntilNextKdr', label: 'Kills → Next K/D', align: 'right'},
  {id: 'winsUntilNextWlr', label: 'Wins → Next W/L', align: 'right'},
  {id: 'kdr', label: 'K/D', align: 'right'},
  {id: 'wlr', label: 'W/L', align: 'right'},
  {id: 'bestStreak', label: 'Best Streak', align: 'right'},
  {id: 'streak', label: 'Streak', align: 'right'},
  {id: 'kills', label: 'Kills', align: 'right'},
  {id: 'level', label: 'Level', align: 'right'},
  {id: 'time', label: 'Time (seconds)', align: 'right'},
  {id: 'value', label: 'Value', align: 'right'},
  {id: 'votes', label: 'Votes', align: 'right'},
  {id: 'wins', label: 'Wins', align: 'right'},
  {id: 'xp', label: 'XP', align: 'right'},
  {id: 'strength', label: 'Strength', align: 'right'},
  {id: 'onlineTime', label: 'Playtime', align: 'right'},
  {id: 'joinedAt', label: 'Joined At', align: 'right'},
  {id: 'coins', label: 'Coins', align: 'right'},
] as const

const PERIOD_TYPES = new Set([
  LeaderboardType.GAME,
  LeaderboardType.PLAYER_DEATHS,
  LeaderboardType.PLAYER_KDR,
  LeaderboardType.PLAYER_KILLS,
  LeaderboardType.PLAYER_PLAYTIME,
  LeaderboardType.PLAYER_WINS,
  LeaderboardType.PLAYER_WLR,
])

export default function Leaderboard(): React.JSX.Element | null {
  const [searchParams] = useSearchParams()
  const {type, game} = useParams() as {type: string; game: string}
  const leaderboardColumn = game ? (`${game}_${type}` as LeaderboardColumn) : undefined
  const leaderboardType = game ? LeaderboardType.GAME : (type as LeaderboardType)
  const {status, data, error} = useLeaderboard({
    type: leaderboardType,
    column: leaderboardColumn,
    gameMode: searchParams.get('gameMode') === 'all' ? undefined : (searchParams.get('gameMode') ?? undefined),
    month: searchParams.get('month') ?? undefined,
    period: searchParams.get('period') ?? undefined,
    sortBy: searchParams.get('sortBy') ?? undefined,
    sortOrder: searchParams.get('sortOrder') ?? undefined,
    round: searchParams.get('round') ?? undefined,
    gameKey: searchParams.get('gameKey') ?? undefined,
  })

  const setSplashScreenOpen = useOverlayStore(state => state.setSplashScreenOpen)
  React.useEffect(() => {
    setSplashScreenOpen(status === 'pending')
  }, [setSplashScreenOpen, status])

  if (status === 'error') return <ErrorScreen payload={error} />
  if (!data) return null

  return <TableContent column={leaderboardColumn} data={data.data} metadata={data.metadata} type={leaderboardType} />
}

const MONTHS = [
  {
    id: 'current',
    name: 'Current Month',
  },
  {
    id: 'previous',
    name: 'Previous Month',
  },
]

const ROWS_PER_PAGE = 30

function TableContent({
  data,
  metadata,
  type,
  column,
}: {
  data: any[]
  metadata: Record<string, any>
  type: LeaderboardType
  column?: LeaderboardColumn | undefined
}): React.JSX.Element {
  const [filter, setFilter] = React.useState('')
  const [page, setPage] = React.useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const filteredData = matchSorter(data, filter, {
    keys: ['name', 'gameMode', 'mapName', 'leader', 'nickname', 'player', 'membersString'],
    sorter: rankedItems => rankedItems,
  })
  const currentSlice = filteredData.slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE)
  const {data: punishments} = usePunishmentsBulk(currentSlice?.map(value => value.xuid).filter(Boolean) ?? [])
  const {data: tournament} = useTournament(type === LeaderboardType.TOURNAMENT)
  const {data: user} = useCurrentUser()
  const gameMode = searchParams.get('gameMode') as GameMode | undefined
  const period = (searchParams.get('period') as Period | undefined) ?? Period.GLOBAL
  const setTournamentDetails = useOverlayStore(state => state.setTournamentDetails)
  const sortBy = searchParams.get('sortBy') as LeaderboardSortBy | undefined
  const sortOrder = searchParams.get('sortOrder') as string | undefined
  const roundInput = searchParams.get('round') as string | undefined
  const styles = useStyles()

  React.useEffect(() => {
    if (filter) setPage(0)
  }, [filter])

  function PeriodSelector(): React.JSX.Element | null {
    if (!PERIOD_TYPES.has(type)) return null
    return (
      <Select
        onChange={event => {
          if (event.target.value === 'global') searchParams.delete('period')
          else searchParams.set('period', event.target.value)
          setSearchParams(searchParams)
        }}
        rounded="full"
        value={period}
      >
        {getPeriodOptions(type !== LeaderboardType.PLAYER_KDR && type !== LeaderboardType.PLAYER_WLR)}
      </Select>
    )
  }

  function GameModeSelector(): React.JSX.Element {
    return (
      <Select
        onChange={event => {
          if (event.target.value === 'all') searchParams.delete('gameMode')
          else searchParams.set('gameMode', event.target.value)
          setSearchParams(searchParams)
        }}
        rounded="full"
        value={gameMode}
      >
        <option value="all">All Game Modes</option>
        {Object.entries(GameModeToString).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </Select>
    )
  }

  function MonthSelector(): React.JSX.Element {
    return (
      <Select
        onChange={event => {
          searchParams.set('month', event.target.value)
          setSearchParams(searchParams)
        }}
        rounded="full"
        value={searchParams.get('month') ?? 'current'}
      >
        {MONTHS.map(month => (
          <option key={month.id} value={month.id}>
            {month.name}
          </option>
        ))}
      </Select>
    )
  }

  function SortOrderSelector(): React.JSX.Element {
    return (
      <Select
        onChange={event => {
          searchParams.set('sortOrder', event.target.value)
          setSearchParams(searchParams)
        }}
        rounded="full"
        value={sortOrder}
      >
        <option value="asc">Ascending</option>
        <option value="desc">Descending</option>
      </Select>
    )
  }

  function SortBySelector(): React.JSX.Element {
    return (
      <Select
        onChange={event => {
          searchParams.set('sortBy', event.target.value)
          setSearchParams(searchParams)
        }}
        rounded="full"
        value={sortBy}
      >
        <option value="best">Best</option>
        <option value="current">Current</option>
      </Select>
    )
  }

  function GameKeySelector(): React.JSX.Element {
    return (
      <Select
        onChange={event => {
          searchParams.set('gameKey', event.target.value)
          setSearchParams(searchParams)
        }}
        rounded="full"
        value={searchParams.get('gameKey') ?? ''}
      >
        {Object.entries(metadata.gameKeys as Record<string, string>).map(([key, label]) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </Select>
    )
  }

  function TournamentRoundSelector(): React.JSX.Element | null {
    if (!tournament) return null

    // Calculate the current round based on the current time
    const currentTime = Math.floor(Date.now() / 1000)
    const elapsed = currentTime - Math.floor(new Date(tournament.startTime).getTime() / 1000)
    const maxRound = Math.min(Math.floor(elapsed / tournament.roundDuration) + 1, tournament.roundCount)

    // Check if the current time has passed the tournament's endTime
    const hasEnded = currentTime > Math.floor(new Date(tournament.endTime).getTime() / 1000)

    // Use input.round, or default to the current round or the last round if the tournament has ended
    const round = roundInput ? Number(roundInput) : hasEnded ? tournament.roundCount : maxRound

    // Calculate the after and before timestamps based on the round
    const after = Math.floor(new Date(tournament.startTime).getTime() / 1000) + (round - 1) * tournament.roundDuration
    const before = after + tournament.roundDuration

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
    })

    return (
      <Tooltip
        label={`${dateFormatter.format(after * 1000)} - ${dateFormatter.format(before * 1000)}${
          hasEnded ? ' (Ended)' : ''
        }`}
      >
        <Select
          onChange={event => {
            searchParams.set('round', event.target.value)
            setSearchParams(searchParams)
          }}
          rounded="full"
          value={round}
        >
          {Array.from({length: tournament.roundCount}, (_, index) => index + 1).map(round => (
            <option key={round} value={round}>
              Round {round}
            </option>
          ))}
        </Select>
      </Tooltip>
    )
  }

  let title =
    type === LeaderboardType.GAME && column
      ? LeaderboardColumnToString[column]
      : type === LeaderboardType.TOURNAMENT
        ? (tournament?.title ?? 'Tournament')
        : type === LeaderboardType.WIN_STREAKS
          ? `${metadata.gameKeyFriendly} Win Streaks`
          : LeaderboardTypeToString[type]

  if (period !== Period.GLOBAL) title += ` (${PeriodToString[period]})`
  if (sortBy) title += ` (${LeaderboardSortByToString[sortBy]})`

  const possibleWinners = filteredData.filter(value => value.votes === getDaysInMonth(subMonths(new Date(), 1)))

  return (
    <div className={styles.wrapper}>
      <MetaTags title={title} />

      <Paper className={clsx(styles.root, appStyles.backdrop)}>
        <div className={clsx(styles.tableWrapper, appStyles.scroller)}>
          <Flex align="center" direction={['column', 'row']} gap={4} justify="space-between">
            <Flex align="center" gap={2} justify="center">
              <Heading size="md">{title}</Heading>
              {type === LeaderboardType.PLAYER_VOTES && searchParams.get('month') === 'previous' && isAdmin(user) && (
                <Button
                  onClick={() =>
                    window.open(`${window.location.origin}/player/${_.sample(possibleWinners).nickname}`, '_blank')
                  }
                  size="xs"
                >
                  Open Random Top Voter
                </Button>
              )}
              {type === LeaderboardType.PLAYER_AGE && (
                <Tooltip label="This leaderboard does not include some early NetherGames players.">
                  <InfoIcon boxSize="16px" />
                </Tooltip>
              )}
              {type === LeaderboardType.TOURNAMENT && (
                <Button onClick={() => setTournamentDetails(true)} size="xs">
                  Read Details
                </Button>
              )}
              {[LeaderboardType.PLAYER_KDR, LeaderboardType.PLAYER_WLR].includes(type) && (
                <Tooltip label="This leaderboard may not be accurate as it currently is a beta feature.">
                  <span className={dropdownStyles.newIndicator}>Beta</span>
                </Tooltip>
              )}
            </Flex>
            <InputGroup w="unset">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" fontSize={['sm', 'md']} />
              </InputLeftElement>
              <Input
                autoComplete="off"
                fontSize={['sm', 'md']}
                maxLength={255}
                onChange={event => setFilter(event.target.value)}
                placeholder="Search"
                rounded="full"
                type="text"
                value={filter}
                w="unset"
              />
            </InputGroup>
          </Flex>

          <Table>
            {currentSlice?.length === 0 ? (
              <Stack align="center" h="full" justify="center" py={16}>
                <Stack align="center" justify="center" textAlign="center">
                  <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={128} width={128} />
                  <Heading fontSize="3xl" fontWeight="bold">
                    *cricket noises*
                  </Heading>
                  <Text color="gray.400" fontSize="lg">
                    No data is available for this leaderboard.
                  </Text>
                  <Text color="gray.400" fontSize="lg">
                    Try selecting a different game mode.
                  </Text>
                </Stack>
              </Stack>
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    {COLUMNS.map(({id, align, label}) => {
                      if (data[0]?.[id] == null) return null
                      return (
                        <TableCell align={align as any} key={id}>
                          {label}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currentSlice?.map(row => {
                    const punishment = row.xuid ? punishments?.[row.xuid] : undefined
                    if (punishment?.banned) return null
                    return (
                      <TableRow hover key={row.id} role="checkbox" tabIndex={-1}>
                        {COLUMNS.map(column => {
                          const value = row[column.id]

                          if (value == null) return null
                          if (['name', 'leader', 'nickname', 'player'].includes(column.id)) {
                            return (
                              <TableCell align={column.align as any} key={column.id}>
                                <Flex align="center">
                                  {['guilds', 'factions'].includes(type) && column.id === 'name' ? null : (
                                    <div style={{marginRight: '0.75rem'}}>
                                      <Avatar name={value} shouldShowOnline={false} size={32} skinHash={row.skinHash} />
                                    </div>
                                  )}
                                  <Stack align="center" direction="row" justify="center" spacing={2}>
                                    {['factions', 'guilds'].includes(type) && column.id === 'name' ? (
                                      <Text fontWeight="bold">
                                        <Link as={RouterLink} to={`/${type.slice(0, -1)}/${value}`}>
                                          {value}
                                        </Link>
                                      </Text>
                                    ) : value === 'Anonymous User' ? (
                                      <Text color="gray.400">{value}</Text>
                                    ) : (
                                      <Text
                                        color={row.topRank ? RankToColorString[row.topRank as Rank] : 'white'}
                                        fontWeight="bold"
                                      >
                                        <PlayerHoverCard username={value}>
                                          <Link as={RouterLink} to={`/player/${value}`}>
                                            {value}
                                          </Link>
                                        </PlayerHoverCard>
                                      </Text>
                                    )}
                                  </Stack>
                                </Flex>
                              </TableCell>
                            )
                          }

                          if (column.id === 'members') {
                            return (
                              <TableCell align={column.align as any} key={column.id}>
                                <Flex gap={3}>
                                  {value.map((member: any) => (
                                    <Flex align="center" key={member.xuid}>
                                      <div style={{marginRight: '0.75rem'}}>
                                        <Avatar
                                          name={value}
                                          shouldShowOnline={false}
                                          size={32}
                                          skinHash={member.skinHash}
                                        />
                                      </div>
                                      <Stack align="center" direction="row" justify="center" spacing={2}>
                                        {member.player === 'Anonymous User' ? (
                                          <Text color="gray.400">{member.player}</Text>
                                        ) : (
                                          <Text
                                            color={member.topRank ? RankToColorString[member.topRank as Rank] : 'white'}
                                            fontWeight="bold"
                                          >
                                            <PlayerHoverCard username={member.player}>
                                              <Link as={RouterLink} to={`/player/${member.player}`}>
                                                {member.player}
                                              </Link>
                                            </PlayerHoverCard>
                                          </Text>
                                        )}
                                      </Stack>
                                    </Flex>
                                  ))}
                                </Flex>
                              </TableCell>
                            )
                          }

                          return (
                            <TableCell align={column.align as any} key={column.id}>
                              {column.id === 'joinedAt' ? (
                                <Tooltip label={formatDistance(value * 1000, Date.now(), {addSuffix: true})}>
                                  <span>{dateFormatter.format(value * 1000)}</span>
                                </Tooltip>
                              ) : typeof value === 'number' ? (
                                numberFormatter.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </>
            )}
          </Table>
        </div>

        <Flex align="center" direction={['column', 'row']} justify="space-between">
          <Flex align="center" gap={4} justify="center">
            <PeriodSelector />
            {type === LeaderboardType.MAP_VOTES && <GameModeSelector />}
            {type === LeaderboardType.PLAYER_VOTES && <MonthSelector />}
            {type === LeaderboardType.PLAYER_AGE && <SortOrderSelector />}
            {type === LeaderboardType.TOURNAMENT && <TournamentRoundSelector />}
            {type === LeaderboardType.WIN_STREAKS && <GameKeySelector />}
            {type === LeaderboardType.WIN_STREAKS && <SortBySelector />}
          </Flex>

          <TablePagination
            backIconButtonProps={{'aria-label': 'previous page'}}
            component="div"
            count={filteredData.length}
            nextIconButtonProps={{'aria-label': 'next page'}}
            onPageChange={(_event, newPage) => setPage(newPage)}
            page={page}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={[]}
          />
        </Flex>
      </Paper>
    </div>
  )
}
