import styles from '@app/components/App.module.css'
import {LogoDoubleLine} from '@app/components/Logo/LogoDoubleLine'
import {SearchBar} from '@app/components/Search/SearchBar'
import {MetaTags} from '@app/components/UI/MetaTags'
import {Stack} from '@chakra-ui/react'
import type React from 'react'

export default function Home(): React.JSX.Element {
  return (
    <>
      <MetaTags />
      <Stack
        align="center"
        borderWidth={1}
        className={styles.backdrop}
        justify="center"
        m={4}
        minW={['calc(var(--chakra-sizes-sm) - 2rem)', 'lg']}
        p={4}
        rounded="2xl"
        spacing={5}
      >
        <LogoDoubleLine height="5rem" />
        <SearchBar variant="main" />
      </Stack>
    </>
  )
}
