import {useSearchResults} from '@app/common/api'
import styles from '@app/components/App.module.css'
import ErrorScreen from '@app/components/UI/ErrorScreen'
import {Box, Heading, Link, Stack} from '@chakra-ui/react'
import type React from 'react'
import {Link as RouterLink, useParams} from 'react-router-dom'

export default function SearchResults(): React.JSX.Element | null {
  const {name} = useParams()
  const {status, data, error} = useSearchResults(name!)

  if (status === 'error') return <ErrorScreen payload={error} />
  if (!data) return null

  return (
    <Stack align="center" justify="center" minW={['95%', 'lg']} p={4} spacing={4} textAlign="center">
      {data.map(result => (
        <Box borderWidth={1} className={styles.backdrop} key={result.type} p={4} rounded="2xl" w={['unset', '22.5rem']}>
          <Link as={RouterLink} to={`/${result.type}/${result.data.name}`}>
            <Heading fontSize="2xl">
              {result.data.name} ({result.type})
            </Heading>
          </Link>
        </Box>
      ))}
    </Stack>
  )
}
