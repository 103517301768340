import styles from '@app/components/App.module.css'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {MetaTags} from '@app/components/UI/MetaTags'
import {InfoIcon} from '@chakra-ui/icons'
import {Box, FormControl, FormLabel, Heading, Input, Stack, Text} from '@chakra-ui/react'
import {getLevelFromXp, getXpToReachLevel} from '@nethergames/utils'
import React from 'react'

const MAX_INT32 = 2_147_483_647
function coerceInteger(string: string): number {
  const value = Number(string)
  if (value < 0) return 0
  if (value > MAX_INT32) return MAX_INT32
  return value
}

export default function XPCalculator(): React.JSX.Element {
  const [xp, setXp] = React.useState(0)
  const level = React.useMemo(() => getLevelFromXp(xp), [xp])
  const xpToNextLevel = React.useMemo(() => getXpToReachLevel(level + 1), [level])

  return (
    <>
      <MetaTags title="XP Calculator" />
      <Box borderWidth={1} className={styles.backdrop} p={4} rounded="2xl" w={['unset', '22.5rem']}>
        <Stack align="center" direction="row">
          <Heading mb={1} size="lg">
            XP Calculator
          </Heading>
          <Tooltip label="The same calculations apply to both player XP as well as guild XP." padding={16}>
            <InfoIcon boxSize="16px" />
          </Tooltip>
        </Stack>

        <Text fontSize="sm" mb={3}>
          Use this tool to convert XP to levels and vice versa.
        </Text>

        <Stack gap={1}>
          <FormControl>
            <FormLabel>XP</FormLabel>
            <Input
              max={MAX_INT32}
              min={0}
              onChange={event => setXp(coerceInteger(event.target.value))}
              onFocus={event => event.target.select()}
              placeholder="XP"
              size="md"
              type="number"
              value={xp.toString()}
              w="full"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Levels</FormLabel>
            <Input
              max={MAX_INT32}
              min={0}
              onChange={event => setXp(getXpToReachLevel(coerceInteger(event.target.value)))}
              onFocus={event => event.target.select()}
              placeholder="Levels"
              size="md"
              type="number"
              value={level.toString()}
              w="full"
            />
          </FormControl>
          <FormControl>
            <FormLabel>XP to Next Level</FormLabel>
            <Input placeholder="XP" readOnly size="md" type="number" value={xpToNextLevel - xp} w="full" />
          </FormControl>
        </Stack>
      </Box>
    </>
  )
}
