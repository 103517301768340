import {FormType} from '@app/common/constants'
import {useRequestsStore} from '@app/common/requests'
import {useOverlayStore} from '@app/common/stores'
import Home from '@app/components/Home/Home'
import React from 'react'
import {useParams} from 'react-router-dom'

export default function RequestsModalOpener(): React.JSX.Element {
  const {formType} = useParams() as {formType: string}
  const navigate = useRequestsStore(state => state.navigate)
  const setRequestsOpen = useOverlayStore(state => state.setRequestsOpen)

  React.useEffect(() => {
    navigate(Number(formType) in FormType ? Number(formType) : FormType.WELCOME_SCREEN)
    setRequestsOpen(true)
  }, [formType, navigate, setRequestsOpen])

  return <Home />
}
