import type {APIPlayer} from '@app/common/api'
import {usePlayerLeaderboard} from '@app/common/api'
import type {GameMode} from '@app/common/constants'
import {GameModeToString, Period} from '@app/common/constants'
import {flattenObject, getPeriodOptions} from '@app/common/utils'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {LabelValue} from '@app/components/UI/LabelValue'
import {InfoIcon} from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import {formatDistanceToNow, fromUnixTime} from 'date-fns'
import _ from 'lodash'
import React from 'react'

type ModalProps = {
  buttonRef: any
  isOpen: boolean
  onClose(): void
  player: APIPlayer
}

const formatter = new Intl.NumberFormat('en-US')

export function PlayerLeaderboardModal(props: ModalProps): React.JSX.Element | null {
  const [period, setPeriod] = React.useState(Period.GLOBAL)
  const {status, data} = usePlayerLeaderboard(props.player.xuid, period, props.isOpen)

  return (
    <Modal
      finalFocusRef={props.buttonRef}
      footer={
        <Stack align="center" as={ModalFooter} direction="row" justify="space-between">
          <Select
            maxW="150px"
            onChange={event => setPeriod(event.target.value as Period)}
            rounded="full"
            value={period}
          >
            {getPeriodOptions()}
          </Select>
          <Button ml="auto" onClick={props.onClose}>
            Close
          </Button>
        </Stack>
      }
      header={
        <Stack align="center" direction="row">
          <div>Leaderboard Positions</div>
          <Tooltip
            label={`Updated on demand every 24 hours.${
              data ? ` Last updated ${formatDistanceToNow(fromUnixTime(data.timestamp))} ago.` : ''
            }`}
          >
            <InfoIcon boxSize="16px" />
          </Tooltip>
        </Stack>
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody fontSize="md">
        {status === 'error' ? (
          <Stack align="center" h="full" justify="center" py={16} textAlign="center">
            <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
            <Heading fontSize="2xl" fontWeight="bold">
              *cricket noises*
            </Heading>
            <Text color="whiteAlpha.800" fontSize="md">
              <strong>{props.player.name}</strong> has no stats available for the selected period.
            </Text>
          </Stack>
        ) : status === 'pending' || !data ? (
          <Stack align="center" justify="center" py={16}>
            <Spinner />
            {period !== Period.GLOBAL && (
              <Text fontSize="xs" pt={4}>
                It can take a while to load stats for <strong>{period}</strong>. Please be patient.
              </Text>
            )}
          </Stack>
        ) : (
          <Stack direction="column" spacing={2}>
            <Text fontSize="md" pb={1}>
              Global Kills: <strong>#{formatter.format(data.dataNested.kills)}</strong> · Global Wins:{' '}
              <strong>#{formatter.format(data.dataNested.wins)}</strong>
            </Text>

            <Accordion allowToggle>
              {Object.entries(data.dataNested).map(([gamemode, value]) => {
                if (['kills', 'wins'].includes(gamemode)) return null
                return (
                  <AccordionItem key={gamemode}>
                    <AccordionButton>
                      <Box flex={1} fontWeight="bold" textAlign="left">
                        {GameModeToString[gamemode as GameMode]}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={0}>
                      {Object.entries(value).map(([outerKey, value]) => {
                        if (Boolean(value) && (value as any).constructor === Object)
                          return Object.entries(flattenObject(value)).map(([innerKey, value]) => {
                            if (gamemode === 'cq' && outerKey === 'squads' && ['kills', 'wins'].includes(innerKey))
                              return null
                            const key = _.startCase(`${outerKey} ${innerKey}`)
                            return <LabelValue key={key} label={key} value={`#${formatter.format(value)}`} />
                          })
                        return (
                          <LabelValue
                            key={outerKey}
                            label={_.startCase(outerKey)}
                            value={`#${formatter.format(value as number)}`}
                          />
                        )
                      })}
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </Stack>
        )}
      </ModalBody>
    </Modal>
  )
}
