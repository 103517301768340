import {FormType} from '@app/common/constants'
import {CrispIcon} from '@app/components/Logo/CrispIcon'
import {MegaphoneIcon, ScaleIcon, ShieldCheckIcon} from '@heroicons/react/24/solid'
import {
  FaBug,
  FaCode,
  FaCreditCard,
  FaDiscord,
  FaEnvelope,
  FaGavel,
  FaGlobe,
  FaHammer,
  FaHeadset,
  FaImage,
  FaInfinity,
  FaLink,
  FaLock,
  FaPalette,
  FaShieldAlt,
  FaTrash,
  FaVideo,
  FaVolumeMute,
  FaYoutube,
} from 'react-icons/fa'
import {MdDesignServices, MdLocalPolice} from 'react-icons/md'
import {RxCounterClockwiseClock} from 'react-icons/rx'

export const FormTypeToIcon: Record<FormType, any> = {
  [FormType.SERVER_BAN_APPEAL]: FaGavel,
  [FormType.LIVE_CHAT_BAN_APPEAL]: CrispIcon,
  [FormType.DISCORD_BAN_APPEAL]: FaDiscord,
  [FormType.MODMAIL_BAN_APPEAL]: FaEnvelope,
  [FormType.SERVER_MUTE_APPEAL]: FaVolumeMute,
  [FormType.BUILDER_APPLICATION]: FaHammer,
  [FormType.DESIGNER_APPLICATION]: FaPalette,
  [FormType.DEVELOPER_APPLICATION]: FaCode,
  [FormType.DISCORD_MOD_APPLICATION]: FaDiscord,
  [FormType.SUPPORT_REP_APPLICATION]: FaHeadset,
  [FormType.TRAINEE_APPLICATION]: MdLocalPolice,
  [FormType.PARTNER_APPLICATION]: FaInfinity,
  [FormType.YOUTUBE_APPLICATION]: FaYoutube,
  [FormType.PLAYER_REPORT]: FaShieldAlt,
  [FormType.FACTIONS_ROLLBACK]: RxCounterClockwiseClock,
  [FormType.BILLING_ISSUE]: FaCreditCard,
  [FormType.BUG_BOUNTY]: FaLock,
  [FormType.DISCORD_REPORT]: FaDiscord,
  [FormType.VPN_ALLOWLIST]: FaGlobe,
  [FormType.GUILD_ICON_APPROVAL]: FaImage,
  [FormType.ACCOUNT_DELETION]: FaTrash,
  [FormType.COLLAGE_CLIPS]: FaVideo,
  [FormType.GAME_DESIGNER_APPLICATION]: MdDesignServices,
  [FormType.LINKED_ACCOUNTS_APPEAL]: FaLink,
  [FormType.SKYBLOCK_ROLLBACK]: RxCounterClockwiseClock,
  [FormType.BUILDING_COMPETITION]: FaHammer,
  [FormType.INTEGRITY_REPORT]: FaShieldAlt,
  [FormType.FACTION_REPORT]: FaShieldAlt,
  [FormType.GUILD_REPORT]: FaShieldAlt,

  // invalid types
  [FormType.WELCOME_SCREEN]: null,
  [FormType.APPEAL_SCREEN]: ScaleIcon,
  [FormType.STAFF_APPLICATION_SCREEN]: MdLocalPolice,
  [FormType.BUG_REPORT_SCREEN]: FaBug,
  [FormType.OTHER_HELP_SCREEN]: FaEnvelope,
  [FormType.FEEDBACK_SCREEN]: MegaphoneIcon,
  [FormType.FINISH_SCREEN]: null,
  [FormType.SUBMISSIONS_SCREEN]: ShieldCheckIcon,
  [FormType.PREVIEW_SCREEN]: null,
}
