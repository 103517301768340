import {VoteStatus, useCurrentUser, usePlayer} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import styles from '@app/components/App.module.css'
import {Avatar} from '@app/components/Avatar/Avatar'
import ErrorScreen from '@app/components/UI/ErrorScreen'
import {MetaTags} from '@app/components/UI/MetaTags'
import {Box, Button, Flex, Heading, Link, Stack, Text} from '@chakra-ui/react'
import React from 'react'
import {Link as RouterLink, useParams} from 'react-router-dom'

export default function VotePlayer(): React.JSX.Element | null {
  const {player} = useParams()
  const {status, data, error} = usePlayer(player!)
  const {data: currentUser} = useCurrentUser()
  const setSplashScreenOpen = useOverlayStore(state => state.setSplashScreenOpen)
  React.useEffect(() => {
    setSplashScreenOpen(status === 'pending')
  }, [setSplashScreenOpen, status])

  if (status === 'error') return <ErrorScreen payload={error} />
  if (!data) return null

  const isCurrentUser = currentUser?.name?.toLowerCase() === data.name.toLowerCase()
  const youHave = isCurrentUser ? 'You have' : `${data.name} has`
  const youHavent = isCurrentUser ? "You haven't" : `${data.name} hasn't`
  const your = isCurrentUser ? 'your' : 'their'

  return (
    <>
      <MetaTags title={`Vote Status for ${data.name}`} />

      <Box borderWidth={1} className={styles.backdrop} p={4} rounded="2xl" w={['unset', '22.5rem']}>
        <Flex align="center" mb={2}>
          <Avatar name={data.name} shouldShowOnline={false} size={32} skinHash={data.skinHash} />
          <Box ml={3}>
            <Link as={RouterLink} to={`/player/${data.name}`}>
              <Heading fontSize="2xl">{data.name}</Heading>
            </Link>
          </Box>
        </Flex>

        <Stack spacing={4}>
          {data.voteStatus === VoteStatus.NONE ? (
            <>
              <Text fontSize="lg">{youHavent} voted yet today.</Text>
              <Stack direction="row" justify="space-between" spacing={2}>
                <Button as="a" href="https://minecraftpocket-servers.com/server/36864/vote" target="_blank">
                  Vote Now
                </Button>
                <Button as={RouterLink} colorScheme="gray" to="/vote" variant="ghost">
                  Check Another Player
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Text fontSize="lg">
                {data.voteStatus === VoteStatus.UNCLAIMED ? (
                  <>
                    {youHave} voted today, but {your} rewards are unclaimed!{' '}
                    {isCurrentUser && 'Join NetherGames to claim your rewards.'}
                  </>
                ) : (
                  <>
                    {youHave} voted today, and {your} rewards are claimed!{' '}
                    {isCurrentUser && "Don't forget to vote again tomorrow for your chance to win a free rank upgrade."}
                  </>
                )}
              </Text>
              <Button as={RouterLink} to="/vote">
                Check Another Player
              </Button>
            </>
          )}
        </Stack>
      </Box>
    </>
  )
}
