import type React from 'react'

export function CrispIcon(props: React.SVGProps<SVGSVGElement>): React.JSX.Element {
  return (
    <svg viewBox="0 0 35 30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>Crisp</title>
      <defs>
        <filter height="138.7%" id="a" width="131.4%" x="-15.7%" y="-15.1%">
          <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter1" />
          <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
        </filter>
        <path d="m14.23 20.46-9.65 1.1L3 5.12 30.07 2l1.58 16.46-9.37 1.07-3.5 5.72-4.55-4.8z" id="b" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#fff" stroke="#fff" strokeWidth="2" xlinkHref="#b" />
      </g>
    </svg>
  )
}
