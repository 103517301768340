import {getAvatarUrl} from '@app/common/utils'
import styles from '@app/components/Avatar/Avatar.module.css'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import React from 'react'

type AvatarProps = {
  name: string
  skinHash: string | null
  online?: boolean
  size?: 32 | 80
  shouldShowOnline?: boolean
  shouldShowOnlineTooltip?: boolean
}

export function Avatar(props: AvatarProps): React.JSX.Element {
  const {name, skinHash, online = false, size = 80, shouldShowOnline = true, shouldShowOnlineTooltip = true} = props
  const statusSize = size === 32 ? 10 : 16
  const statusPos = size === 32 ? 22 : 60

  return (
    <div
      aria-hidden={false}
      aria-label={`${name}${shouldShowOnline ? `, ${online ? 'Online' : 'Offline'}` : ''}`}
      className={styles.avatarWrapper}
      role="img"
      style={{width: size, height: size}}
    >
      <svg aria-hidden className={styles.mask} height={size} viewBox={`0 0 ${size} ${size}`} width={size}>
        <title>{name}</title>
        <foreignObject
          height={size}
          mask={`url(#svg-mask-avatar-status-${shouldShowOnline ? `round-${size}` : 'none'})`}
          width={size}
          x={0}
          y={0}
        >
          <div className={styles.avatarStack}>
            <LazyImage alt="" aria-hidden className={styles.avatar} draggable={false} src={getAvatarUrl(skinHash)} />
          </div>
        </foreignObject>
        {shouldShowOnline && (
          <Tooltip label={online ? 'Online' : 'Offline'} padding={8} shouldShow={shouldShowOnlineTooltip}>
            <rect
              fill={`var(--chakra-colors-${online ? 'green' : 'gray'}-400)`}
              height={statusSize}
              mask={`url(#svg-mask-status-${online ? 'online' : 'offline'})`}
              style={{pointerEvents: 'auto'}}
              width={statusSize}
              x={statusPos}
              y={statusPos}
            />
          </Tooltip>
        )}
      </svg>
    </div>
  )
}

function LazyImage(props: React.ImgHTMLAttributes<HTMLImageElement>): React.JSX.Element {
  const {src, ...rest} = props
  const [imageSrc, setImageSrc] = React.useState<string | undefined>()
  React.useEffect(() => {
    if (src) {
      const imageLoader = new Image()
      imageLoader.src = src
      imageLoader.addEventListener('load', () => setImageSrc(src))
    }
  }, [src])
  return <img {...rest} alt="" src={imageSrc} />
}
