import {useRequestsStore} from '@app/common/requests'
import {AnimatePresence} from 'motion/react'
import type React from 'react'

export function Sequencer({children}: {children: React.ReactNode}): React.JSX.Element {
  const direction = useRequestsStore(state => state.direction)
  return (
    <AnimatePresence custom={direction} initial={false}>
      {children}
    </AnimatePresence>
  )
}
