import {useTournamentAdmin} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, FormControl, FormHelperText, FormLabel, Input, ModalBody, Stack} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {FormProvider, useForm, useFormContext} from 'react-hook-form'
import {toast} from 'react-hot-toast'

export default function AdminTournamentModal(): React.JSX.Element {
  const methods = useForm()
  const queryClient = useQueryClient()

  async function onSubmit(data: any): Promise<void> {
    try {
      await api.patch('/admin/tournament', data)
      await queryClient.invalidateQueries({queryKey: ['admin', 'tournament']})
      toast.success('Tournament updated')
    } catch {
      toast.error('Failed to update tournament')
    }
  }

  const [adminTournamentOpen, setAdminTournamentOpen] = useOverlayStore(state => [
    state.adminTournamentOpen,
    state.setAdminTournamentOpen,
  ])

  return (
    <FormProvider {...methods}>
      <Modal
        footer={
          <ModalFooter>
            <Button isLoading={methods.formState.isSubmitting} onClick={methods.handleSubmit(onSubmit)}>
              Save
            </Button>
          </ModalFooter>
        }
        header="Tournament"
        isOpen={adminTournamentOpen}
        onClose={() => setAdminTournamentOpen(false)}
      >
        <AdminTournamentModalContent />
      </Modal>
    </FormProvider>
  )
}

function AdminTournamentModalContent(): React.JSX.Element | null {
  const [isResetting, setIsResetting] = React.useState(false)
  const {data} = useTournamentAdmin()
  const exampleDate = React.useMemo(() => new Date().toISOString(), [])
  const methods = useFormContext()

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  React.useEffect(() => {
    if (data) methods.reset(data)
  }, [data])

  if (!data) return null

  async function handleTournamentReset(): Promise<void> {
    try {
      setIsResetting(true)
      await api.delete('/admin/tournament')
      toast.success('Tournament reset')
    } catch {
      toast.error('Failed to reset tournament')
    } finally {
      setIsResetting(false)
    }
  }

  return (
    <ModalBody as={Stack} gap="8px" px={0}>
      <FormControl>
        <FormLabel fontSize="sm">Danger Zone</FormLabel>
        <Button
          colorScheme="red"
          isLoading={isResetting}
          onClick={async () => handleTournamentReset()}
          size="sm"
          variant="outline"
        >
          Reset Tournament
        </Button>
        <FormHelperText>
          This will delete all logged tournament data and should only be done if a) a new tournament is about to start,
          so the old data needs to be deleted, or b) the tournament's integrity has been compromised, so a reset is
          needed.
        </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">Title</FormLabel>
        <Input
          onChange={event => methods.setValue('title', event.target.value)}
          placeholder="Bedwars Tournament"
          rounded="md"
          size="sm"
          value={methods.watch('title')}
        />
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">Columns</FormLabel>
        <Input
          onChange={event => methods.setValue('columns', event.target.value)}
          placeholder="bw_final_kills"
          rounded="md"
          size="sm"
          value={methods.watch('columns')}
        />
        <FormHelperText>
          Comma separated list of columns to use for the tournament. Must be valid columns in the database. Ask Dries if
          you're unsure.
        </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">Server Types</FormLabel>
        <Input
          onChange={event => methods.setValue('serverTypes', event.target.value)}
          placeholder="bw"
          rounded="md"
          size="sm"
          value={methods.watch('serverTypes')}
        />
        <FormHelperText>
          Comma separated list of server types to use for the tournament. Must be valid server types. Ask Dries if
          you're unsure.
        </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">Start Time</FormLabel>
        <Input
          onChange={event => methods.setValue('startTime', event.target.value)}
          placeholder={exampleDate}
          rounded="md"
          size="sm"
          value={methods.watch('startTime')}
        />
        <FormHelperText userSelect="text">ISO 8601 formatted date string in UTC.</FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">End Time</FormLabel>
        <Input
          onChange={event => methods.setValue('endTime', event.target.value)}
          placeholder={exampleDate}
          rounded="md"
          size="sm"
          value={methods.watch('endTime')}
        />
        <FormHelperText userSelect="text">ISO 8601 formatted date string in UTC.</FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">Round Duration (Seconds)</FormLabel>
        <Input
          onChange={event => methods.setValue('roundDuration', event.target.value)}
          placeholder="86400"
          rounded="md"
          size="sm"
          value={methods.watch('roundDuration')}
        />
        <FormHelperText>How long each round should last in seconds, e.g. 86400 for 24 hours.</FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">Round Count</FormLabel>
        <Input
          onChange={event => methods.setValue('roundCount', event.target.value)}
          placeholder="7"
          rounded="md"
          size="sm"
          value={methods.watch('roundCount')}
        />
        <FormHelperText>
          How many rounds should fit into the tournament start & end time, e.g. 7 for 7 days for 24 hour rounds.
        </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">Farm Threshold</FormLabel>
        <Input
          onChange={event => methods.setValue('farmThreshold', event.target.value)}
          placeholder="5"
          rounded="md"
          size="sm"
          value={methods.watch('farmThreshold')}
        />
        <FormHelperText>
          This is the maximum number of times a player can kill the same opponent within a round without the kills being
          considered 'farmed.' If this number is exceeded, all kills against that opponent are omitted from the player's
          total.
        </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">Discord Channel ID</FormLabel>
        <Input
          onChange={event => methods.setValue('discordChannelId', event.target.value)}
          placeholder="123456789"
          rounded="md"
          size="sm"
          value={methods.watch('discordChannelId')}
        />
        <FormHelperText>The channel ID where the announcement message is sent.</FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">Discord Message ID</FormLabel>
        <Input
          onChange={event => methods.setValue('discordMessageId', event.target.value)}
          placeholder="123456789"
          rounded="md"
          size="sm"
          value={methods.watch('discordMessageId')}
        />
        <FormHelperText>The message ID of the announcement message.</FormHelperText>
      </FormControl>
    </ModalBody>
  )
}
