import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button} from '@chakra-ui/react'
import React from 'react'

type ModalProps = {
  buttonRef?: any
  isOpen: boolean
  onClose(): void
}

export function LogoutModal(props: ModalProps): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)

  async function onSubmit(event: any): Promise<void> {
    event.preventDefault()
    setIsLoading(true)
    await api.post('users/@me/logout').catch(() => {})
    window.location.reload()
  }

  return (
    <Modal
      finalFocusRef={props.buttonRef}
      footer={
        <ModalFooter>
          <Button colorScheme="gray" onClick={props.onClose} variant="ghost">
            Cancel
          </Button>
          <Button colorScheme="red" isLoading={isLoading} ml={3} onClick={async event => onSubmit(event)}>
            Log Out
          </Button>
        </ModalFooter>
      }
      header="Log Out"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody fontSize="md">Are you sure you want to logout?</ModalBody>
    </Modal>
  )
}
