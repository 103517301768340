import {XBOX_REDIRECT_URI} from '@app/common/constants'
import {createPopup} from '@app/common/utils'
import {api} from '@app/hooks/useApi'
import {Button} from '@chakra-ui/react'
import type React from 'react'
import {FaXbox} from 'react-icons/fa'

export function AuthButton(): React.JSX.Element {
  return (
    <Button
      _active={{bg: '#226722'}}
      _disabled={{bg: '#107C10', cursor: 'not-allowed'}}
      _hover={{bg: '#226722'}}
      bg="#107C10"
      color="white"
      leftIcon={<FaXbox />}
      onClick={async () =>
        createPopup(async () => {
          const {data} = await api.post<{url: string}>('/oauth/xbox/authorize', {redirect_uri: XBOX_REDIRECT_URI})
          return data.url
        })
      }
      size="md"
    >
      Login with Xbox
    </Button>
  )
}
