import {useOverlayStore} from '@app/common/stores'
import Home from '@app/components/Home/Home'
import React from 'react'

export default function MFAVerifyOpener(): React.JSX.Element {
  const setMfaVerifyOpen = useOverlayStore(state => state.setMfaVerifyOpen)

  React.useEffect(() => {
    setMfaVerifyOpen(true)
  }, [setMfaVerifyOpen])

  return <Home />
}
