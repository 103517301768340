import type {APISearchResult} from '@app/common/api'
import {getErrorMessage} from '@app/common/utils'
import {api} from '@app/hooks/useApi'
import React from 'react'
import type {FieldValues, FormState, UseFormHandleSubmit, UseFormRegister} from 'react-hook-form'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

type UseSearchReturn = {
  results: APISearchResult[]
  formState: FormState<FieldValues>
  handleSubmit: UseFormHandleSubmit<FieldValues>
  register: UseFormRegister<FieldValues>
  onSubmit(data: {name: string}): void
}

export function useSearch(onSuccessfulSearch?: () => void): UseSearchReturn {
  const navigate = useNavigate()
  const [failCount, setFailCount] = React.useState<number>(0)
  const [lastSearch, setLastSearch] = React.useState<string | null>(null)
  const [results, setResults] = React.useState<APISearchResult[]>([])
  const {formState, handleSubmit, register, setError} = useForm({mode: 'onSubmit', reValidateMode: 'onSubmit'})

  async function onSubmit({name}: {name: string}): Promise<void> {
    name = name.trim()
    if (name.length === 0)
      setError('name', {type: 'validate', message: "Please enter something at least. I can't guess what you want!"})
    else if (name === lastSearch) {
      setFailCount(value => value + 1)
      if (failCount >= 5) setError('name', {type: 'validate', message: 'Is something stuck on your enter key?'})
    } else
      try {
        const {data} = await api.get<APISearchResult[]>('/search', {params: {name}})
        setLastSearch(name)
        setResults(data)
        if (data.length === 0) setError('name', {type: 'validate', message: 'Unknown Player, Guild or Faction'})
        else if (data.length === 1 && data[0]) {
          const {type, data: result} = data[0]
          navigate(`/${type}/${result.name}`)
          onSuccessfulSearch?.()
        } else {
          navigate(`/results/${name}`)
          onSuccessfulSearch?.()
        }
      } catch (error) {
        setError('name', {type: 'validate', message: getErrorMessage(error, 'name')})
      }
  }

  return {results, formState, handleSubmit, register, onSubmit}
}
