import {useServerSettingsAdmin} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, Input, ModalBody, Stack} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {FormProvider, useForm, useFormContext} from 'react-hook-form'
import {toast} from 'react-hot-toast'

export default function AdminServerSettingsModal(): React.JSX.Element {
  const methods = useForm()
  const queryClient = useQueryClient()

  async function onSubmit(data: any): Promise<void> {
    try {
      await api.patch('/admin/servers/settings', data)
      await queryClient.invalidateQueries({queryKey: ['admin', 'settings']})
      toast.success('Server MOTD updated')
    } catch {
      toast.error('Failed to update server MOTD')
    }
  }

  const [adminServerSettingsOpen, setAdminServerSettingsOpen] = useOverlayStore(state => [
    state.adminServerSettingsOpen,
    state.setAdminServerSettingsOpen,
  ])

  return (
    <FormProvider {...methods}>
      <Modal
        footer={
          <ModalFooter>
            <Button isLoading={methods.formState.isSubmitting} onClick={methods.handleSubmit(onSubmit)}>
              Save
            </Button>
          </ModalFooter>
        }
        header="Server MOTD"
        isOpen={adminServerSettingsOpen}
        onClose={() => setAdminServerSettingsOpen(false)}
      >
        <AdminServerSettingsModalContent />
      </Modal>
    </FormProvider>
  )
}

function AdminServerSettingsModalContent(): React.JSX.Element | null {
  const methods = useFormContext()
  const {data} = useServerSettingsAdmin()

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  React.useEffect(() => {
    if (data) methods.reset(data)
  }, [data])

  if (!data) return null

  return (
    <ModalBody as={Stack} gap="8px" px={0} py={4}>
      <Input defaultValue={data.motds[0]} onChange={event => methods.setValue('motds.0', event.target.value)} />
    </ModalBody>
  )
}
