import * as styles from '@app/components/CrashScreen/CrashScreen.css'
import type React from 'react'

export function CrashScreen({
  title = 'Well, this is awkward',
  note = <>Looks like the app has crashed unexpectedly...</>,
  note2 = <>We've tracked the error and will get right on it.</>,
  showReload = true,
} = {}): React.JSX.Element {
  return (
    <div className={styles.wrapper}>
      <div className={styles.image} />
      <div className={styles.text}>
        <h4>{title}</h4>
        <div className={styles.note}>
          <p>{note}</p>
          <p>{note2}</p>
        </div>
      </div>
      {showReload && (
        <button
          onClick={() => window.location.reload()}
          style={{
            alignItems: 'center',
            appearance: 'none',
            backgroundColor: '#f59e0b',
            borderRadius: 8,
            color: '#fff',
            cursor: 'pointer',
            display: 'flex',
            fontSize: 14,
            fontWeight: 500,
            height: 38,
            justifyContent: 'center',
            lineHeight: '16px',
            minHeight: 38,
            minWidth: 96,
            overflow: 'hidden',
            padding: '2px 16px',
            position: 'relative',
            textDecoration: 'none',
            transition: 'background-color .17s ease, color .17s ease',
          }}
          type="button"
        >
          Reload
        </button>
      )}
    </div>
  )
}
