import {useOverlayStore} from '@app/common/stores'
import styles from '@app/components/Loading/Loading.module.css'
import {LogoIcon} from '@app/components/Logo/LogoIcon'
import {useDelayUnmount} from '@app/hooks/useDelayedUnmount'
import CircularProgress from '@material-ui/core/CircularProgress'
import {AnimatePresence, m as motion} from 'motion/react'
import React from 'react'
import {useInterval} from 'usehooks-ts'

const LOADING_MESSAGES = [
  "'Cause every day's a brand new day, you gotta Carpe Diem",
  "'Cause you better believe I'm gonna drop that dime on you",
  'A leather wallet or a singing trout?',
  'A night to remember',
  "Ain't got no tears left to cry",
  "Ain't no mountain high enough, ain't no valley low enough",
  "Ain't nobody loves me better",
  'All the VIPs, get your things, follow me',
  "All this time I was finding myself, and I didn't know I was lost",
  'And another one bites the dust',
  "And if you feel you're sinking, I will jump right over into cold, cold water for you",
  "And we'll never be royals, it don't run in our blood",
  'Another lonely night',
  'Anything you can do, I can do better',
  'Are you with me',
  "Baby, I don't need dollar bills to have fun tonight",
  "Baby, you're a firework, come on let your colors burst",
  "Band-aids don't fix bullet holes, you say sorry just for show",
  'Because today I have multiple chances',
  'Blue are the feelings that live inside me',
  'Boom clap, the sound of my heart',
  "Born this way, baby, there ain't no other way",
  'Breathe, just breathe',
  'Bring the fire tonight',
  'Bring the lights up, bust the doors down, dust yourself off',
  'Bubblegum cherry pop, go to the hop',
  'But I set fire to the rain, watched it pour as I touched your face',
  'Call me maybe',
  'Call me, beep me, if you wanna reach me',
  'Can I have this dance?',
  'Can you hear me? SOS, help me put my mind to rest',
  "Can't blame a girl for trying",
  "Can't seem to let you go, can't seem to hold you close",
  'Caught in a bad romance',
  "Cause baby, you're a firework",
  'Cause if you liked it, then you should have put a ring on it',
  "Cause you're hot then you're cold",
  "Change my hair to black and then I'll dye it back to orange",
  "Ciao adios, I'm done",
  'Clap along if you feel like a room without a roof',
  'Click clack on the high high let the base vibrate',
  'Counting stars',
  "Cross my heart, hope to die, to my lover, I'd never lie",
  'Dancing on the hood in the middle of the woods',
  'Darling, darling, you know that we are sold out',
  'Dear boy, I wanna follow you',
  'Destroying beds in Bedwars',
  "Devil in disguise, oh you're a queen with a little bit of savage",
  'Do people actually read these?',
  "Don't push me",
  "Don't stop believin', hold on to that feeling",
  "Don't stop me now",
  "Drop it like it's hot",
  "Dude, we're getting the band back together",
  "Even though now you're a wuss: It's the way of the Platypus!",
  "Everday's a dream in California",
  "Every little thing that you say or do, I'm hung up, I'm hung up on you",
  'Everybody clap your hands, clap, clap, clap your hands',
  'Everybody wants to rule the world',
  "Everything's better with Perry",
  'Evil in the night',
  'Follow my simple instruction',
  'For a better day',
  'Forever young',
  'Getcha head in the game',
  'Getting orthodontia: Sign me up!',
  'Gimme, gimme, gimme a man after midnight',
  "Girls hit your hallelujah, 'cause Uptown Funk gon' give it to you",
  'Gitchee gitchee goo',
  'Go down to the pier and get some fish and chips',
  "Got a feeling that I'm going under, but I know that I'll make it out alive",
  "Got a long list of ex-lovers, they'll tell you I'm insane",
  'Gotta be, gotta be, gotta, gotta be me',
  'Ground control to Major Tom',
  "Guess who's back? Back again",
  'Harder. Better. Faster. Stronger',
  'Havana, ooh na-na, half of my heart is in Havana, ooh-na-na',
  "Haven't I made it obvious? Haven't I made it clear?",
  "Hello from the other side, I must've called a thousand times",
  "Hello world, hear the song that we're singing",
  "Hey brother, there's an endless road to rediscover",
  "Hey Ferb, I know what we're gonna do today",
  "Hey there, Delilah, what's it like in New York City?",
  "High on life 'til the day we die",
  'High, high hopes for a living',
  "Hope there's someone who'll take care of me when I die",
  "How could we be naughty when I'd thought we'd been so nice?",
  'How deep is your love?',
  "How far I'll go",
  'How much wood would a woodchuck chuck if a woodchuck could chuck wood?',
  'I believe we can (ooh, I believe, I believe we can)',
  'I came in like a wrecking ball, I never hit so hard in love',
  "I can almost see it, that dream I'm dreaming",
  "I can't stop singing, make it stop, make it stop",
  'I could show you incredible things',
  'I did not hit her. Oh, hi Mark',
  "I don't wanna live forever",
  "I found a girl who's in love with a girl",
  "I got a feeling that tonight's gonna be a good night",
  'I got a spirit in the sky',
  'I got a squirrel on a treadmill where my heart should be',
  'I got that sunshine in my pocket, got that good soul in my feet',
  'I got the eye of the tiger, a fighter, dancing through the fire',
  'I got the eye of the tiger, a fighter',
  'I had a heavy breakfast but a real light lunch',
  "I hear what you're saying, I got a bad reputation",
  'I just wanna go back, back to 1999',
  'I kissed a girl just to try it',
  'I kissed a girl',
  'I knew you were trouble when you walked in',
  'I know what you did last summer',
  'I lost my backbone somewhere in Stockholm',
  'I need a girl like you, yeah yeah',
  "I run so fast I got a speedin' ticket from a state trooper!",
  "I think I'd have a heart attack",
  'I think my sanity is starting to unwind',
  'I took a pill in Ibiza, to show Avicii I was cool',
  'I wanna be the ocean to your shore',
  'I wanna dance with somebody, I wanna feel the heat with somebody',
  'I want to break free, I want to break free from your lies',
  "I want you to know that it's our time",
  "I wear your granddad's clothes, I look incredible",
  'I will never forget you',
  'I will survive',
  'I wish that I could let you love me',
  "I'll be waiting for love",
  "I'm a sucker for you",
  "I'm a survivor, I'm not gon' give up",
  "I'm addicted to you, hooked on your love",
  "I'm an Albatraoz",
  "I'm beginnin' to feel like a Rap God",
  "I'm better under your reflection",
  "I'm bulletproof, nothing to lose",
  "I'm falling for ya",
  "I'm fearless",
  "I'm friends with the monster that's under my bed",
  "I'm gonna give you my heart",
  "I'm gonna I, I'm gonna g-g-g-gonna swag it out",
  "I'm gonna love ya like no one could",
  "I'm gonna swing from the chandelier, from the chandelier",
  "I'm marching to the beat of my drum",
  "I'm not in love",
  "I'm on the edge of glory, and I'm hanging on a moment of truth",
  "I'm only human, and I bleed when I fall down",
  "I'm only one call away, I'll be there to save the day",
  "I'm right where I wanna be",
  "I'm sexy and I know it",
  "I'm still falling for you",
  "I'm too sexy for my shirt",
  "I'm waking up, I feel it in my bones, enough to make my systems blow",
  "I'm walking on sunshine",
  "I'm wide awake and I can see the perfect sky is torn",
  "I'm wide awake, not losing any sleep",
  "I've been here all day",
  "I've been here all night",
  "I've got the Christmas feeling",
  'Ice ice baby',
  'If I could turn back time, if I could find a way',
  "If I didn't feel ambiguous I'd launch a big assault",
  'If we go down, then we go down together',
  'If you go hard you gotta get on the floor',
  'If you like Pina Coladas',
  'In 1959, soccer was sublime',
  'In the name of love',
  "It's a picture postcard perfect kind of a summer afternoon",
  "It's finally summer time, we can do whatever we want",
  "It's going down, I'm yelling timber",
  "It's gonna be okay",
  "It's just about the time spent together",
  "It's just the two, it's just the two of us today",
  "It's my house, come on, turn it up!",
  "It's not Christmas without you",
  "It's our vacation, what time is it?",
  "It's out with the old and in with the new",
  "It's raining men, hallelujah, it's raining men, amen",
  "It's time to leave those old days behind",
  "It's tricky, tricky, tricky, tricky",
  'Just a city boy, born and raised in South Detroit',
  "Just a small town girl, livin' in a lonely world",
  "Just fourteen hours 'till the sun goes down",
  'Keep it undercover',
  'Kick it up a notch',
  "Kill 'em with kindness",
  'Last Friday night, yeah we danced on tabletops',
  'Lean on me',
  'Legends never die',
  'Let it snow, let it snow, let it snow',
  'Let me love you',
  "Let's get down to business, let's get down to business",
  "Life's what you make it, so let's make it rock",
  'Lightning strikes every time she moves',
  'Lights will guide you home, and ignite your bones, and I will try to fix you',
  'Llama in my living room',
  'Look what you made me do',
  'Love me like you do, la-la-love me like you do',
  'Make your own kind of music',
  "Man's not hot",
  'May I have your attention, please?',
  "Me and my bro, we're takin' care of things",
  "Mom, I adore ya, and I'll do anything for ya",
  'More than you know',
  'Most girls',
  'My heart is a ghost town',
  'My house, come on',
  'My loneliness is killing me, and I, I must confess I still believe',
  'My name is no, my sign is no, my number is no',
  'Never give up, no',
  'Never gonna give you up',
  'Nice to meet you, where you been?',
  'No one can stop us when we taste the feeling',
  "No, I can't take one more step towards you",
  'Nobody else needs to know where we might go',
  "Nobody sees him, he's not looking for glory",
  "Nothing's out of reach, we got the backyard beach",
  "Now I'm the Queen of Mars",
  "Oh don't you dare look back, just keep your eyes on me",
  'Oh, sometimes. I get a good feeling, yeah',
  'On the floor',
  'On top of the world',
  'One kiss is all it takes, falling in love with me',
  'One kiss is all it takes',
  'Only girl in the world',
  'Only know you love her when you let her go',
  'Oops! I did it again',
  'Our hearts are like firestones, and when they strike, we feel the love',
  'Over the hills and far away, a million miles from L.A',
  'Party on a weekday',
  'Party rock is in the house tonight',
  "Perhaps we shouldn't put that in the chorus",
  'Please stand up, please stand up',
  'Pour some sugar on me',
  'Push it. Push it good',
  'Rather be',
  'Ready or not, here I come',
  'Reticulating splines',
  "Ring ring, I'm calling you up",
  "Rockabye, baby, don't you cry",
  'Run away-ay with me, lost souls and reverie',
  'Run, Rudolph, run',
  'Runaway with me',
  'Running down to the riptide',
  "Russia's greatest love machine",
  "Santa Claus is comin' to town",
  'Scars to your beautiful',
  'Shape of you, we push and pull like a magnet do',
  'She was the meanest cat in old Chicago town',
  "She'll change her name to Larry, Larry, Larry",
  "She's crazy like a fool, what about it Daddy Cool?",
  "She's so gone",
  'Should I stay or should I go?',
  'Sing me to sleep now, sing me to sleep',
  "Sing on the elevator, watchin' people stare",
  "So I put my hands up, they're playing my song, the butterflies fly away",
  "So if you're happy, then I'm happy too",
  "So let's twist our frown upside down",
  'So those are the rules and we hope you enjoy them',
  "So wake me up when it's all over, when I'm wiser and I'm older",
  "So we're a team, my friends, that's that",
  'So, baby, pull me closer in the backseat of your Rover',
  'Somebody that I used to know',
  "Somethin' that you've got to love this girl",
  "Something's wrong, I can feel it",
  'Sometimes I feel like a big snowman',
  'Sometimes I just wanna kiss girls, girls, girls',
  'Sometimes the bomb might explode without any warning',
  'Starships were meant to fly',
  'Stay with me',
  "Stayin' alive, stayin' alive",
  'Sugar, how you get so fly?',
  'Suity-up, booty-up, diddly-deedly-da',
  'Summer all over the world',
  'Summer belongs to everyone, so have some fun',
  'Sun is shining',
  'Surf crazy, ride the perfect wave',
  'Surfing tidal waves, creating nanobots',
  'Swish, swish, bish',
  'Take a chance with life, just roll the dice',
  'Take me into your loving arms',
  "Take me to church, I'll worship like a dog",
  'Take on me',
  'Taki Taki, Taki Taki, rumba!',
  'Tell me what you came here for',
  'Ten more days under water',
  'Thank you Santa Claus',
  "Thank you, next, I'm so grateful for my ex",
  "That's a wicked groove you were starting to move",
  'The cake is a lie',
  'The clouds look like sheep and vice versa',
  'The final countdown',
  'The middle',
  'The only way is up',
  'The real Slim Shady',
  'The ting goes skrrrahh',
  'The whole universe is against me!',
  'There was a cat that really was gone',
  "There's a platypus controlling me",
  "There's no turning back",
  "There's nothing holding me back",
  "There's somethin' in my trousers, you know it ain't ants",
  "There's something about the sunshine",
  "There's vomit on his sweater already. Mom's spaghetti",
  "These boots are made for walkin', and that's just what they'll do",
  'This girl is on fire',
  'This is my dance floor',
  'This is my fight song, take back my life song',
  "This is my heartbeat song and I'm gonna play it",
  'This is my kingdom come, this is my kingdom come',
  "This is real, this is me, I'm exactly where I'm supposed to be now",
  'Three is a magic number',
  'Today is gonna be a great day',
  'Tonight is all electric and we are all connected',
  'Tonight is all Elqctric and we are all connected',
  'Tonight is the night, come on surrender',
  'True friends will go to the ends of the earth',
  'TTYLXOX',
  'Twist my hips, watch me',
  "Two plus two is four. Minus one that's three, quick maths",
  "U can't touch this",
  'Una mattina mi son svegliato, o bella ciao, bella ciao, bella ciao ciao ciao',
  'Wait a second, let me catch my breath',
  'Waka Waka, this time for Africa',
  'We are the champions',
  'We are the heroes of our time',
  "We can light it up, so they can't put it out",
  "We can't stop, and we won't stop",
  "We don't talk about Bruno",
  "We don't talk anymore",
  'We found love in a hopeless place',
  'We gotta get back in time',
  'We might find our place in this world someday, but at least for now, I gotta go my own way',
  "We might not know why, we might not know how, but baby, tonight, we're beautiful now",
  'We will rock you',
  'We wish you a Merry Christmas',
  "We're all in this together",
  "We're all just trying to find our bones",
  "We're broken arrows in the dark",
  "We're cool for the summer",
  "We're gonna rock, we're gonna roll, we're gonna bop, we're gonna bowl",
  "We're just like a heatwave",
  "We're no strangers to love",
  "We're not gonna take it anymore",
  "We're on top of the world",
  "We're running out of reasons, but we can't let go",
  "We're soaring, flying, there's not a star in heaven that we can't reach",
  "We're the coolest cats in town",
  "We're up all night to get lucky",
  "What can I say except you're welcome",
  "What doesn't kill you makes you stronger",
  "Whatcha doin'?",
  'When was the last time you had fun?',
  "When you try your best, but you don't succeed",
  "When you're ready, come and get it",
  'Where are you now? Atlantis, under the sea',
  "Who's that guy with the vacant expression?",
  'Why are there no side railings in The Bridge?',
  'Why are you not on NG?',
  'Why I got you on my mind',
  'Why is daddy at the Thai Massage?',
  'Why is daddy wearing fake mustache?',
  'Will the real Slim Shady please stand up?',
  "Womanizer, woman-womanizer, you're a womanizer",
  'Work, work, work, work, work, work',
  'You are the music in me',
  'You belong with me',
  'You better jump right to it, tell that boy how you feel',
  'You can be my guiding light',
  'You get the best of both worlds, chill it out, take it slow, then you rock out the show',
  'You get the best of both worlds',
  'You know the rules, and so do I',
  'You know you can call on me',
  "You make me feel like I'm living a teenage dream",
  'You only get one shot, do not miss your chance to blow',
  'You should see me in a crown',
  "You wouldn't get this from any other guy",
  "You'll always find your way back home",
  "You'll believe God is a woman",
  "You're a real-life fantasy, you're a real-life fantasy",
  "You're G-O-I-N-G-D-O-W-N",
  "You're one in a million",
  "You're the one I can't ignore",
  "Your song's got me feeling like I'm",
  "Your sweet, sweet loving, won't you put it on me?",
  'One, two, buckle my shoe',
  'Three, four, shut the door',
]

function randomNoRepeats(array: string[]): () => string {
  let copy = [...array]
  return () => {
    if (copy.length === 0) copy = [...array]
    const index = Math.floor(Math.random() * copy.length)
    const item = copy[index]
    copy.splice(index, 1)
    return item!
  }
}

const chooser = randomNoRepeats(LOADING_MESSAGES)

export function Loading(): React.JSX.Element {
  const splashScreenOpen = useOverlayStore(state => state.splashScreenOpen)
  const shouldRender = useDelayUnmount(splashScreenOpen, 100)
  React.useEffect(() => {
    document.body.style.overflow = shouldRender ? 'hidden' : 'visible'
  }, [shouldRender])

  return (
    <AnimatePresence initial={false}>
      {shouldRender && (
        <motion.div
          animate={{opacity: 1}}
          className={styles.container}
          exit={{opacity: 0}}
          initial={{opacity: 0}}
          key="loading"
        >
          <LoadingContent isLoading={splashScreenOpen} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

function LoadingContent({isLoading = true} = {}): React.JSX.Element {
  const loadingMessage = React.useRef(chooser())
  const [progress, setProgress] = React.useState(10)
  useInterval(() => {
    setProgress(prev => (isLoading ? Math.max(prev + 10, 100) : 100))
  }, 100)

  return (
    <>
      <div className={styles.wrapper}>
        <CircularProgress
          className={styles.spinner!}
          color="inherit"
          size={250}
          thickness={1}
          value={progress}
          variant="determinate"
        />
        <div className={styles.logo}>
          <LogoIcon height={100} width={100} />
        </div>
      </div>
      <div>{loadingMessage.current}</div>
    </>
  )
}
