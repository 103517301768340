import type React from 'react'
import {Helmet} from 'react-helmet-async'
import {useLocation} from 'react-router-dom'

export function MetaTags({
  url = 'https://portal.nethergames.org',
  title,
  description = 'Official portal for the NetherGames Network.',
  image,
}: {
  url?: string
  title?: string
  description?: string
  image?: string
}): React.JSX.Element {
  const location = useLocation()
  const domain = `${url}${location.pathname}`
  const titleLong = title ? `${title} | NetherGames Portal` : 'NetherGames Portal'
  const titleShort = title ?? 'NetherGames Portal'

  return (
    <Helmet>
      <title>{titleLong}</title>
      <meta content={titleShort} name="title" />
      <meta content="#ff9f00" name="theme-color" />
      <meta content={description} name="description" />
      <meta content={domain} property="twitter:url" />
      <meta content={titleShort} property="twitter:title" />
      <meta content={image} property="twitter:image" />
      <meta content={description} property="twitter:description" />
      <meta content="summary_large_image" property="twitter:card" />
      <meta content={domain} property="og:url" />
      <meta content="website" property="og:type" />
      <meta content={titleShort} property="og:title" />
      <meta content={image} property="og:image" />
      <meta content={description} property="og:description" />
    </Helmet>
  )
}
