import type {BackupCode} from '@app/common/api'
import {useCurrentUser} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Button, Heading, Stack, Text} from '@chakra-ui/react'
import {saveAs} from 'file-saver'
import type React from 'react'

export default function MFABackupCodes(): React.JSX.Element {
  const [mfaBackupCodes, setMfaBackupCodes] = useOverlayStore(state => [state.mfaBackupCodes, state.setMfaBackupCodes])
  return (
    <Modal header="2FA is activated! 🎉" isOpen={mfaBackupCodes.length > 0} onClose={() => setMfaBackupCodes([])}>
      <MfaBackupCodesContent backupCodes={mfaBackupCodes} />
    </Modal>
  )
}

function getDownloadFileContents({email, backupCodes}: {email: string; backupCodes: BackupCode[]}): string {
  const message = `These are your NetherGames backup codes for account ${email}. Keep them safe!`
  const codes = backupCodes
    .map(backupCode => {
      return `* ${backupCode.code.slice(0, 4)}-${backupCode.code.slice(4)}${backupCode.consumed ? ' (used)' : ''}`
    })
    .join('\r\n')
  return `${message}\r\n\r\n${codes}`
}

function BackupCodeRenderer({code}: {code: string}): React.JSX.Element {
  return (
    <Text color="whiteAlpha.800" fontFamily="mono" fontSize="sm" userSelect="text">
      {code.slice(0, 4)}-{code.slice(4)}
    </Text>
  )
}

function MfaBackupCodesContent({backupCodes}: {backupCodes: BackupCode[]}): React.JSX.Element | null {
  const {data: user} = useCurrentUser()
  if (!user) return null

  return (
    <ModalBody fontSize="md">
      <Stack direction="column" spacing={3}>
        <Heading as="h3" color="whiteAlpha.700" fontSize="sm" letterSpacing="tight" textTransform="uppercase">
          Download Backup Codes
        </Heading>

        <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
          Without backup codes, you risk <strong>losing your account permanently</strong> if you lose access to your
          authenticator app. We recommend saving these on cloud storage.{' '}
          <strong>You won't be able to see these again!</strong>
        </Text>

        <Button
          colorScheme="orange"
          onClick={() => {
            const blob = new Blob([getDownloadFileContents({email: user.email, backupCodes})], {
              type: 'text/plain;charset=utf-8',
            })
            saveAs(blob, 'nethergames-backup-codes.txt')
          }}
          size="sm"
        >
          Download Backup Codes
        </Button>

        <Heading as="h3" color="whiteAlpha.700" fontSize="sm" letterSpacing="tight" textTransform="uppercase">
          Or if you prefer to copy them:
        </Heading>

        <Stack direction="row" spacing={4}>
          <Stack direction="column" spacing={1}>
            {backupCodes.slice(0, backupCodes.length / 2).map(backupCode => (
              <BackupCodeRenderer code={backupCode.code} key={backupCode.code} />
            ))}
          </Stack>

          <Stack direction="column" spacing={1}>
            {backupCodes.slice(backupCodes.length / 2).map(backupCode => (
              <BackupCodeRenderer code={backupCode.code} key={backupCode.code} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </ModalBody>
  )
}
