import {useTournament} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Link, ListItem, OrderedList, Stack, UnorderedList} from '@chakra-ui/react'
import {formatDistance} from 'date-fns'
import emoji from 'emoji-dictionary'
import type React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export default function TournamentDetails(): React.JSX.Element {
  const {tournamentDetails, setTournamentDetails} = useOverlayStore(state => ({
    tournamentDetails: state.tournamentDetails,
    setTournamentDetails: state.setTournamentDetails,
  }))
  return (
    <Modal header="Tournament Details" isOpen={tournamentDetails} onClose={() => setTournamentDetails(false)}>
      <TournamentDetailsContent />
    </Modal>
  )
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  month: 'short',
})

function TournamentDetailsContent(): React.JSX.Element | null {
  const {data: tournament} = useTournament()
  if (!tournament) return null
  return (
    <Stack as={ModalBody} fontSize="md" letterSpacing="tight" spacing="8px" userSelect="text">
      <ReactMarkdown
        allowedElements={['a', 'del', 'em', 'li', 'ol', 'p', 'strong', 'u', 'ul']}
        components={{
          a: ({node, children}) => {
            if (node === undefined) return null

            const props = node.properties as {href: string}
            const url = new URL(props.href)
            return (
              <Link color="orange.300" href={url.toString()} target="_blank">
                {children}
              </Link>
            )
          },
          ul: ({children}) => <UnorderedList>{children}</UnorderedList>,
          ol: ({children}) => <OrderedList>{children}</OrderedList>,
          li: ({children}) => <ListItem>{children}</ListItem>,
        }}
        remarkPlugins={[[remarkGfm, {singleTilde: false}]]}
      >
        {tournament.content
          .replaceAll('\n', '\n\n')
          .replaceAll(/<t:(?<temp2>\d+):(?<temp1>[\dA-Za-z]+)>/g, (_, timestamp, format) => {
            const date = new Date(Number.parseInt(timestamp, 10) * 1000)
            return format === 'f' ? dateFormatter.format(date) : formatDistance(date, new Date(), {addSuffix: true})
          })
          .replaceAll(/:\w+:/gi, (name: any) => emoji.getUnicode(name))}
      </ReactMarkdown>
    </Stack>
  )
}
