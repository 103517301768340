import {nanoid} from 'nanoid'
import React from 'react'

const SCOPE = nanoid()
function SvgComponent(props: React.SVGProps<SVGSVGElement>): React.JSX.Element {
  return (
    <svg viewBox="0 0 635.353 132.292" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>NetherGames</title>
      <defs>
        <path
          d="M18.27 0H265.8c6.95 1.34 13.03 5.61 16.15 12.04 4.96 10.06 1.08 22.73-8.75 28.18-6.6 4.19-13.82 1.59-20.16 5.84-5.82 3.61-8.23 10.33-8.16 16.93 0 77.34-.04 154.68-.06 232.01-.04 4.07.36 8.1-.8 12.01-1.93 7.64-7.7 14.15-15.11 16.87-4.11 1.75-8.59 1.7-12.96 1.26-8.13-1.52-15.15-7.09-18.35-14.74-2.14-4.46-1.44-9.58-1.55-14.4-.04-53.01-.16-105.98-.06-159 .03-4.29-.74-8.66-2.29-12.66-4.49-10.72-17.08-17.18-28.36-14.01-7.98 1.95-14.53 8.16-17.16 15.9-1.13 3.14-1.15 6.46-1.19 9.77.02 46.65-.05 93.36-.02 140-.04 4.31-1.26 8.79-3.42 12.52-5.36 9.37-16.99 14.27-27.42 11.35-10.09-2.61-18.33-12.24-18.08-22.86.01-20.67-.04-41.35 0-62.01-.04-5.77-1.05-11.81-4.41-16.63-5.58-8.35-16.51-12.79-26.27-10.02-7.44 1.88-13.66 7.4-16.56 14.48-1.56 3.6-1.75 7.3-1.85 11.17 0 45.67-.03 91.34-.03 137.01-.02 6.52-2.84 12.89-7.64 17.31-3.74 3.74-8.51 5.61-13.61 6.68h-6.15C10.27 373.33 1.82 364.91 0 353.69V18.19C1.8 8.74 8.79 1.74 18.27 0Zm79.74 111c.04-10-.01-20 .05-30 .01-4.27-.09-8.33-1.7-12.35-3.1-8.2-10.75-14.24-19.43-15.44-8.26-1.19-16.88 2.09-22.21 8.52-3.85 4.54-5.75 10.34-5.75 16.27-.03 14.32.02 28.67.01 43-.01 7.37 3.1 14.52 8.79 19.25 6.71 5.7 16.36 7.33 24.56 4.08 8.16-3.1 14.25-10.74 15.39-19.4.54-4.58.26-9.32.29-13.93zm107 87c.06 9.34-.05 18.68.04 28.01-.07 5.1 2.06 9.75 6.34 12.61 5.62 3.91 13.43 2.8 17.9-2.34 3.25-3.47 3.75-7.71 3.71-12.28-.07-11.67.03-23.34-.01-35.01.13-5.93-2.89-11.17-8.27-13.72-6.22-2.97-13.63-.56-17.35 5.11-3.36 4.77-2.18 12.05-2.36 17.62zm-70.1 33c-.22-5.55.91-12.19-1.71-17.18-3.13-5.65-10.45-8.15-16.21-4.84-3.96 2.11-6.54 6.5-6.37 11.01.01 7.34-.1 14.7.05 22.04.24 5.97 5.38 11.04 11.31 11.36 4.46.36 9.05-2.38 11.2-6.24 2.55-4.96 1.57-10.72 1.73-16.15zM36.9 313c-.1-9.67.17-19.36-.04-29.03-.1-5.24-2.93-9.78-7.5-12.3-5.79-3.15-13.02-1.48-17.08 3.62-2.88 3.42-3.26 7.41-3.24 11.71.11 11.67.02 23.34.07 35.01-.1 5.36 2.31 10.14 6.91 12.95 5.81 3.58 13.5 2.07 17.69-3.26 4.43-5.16 2.97-12.45 3.19-18.7z"
          id={`${SCOPE}_d`}
        />
        <path
          d="M76.93 53.21c8.68 1.2 16.33 7.24 19.43 15.44 1.61 4.02 1.71 8.08 1.7 12.35-.06 10-.01 20-.05 30-.03 4.61.25 9.35-.29 13.93-1.14 8.66-7.23 16.3-15.39 19.4-8.2 3.25-17.85 1.62-24.56-4.08-5.69-4.73-8.8-11.88-8.79-19.25.01-14.33-.04-28.68-.01-43 0-5.93 1.9-11.73 5.75-16.27 5.33-6.43 13.95-9.71 22.21-8.52z"
          id={`${SCOPE}_a`}
        />
        <path
          d="M1739 103.32c13.47-.44 28.35 1.59 40.13 8.53 7.65 4.38 13.63 11.04 17.58 18.89-6.93 3.6-13.88 7.17-20.83 10.73-6.88-9.99-20.11-15.07-31.88-15.42-7.95-.16-17.48.99-23.98 6-5.45 4.07-7.92 11.58-5.01 17.9 2.27 4.68 7.78 7.18 12.49 8.66 10.41 3.11 21.75 3.31 32.47 5.66 11.13 2.32 23.16 6.12 31.24 14.54 6.38 6.52 9.08 15 9.79 23.94v6.74c-.79 10.01-4.72 19.46-12.24 26.27-10.77 9.99-26.44 13.85-40.75 14.58-14 .64-29.53-.74-42.22-7.13-11.23-5.56-19.39-15.38-24-26.91 7.26-3.8 14.54-7.56 21.82-11.34 1.7 3.63 3.65 7.06 6.48 9.94 6.53 6.84 15.69 10.35 24.93 11.57 8.11 1 16.66.61 24.49-1.85 5.54-1.82 11.01-5.03 13.68-10.43 2.69-5.5 2.47-12.96-1.64-17.74-2.94-3.5-7.49-5.77-11.75-7.22-10.37-3.54-20.9-3.35-32-5.78-11-2.29-22.9-6.02-30.8-14.44-7.87-8.26-9.97-19.99-8.62-31 1.19-9.19 6.28-17.41 13.65-22.95 10.29-7.87 24.23-11.2 36.97-11.74z"
          id={`${SCOPE}_e`}
        />
        <path
          d="M1168 104.13c17.71.99 34.67 8.64 47.3 21.07-5.43 5.57-10.84 11.15-16.29 16.7-9.23-8.46-21.53-13.38-34.01-13.85-13.37-.43-26.63 4.21-35.81 14.14-8.09 8.67-12.29 20.1-13.23 31.81-.87 11.05 1.06 22.52 7.08 31.97 4.66 7.4 11.78 13.01 19.95 16.05 9.62 3.57 20.41 4.25 30.5 2.64 8.08-1.33 14.97-5.23 21.44-10.07.15-8.19.04-16.39.07-24.59-11.86-.01-23.71.02-35.57-.01.02-7.83-.01-15.66.02-23.5 19.85.01 39.7 0 59.55.01.01 19.49-.02 38.98.01 58.47-5.19 5.92-11.04 11.16-17.79 15.24-11.55 7.05-24.78 9.9-38.22 9.79-16.47-.1-33.01-3.62-46.54-13.41-12.58-9.03-20.75-22.67-24.22-37.64-3.92-17.07-2.72-35.57 3.81-51.86 5.32-13.25 14.51-24.79 26.7-32.32 13.43-8.39 29.58-11.44 45.25-10.64z"
          id={`${SCOPE}_f`}
        />
        <path
          d="M316 108c1.55 0 3.11.01 4.66.02 31.09 38.29 62.22 76.56 93.33 114.84.02-38.29 0-76.57.01-114.86 3.5-.01 7.01 0 10.51.01-.01 46.33-.01 92.65 0 138.98-1.41 0-2.82.01-4.22.01-31.18-38.21-62.33-76.44-93.53-114.63-.01 38.21-.01 76.41 0 114.62-3.59.01-7.17.01-10.76.01V108z"
          id={`${SCOPE}_g`}
        />
        <path
          d="M455.5 107.95c32-.02 63.99-.02 95.99 0-.02 3.31.15 6.65-.06 9.96-.72.24-1.51.34-2.36.3-27.68-.05-55.39-.01-83.06-.02-.02 17.93-.01 35.87-.01 53.81 27.17.01 54.33-.01 81.5 0 .01 3.25.01 6.51-.01 9.76-27.16-.01-54.32-.01-81.48-.01-.01 18.32-.02 36.63 0 54.94 29.29-.01 58.58 0 87.86 0 .01 3.43.01 6.87.01 10.3-32.8.01-65.59.02-98.39 0 .02-46.35 0-92.7.01-139.04z"
          id={`${SCOPE}_h`}
        />
        <path
          d="M575.94 108.01c37.12-.01 74.25-.02 111.37 0 0 3.16 0 6.32.01 9.49-16.77.01-33.54-.01-50.32.01-.01 43.16.01 86.32 0 129.49-3.59 0-7.17 0-10.76-.01.01-43.16 0-86.32.01-129.48-16.77-.02-33.54 0-50.3-.01-.01-3.17-.02-6.33-.01-9.49z"
          id={`${SCOPE}_i`}
        />
        <path
          d="M707 108c3.59 0 7.17 0 10.76.01-.01 21.99-.01 43.99-.01 65.98 29.5.01 59 .01 88.5 0 0-21.99 0-43.99-.01-65.98 3.59-.01 7.17-.01 10.76-.01v139c-3.59 0-7.17 0-10.76-.01.01-21.08.01-42.16.01-63.24h-88.5c0 21.08 0 42.16.01 63.24-3.59.01-7.17.01-10.76.01V108z"
          id={`${SCOPE}_j`}
        />
        <path
          d="M847.5 107.95c32-.02 63.99-.02 95.99 0-.02 3.3.16 6.63-.07 9.93-.64.29-1.43.4-2.35.33-27.68-.05-55.38 0-83.06-.02-.02 17.93-.01 35.87-.01 53.81 27.17.01 54.33-.01 81.5 0 .01 3.25.01 6.51-.01 9.76-27.16-.01-54.32-.01-81.48-.01-.01 18.32-.02 36.63 0 54.94 29.29-.01 58.58 0 87.86 0 .01 3.43.01 6.87.01 10.3-32.8.01-65.59.02-98.39 0 .02-46.35 0-92.7.01-139.04z"
          id={`${SCOPE}_k`}
        />
        <path
          d="M970.01 107.94c20.66.02 41.33-.05 61.99.04 12.03.13 23.77 4 32.37 12.66 12.31 12.25 15.54 31.9 9.25 47.87-3.33 8.38-9.58 15.36-17.58 19.53-7.55 4-15.75 5.36-24.21 5.52 16.33 17.81 32.63 35.63 48.96 53.44-4.78-.01-9.56.05-14.35-.06-15.87-17.66-31.91-35.18-47.83-52.8-12.62-.03-25.24-.02-37.85-.01-.01 17.62-.01 35.24 0 52.86-3.59.01-7.17.01-10.76.01 0-46.35-.01-92.71.01-139.06zm10.74 10.07v65.98c16.42 0 32.84.04 49.25-.01 8.71-.1 17.65-1.77 24.52-7.48 6.48-5.39 9.48-13.27 10.24-21.49.87-8.17-.82-16.48-5.47-23.31-4.44-6.65-11.49-11.12-19.27-12.78-5.07-1.19-9.86-.9-15.02-.92-14.75.01-29.5-.01-44.25.01z"
          id={`${SCOPE}_l`}
        />
        <path
          d="M1288.89 108.11c9.53-.25 19.11-.06 28.65-.08 20.75 46.34 41.61 92.64 62.38 138.97h-28.71c-3.89-8.65-7.82-17.29-11.68-25.96-24.23-.09-48.47-.01-72.7-.04-4 8.66-7.95 17.34-11.95 26-9.5 0-19 .01-28.51-.01 19.8-44.07 39.59-88.14 59.39-132.22 1.02-2.22 1.88-4.57 3.13-6.66zm-11.54 88.4c17.2-.03 34.39-.01 51.58-.01-8.59-19.7-17.16-39.4-25.77-59.09-8.65 19.68-17.18 39.41-25.81 59.1z"
          id={`${SCOPE}_m`}
        />
        <path
          d="M1390 108c10.06-.03 20.11.05 30.17-.04 14.59 19.86 29.04 39.83 43.55 59.74 14.49-19.9 28.98-39.81 43.49-59.7 10.01 0 20.03-.01 30.04.01v138.98c-8.75.02-17.5.01-26.25.01-.01-33.58.01-67.16-.01-100.74-15.09 20.05-30.14 40.14-45.21 60.21-1.74.03-3.48.04-5.22.06-14.81-20.12-29.52-40.33-44.3-60.48-.01 33.65-.01 67.3-.01 100.94-8.75.02-17.5.01-26.25.01V108z"
          id={`${SCOPE}_n`}
        />
        <path
          d="M1561.01 107.94h104.11c-.01 8.52.02 17.04-.02 25.56-26.03.01-52.06 0-78.1.01v31.98c25.13.02 50.26-.01 75.38.02-.02 8.16 0 16.32-.01 24.48-25.12.02-50.24 0-75.37.01 0 10.4-.01 20.79.01 31.18 26.03.01 52.07 0 78.11.01.01 8.6 0 17.2 0 25.8-34.7.02-69.41.01-104.12.01 0-46.35-.01-92.71.01-139.06z"
          id={`${SCOPE}_o`}
        />
        <path
          d="M224.72 175.27c5.38 2.55 8.4 7.79 8.27 13.72.04 11.67-.06 23.34.01 35.01.04 4.57-.46 8.81-3.71 12.28-4.47 5.14-12.28 6.25-17.9 2.34-4.28-2.86-6.41-7.51-6.34-12.61-.09-9.33.02-18.67-.04-28.01.18-5.57-1-12.85 2.36-17.62 3.72-5.67 11.13-8.08 17.35-5.11z"
          id={`${SCOPE}_b`}
        />
        <path
          d="M29.36 271.67c4.57 2.52 7.4 7.06 7.5 12.3.21 9.67-.06 19.36.04 29.03-.22 6.25 1.24 13.54-3.19 18.7-4.19 5.33-11.88 6.84-17.69 3.26-4.6-2.81-7.01-7.59-6.91-12.95-.05-11.67.04-23.34-.07-35.01-.02-4.3.36-8.29 3.24-11.71 4.06-5.1 11.29-6.77 17.08-3.62z"
          id={`${SCOPE}_c`}
        />
        <path
          d="M118.22 312.22c6.1-2.46 13.24 1.54 14.43 7.96.64 4.87.13 9.91.29 14.82-.13 5.11.85 9.94-2.88 14.06-4.49 4.97-12.38 4.64-16.51-.58-3.63-4.74-2.23-10.91-2.48-16.48.04-3.34-.15-6.71.08-10.04.39-4.33 2.93-8.18 7.07-9.74z"
          id={`${SCOPE}_p`}
        />
        <path
          d="M218.46 333.48c4.19-.9 9.2.85 12.24 3.83 4.45 4.09 5.36 10.92 2.96 16.3-2.92 6-10.24 9.61-16.7 7.49-6.1-1.66-10.62-7.76-10.23-14.11.09-6.65 5.23-12.36 11.73-13.51z"
          id={`${SCOPE}_q`}
        />
      </defs>
      <g stroke="#ffb840" strokeWidth="10pt" transform="matrix(.35278 0 0 .35278 0 0)">
        <use height="100%" href={`#${SCOPE}_a`} width="100%" />
        <use height="100%" href={`#${SCOPE}_b`} width="100%" />
        <use height="100%" href={`#${SCOPE}_c`} width="100%" />
      </g>
      <g transform="matrix(.35278 0 0 .35278 0 0)">
        <use fill="#ef963f" height="100%" href={`#${SCOPE}_d`} width="100%" />
        <use fill="#ffb840" height="100%" href={`#${SCOPE}_a`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_e`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_f`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_g`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_h`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_i`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_j`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_k`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_l`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_m`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_n`} width="100%" />
        <use fill="currentColor" height="100%" href={`#${SCOPE}_o`} width="100%" />
        <use fill="#ffb840" height="100%" href={`#${SCOPE}_b`} width="100%" />
        <use fill="#ffb840" height="100%" href={`#${SCOPE}_c`} width="100%" />
        <use fill="#ef963f" height="100%" href={`#${SCOPE}_p`} width="100%" />
        <use fill="#ef963f" height="100%" href={`#${SCOPE}_q`} width="100%" />
      </g>
    </svg>
  )
}

export const LogoOneLine = React.memo(SvgComponent)
