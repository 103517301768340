import {EvidenceType, useAPIAdminPunishments} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Punishment} from '@app/components/Modal/Punishment'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Heading, Input, Stack, Text} from '@chakra-ui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import React from 'react'

export default function AdminPunishmentsModal(): React.JSX.Element {
  const [adminPunishmentsOpen, setAdminPunishmentsOpen] = useOverlayStore(state => [
    state.adminPunishmentsOpen,
    state.setAdminPunishmentsOpen,
  ])

  return (
    <Modal header="Punishments" isOpen={adminPunishmentsOpen} onClose={() => setAdminPunishmentsOpen(false)}>
      <AdminPunishmentsModalContent />
    </Modal>
  )
}

function AdminPunishmentsModalContent(): React.JSX.Element {
  const [target, setTarget] = React.useState<string | null>(null)
  const [issuer, setIssuer] = React.useState<string | null>(null)
  const [after, setAfter] = React.useState<string | null>(null)
  const [before, setBefore] = React.useState<string | null>(null)
  const [category, setCategory] = React.useState<string | null>(null)
  const [offset, setOffset] = React.useState<number | null>(null)
  const {data: punishments} = useAPIAdminPunishments({
    after,
    before,
    category,
    evidenceType: EvidenceType.ALL,
    issuer,
    offset,
    target,
  })

  return (
    <ModalBody as={Stack} gap="8px" h="full" overflowY="auto">
      <Stack>
        <Stack>
          <SmallHeading>Target Player</SmallHeading>
          <Input
            isDisabled={Boolean(issuer)}
            onChange={event => setTarget(event.target.value)}
            placeholder="Target Player"
          />
        </Stack>

        <Stack>
          <SmallHeading>Issuer Player</SmallHeading>
          <Input
            isDisabled={Boolean(target)}
            onChange={event => setIssuer(event.target.value)}
            placeholder="Issuer Player"
          />
        </Stack>

        <Stack>
          <SmallHeading>After</SmallHeading>
          <Input onChange={event => setAfter(event.target.value)} placeholder="After" type="date" />
        </Stack>

        <Stack>
          <SmallHeading>Before</SmallHeading>
          <Input onChange={event => setBefore(event.target.value)} placeholder="Before" type="date" />
        </Stack>

        <Stack>
          <SmallHeading>Punishment Category</SmallHeading>
          <Input onChange={event => setCategory(event.target.value)} placeholder="Category" />
        </Stack>

        <Stack>
          <SmallHeading>Offset</SmallHeading>
          <Input onChange={event => setOffset(Number(event.target.value))} placeholder="Offset" />
        </Stack>
      </Stack>

      {punishments ? (
        punishments.length === 0 && (
          <Stack align="center" h="full" justify="center" py={16} textAlign="center">
            <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
            <Heading fontSize="2xl" fontWeight="bold">
              *cricket noises*
            </Heading>
            <Text color="whiteAlpha.800" fontSize="md">
              No punishments found matching your criteria.
            </Text>
          </Stack>
        )
      ) : (
        <Spinner />
      )}

      <Stack spacing="8px">
        <SmallHeading>Punishments — {punishments?.length ?? 0}</SmallHeading>
        <Stack spacing="16px">
          {punishments?.map(punishment => (
            <Punishment key={punishment.id} punishment={punishment} />
          ))}
        </Stack>
      </Stack>
    </ModalBody>
  )
}
