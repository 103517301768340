import {useOverlayStore} from '@app/common/stores'
import Home from '@app/components/Home/Home'
import React from 'react'

export default function AdminRequestsModalOpener(): React.JSX.Element {
  const setAdminRequestsOpen = useOverlayStore(state => state.setAdminRequestsOpen)
  React.useEffect(() => {
    setAdminRequestsOpen(true)
  }, [setAdminRequestsOpen])

  return <Home />
}
