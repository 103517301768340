import type {
  ChatLogContexts,
  FormFieldType,
  FormSubmissionStatus,
  GameMode,
  PromotionType,
  Rank,
  TextInputStyle,
  TextInputValidation,
  Tier,
} from '@app/common/constants'
import {
  API_HOST,
  CDN_HOST,
  FormRequirementType,
  FormType,
  GameModeToString,
  INVALID_FORM_TYPES,
} from '@app/common/constants'
import {useBanStore, useOverlayStore, useSettingsStore} from '@app/common/stores'
import type {Extra, ExtraNested, FactionData, WinsData} from '@app/common/types'
import {formatBitfield, formatMinutes, hasFlag} from '@app/common/utils'
import {api, apiV2, useBlockingAPI} from '@app/hooks/useApi'
import type {UseQueryResult} from '@tanstack/react-query'
import {QueryCache, QueryClient, useQuery, useQueryClient} from '@tanstack/react-query'
import type {AxiosError} from 'axios'
import axios from 'axios'
import _ from 'lodash'
import {devtools, persist} from 'zustand/middleware'
import {createWithEqualityFn as create} from 'zustand/traditional'

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: Error, query) => {
      if (query.meta?.onError) {
        // @ts-expect-error this is fine
        return query.meta.onError(error, query)
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        return failureCount < 3 && axios.isAxiosError(error) && error.response?.status === 429
      },
    },
  },
})

export enum PlayerFlags {
  NONE = 0,
  PREMIUM = 1 << 0,
  BYPASS_DISCORD_FORM_REQUIREMENT = 1 << 1,
  DISABLE_SKIN = 1 << 2,
  DISABLE_LOCATION = 1 << 3,
  QUARANTINED = 1 << 4,
  DISABLE_DISCORD = 1 << 5,
  DISABLE_RANK = 1 << 6,
  DISABLE_FRIEND_REQUESTS = 1 << 7,
  DISABLE_GUILD_TAG = 1 << 8,
  DISABLE_OTHER_PLAYERS = 1 << 9,
  DISABLE_ANNOUNCEMENTS = 1 << 10,
  DISABLE_FPS_MODE = 1 << 11,
  DISABLE_RANKED_CHAT = 1 << 12,
  FOOD_SHOPKEEPER_PACK = 1 << 13,
  DISABLE_GUILD_INVITES = 1 << 14,
  RAINBOW_ARMOR = 1 << 15,
  PSEUDO_ADMIN = 1 << 17,
  DISABLE_STATS = 1 << 18,
  STAFF_ALUMNI = 1 << 19,
  VPN_ALLOWLIST = 1 << 20,
  DISABLE_PREMIUM = 1 << 21,
  SPAMMER = 1 << 22,
  DISABLE_TOURNAMENT = 1 << 23,
  TOURNAMENT_CAPE = 1 << 24,
  ANONYMIZE_LEADERBOARD = 1 << 25,
  INVISIBLE_MODE = 1 << 26,
  DISABLE_WIN_STREAKS = 1 << 27,
  APPLICATION_SPAMMER = 1 << 28,
  AUTOMATED = 1 << 29,
  CAN_VIEW_PORTAL_IPS = 1 << 31,
}

export const PlayerFlagsDatabase = {
  BYPASS_DISCORD_FORM_REQUIREMENT: PlayerFlags.BYPASS_DISCORD_FORM_REQUIREMENT,
  DISABLE_SKIN: PlayerFlags.DISABLE_SKIN,
  DISABLE_LOCATION: PlayerFlags.DISABLE_LOCATION,
  QUARANTINED: PlayerFlags.QUARANTINED,
  DISABLE_DISCORD: PlayerFlags.DISABLE_DISCORD,
  FOOD_SHOPKEEPER_PACK: PlayerFlags.FOOD_SHOPKEEPER_PACK,
  DISABLE_GUILD_INVITES: PlayerFlags.DISABLE_GUILD_INVITES,
  RAINBOW_ARMOR: PlayerFlags.RAINBOW_ARMOR,
  PSEUDO_ADMIN: PlayerFlags.PSEUDO_ADMIN,
  DISABLE_STATS: PlayerFlags.DISABLE_STATS,
  STAFF_ALUMNI: PlayerFlags.STAFF_ALUMNI,
  VPN_ALLOWLIST: PlayerFlags.VPN_ALLOWLIST,
  DISABLE_PREMIUM: PlayerFlags.DISABLE_PREMIUM,
  SPAMMER: PlayerFlags.SPAMMER,
  DISABLE_TOURNAMENT: PlayerFlags.DISABLE_TOURNAMENT,
  TOURNAMENT_CAPE: PlayerFlags.TOURNAMENT_CAPE,
  ANONYMIZE_LEADERBOARD: PlayerFlags.ANONYMIZE_LEADERBOARD,
  INVISIBLE_MODE: PlayerFlags.INVISIBLE_MODE,
  DISABLE_WIN_STREAKS: PlayerFlags.DISABLE_WIN_STREAKS,
  APPLICATION_SPAMMER: PlayerFlags.APPLICATION_SPAMMER,
  AUTOMATED: PlayerFlags.AUTOMATED,
  CAN_VIEW_PLAYER_IPS: PlayerFlags.CAN_VIEW_PORTAL_IPS,
}

export const AdminPlayerFlags = formatBitfield(PlayerFlags)

export type APIUser = APIPlayer & {
  apiPermissions: number
  email: string
  flags: number
  friendSlots: number
  mfaEnabled: boolean
}

type UserStore = {
  user: APIUser | null
  setUser(user: APIUser | null): void
}

const useUserStore = create<UserStore>()(
  devtools(
    persist(set => ({user: null, setUser: (user: APIUser | null) => set({user})}), {name: 'UserStore', version: 1}),
  ),
)

export function useCurrentUser(): UseQueryResult<APIUser, AxiosError> {
  const userStore = useUserStore()
  const setMfaEnableOpen = useOverlayStore(state => state.setMfaEnableOpen)

  return useQuery<APIUser, AxiosError>({
    queryKey: ['user'],
    queryFn: async () => {
      const {data} = await api.get<APIUser>('users/@me')
      userStore.setUser(data)
      if (data.staff && !data.mfaEnabled) {
        setMfaEnableOpen(true, true)
      }

      window.localStorage.removeItem('mfa')
      return data
    },
    ...(userStore.user ? {placeholderData: userStore.user} : {}),
    meta: {
      onError: (error: Error) => {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          userStore.setUser(null)
        }
      },
    },
  })
}

export type YoutubeMetadata = {
  playerXuid: string
  channelId: string
  subscriberCount: number
  viewCount: number
  videoCount: number
  avgViewCount: number
}

export function useYoutubeMetadata(enabled = false): UseQueryResult<YoutubeMetadata, AxiosError<any>> {
  return useQuery<YoutubeMetadata, AxiosError<any>>({
    queryKey: ['user', 'youtube-metadata'],
    queryFn: async () => {
      const {data} = await api.get<YoutubeMetadata>('users/@me/youtube-metadata')
      return data
    },
    enabled,
  })
}

type APIOfflineMessage = {
  id: number
  content: string
}

export function useOfflineMessages(enabled = true): UseQueryResult<APIOfflineMessage[], AxiosError<any>> {
  return useQuery<APIOfflineMessage[], AxiosError<any>>({
    queryKey: ['user', 'offline-messages'],
    queryFn: async () => {
      const {data} = await api.get<APIOfflineMessage[]>('users/@me/offline-messages')
      return data
    },
    enabled,
  })
}

export type APIPartialPlayer = {
  xuid: string
  name: string
  skinHash: string
}

export type APIUserGuildInvite = {
  id: number
  guild: {
    id: number
    name: string
    leader: string
  }
  inviter: APIPartialPlayer
  timestamp: string
}

export function useUserGuildInvites(enabled = true): UseQueryResult<APIUserGuildInvite[], AxiosError<any>> {
  return useQuery<APIUserGuildInvite[], AxiosError<any>>({
    queryKey: ['user', 'guild-invites'],
    queryFn: async () => {
      const {data} = await api.get<APIUserGuildInvite[]>('users/@me/guild-invites')
      return data
    },
    enabled,
  })
}

export type APIGuildInvite = {
  id: number
  inviter: APIPartialPlayer
  invitee: APIPartialPlayer
  timestamp: string
}

export function useGuildInvites(guild: string, enabled = true): UseQueryResult<APIGuildInvite[], AxiosError<any>> {
  return useQuery<APIGuildInvite[], AxiosError<any>>({
    queryKey: ['guilds', guild, 'invites'],
    queryFn: async () => {
      const {data} = await api.get<APIGuildInvite[]>(`guilds/${guild}/invites`)
      return data
    },
    enabled,
  })
}

export enum RelationshipType {
  FRIEND = 1,
  INCOMING = 3,
  OUTGOING = 4,
}

export type APIRelationship = {
  xuid: string
  type: RelationshipType
  discordId: string | null
  discordTag: string | null
  lastSeen: number
  lastServer: string
  level: number
  player: string
  ranks: Rank[]
  staff: boolean
  online: boolean
  skinHash: string | null
}

export function useRelationships(enabled = true): UseQueryResult<APIRelationship[], AxiosError<any>> {
  return useQuery<APIRelationship[], AxiosError<any>>({
    queryKey: ['user', 'relationships'],
    queryFn: async () => {
      const {data} = await api.get<APIRelationship[]>('users/@me/relationships')
      return data
    },
    enabled,
  })
}

export function usePlayerRelationships(username: string): UseQueryResult<APIRelationship[], AxiosError<any>> {
  return useQuery<APIRelationship[], AxiosError<any>>({
    queryKey: ['player', username, 'relationships'],
    queryFn: async () => {
      const {data} = await api.get<APIRelationship[]>(`players/${username}/relationships`)
      return data
    },
  })
}

export enum ApplicationFlags {
  NONE = 0,
  BETA_ACCESS = 1 << 0,
  ADMIN_ACCESS = 1 << 1,
  HIGH_RATE_LIMIT = 1 << 2,
  BYPASS_CACHE = 1 << 5,
}

export const AdminApplicationFlags = formatBitfield(ApplicationFlags)

export type APIApplication = {
  id: string
  name: string
  description: string
  ownerId: string
  token: string
  createdAt: string
  lastActiveAt?: string
  flags: number
}

export function useApplications(): UseQueryResult<APIApplication[], AxiosError<any>> {
  const queryClient = useQueryClient()
  return useQuery<APIApplication[], AxiosError<any>>({
    queryKey: ['applications'],
    queryFn: async () => {
      const {data} = await api.get<APIApplication[]>('applications')
      for (const application of data) queryClient.setQueryData(['applications', application.id], application)
      return data
    },
  })
}

export function useApplication(applicationId: string): UseQueryResult<APIApplication, AxiosError<any>> {
  return useQuery<APIApplication, AxiosError<any>>({
    queryKey: ['applications', applicationId],
    queryFn: async () => {
      const {data} = await api.get<APIApplication>(`applications/${applicationId}`)
      return data
    },
  })
}

export enum FactionRole {
  MEMBER = 0,
  OFFICER = 1,
  LEADER = 2,
}

export type APIFaction = {
  id: number
  name: string
  strength: number
  balance: number
  leader: APIPlayer
  officers: APIPlayer[]
  members: APIPlayer[]
  xuids: string[]
  autoKickDays: number
  flags: number
  motd: string
  allies: string[]
}

export function useFaction(factionName: string): UseQueryResult<APIFaction, AxiosError<any>> {
  const queryClient = useQueryClient()
  return useQuery<APIFaction, AxiosError<any>>({
    queryKey: ['factions', factionName],
    queryFn: async () => {
      const {data} = await api.get<APIFaction>(`factions/${factionName}`, {params: {expand: true}})
      const members = [data.leader, ...data.officers, ...data.members]
      for (const member of members) queryClient.setQueryData(['players', member.name], member)
      data.xuids = members.map(member => member.xuid)
      return data
    },
  })
}

export type APIGuild = {
  id: number
  name: string
  leader: APIPlayer & {memberFlags: number}
  xp: number
  level: number
  memberCount: number
  maxSize: number
  motd: string
  tag: string | null
  tagColor: string | null
  officers: (APIPlayer & {memberFlags: number})[]
  members: (APIPlayer & {memberFlags: number})[]
  position: number
  xuids: string[]
  discordInvite: string | null
  autoKickDays: number
  flags: number
}

export function useGuild(guildName: string): UseQueryResult<APIGuild, AxiosError<any>> {
  const queryClient = useQueryClient()
  return useQuery<APIGuild, AxiosError<any>>({
    queryKey: ['guilds', guildName],
    queryFn: async () => {
      const {data} = await api.get<APIGuild>(`guilds/${guildName}`, {params: {expand: true, withStats: true}})
      const members = [...(data.leader ? [data.leader] : []), ...data.officers, ...data.members]
      for (const member of members) queryClient.setQueryData(['players', member.name], member)
      data.xuids = members.map(member => member.xuid)
      return data
    },
  })
}

export function useGuildToken(guildName: string): UseQueryResult<string, AxiosError<any>> {
  return useQuery<string, AxiosError<any>>({
    queryKey: ['guilds', guildName, 'token'],
    queryFn: async () => {
      const {data} = await api.get<{token: string}>(`guilds/${guildName}/token`)
      return data.token
    },
  })
}

function getRank(index: number): string {
  switch (index) {
    case 0: {
      return '🥇'
    }

    case 1: {
      return '🥈'
    }

    case 2: {
      return '🥉'
    }

    default: {
      return `#${index + 1}`
    }
  }
}

type UseLeaderboardResult = {
  data: any[]
  metadata: Record<string, any>
}

export function useLeaderboard(params: Record<string, any>): UseQueryResult<UseLeaderboardResult, AxiosError<any>> {
  const isPlayerBanned = useBanStore(state => state.isPlayerBanned)
  const bypassAnonymous = useSettingsStore(state => state.bypassAnonymous)

  return useQuery<UseLeaderboardResult, AxiosError<any>>({
    queryKey: ['leaderboard', params, bypassAnonymous],
    queryFn: async () => {
      const {data} = await api.get<any>('leaderboard', {
        params: {...params, bypassAnonymous: bypassAnonymous ? true : undefined, limit: 1000},
      })
      const results = (Array.isArray(data) ? data : data.results)
        .filter((item: any) => ('xuid' in item ? !isPlayerBanned(item.xuid) : true))
        .map((item: any, index: number) => ({
          ...item,
          gameMode: item.gameMode ? GameModeToString[item.gameMode as GameMode] : undefined,
          index,
          mapName: item.mapName ? _.startCase(item.mapName) : undefined,
          membersString: item.members ? item.members.map((member: any) => member.player).join(', ') : undefined,
          membersArray: item.members ? item.members.map((member: any) => member.player) : undefined,
          onlineTime: item.onlineTime ? formatMinutes(item.onlineTime) : undefined,
          rank: getRank(index),
        }))
      return {data: results, metadata: data}
    },
  })
}

export enum PunishmentType {
  BAN = 'BAN',
  MUTE = 'MUTE',
}

export type APIPunishmentEvidence = {
  id: string
  attachedBy: string
  type: 'AWS_MANAGED' | 'LINK' | 'REPLAY_ID' | 'TEXT'
  data: string
  note: string | null
}

export type APIChatLogEntry = {
  sender_xuid: string
  sender_name: string
  content: string
  context:
    | 'global'
    | 'party'
    | 'guild'
    | 'staff'
    | 'moderation'
    | 'admin'
    | 'trainee'
    | 'faction'
    | 'faction_ally'
    | 'ranked'
    | 'whisper'
  party_leader: string | null
  timestamp: string
  server_id: string
}

export type APIPunishment = {
  id: string
  player: string
  active: boolean
  affectedPlayers: string[]
  affectedXuids: string[]
  alt: boolean
  evidence?: APIPunishmentEvidence[] // staff only
  issuedAt: number
  issuedBy?: string | null // staff only
  issuedByName?: string | null // staff only
  note?: string | null // staff only
  permanent: boolean
  reason: string
  reasonCategory: string
  reasonName: string
  type: PunishmentType
  validUntil: number
}

type PlayerPunishmentsResponse = {
  xuid: string
  name: string
  punishments: APIPunishment[]
}

export function usePlayerPunishments(
  playerName: string,
  includeStaffDetails: boolean,
): UseQueryResult<PlayerPunishmentsResponse, AxiosError<any>> {
  return useQuery<PlayerPunishmentsResponse, AxiosError<any>>({
    queryKey: ['player', 'punishments', playerName, includeStaffDetails],
    queryFn: async () => {
      const {data} = await api.get<PlayerPunishmentsResponse>(`players/${playerName}/punishments`, {
        params: {includeStaffDetails: includeStaffDetails ? true : undefined},
      })
      return {
        ...data,
        punishments: data.punishments.sort((a, b) => b.issuedAt - a.issuedAt || b.type.localeCompare(a.type)),
      }
    },
  })
}

type TracingType = 'DEVICE_ID' | 'IP' | 'SELF_SIGNED_ID'
type TracingMatch = Omit<APIRelationship, 'type'> & {type: TracingType}

export function usePlayerAlts(playerName: string): UseQueryResult<TracingMatch[], AxiosError<any>> {
  return useQuery<TracingMatch[], AxiosError<any>>({
    queryKey: ['admin', 'players', playerName, 'alts'],
    queryFn: async () => {
      const {data} = await apiV2.get<TracingMatch[]>(`admin/players/${playerName}/alts`)
      return data
    },
  })
}

export enum VoteStatus {
  NONE = 0,
  UNCLAIMED = 1,
  CLAIMED = 2,
}

export const VoteStatusToString = {
  [VoteStatus.NONE]: 'Not Voted Yet',
  [VoteStatus.UNCLAIMED]: 'Voted (Not Claimed)',
  [VoteStatus.CLAIMED]: 'Voted (Claimed)',
}

export enum DMStatus {
  EVERYONE = 0,
  FRIENDS = 1,
  BLOCKED = 2,
}

export type WinStreaks = {
  gameKey: string
  gameKeyFriendly: string
  current: number
  best: number
}

export type APIPlayer = {
  xuid: string
  name: string
  skinHash: string | null
  online: boolean
  flags: number
  dmStatus: DMStatus
  usernameHistory: string[]
  reportCount: number

  // Profile
  bio: string
  discordId: string | null
  discordTag: string | null
  guild: string | null
  ranks: Rank[]
  tier: Tier | null

  // Properties
  banned: boolean
  bannedUntil: number | null
  muted: boolean
  mutedUntil: number | null
  staff: boolean
  titan: boolean
  titanUntil: string | null
  lastRankTimestamp: string | null
  voteStatus: VoteStatus

  // Tracking
  firstJoin: string | null
  firstJoined: number
  lastJoin: string | null
  lastJoined: number
  lastQuit: number
  lastSeen: string
  lastServer: string

  // Stats
  crateKeys: number
  credits: number
  deaths: number
  deathsTotal: number
  kdr: number
  kdrTotal: number
  kills: number
  killsTotal: number
  level: number
  losses: number
  wins: number
  wlr: number
  xp: number

  // Display
  formattedLevel: string
  levelColors: string[]
  levelFormat: string
  rankColors: string[]
  skinVisibility: boolean
  tierColor: string | null
  youtubeChannelUrl: string | null

  // Progress
  killsUntilNextKdr: number
  winsUntilNextWlr: number
  xpToNextLevel: number
  discoveredTokens: string[]
  discoveredZones: string[]

  // Expanded
  factionData: FactionData | null
  guildData: any
  lastServerParsed: {pretty: string}
  punishmentsNew: APIPunishment[]
  winsData: WinsData
  winStreaks?: WinStreaks[]

  // Expanded Stats
  extra: Extra
  extraNested: ExtraNested
  leaderboard: boolean
}

export function usePlayer(
  playerName: string,
  {period, enabled = true}: {period?: string; enabled?: boolean} = {},
): UseQueryResult<APIPlayer, AxiosError<any>> {
  return useQuery<APIPlayer, AxiosError<any>>({
    queryKey: ['players', playerName, period],
    queryFn: async () => {
      const {data} = await api.get<APIPlayer>(`players/${playerName}`, {
        params: {period, withReportCount: true, withUsernameHistory: true, withWinStreaks: true},
      })
      return data
    },
    enabled,
  })
}

export type PunishmentProperties = Pick<APIPlayer, 'banned' | 'bannedUntil' | 'muted' | 'mutedUntil'> & {alt: boolean}
export type PunishmentsBulk = Record<string, PunishmentProperties>

export function usePunishmentsBulk(xuids: string[]): UseQueryResult<PunishmentsBulk, AxiosError<any>> {
  const addBannedPlayer = useBanStore(state => state.addPlayer)
  return useQuery<PunishmentsBulk, AxiosError<any>>({
    queryKey: ['punishments', xuids],
    queryFn: async () => {
      const {data} = await api.post<PunishmentsBulk>('punishments', {xuids})
      for (const [xuid, {banned}] of Object.entries(data)) {
        if (banned) addBannedPlayer(xuid)
      }

      return data
    },
    enabled: xuids.length > 0,
  })
}

type APIPlayerLeaderboard = {
  player: string
  dataNested: ExtraNested & {kills: number; wins: number}
  timestamp: number
}

export type PunishmentReason = {
  name: string
  type: 'BAN' | 'MUTE'
  hasRollback: boolean
  adminOnly: boolean
  internalOnly: boolean
  nonAppealable: boolean
  flagRecentEvidence: boolean
  points: number
  category: string
}

export function useAdminListPunishmentReasons(): UseQueryResult<PunishmentReason[], AxiosError<any>> {
  return useQuery<PunishmentReason[], AxiosError<any>>({
    queryKey: ['admin', 'list', 'punishmentReasons'],
    queryFn: async () => {
      const {data} = await api.get<PunishmentReason[]>('admin/punishment-reasons')
      return data
    },
  })
}

export function usePlayerLeaderboard(
  xuid: string,
  period: string,
  enabled: boolean,
): UseQueryResult<APIPlayerLeaderboard, AxiosError<any>> {
  return useQuery<APIPlayerLeaderboard, AxiosError<any>>({
    queryKey: ['players', xuid, 'leaderboard', period],
    queryFn: async () => {
      const {data} = await api.get<APIPlayerLeaderboard>(`players/${xuid}/leaderboard`, {params: {period}})
      return data
    },
    enabled,
  })
}

export type APISearchResult =
  | {type: 'faction'; data: APIFaction}
  | {type: 'guild'; data: APIGuild}
  | {type: 'player'; data: APIPlayer}

export function useSearchResults(name: string): UseQueryResult<APISearchResult[], AxiosError<any>> {
  const api = useBlockingAPI()
  return useQuery<APISearchResult[], AxiosError<any>>({
    queryKey: ['search', name],
    queryFn: async () => {
      const {data} = await api.get<APISearchResult[]>('search', {params: {name}})
      return data
    },
  })
}

export type APIStaffDirectoryEntry = {
  xuid: string
  player: string
  ranks: Rank[]
  discordId: string | null
  discordTag: string | null
  lastSeen: string
  lastServer: string
  online: boolean
  skinHash: string | null
  mfaEnabled: boolean
}

export type APIStaffDirectory = {
  total: number
  staff: Record<string, APIStaffDirectoryEntry[]>
}

export function useStaffDirectory(): UseQueryResult<APIStaffDirectory, AxiosError<any>> {
  return useQuery<APIStaffDirectory, AxiosError<any>>({
    queryKey: ['admin', 'staff-directory'],
    queryFn: async () => {
      const {data} = await api.get<APIStaffDirectory>('admin/staff-directory')
      return data
    },
  })
}

export function useYoutubeDirectory(): UseQueryResult<APIStaffDirectory, AxiosError<any>> {
  return useQuery<APIStaffDirectory, AxiosError<any>>({
    queryKey: ['admin', 'youtube-directory'],
    queryFn: async () => {
      const {data} = await api.get<APIStaffDirectory>('admin/youtube-directory')
      return data
    },
  })
}

export function useStaffAlumni(): UseQueryResult<APIStaffDirectoryEntry[], AxiosError<any>> {
  return useQuery<APIStaffDirectoryEntry[], AxiosError<any>>({
    queryKey: ['admin', 'staff-alumni'],
    queryFn: async () => {
      const {data} = await api.get<APIStaffDirectoryEntry[]>('admin/staff-alumni')
      return data
    },
  })
}

export function useHealthCheck(): UseQueryResult<boolean, AxiosError> {
  return useQuery<boolean, AxiosError>({
    queryKey: ['health'],
    queryFn: async () => {
      const {status} = await axios.get(`${API_HOST}/_health`, {validateStatus: () => true})
      return status === 200
    },
    initialData: true,
  })
}

export enum Provider {
  DISCORD = 'discord',
  XBOX = 'xbox',
  YOUTUBE = 'youtube',
}

export function useOAuthCallback({
  provider,
  code,
  state,
  redirectUri,
}: {
  provider: Provider
  code: string
  state: string
  redirectUri?: string
}): UseQueryResult<any, AxiosError<any>> {
  return useQuery<any, AxiosError>({
    queryKey: ['oauth', 'callback', provider, code, state, redirectUri],
    queryFn: async () => {
      const {data} = await api.post<any>(`/oauth/${provider}/callback`, {
        code,
        state,
        redirect_uri: redirectUri,
      })
      return data
    },
    refetchOnWindowFocus: false,
  })
}

export type APITextInputResponse = {
  type: FormFieldType.TEXT_INPUT
  name: string
  style: TextInputStyle
  validate: TextInputValidation
  label: string
  description: string | null
  minLength: number
  maxLength: number
  required: boolean
  disabled: boolean
  placeholder: string | null
  value?: string
  metadata?: Record<string, any>
}

export type APISelectOptionResponse = {
  label: string
  value: string
  description: string | null
  icon: string | null
  default: boolean
}

export type APISelectResponse = {
  type: FormFieldType.SELECT
  name: string
  label: string
  description: string | null
  required: boolean
  options: APISelectOptionResponse[]
  placeholder: string | null
  minValues: number
  maxValues: number
  value?: string[] | string
  metadata?: Record<string, any>
}

export type APIRadioResponse = {
  type: FormFieldType.RADIO
  name: string
  label: string
  description: string | null
  required: boolean
  options: string[]
  defaultOption?: string
  value?: string
}

export type APINumberInputResponse = {
  type: FormFieldType.NUMBER_INPUT
  name: string
  label: string
  description: string | null
  required: boolean
  min: number
  max: number
  value?: string
}

export type APIFormFieldResponse = APINumberInputResponse | APIRadioResponse | APISelectResponse | APITextInputResponse

export type APIFormRequirementResponse = {
  type: FormRequirementType
  value: boolean
  expiresAt?: string | null
}

export type APIFormResponse = {
  type: FormType
  requirements: APIFormRequirementResponse[]
  fields: APIFormFieldResponse[]
}

export type APIFormSubmissionResponse = {
  id: string
  flags: number
  formType: FormType
  playerXuid: string
  offenderXuid: string
  timestamp: string
  status: FormSubmissionStatus
  reason: string
  reviewedAt: string | null
  reviewedBy?: string | null
  punisherXuids?: string[]
  fields: APIFormFieldResponse[]
  gptScore: number
  score?: number
}

type WithRequired<T, K extends keyof T> = T & {[P in K]-?: T[P]}

type APIFormSubmissionResponseAdmin = APIFormSubmissionResponse & {
  player: string
  offender: string
  reviewer: string | null
  punishments: WithRequired<APIPunishment, 'evidence' | 'issuedBy' | 'issuedByName'>[]
}

class APIFormData {
  public readonly data: APIFormResponse

  public constructor(data: APIFormResponse) {
    this.data = data
  }

  public get requirements(): APIFormRequirementResponse[] {
    return this.data.requirements
  }

  public get canDismissRequirements(): boolean {
    return Boolean(this.requirements.every(requirement => requirement.value))
  }

  public get isDiscordRequired(): boolean {
    return Boolean(this.requirements.some(req => req.type === FormRequirementType.DISCORD_CONNECTION))
  }

  public get isYoutubeRequired(): boolean {
    return (
      this.data.type === FormType.PARTNER_APPLICATION ||
      Boolean(this.requirements.some(req => req.type === FormRequirementType.YOUTUBE_CONNECTION))
    )
  }

  public get isConnectionRequired(): boolean {
    return this.isDiscordRequired || this.isYoutubeRequired
  }
}

export function useAPIForm(formType: FormType): UseQueryResult<APIFormData, AxiosError<any>> {
  return useQuery<APIFormData, AxiosError<any>>({
    queryKey: ['form', formType],
    queryFn: async () => {
      const {data} = await api.get<APIFormResponse>(`forms/${formType}`)
      return new APIFormData(data)
    },
    enabled: !INVALID_FORM_TYPES.has(formType),
  })
}

export function useAPIFormSubmissions(): UseQueryResult<APIFormSubmissionResponse[], AxiosError<any>> {
  return useQuery<APIFormSubmissionResponse[], AxiosError<any>>({
    queryKey: ['user', 'form-submissions'],
    queryFn: async () => {
      const {data} = await api.get<APIFormSubmissionResponse[]>('users/@me/form-submissions')
      return data
    },
  })
}

export function useAPIFormSubmissionAdmin(
  requestId: string,
): UseQueryResult<APIFormSubmissionResponseAdmin, AxiosError<any>> {
  return useQuery<APIFormSubmissionResponseAdmin, AxiosError<any>>({
    queryKey: ['admin', 'form-submissions', requestId],
    queryFn: async () => {
      const {data} = await api.get<APIFormSubmissionResponseAdmin>(`admin/form-submissions/${requestId}`)
      return data
    },
    enabled: Boolean(requestId),
  })
}

type APIFormSubmissionsResponseAdmin = {
  players: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>[]
  offenders: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>[]
  reviewers: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>[]
  punishers: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>[]
  submissions: APIFormSubmissionResponse[]
}

export function useAPIFormSubmissionsAdmin(params: {
  formType: FormType[]
  status: FormSubmissionStatus[]
  playerXuid: string[]
  offenderXuid: string[]
  reviewedBy: string[]
  punisherXuids: string[]
}): UseQueryResult<APIFormSubmissionsResponseAdmin, AxiosError<any>> {
  return useQuery<APIFormSubmissionsResponseAdmin, AxiosError<any>>({
    queryKey: ['admin', 'form-submissions', params],
    queryFn: async () => {
      const {data} = await apiV2.post<APIFormSubmissionsResponseAdmin>('admin/form-submissions', params)
      return data
    },
  })
}

type APIFormSubmissionSimilarsAdmin = {
  players: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>[]
  submissions: APIFormSubmissionResponse[]
}

export function useAPIFormSubmissionSimilarsAdmin(
  requestId: string,
  includeAllFormTypes: boolean,
): UseQueryResult<APIFormSubmissionSimilarsAdmin, AxiosError<any>> {
  return useQuery({
    queryKey: ['admin', 'form-submissions', requestId, 'similars', includeAllFormTypes],
    queryFn: async () => {
      const {data} = await api.get(`admin/form-submissions/${requestId}/similars`, {params: {includeAllFormTypes}})
      return data
    },
  })
}

export enum EvidenceType {
  ALL = 'ALL',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  ONLY_SUBMITTED = 'ONLY_SUBMITTED',
}

export function useAPIStaffChatLogQuery(
  targetPlayer: string | null,
  query: StaffChatLogsQuery,
): UseQueryResult<APIChatLogEntry[], AxiosError<any>> {
  return useQuery({
    queryKey: ['staff', 'chat_logs', query],
    queryFn: async () => {
      const {data} = await api.post<APIChatLogEntry[]>(
        `players/${targetPlayer}/chat_logs`,
        query.context_search && query.context_search.length > 0
          ? {
              context_search: query.context_search,
            }
          : {},
        {
          params: {
            content_search: query.content_search || undefined,
            page: query.page || undefined,
            page_size: query.page_size || undefined,
            limit: query.limit || undefined,
          },
        },
      )
      return data
    },
  })
}

type StaffChatLogsQuery = {
  page?: number | null
  page_size?: number | null
  content_search?: string | null
  context_search?: ChatLogContexts[] | null
  limit?: number
}

type AdminPunishmentsQuery = {
  target?: string | null
  issuer?: string | null
  after?: string | null
  before?: string | null
  category?: string | null
  evidenceType?: EvidenceType | null
  offset?: number | null
}

export function useAPIAdminPunishments(query: AdminPunishmentsQuery): UseQueryResult<APIPunishment[], AxiosError<any>> {
  return useQuery({
    queryKey: ['admin', 'punishments', query],
    queryFn: async () => {
      const {data} = await api.get<APIPunishment[]>('admin/punishments', {
        params: {
          target: query.target || undefined,
          issuer: query.issuer || undefined,
          after: query.after || undefined,
          before: query.before || undefined,
          category: query.category || undefined,
          evidenceType: query.evidenceType || undefined,
          offset: query.offset || undefined,
        },
      })
      return data
    },
  })
}

export type InventoryBackupItem = {
  slot: number
  name: string
  image: string
  damage: number
  enchantments: string[]
  contraband: boolean
}

export type DeathCause = {
  rollbackId: string
  isCombatLogger: boolean
  deathCause: number
  deathCauseBy: string // damager name or "Self"
  deathCauseXuid: string | -1 // damager xuid or -1
  deathCauseLocation: string
  streak: number
}

export type APIInventoryBackup = {
  deathCause: DeathCause
  deathTime: number
  hasExecuted: boolean
  inventoryId: number
  itemCount: number
  playerName: string
  inventory: InventoryBackupItem[]
  armor: InventoryBackupItem[]
  damagerName: string
}

export function useAPIInventoryBackup(
  inventoryId: string | null,
  formType: FormType.SKYBLOCK_ROLLBACK | FormType.FACTIONS_ROLLBACK,
  admin = false,
): UseQueryResult<APIInventoryBackup, AxiosError<any>> {
  return useQuery<APIInventoryBackup, AxiosError<any>>({
    queryKey: ['inventory-backups', inventoryId],
    queryFn: async () => {
      const {data} = await api.get<APIInventoryBackup>(
        `${admin ? 'admin' : 'users/@me'}/inventory-backups/${
          formType === FormType.FACTIONS_ROLLBACK ? 'factions' : 'skyblock'
        }/${inventoryId}`,
      )
      return data
    },
    enabled: Boolean(inventoryId),
  })
}

type AdminPlayer = {
  xuid: string
  player: string
  email: string
  ranks: Rank[]
  flags: number
  mfaEnabled: boolean
  apiPermissions: number
  bio: string
  discordId: string | null
  discordTag: string | null
  extraFriends: number
  stripeCustomerId: string | null
  titanExpire: number
  youtubeChannelId: string | null
  skinHash: string | null
  xp: number
}

export function useAdminPlayer(player: string): UseQueryResult<AdminPlayer, AxiosError<any>> {
  return useQuery<AdminPlayer, AxiosError<any>>({
    queryKey: ['admin', 'players', player],
    queryFn: async () => {
      const {data} = await api.get<AdminPlayer>(`admin/players/${player}`)
      return data
    },
  })
}

export type AdminPlayerSession = {
  id: string
  playerXuid: string
  creationTime: string
  expirationTime: string
  approxLastUsedTime: string
  clientIp: string
  clientCountry: string
  clientOs: string
  clientBrowser: string
}

export function useAdminPlayerSessions(
  player: string,
  enabled = true,
): UseQueryResult<AdminPlayerSession[], AxiosError<any>> {
  return useQuery<AdminPlayerSession[], AxiosError<any>>({
    queryKey: ['admin', 'players', player, 'sessions'],
    queryFn: async () => {
      const {data} = await api.get<AdminPlayerSession[]>(`admin/players/${player}/sessions`)
      return data
    },
    enabled,
  })
}

export function useAdminPlayerApplications(player: string): UseQueryResult<APIApplication[], AxiosError<any>> {
  return useQuery<APIApplication[], AxiosError<any>>({
    queryKey: ['admin', 'players', player, 'applications'],
    queryFn: async () => {
      const {data} = await api.get<APIApplication[]>(`admin/players/${player}/applications`)
      return data
    },
  })
}

export function useAdminApplication(applicationId: string): UseQueryResult<APIApplication, AxiosError<any>> {
  return useQuery<APIApplication, AxiosError<any>>({
    queryKey: ['admin', 'applications', applicationId],
    queryFn: async () => {
      const {data} = await api.get<APIApplication>(`admin/applications/${applicationId}`)
      return data
    },
  })
}

export enum ProductType {
  ULTRA_RANK = 1,
  EMERALD_RANK = 2,
  LEGEND_RANK = 3,
  TITAN_RANK = 4,
  MINER_MINI_HELPER = 5,
  LUMBERJACK_MINI_HELPER = 6,
  HARVESTER_MINI_HELPER = 7,
  EXTRA_FRIEND_SLOTS = 9,
  STATS_RESET_KEY = 10,
  XP_BOOSTERS = 11,
  TRANSFER_KEYS = 12,
  FOOD_SHOPKEEPER_PACK = 13,
  UNICORN = 14,
}

export enum AdminAuditLogActionType {
  PLAYER_BIO_DELETE = 3,
  PLAYER_DELETE = 4,
  PLAYER_SKIN_DELETE = 5,
  PLAYER_UPDATE = 6,
  ENTITLEMENT_CREATE = 7,
  PLAYER_STATS_DELETE = 8,
  PLAYER_MFA_DISABLE = 9,
  PAYMENT_REFUND = 10,
  PUNISHMENT_WHITELIST_CREATE = 11,
  PLAYER_ALT_UNLINK = 12,
  PLAYER_SYSTEM_MESSAGE = 13,
}

export const AdminAuditLogActionTypeToString: Record<AdminAuditLogActionType, string> = {
  [AdminAuditLogActionType.PLAYER_BIO_DELETE]: 'Clear Player Bio',
  [AdminAuditLogActionType.PLAYER_DELETE]: 'Delete Player',
  [AdminAuditLogActionType.PLAYER_SKIN_DELETE]: 'Clear Player Skin',
  [AdminAuditLogActionType.PLAYER_UPDATE]: 'Update Player',
  [AdminAuditLogActionType.ENTITLEMENT_CREATE]: 'Create Entitlement',
  [AdminAuditLogActionType.PLAYER_STATS_DELETE]: 'Reset Player Stats',
  [AdminAuditLogActionType.PLAYER_MFA_DISABLE]: 'Disable Player MFA',
  [AdminAuditLogActionType.PAYMENT_REFUND]: 'Refund Payment',
  [AdminAuditLogActionType.PUNISHMENT_WHITELIST_CREATE]: 'Create Punishment Whitelist',
  [AdminAuditLogActionType.PLAYER_ALT_UNLINK]: 'Unlink Player Alt',
  [AdminAuditLogActionType.PLAYER_SYSTEM_MESSAGE]: 'Send Player System Message',
}

type AdminAuditLogEntryBase = {
  id: number
  actionType: AdminAuditLogActionType
  actorXuid: string
  actorName: string
  targetXuid: string | null
  targetName: string | null
  timestamp: string
}

export enum AdminAuditLogChangeStatus {
  ADDED = 'added',
  DELETED = 'deleted',
  UPDATED = 'updated',
}

export type AdminAuditLogChange = {
  property: string
  previousValue: any
  currentValue: any
  status: AdminAuditLogChangeStatus
}

export type AdminAuditLogEntry = AdminAuditLogEntryBase &
  (
    | {
        version: 1
        changes: Record<string, any>
      }
    | {
        version: 2
        changes: AdminAuditLogChange[]
      }
  )

type AdminAuditLogResponse = {
  entries: AdminAuditLogEntry[]
  actors: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>[]
  players: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>[]
}

export function useAuditLogAdmin({
  actionType,
  actor,
  target,
}: {
  actionType?: AdminAuditLogActionType | null
  actor?: string | null
  target?: string | null
}): UseQueryResult<AdminAuditLogResponse, AxiosError<any>> {
  return useQuery<AdminAuditLogResponse, AxiosError<any>>({
    queryKey: ['admin', 'audit-logs', actionType, actor, target],
    queryFn: async () => {
      const {data} = await api.get<AdminAuditLogResponse>('admin/audit-logs', {
        params: {
          actionType: actionType || undefined,
          actor: actor || undefined,
          target: target || undefined,
        },
      })
      return {
        ...data,
        entries: data.entries.map(entry => ({
          ...entry,
          actorName: data.actors.find(actor => actor.xuid === entry.actorXuid)?.name ?? 'Deleted User',
          targetName: data.players.find(player => player.xuid === entry.targetXuid)?.name ?? 'Deleted User',
          flagNames:
            entry.version === 2
              ? entry.changes
                  .filter(change => change.property === 'flags')
                  .flatMap(change =>
                    Object.entries(PlayerFlagsDatabase)
                      .map(([flag, value]) => {
                        const flagAdded = hasFlag(change.currentValue, value)
                        const flagRemoved = hasFlag(change.previousValue, value)
                        if ((flagAdded && flagRemoved) || (!flagAdded && !flagRemoved)) {
                          return null
                        }

                        return flagAdded ? `+${flag}` : `-${flag}`
                      })
                      .filter(Boolean)
                      .join(', '),
                  )
              : [],
        })),
      }
    },
  })
}

export type BackupCode = {
  code: string
  consumed: boolean
}

export type WebauthnAuthenticator = {
  id: string
  name: string
  counter: number
  credentialBackedUp: boolean
  credentialDeviceType: 'multiDevice' | 'singleDevice'
  credentialId: string
  credentialPublicKey: string
}

export function useWebauthnAuthenticators(): UseQueryResult<WebauthnAuthenticator[], AxiosError<any>> {
  return useQuery<WebauthnAuthenticator[], AxiosError<any>>({
    queryKey: ['user', 'webauthn'],
    queryFn: async () => {
      const {data} = await api.get<WebauthnAuthenticator[]>('users/@me/mfa/webauthn/credentials')
      return data
    },
  })
}

export type PlayerIPResult = {
  ip: string
  city: string
  country: string
  countryCode: string
  location: {
    accuracyRadius: number
    latitude: number
    longitude: number
    timeZone: string
  }
  postalCode: string
}

export function usePlayerIPs(player: string, enabled = true): UseQueryResult<PlayerIPResult[], AxiosError<any>> {
  return useQuery<PlayerIPResult[], AxiosError<any>>({
    queryKey: ['admin', 'players', player, 'ips'],
    queryFn: async () => {
      const {data} = await api.get<PlayerIPResult[]>(`admin/players/${player}/ips`)
      return data
    },
    enabled,
  })
}

type IPAltSummary = {
  key: string
  city: string
  country: string
  countryCode: string
  xuids: string[]
}

export function usePlayerIPAltSummary(
  playerName: string,
  groupBy: 'ip' | 'location',
): UseQueryResult<Record<string, IPAltSummary[]>, AxiosError<any>> {
  return useQuery<Record<string, IPAltSummary[]>, AxiosError<any>>({
    queryKey: ['admin', 'players', playerName, 'ip-alt-summary', groupBy],
    queryFn: async () => {
      const {data} = await api.get<Record<string, IPAltSummary[]>>(`admin/players/${playerName}/ip-alt-summary`, {
        params: {groupBy},
      })
      return data
    },
  })
}

export enum AnnouncementType {
  Board = 'board',
  Bossbar = 'bossbar',
  Message = 'message',
  Title = 'title',
  TitleOld = 'title_old',
}

export type ServerAnnouncement = {
  id: number
  content: string
  type: AnnouncementType
}

export function useServerAnnouncementsAdmin(): UseQueryResult<ServerAnnouncement[], AxiosError<any>> {
  return useQuery<ServerAnnouncement[], AxiosError<any>>({
    queryKey: ['admin', 'announcements'],
    queryFn: async () => {
      const {data} = await api.get<ServerAnnouncement[]>('admin/announcements')
      return data.sort((a, b) => b.id - a.id)
    },
  })
}

export type ServerUpdate = {
  id: number
  thumbnailHash: string | null
  thumbnailUrl: string | null
  title: string
  timestamp: string
  content: string
}

export function useServerUpdatesAdmin(): UseQueryResult<ServerUpdate[], AxiosError<any>> {
  return useQuery<ServerUpdate[], AxiosError<any>>({
    queryKey: ['admin', 'updates'],
    queryFn: async () => {
      const {data} = await api.get<ServerUpdate[]>('admin/updates')
      return data
        .sort((a, b) => b.id - a.id)
        .map(update => ({
          ...update,
          thumbnailUrl: update.thumbnailHash ? `${CDN_HOST}/update-thumbnails/${update.thumbnailHash}.png` : null,
        }))
    },
  })
}

export type ServerSettings = {
  motds: string[]
}

export function useServerSettingsAdmin(): UseQueryResult<ServerSettings, AxiosError<any>> {
  return useQuery<ServerSettings, AxiosError<any>>({
    queryKey: ['admin', 'settings'],
    queryFn: async () => {
      const {data} = await api.get<ServerSettings>('servers/settings')
      return data
    },
  })
}

export type APITournamentAdmin = {
  id: number
  title: string
  columns: string
  serverTypes: string
  startTime: string
  endTime: string
  roundDuration: number
  roundCount: number
  farmThreshold: number
  discordChannelId: string
  discordMessageId: string
}

export function useTournamentAdmin(): UseQueryResult<APITournamentAdmin, AxiosError<any>> {
  return useQuery<APITournamentAdmin, AxiosError<any>>({
    queryKey: ['admin', 'tournament'],
    queryFn: async () => {
      const {data} = await api.get<APITournamentAdmin>('admin/tournament')
      return data
    },
  })
}

export type APITournament = {
  columns: string[]
  content: string
  endTime: string
  roundCount: number
  roundDuration: number
  serverTypes: string[]
  startTime: string
  title: string
}

export function useTournament(enabled = true): UseQueryResult<APITournament, AxiosError<any>> {
  return useQuery<APITournament, AxiosError<any>>({
    queryKey: ['tournament'],
    queryFn: async () => {
      const {data} = await api.get<APITournament>('tournament')
      return data
    },
    enabled,
  })
}

export type APIPromotion = {
  id: string
  type: PromotionType
  discount: number
  flags: number
  title: string
  description: string
  startDate: string
  endDate: string
}

export function usePromotionsAdmin(): UseQueryResult<APIPromotion[], AxiosError<any>> {
  return useQuery<APIPromotion[], AxiosError<any>>({
    queryKey: ['admin', 'billing', 'promotions'],
    queryFn: async () => {
      const {data} = await api.get<APIPromotion[]>('admin/billing/promotions')
      return data.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
    },
  })
}

export type TierSummary = {
  Diamond: number
  Sapphire: number
  Amethyst: number
  Opal: number
  Gold: number
  Silver: number
  Bronze: number
  Steel: number
  'No Tier': number
}

export function useTierSummary(): UseQueryResult<TierSummary, AxiosError<any>> {
  return useQuery<TierSummary, AxiosError<any>>({
    queryKey: ['players', 'tier-summary'],
    queryFn: async () => {
      const {data} = await api.get<TierSummary>('players/tier-summary')
      return data
    },
  })
}

export interface ProxyServer {
  id: string
  podId: string
  podIp: string
  namespace: string
  createdAt: Date
  state: ProxyState
}

export type LeakDetectionLevel = 'DISABLED' | 'SIMPLE' | 'ADVANCED' | 'PARANOID'

export interface ProxyState {
  production: boolean
  playerCount: number
  highestSupportedProtocolVersion: string
  highestSupportedRakNetVersion: number
  leakDetectionLevel: LeakDetectionLevel
  gracefulShutdownBarrier: number
  gracefulShutdownInitiated: boolean
}

export function useProxyList(): UseQueryResult<Array<ProxyServer>, AxiosError<any>> {
  return useQuery<Array<ProxyServer>, AxiosError<any>>({
    queryKey: ['developer', 'proxy', 'list'],
    queryFn: async () => {
      const {data} = await api.get<Array<ProxyServer>>('developer/proxy')
      return data
    },
  })
}
