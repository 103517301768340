import type {APIPunishment, APIPunishmentEvidence} from '@app/common/api'
import {PunishmentType, useCurrentUser} from '@app/common/api'
import {dateFormatter} from '@app/common/constants'
import {isAdmin} from '@app/common/utils'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {PlayerInfo} from '@app/components/Modal/PlayerInfo'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {api} from '@app/hooks/useApi'
import {Button, Link, Stack, Text} from '@chakra-ui/react'
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/solid'
import React from 'react'
import {toast} from 'react-hot-toast'

function isValidUrl(url: string): boolean {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

function withHttp(url: string): string {
  return /^https?:\/\//i.test(url) ? url : `https://${url}`
}

function PunishmentEvidenceLink({url}: {url: string}): React.JSX.Element {
  const urlWithHttp = withHttp(url)
  if (!isValidUrl(urlWithHttp)) return <>That's not a valid URL</>
  return (
    <Stack
      align="center"
      as={Link}
      color="orange.300"
      direction="row"
      fontWeight="semibold"
      href={urlWithHttp}
      spacing="4px"
      target="_blank"
    >
      <Text>{new URL(urlWithHttp).hostname.replace(/^www\./, '')}</Text>
      <ArrowTopRightOnSquareIcon color="var(--chakra-colors-orange-300)" height={16} width={16} />
    </Stack>
  )
}

function Evidence({evidence}: {evidence: APIPunishmentEvidence}) {
  switch (evidence.type) {
    case 'LINK': {
      return (
        <>
          {evidence.data.split(';').map(link => (
            <PunishmentEvidenceLink key={link} url={link} />
          ))}
        </>
      )
    }
    case 'REPLAY_ID': {
      return <Text>Replay ID: {evidence.data}</Text>
    }
    case 'AWS_MANAGED':
    case 'TEXT': {
      return /^https?:\/\//.test(evidence.data) ? (
        <PunishmentEvidenceLink url={evidence.data} />
      ) : (
        <Text>{evidence.data}</Text>
      )
    }
    default: {
      return <Text>Unknown evidence type: {evidence.type}</Text>
    }
  }
}

function PunishmentEvidence({evidence}: {evidence: APIPunishmentEvidence}): React.JSX.Element {
  return (
    <Stack gap="4px">
      <Evidence evidence={evidence} />
      {evidence.note && <Text color="gray.500">{evidence.note}</Text>}
    </Stack>
  )
}

export function PunishmentModal(props: {
  isOpen: boolean
  onClose(): void
  punishment: APIPunishment
}): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const [isWhitelisting, setIsWhitelisting] = React.useState(false)
  return (
    <Modal
      footer={
        isAdmin(user) && props.punishment.alt ? (
          <ModalFooter>
            <Button
              colorScheme="red"
              isLoading={isWhitelisting}
              onClick={async () => {
                try {
                  setIsWhitelisting(true)
                  await api.post(
                    `/admin/players/${props.punishment.player}/punishments/${props.punishment.id}/whitelist`,
                  )
                  toast.success('Punishment whitelisted')
                } catch {
                  toast.error('Failed to whitelist punishment')
                } finally {
                  setIsWhitelisting(false)
                }
              }}
            >
              Whitelist
            </Button>
          </ModalFooter>
        ) : null
      }
      header={`${props.punishment.type === PunishmentType.BAN ? 'Ban' : 'Mute'} Details`}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody as={Stack} fontSize="md" letterSpacing="tight" spacing="16px" userSelect="text">
        <Stack spacing="4px">
          <SmallHeading>ID</SmallHeading>
          <Text>{props.punishment.id}</Text>
        </Stack>

        <Stack spacing="4px">
          <SmallHeading>Reason</SmallHeading>
          <Text>{props.punishment.reason}</Text>
        </Stack>

        {props.punishment.note && (
          <Stack spacing="4px">
            <SmallHeading>Note</SmallHeading>
            <Text>{props.punishment.note}</Text>
          </Stack>
        )}

        <Stack spacing="4px">
          <SmallHeading>Valid Until</SmallHeading>
          <Text>
            {props.punishment.permanent
              ? 'Permanent'
              : dateFormatter.format(new Date(props.punishment.validUntil * 1000))}
          </Text>
        </Stack>

        <Stack spacing="4px">
          <SmallHeading>Issued At</SmallHeading>
          <Text>
            {props.punishment.issuedAt ? dateFormatter.format(new Date(props.punishment.issuedAt * 1000)) : 'Unknown'}
          </Text>
        </Stack>

        {props.punishment.issuedByName && (
          <Stack spacing="4px">
            <SmallHeading>Issued By</SmallHeading>
            <Text>
              <Link
                color="orange.300"
                fontWeight="semibold"
                href={`/player/${props.punishment.issuedByName}`}
                target="_blank"
              >
                {props.punishment.issuedByName}
              </Link>
            </Text>
          </Stack>
        )}

        <Stack spacing="4px">
          <SmallHeading>Active</SmallHeading>
          <Text>{props.punishment.active ? 'Yes' : 'No'}</Text>
        </Stack>

        <Stack spacing="4px">
          <SmallHeading>Alt Punishment</SmallHeading>
          <Text>{props.punishment.alt ? 'Yes' : 'No'}</Text>
        </Stack>

        {props.punishment.evidence && props.punishment.evidence.length > 0 && (
          <Stack spacing="4px">
            <SmallHeading>Evidence</SmallHeading>
            <Stack direction="column">
              {props.punishment.evidence.map(evidence => (
                <PunishmentEvidence evidence={evidence} key={evidence.id} />
              ))}
            </Stack>
          </Stack>
        )}

        <Stack spacing="4px">
          <SmallHeading>Affected Players</SmallHeading>
          <Stack spacing="4px">
            {props.punishment.affectedPlayers.map(player => (
              <PlayerInfo isDark key={player} player={player} />
            ))}
          </Stack>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
