import type {ServerAnnouncement} from '@app/common/api'
import {AnnouncementType, useServerAnnouncementsAdmin} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import {PencilSquareIcon, PlusIcon, TrashIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {toast} from 'react-hot-toast'
import TextareaAutosize from 'react-textarea-autosize'

export default function AdminServerAnnouncementsModal(): React.JSX.Element {
  const [adminServerAnnouncementsOpen, setAdminServerAnnouncementsOpen] = useOverlayStore(state => [
    state.adminServerAnnouncementsOpen,
    state.setAdminServerAnnouncementsOpen,
  ])

  return (
    <Modal
      header="Server Announcements"
      isOpen={adminServerAnnouncementsOpen}
      onClose={() => setAdminServerAnnouncementsOpen(false)}
    >
      <AdminServerAnnouncementsModalContent />
    </Modal>
  )
}

function AdminServerAnnouncementsModalContent(): React.JSX.Element {
  const createModal = useDisclosure()
  const {data: announcements} = useServerAnnouncementsAdmin()
  return (
    <ModalBody as={Stack} gap="8px">
      <ServerAnnouncementCreateModal {...createModal} />
      <Button
        colorScheme="orange"
        leftIcon={<PlusIcon height={16} width={16} />}
        onClick={() => createModal.onOpen()}
        size="sm"
      >
        Create Announcement
      </Button>
      {announcements?.map(announcement => (
        <ServerAnnouncementRenderer announcement={announcement} key={announcement.id} />
      ))}
    </ModalBody>
  )
}

const AnnouncementTypeToString: Record<AnnouncementType, string> = {
  [AnnouncementType.Board]: 'Board',
  [AnnouncementType.Bossbar]: 'Bossbar',
  [AnnouncementType.Message]: 'Message',
  [AnnouncementType.Title]: 'Title',
  [AnnouncementType.TitleOld]: 'Title (Hidden)',
}

const EXAMPLE_TITLE_JSON = `{
  "title": "§l§cEASTER SALE",
  "subtitle": "§e25% off everything!\n§eSale ends on the\n§eApril 20, 11:59 PM (GMT)\n§angmc.co/store",
  "fadeIn": -1,
  "duration": 3000,
  "fadeOut": -1
}`

const AnnouncementTypeToDefaultValue: Record<AnnouncementType, string> = {
  [AnnouncementType.Board]:
    '§r§l§6FOLLOW US ON TWITTER{LINE}§r§aWant to hear about giveaways, updates & more?{LINE}§r§aFollow §l§b@NetherGamesMC§r§a on Twitter!',
  [AnnouncementType.Bossbar]: '§a§ITITLE -> §r§fSubtitle',
  [AnnouncementType.Message]:
    '§o§l§eN§6G§r§7: §l§aEMERALD §r§band §l§bLEGEND §r§bplayers get to have pets, join full servers and much more! Buy it now at §6ngmc.co/store',
  [AnnouncementType.Title]: EXAMPLE_TITLE_JSON,
  [AnnouncementType.TitleOld]: EXAMPLE_TITLE_JSON,
}

function ServerAnnouncementCreateModal({isOpen, onClose}: {isOpen: boolean; onClose(): void}): React.JSX.Element {
  const queryClient = useQueryClient()
  const [announcementType, setAnnouncementType] = React.useState<AnnouncementType>(AnnouncementType.Title)
  const [announcementContent, setAnnouncementContent] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    setAnnouncementContent(AnnouncementTypeToDefaultValue[announcementType])
  }, [announcementType])

  React.useEffect(() => {
    if (announcementType === AnnouncementType.Title || announcementType === AnnouncementType.TitleOld) {
      try {
        setAnnouncementContent(JSON.stringify(JSON.parse(announcementContent), null, 2))
      } catch {
        /* noop */
      }
    }
  }, [announcementType, announcementContent])

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!announcementType || !announcementContent}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.post('/admin/announcements', {type: announcementType, content: announcementContent})
                await queryClient.invalidateQueries({queryKey: ['admin', 'announcements']})
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Create
          </Button>
        </ModalFooter>
      }
      header="Create Announcement"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup onChange={value => setAnnouncementType(value as AnnouncementType)} value={announcementType}>
            <Stack>
              {Object.entries(AnnouncementTypeToString).map(([key, label]) => (
                <Radio key={key} value={key}>
                  {label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Content</FormLabel>
          <Textarea
            as={TextareaAutosize}
            onChange={event => setAnnouncementContent(event.target.value)}
            value={announcementContent}
          />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}

function ServerAnnouncementRenderer({announcement}: {announcement: ServerAnnouncement}) {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const queryClient = useQueryClient()
  const updateModal = useDisclosure()

  return (
    <Stack
      align="center"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
    >
      <Stack align="center" direction="row" flex="1" spacing="16px">
        <Stack spacing="1">
          <Text fontWeight="semibold" textTransform="uppercase">
            {AnnouncementTypeToString[announcement.type]}
          </Text>

          <Text fontWeight="light">{announcement.content}</Text>
        </Stack>
      </Stack>

      <ServerAnnouncementUpdateModal announcement={announcement} {...updateModal} />
      <Stack direction="row" spacing="1.5">
        <Tooltip label="Delete Announcement">
          <IconButton
            aria-label="Delete Announcement"
            colorScheme="red"
            icon={<TrashIcon height={16} width={16} />}
            isLoading={isDeleting}
            onClick={async () => {
              try {
                setIsDeleting(true)
                await api.delete(`/admin/announcements/${announcement.id}`)
                await queryClient.invalidateQueries({queryKey: ['admin', 'announcements']})
              } catch {
                toast.error('Failed to delete announcement')
              } finally {
                setIsDeleting(false)
              }
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>

        <Tooltip label="Update Announcement">
          <IconButton
            aria-label="Update Announcement"
            colorScheme="orange"
            icon={<PencilSquareIcon height={16} width={16} />}
            onClick={() => updateModal.onOpen()}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}

function ServerAnnouncementUpdateModal({
  announcement,
  isOpen,
  onClose,
}: {
  announcement: ServerAnnouncement
  isOpen: boolean
  onClose(): void
}): React.JSX.Element {
  const [announcementType, setAnnouncementType] = React.useState<AnnouncementType>(announcement.type)
  const [announcementContent, setAnnouncementContent] = React.useState(announcement.content)
  const [isLoading, setIsLoading] = React.useState(false)
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (announcementType === AnnouncementType.Title || announcementType === AnnouncementType.TitleOld) {
      try {
        setAnnouncementContent(JSON.stringify(JSON.parse(announcementContent), null, 2))
      } catch {
        /* noop */
      }
    }
  }, [announcementType, announcementContent])

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!announcementType || !announcementContent}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.patch(`/admin/announcements/${announcement.id}`, {
                  type: announcementType,
                  content: announcementContent,
                })
                await queryClient.invalidateQueries({queryKey: ['admin', 'announcements']})
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Update
          </Button>
        </ModalFooter>
      }
      header="Update Announcement"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup onChange={value => setAnnouncementType(value as AnnouncementType)} value={announcementType}>
            <Stack>
              {Object.entries(AnnouncementTypeToString).map(([key, label]) => (
                <Radio key={key} value={key}>
                  {label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Content</FormLabel>
          <Textarea
            as={TextareaAutosize}
            onChange={event => setAnnouncementContent(event.target.value)}
            value={announcementContent}
          />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}
