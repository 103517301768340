import {FormHelperText, Link} from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export function FormHelperTextMarkdown({children}: {children: string}) {
  return (
    <FormHelperText letterSpacing="tight">
      <ReactMarkdown
        allowedElements={['a', 'p']}
        components={{
          a: ({node, children}) => {
            if (node === undefined) return null

            const props = node.properties as {href: string}
            const url = new URL(props.href)
            return (
              <Link color="orange.300" href={url.toString()} target="_blank">
                {children}
              </Link>
            )
          },
        }}
        remarkPlugins={[[remarkGfm, {singleTilde: false}]]}
      >
        {children}
      </ReactMarkdown>
    </FormHelperText>
  )
}
