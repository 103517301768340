import type {APIUserGuildInvite} from '@app/common/api'
import {useUserGuildInvites} from '@app/common/api'
import {Avatar} from '@app/components/Avatar/Avatar'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {Box, Flex, Heading, IconButton, Link, List, Stack, Text} from '@chakra-ui/react'
import {CheckIcon, QuestionMarkCircleIcon, XMarkIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import React from 'react'
import toast from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'

type ModalProps = {
  buttonRef?: any
  isOpen: boolean
  onClose(): void
}

export function AccountGuildInvitesModal(props: ModalProps): React.JSX.Element | null {
  const {data, status} = useUserGuildInvites(props.isOpen)
  return (
    <Modal finalFocusRef={props.buttonRef} header="Guild Invites" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalBody fontSize="md">
        {status === 'pending' || !data ? (
          <Spinner />
        ) : data.length === 0 ? (
          <Stack align="center" h="full" justify="center" py={16} textAlign="center">
            <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
            <Heading fontSize="2xl" fontWeight="bold">
              *cricket noises*
            </Heading>
            <Text color="whiteAlpha.800" fontSize="md">
              No one has invited you to any guilds yet.
            </Text>
          </Stack>
        ) : (
          <List spacing={2} userSelect="text">
            {data.map(invite => (
              <PlayerEntry invite={invite} key={invite.id} onClose={props.onClose} />
            ))}
          </List>
        )}
      </ModalBody>
    </Modal>
  )
}

function PlayerEntry({invite, onClose}: {invite: APIUserGuildInvite; onClose(): void}): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return (
    <Flex justify="space-between">
      <Flex align="center">
        <Avatar name={invite.inviter.name} shouldShowOnline={false} size={32} skinHash={invite.inviter.skinHash} />
        <Box ml={3}>
          <Stack align="center" direction="row">
            <Link
              onClick={event => {
                if (event.metaKey || event.ctrlKey) {
                  window.open(`/guild/${invite.guild.name}`, '_blank')
                } else {
                  navigate(`/guild/${invite.guild.name}`)
                  onClose()
                }
              }}
            >
              <Heading fontSize="md">{invite.guild.name}</Heading>
            </Link>
          </Stack>
          <Text color="gray.400" fontSize="sm">
            Invited by <strong>{invite.inviter.name}</strong>
          </Text>
        </Box>
      </Flex>

      <Stack direction="row">
        <Tooltip label="Accept Invite">
          <IconButton
            aria-label="Accept Invite"
            colorScheme="green"
            icon={<CheckIcon height={16} width={16} />}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              try {
                await api.post(`/users/@me/guild-invites/${invite.id}`)
                await queryClient.invalidateQueries({queryKey: ['user', 'guild-invites']})
              } catch (error) {
                if (axios.isAxiosError(error))
                  toast.error(error.response?.data.message ?? 'Something went wrong, please try again later.')
              }

              setIsLoading(false)
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>
        <Tooltip label="Ignore Invite">
          <IconButton
            aria-label="Ignore Invite"
            colorScheme="red"
            icon={<XMarkIcon height={16} width={16} />}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              try {
                await api.delete(`/users/@me/guild-invites/${invite.id}`)
                await queryClient.invalidateQueries({queryKey: ['user', 'guild-invites']})
              } catch {
                toast.error('Something went wrong! Please try again later.')
              }

              setIsLoading(false)
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Flex>
  )
}
