import type {APIFaction} from '@app/common/api'
import {GuildFlags} from '@app/common/constants'
import {getErrorMessage, hasFlag} from '@app/common/utils'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Stack, Switch} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {toast} from 'react-hot-toast'

function computeFaction(faction: APIFaction): APIFaction & {autoKickOfficers: boolean} {
  return {
    ...faction,
    autoKickOfficers: hasFlag(faction.flags, GuildFlags.AUTO_KICK_OFFICERS),
  }
}

type ModalProps = {
  buttonRef: any
  isOpen: boolean
  onClose(): void
  faction: APIFaction
}

export function FactionUpdateModal(props: ModalProps): React.JSX.Element {
  const queryClient = useQueryClient()
  const {formState, handleSubmit, register, setError, reset, control} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: React.useMemo(() => computeFaction(props.faction), [props.faction]),
  })

  React.useEffect(() => reset(computeFaction(props.faction)), [props.faction, reset])
  React.useEffect(() => {
    if (!props.isOpen) reset(computeFaction(props.faction))
  }, [props.isOpen, props.faction, reset])

  async function onSubmit(data: {autoKickDays: number; autoKickOfficers: boolean}): Promise<void> {
    try {
      const {data: faction} = await api.patch<APIFaction>(`factions/${props.faction.name}`, {
        autoKickDays: Number(data.autoKickDays),
        autoKickOfficers: data.autoKickOfficers,
      })
      await queryClient.invalidateQueries({queryKey: ['factions', faction.name]})
      toast.success(`Updated the ${faction.name} faction`, {id: 'faction_updated'})
      props.onClose()
    } catch (error) {
      setError('autoKickDays', {type: 'validate', message: getErrorMessage(error, 'autoKickDays')})
      setError('autoKickOfficers', {type: 'validate', message: getErrorMessage(error, 'autoKickOfficers')})
    }
  }

  return (
    <Modal
      finalFocusRef={props.buttonRef}
      footer={
        <ModalFooter>
          <Button isLoading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </ModalFooter>
      }
      header={`Update '${props.faction.name}'`}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody fontSize="md">
        <FormControl isInvalid={Boolean(formState.errors.autoKickDays?.message)} mt={4}>
          <FormLabel htmlFor="autoKickDays">Auto-Kick Days</FormLabel>
          <Input
            id="autoKickDays"
            max={365}
            min={0}
            rounded="md"
            size="sm"
            type="number"
            {...register('autoKickDays', {required: false})}
          />
          <FormHelperText>Auto-kick members if inactive for this many days (disabled if zero).</FormHelperText>
          <FormErrorMessage>{formState.errors.autoKickDays?.message}</FormErrorMessage>
        </FormControl>

        <FormControl mt={4} w="full">
          <Stack direction="column" spacing="1">
            <Stack align="center" direction="row">
              <FormLabel flex="1" fontWeight="semibold" htmlFor="autoKickOfficers" mb={0}>
                Auto-Kick Officers
              </FormLabel>
              <Controller
                control={control}
                name="autoKickOfficers"
                render={({field}) => (
                  <Switch
                    id="autoKickOfficers"
                    isChecked={field.value}
                    onChange={event => field.onChange(event.target.checked)}
                  />
                )}
              />
            </Stack>
            <FormHelperText>When enabled, officers will also be auto-kicked.</FormHelperText>
          </Stack>
        </FormControl>
      </ModalBody>
    </Modal>
  )
}
