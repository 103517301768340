import type {StackProps} from '@chakra-ui/react'
import {Heading, Stack} from '@chakra-ui/react'
import {m as motion} from 'motion/react'
import type React from 'react'

export function NavigationButton({
  leftIcon,
  rightIcon,
  title,
  ...props
}: StackProps & {leftIcon?: React.ReactNode; rightIcon: React.ReactNode; title: string}): React.JSX.Element {
  return (
    <Stack
      align="center"
      boxShadow="lg"
      direction="row"
      justify="space-between"
      letterSpacing="tight"
      p={4}
      role="button"
      rounded="lg"
      tabIndex={0}
      transition="ease-in-out 200ms"
      {...props}
    >
      <Stack align="center" direction="row" spacing="8px">
        {leftIcon}
        <Heading size="sm">{title}</Heading>
      </Stack>
      {rightIcon}
    </Stack>
  )
}

const buttonIconVariants = {
  animate: {scale: 1, rotate: 0},
  hover: {
    scale: 1.05,
    rotate: 360,
    transition: {duration: 0.5, ease: 'easeInOut'},
  },
}

export function NavigationButtonGrid({
  icon,
  title,
  ...props
}: StackProps & {icon: React.ReactNode; title: string}): React.JSX.Element {
  return (
    <motion.div animate="animate" whileHover="hover">
      <Stack
        align="center"
        boxShadow="lg"
        direction="column"
        justify="center"
        letterSpacing="tighter"
        p={4}
        role="button"
        rounded="lg"
        tabIndex={0}
        textAlign="center"
        transition="ease-in-out 200ms"
        {...props}
      >
        <motion.div variants={buttonIconVariants}>{icon}</motion.div>
        <Heading size="sm">{title}</Heading>
      </Stack>
    </motion.div>
  )
}
