import {useCurrentUser} from '@app/common/api'
import {useExperimentStore} from '@app/common/experiments'
import {useOverlayStore, useSettingsStore} from '@app/common/stores'
import {isStaff} from '@app/common/utils'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Button, Checkbox, FormControl, FormLabel, Heading, Input, Stack, Switch, Text} from '@chakra-ui/react'
import type {GroupBase} from 'chakra-react-select'
import {Select} from 'chakra-react-select'
import React from 'react'

type ExperimentOption = {
  label: string
  value: string
}

export default function AppSettingsModal(): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const [settingsOpen, setSettingsOpen] = useOverlayStore(state => [state.settingsOpen, state.setSettingsOpen])
  const [shouldCrash, setShouldCrash] = React.useState(false)
  const {experiments, setExperimentOverride} = useExperimentStore()
  const settings = useSettingsStore()

  if (shouldCrash) return {} as any
  return (
    <Modal
      footer={
        <ModalFooter>
          {(isStaff(user) || settings.developerMode) && (
            <Button mr="auto" onClick={() => setShouldCrash(true)} size="sm" variant="outline">
              Trigger React Crash
            </Button>
          )}

          <Button onClick={() => setSettingsOpen(false)} size="sm">
            Close
          </Button>
        </ModalFooter>
      }
      header="Portal Settings"
      isOpen={settingsOpen}
      onClose={() => setSettingsOpen(false)}
    >
      <ModalBody as={Stack} fontSize="md" gap="4px">
        <Stack align="center" direction="row">
          <FormLabel flex="1" fontSize="sm" htmlFor="shouldRenderSkins" mb={0}>
            Render Skins (WebGL)
          </FormLabel>
          <Switch
            id="shouldRenderSkins"
            isChecked={settings.renderSkins}
            onChange={event => settings.setRenderSkins(event.currentTarget.checked)}
          />
        </Stack>

        <Stack>
          <Stack align="center" direction="row">
            <FormLabel flex="1" fontSize="sm" htmlFor="reducedMotion" mb={0}>
              Enable Reduced Motion
            </FormLabel>
            <Switch
              disabled={settings.syncReducedMotion}
              id="reducedMotion"
              isChecked={settings.reducedMotion}
              onChange={event => settings.setReducedMotion(event.currentTarget.checked)}
            />
          </Stack>
          <Checkbox
            isChecked={settings.syncReducedMotion}
            onChange={event => settings.setSyncReducedMotion(event.currentTarget.checked)}
            size="sm"
          >
            Sync with device
          </Checkbox>
        </Stack>

        {isStaff(user) && (
          <Stack align="center" direction="row">
            <FormLabel flex="1" fontSize="sm" htmlFor="bypassAnonymous" mb={0}>
              Bypass Anonymous
            </FormLabel>
            <Switch
              id="bypassAnonymous"
              isChecked={settings.bypassAnonymous}
              onChange={event => settings.setBypassAnonymous(event.currentTarget.checked)}
            />
          </Stack>
        )}

        {isStaff(user) && (
          <Stack align="center" direction="row">
            <FormLabel flex="1" fontSize="sm" htmlFor="bypassFormRequirements" mb={0}>
              Bypass Form Requirements
            </FormLabel>
            <Switch
              id="bypassFormRequirements"
              isChecked={settings.bypassFormRequirements}
              onChange={event => settings.setBypassFormRequirements(event.currentTarget.checked)}
            />
          </Stack>
        )}

        {(isStaff(user) || settings.developerMode) && (
          <Stack align="center" gap="4px">
            <FormControl mb={1}>
              <FormLabel fontSize="sm">Skin Proxy URL</FormLabel>
              <Input
                onChange={event => settings.setSkinProxyUrl(event.currentTarget.value)}
                placeholder="http://localhost:8000/"
                rounded="md"
                size="sm"
                type="text"
                value={settings.skinProxyUrl ?? undefined}
                w="full"
              />
            </FormControl>

            <Stack spacing="8px" w="full">
              <Heading size="sm">Experiment Overrides</Heading>
              <Text color="whiteAlpha.800" fontSize="sm">
                Overrides the experiment buckets for the current user. This is useful for testing new features before
                they are released to everyone.
              </Text>
              <Stack spacing="16px" w="full">
                {experiments.map(experiment => (
                  <FormControl key={experiment.id} w="full">
                    <FormLabel fontSize="sm">
                      {experiment.id.split('_')[0]} - {experiment.title}
                    </FormLabel>
                    <Select<ExperimentOption, false, GroupBase<ExperimentOption>>
                      className="react-select"
                      classNamePrefix="react-select"
                      id={experiment.id}
                      isClearable
                      menuPortalTarget={document.body}
                      onChange={value => setExperimentOverride(experiment.id, value?.value ?? null)}
                      options={experiment.buckets.map((bucket, index) => ({
                        label: `${index > 0 ? `Treatment ${index}: ` : ''}${bucket.title}`,
                        value: bucket.id,
                      }))}
                      selectedOptionStyle="check"
                      size="sm"
                      styles={{menuPortal: provided => ({...provided, zIndex: 9999})}}
                      value={
                        experiment.bucketOverride
                          ? {label: experiment.bucketOverride, value: experiment.bucketOverride}
                          : null
                      }
                    />
                  </FormControl>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
      </ModalBody>
    </Modal>
  )
}
