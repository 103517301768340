import {useCurrentUser} from '@app/common/api'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, Input, Stack, Text} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import React from 'react'
import {toast} from 'react-hot-toast'

type ModalProps = {
  isOpen: boolean
  onClose(): void
}

export function MFADisable(props: ModalProps): React.JSX.Element | null {
  return (
    <Modal header="Disable Two-Factor Auth" isOpen={props.isOpen} onClose={props.onClose}>
      <MFADisableContent onClose={props.onClose} />
    </Modal>
  )
}

function MFADisableContent(props: Pick<ModalProps, 'onClose'>): React.JSX.Element | null {
  const {data: user} = useCurrentUser()
  const queryClient = useQueryClient()
  const [code, setCode] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  if (!user) return null
  return (
    <Stack as={ModalBody} direction="column" fontSize="md" spacing={3}>
      <Text color="whiteAlpha.800" fontSize="md" letterSpacing="tight">
        Enter the 6-digit verification code generated.
      </Text>

      <Stack align="center" direction="row" spacing={2}>
        <Input
          autoComplete="one-time-code"
          maxLength={7}
          onChange={event => setCode(event.target.value)}
          placeholder="000 000"
          size="md"
          type="text"
          value={code}
        />
        <Button
          colorScheme="orange"
          fontSize="sm"
          isDisabled={code.length === 0}
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true)
            try {
              const {data} = await api.post('/users/@me/mfa/totp/disable', {code})
              window.localStorage.setItem('token', data.token)
              await queryClient.invalidateQueries({queryKey: ['user']})
              props.onClose()
            } catch (error) {
              if (axios.isAxiosError(error))
                toast.error(error.response?.data.message ?? 'Something went wrong, please try again later.')
            }

            setIsLoading(false)
          }}
          size="md"
          type="submit"
        >
          Disable
        </Button>
      </Stack>
    </Stack>
  )
}
