import {useOverlayStore} from '@app/common/stores'
import Home from '@app/components/Home/Home'
import React from 'react'

export default function StaffDirectoryModalOpener(): React.JSX.Element {
  const setStaffDirectoryOpen = useOverlayStore(state => state.setStaffDirectoryOpen)
  React.useEffect(() => {
    setStaffDirectoryOpen(true)
  }, [setStaffDirectoryOpen])

  return <Home />
}
