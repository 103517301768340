import React from 'react'

export function useReplaceState(path: string): void {
  const lastPath = React.useMemo(() => window.location.pathname, [])
  React.useEffect(() => {
    window.history.replaceState(null, document.title, path)
    return () => {
      window.history.replaceState(null, document.title, lastPath === path ? '/' : lastPath)
    }
  }, [path, lastPath])
}
