import '@app/global.css'
import '@fontsource-variable/rubik'

import {Buffer} from 'node:buffer'
import {AppSkeleton} from '@app/components/App'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOMClient from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import invariant from 'tiny-invariant'

globalThis.Buffer = Buffer

Sentry.init({
  dsn: 'https://5f60769b37827be63f57e87599768720@sentry.ngmc.co/4',
  enabled: import.meta.env.PROD,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1,
  ignoreErrors: [/ResizeObserver/, /Network Error/, /Request failed with status code/],
})

const container = document.querySelector('#root')
invariant(container, 'Root element not found')
const root = ReactDOMClient.createRoot(container)

function UnsupportedApp() {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        height: '100vh',
        justifyContent: 'center',
        margin: 'auto',
        maxWidth: '40rem',
        padding: '1rem',
        textAlign: 'center',
      }}
    >
      <svg fill="#fb923c" height={64} viewBox="0 -960 960 960" width={64} xmlns="http://www.w3.org/2000/svg">
        <title>Warning</title>
        <path d="M480-120q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-480q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-840q82 0 155.5 35T760-706v-94h80v240H600v-80h110q-41-56-101-88t-129-32q-117 0-198.5 81.5T200-480q0 117 81.5 198.5T480-200q105 0 183.5-68T756-440h82q-15 137-117.5 228.5T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z" />
      </svg>
      <h1 style={{fontSize: 24, fontWeight: 600}}>Browser Update Required</h1>
      <p>
        Your browser is very outdated and does not support some of the latest features. Remember that running outdated
        software can be a security risk, so please update your browser as soon as possible.
      </p>
      <p>
        Learn more about updating your browser at{' '}
        <a
          href="https://www.whatismybrowser.com/guides/how-to-update-your-browser/"
          style={{color: '#fb923c', textDecoration: 'underline'}}
        >
          whatismybrowser.com
        </a>
        .
      </p>
      <p>
        Having trouble?{' '}
        <a href="https://discord.com/invite/ng" style={{color: '#fb923c', textDecoration: 'underline'}}>
          Join our Discord server
        </a>{' '}
        for help.
      </p>
    </div>
  )
}

if (typeof Object.hasOwn !== 'function') {
  root.render(<UnsupportedApp />)
  throw new Error('Unsupported browser')
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppSkeleton />
    </BrowserRouter>
  </React.StrictMode>,
)
