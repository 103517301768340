import type {APIRelationship, PunishmentProperties} from '@app/common/api'
import {RelationshipType, useCurrentUser, useRelationships} from '@app/common/api'
import {dateFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {isAdmin} from '@app/common/utils'
import {Avatar} from '@app/components/Avatar/Avatar'
import {FriendRemoveModal} from '@app/components/Friend/FriendRemoveModal'
import {NGStaffIcon} from '@app/components/Logo/NGStaffIcon'
import {PlayerPunishmentIndicator} from '@app/components/Member/MemberList'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {EditIcon} from '@chakra-ui/icons'
import {Box, Flex, Heading, IconButton, Link, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {CheckIcon, QuestionMarkCircleIcon, XMarkIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import {formatDistance} from 'date-fns'
import React from 'react'
import toast from 'react-hot-toast'
import {FaDiscord} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'

type ModalProps = {
  buttonRef?: any
  isOpen: boolean
  onClose(): void
}

export function FriendModal(props: ModalProps): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const {data: relationships} = useRelationships(props.isOpen)

  if (!user || !relationships)
    return (
      <Modal finalFocusRef={props.buttonRef} isOpen={props.isOpen} onClose={props.onClose}>
        <ModalBody align="center" as={Stack} justify="center" my={16}>
          <Spinner />
        </ModalBody>
      </Modal>
    )

  const friends = relationships.filter(value => value.type === RelationshipType.FRIEND)
  const onlineFriends = friends.filter(value => value.online)
  const offlineFriends = friends.filter(value => !value.online)
  const incoming = relationships.filter(value => value.type === RelationshipType.INCOMING)
  const outgoing = relationships.filter(value => value.type === RelationshipType.OUTGOING)

  return (
    <Modal
      finalFocusRef={props.buttonRef}
      header={
        <Stack align="center" direction="row" gap={1}>
          <Heading size="sm">
            Friends — {friends.length}/{user.friendSlots}
          </Heading>
          <Link href="https://ngmc.co/product/cl504x0w4017301t0posqfrg9" isExternal>
            <Box bg="red.500" color="white" fontSize="sm" fontWeight="bold" px="8px" py="2px" rounded="full">
              Buy more slots
            </Box>
          </Link>
        </Stack>
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody>
        {relationships.length > 0 ? (
          <Stack direction="column" gap="8px">
            {onlineFriends.length > 0 && (
              <Stack direction="column" fontSize="sm" gap="4px">
                <Heading size="sm">Online — {onlineFriends.length}</Heading>
                {onlineFriends.map(relationship => (
                  <PlayerEntry key={relationship.player} onClose={props.onClose} relationship={relationship} />
                ))}
                {onlineFriends.length === 0 && <div>No online friends</div>}
              </Stack>
            )}

            <Stack direction="column" fontSize="sm" gap="4px">
              {onlineFriends.length > 0 && <Heading size="sm">Offline — {offlineFriends.length}</Heading>}
              {offlineFriends.map(relationship => (
                <PlayerEntry key={relationship.player} onClose={props.onClose} relationship={relationship} />
              ))}
              {offlineFriends.length === 0 && <div>No offline friends</div>}
            </Stack>

            <Stack direction="column" fontSize="sm" gap="4px">
              <Heading size="sm">Incoming Friend Requests — {incoming.length}</Heading>
              {incoming.map(relationship => (
                <PlayerEntry key={relationship.player} onClose={props.onClose} relationship={relationship} />
              ))}
              {incoming.length === 0 && <div>No incoming friend requests</div>}
            </Stack>

            <Stack direction="column" fontSize="sm" gap="4px">
              <Heading size="sm">Outgoing Friend Requests — {outgoing.length}</Heading>
              {outgoing.map(relationship => (
                <PlayerEntry key={relationship.player} onClose={props.onClose} relationship={relationship} />
              ))}
              {outgoing.length === 0 && <div>No outgoing friend requests</div>}
            </Stack>
          </Stack>
        ) : (
          <Stack align="center" h="full" justify="center" py={16} textAlign="center">
            <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
            <Heading fontSize="2xl" fontWeight="bold">
              *cricket noises*
            </Heading>
            <Text color="whiteAlpha.800" fontSize="md">
              Hello darkness, my old friend.
            </Text>
          </Stack>
        )}
      </ModalBody>
    </Modal>
  )
}

export function RelationshipPlayer({
  originPlayer,
  punishment,
  relationship,
  onClose,
  shouldShowAdminControls = true,
}: {
  originPlayer?: string
  punishment?: PunishmentProperties | null
  relationship: Omit<APIRelationship, 'type'>
  onClose(): void
  shouldShowAdminControls?: boolean
}): React.JSX.Element {
  const [isUnlinking, setIsUnlinking] = React.useState(false)
  const {data: user} = useCurrentUser()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const setAdminPlayer = useOverlayStore(state => state.setAdminPlayer)

  return (
    <Flex align="center">
      <Avatar name={relationship.player} online={relationship.online} size={32} skinHash={relationship.skinHash} />
      <Box flex="1" ml={3}>
        <Stack align="center" direction="row">
          <Link
            onClick={event => {
              if (event.metaKey || event.ctrlKey) {
                window.open(`/player/${relationship.player}`, '_blank')
              } else {
                navigate(`/player/${relationship.player}`)
                onClose()
              }
            }}
          >
            <Heading fontSize="md">{relationship.player}</Heading>
          </Link>
          {punishment && <PlayerPunishmentIndicator {...punishment} iconSize={16} />}
          {relationship.staff && (
            <Tooltip label="NetherGames Staff" padding={8}>
              <a href="https://ngmc.co/jobs" rel="noreferrer" target="_blank">
                <NGStaffIcon height={16} width={16} />
              </a>
            </Tooltip>
          )}
          {relationship.discordId && relationship.discordTag && (
            <Tooltip label={`Open ${relationship.discordTag} (desktop app only)`} padding={8}>
              <a href={`discord:/users/${relationship.discordId}`}>
                <FaDiscord fill="#5865f2" size={16} />
              </a>
            </Tooltip>
          )}
        </Stack>
        {relationship.online ? (
          <Text color="gray.400" fontSize="sm">
            Currently online at <strong>{relationship.lastServer}</strong>
          </Text>
        ) : (
          <Text color="gray.400" fontSize="sm">
            Last seen at{' '}
            <Tooltip label={formatDistance(relationship.lastSeen * 1000, Date.now(), {addSuffix: true})}>
              <strong>{dateFormatter.format(relationship.lastSeen * 1000)}</strong>
            </Tooltip>
          </Text>
        )}
      </Box>

      {shouldShowAdminControls && isAdmin(user) && (
        <Stack direction="row">
          {originPlayer !== relationship.player && (
            <Box>
              <Tooltip label="Unlink" padding={8}>
                <IconButton
                  aria-label="Unlink"
                  colorScheme="red"
                  icon={<XMarkIcon height={16} width={16} />}
                  isLoading={isUnlinking}
                  onClick={async () => {
                    setIsUnlinking(true)
                    try {
                      await api.delete(`/admin/players/${originPlayer}/alts/${relationship.player}`)
                      await queryClient.invalidateQueries({queryKey: ['admin', 'players', originPlayer, 'alts']})
                      toast.success('Unlinked player!')
                    } catch {
                      toast.error('Failed to unlink player!')
                    } finally {
                      setIsUnlinking(false)
                    }
                  }}
                  rounded="full"
                  size="sm"
                />
              </Tooltip>
            </Box>
          )}

          <Box>
            <Tooltip label="Edit Player (Admin)" padding={8}>
              <IconButton
                aria-label="Edit Player (Admin)"
                icon={<EditIcon />}
                onClick={() => setAdminPlayer(relationship.player)}
                rounded="full"
                size="sm"
              />
            </Tooltip>
          </Box>
        </Stack>
      )}
    </Flex>
  )
}

function PlayerEntry({relationship, onClose}: {relationship: APIRelationship; onClose(): void}): React.JSX.Element {
  const queryClient = useQueryClient()
  const friendRemoveModal = useDisclosure()
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <Flex justify="space-between">
      <RelationshipPlayer onClose={onClose} relationship={relationship} shouldShowAdminControls={false} />
      <FriendRemoveModal player={relationship.player} {...friendRemoveModal} />
      <Stack direction="row">
        {relationship.type === RelationshipType.FRIEND && (
          <Tooltip label="Remove Friend">
            <IconButton
              aria-label="Remove Friend"
              colorScheme="red"
              icon={<XMarkIcon height={16} width={16} />}
              onClick={() => friendRemoveModal.onOpen()}
              rounded="full"
              size="sm"
            />
          </Tooltip>
        )}
        {relationship.type === RelationshipType.INCOMING && (
          <Tooltip label="Ignore">
            <IconButton
              aria-label="Ignore"
              colorScheme="red"
              icon={<XMarkIcon height={16} width={16} />}
              isLoading={isLoading}
              onClick={async () => {
                setIsLoading(true)
                try {
                  await api.delete(`users/@me/relationships/${relationship.player}`)
                  await queryClient.invalidateQueries({queryKey: ['user', 'relationships']})
                } catch {
                  toast.error('Something went wrong! Please try again later.')
                }

                setIsLoading(false)
              }}
              rounded="full"
              size="sm"
            />
          </Tooltip>
        )}
        {relationship.type === RelationshipType.INCOMING && (
          <Tooltip label="Accept">
            <IconButton
              aria-label="Accept"
              colorScheme="green"
              icon={<CheckIcon height={16} width={16} />}
              onClick={async () => {
                toast.loading('Accepting friend request...', {id: `accept_${relationship.player}`})
                try {
                  await api.put(`users/@me/relationships/${relationship.player}`, {type: RelationshipType.FRIEND})
                  await queryClient.invalidateQueries({queryKey: ['user', 'relationships']})
                  toast.success('Accepted friend request!', {id: `accept_${relationship.player}`})
                } catch {
                  toast.error('Failed to accept friend request!', {id: `accept_${relationship.player}`})
                }
              }}
              rounded="full"
              size="sm"
            />
          </Tooltip>
        )}
        {relationship.type === RelationshipType.OUTGOING && (
          <Tooltip label="Cancel">
            <IconButton
              aria-label="Cancel"
              backgroundColor="transparent"
              colorScheme="gray"
              icon={<XMarkIcon height={16} width={16} />}
              onClick={async () => {
                toast.loading('Canceling friend request...', {id: `cancel_${relationship.player}`})
                try {
                  await api.delete(`users/@me/relationships/${relationship.player}`)
                  await queryClient.invalidateQueries({queryKey: ['user', 'relationships']})
                  toast.success('Friend request canceled!', {id: `cancel_${relationship.player}`})
                } catch {
                  toast.error('Failed to cancel friend request!', {id: `cancel_${relationship.player}`})
                }
              }}
              rounded="full"
              size="sm"
            />
          </Tooltip>
        )}
      </Stack>
    </Flex>
  )
}
