import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {api} from '@app/hooks/useApi'
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react'
import axios from 'axios'
import type {GroupBase} from 'chakra-react-select'
import {Select} from 'chakra-react-select'
import React from 'react'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'

const CURRENCY_CODES = {
  aud: 'Australian Dollar',
  bdt: 'Bangladeshi Taka',
  brl: 'Brazilian Real',
  cad: 'Canadian Dollar',
  eur: 'Euro',
  gbp: 'British Pound',
  idr: 'Indonesian Rupiah',
  inr: 'Indian Rupee',
  lkr: 'Sri Lankan Rupee',
  mxn: 'Mexican Peso',
  myr: 'Malaysian Ringgit',
  pkr: 'Pakistani Rupee',
  pln: 'Polish Zloty',
  try: 'Turkish Lira',
  usd: 'US Dollar',
}

type ModalProps = {
  isOpen: boolean
  onClose(): void
}

type ProductPlanOption = {
  label: string
  value: string
}

type CurrencyOption = {
  label: string
  value: string
}

type FormData = {
  player: string
  productPlanId: string
  quantity: number
  geoLockCurrency: string | null
  subscriptionCreditDays: number
  selfRedeemOnly: boolean
}

export function AdminGiftCreateModal(props: ModalProps): React.JSX.Element | null {
  const [productPlans, setProductPlans] = React.useState<ProductPlanOption[] | null>(null)
  React.useEffect(() => {
    api
      .get('admin/billing/product-plans')
      .then(({data}) => setProductPlans(Object.entries<any>(data).map(([id, name]) => ({label: name, value: id}))))
  }, [])

  const methods = useForm<FormData>({
    defaultValues: {
      player: '',
      productPlanId: '',
      quantity: 1,
      geoLockCurrency: null,
      subscriptionCreditDays: 30,
      selfRedeemOnly: false,
    },
  })

  async function onSubmit(data: any): Promise<void> {
    try {
      await api.post('admin/billing/entitlements', data)
      toast.success("Added to the player's Gift Inventory!")
      props.onClose()
    } catch (error) {
      toast.error(
        axios.isAxiosError(error) ? error.response?.data.message : 'Something went wrong! Please try again later.',
      )
    }
  }

  if (!productPlans) return null
  const currencyOptions = Object.entries(CURRENCY_CODES).map(([value, label]) => ({label, value}))
  const selectedPlan = productPlans.find(plan => plan.value === methods.watch('productPlanId'))

  return (
    <Modal
      footer={
        <ModalFooter gap="8px">
          <Button colorScheme="gray" onClick={props.onClose} variant="ghost">
            Cancel
          </Button>
          <Button isLoading={methods.formState.isSubmitting} onClick={methods.handleSubmit(onSubmit)}>
            Create
          </Button>
        </ModalFooter>
      }
      header="Giveaway"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <Stack gap="4px">
          <Text color="red.300" fontSize="sm" fontWeight="semibold" letterSpacing="tight">
            This will create an entitlement in the player's Gift Inventory at{' '}
            <Link href="https://ngmc.co/store" isExternal textDecor="underline">
              https://ngmc.co/store
            </Link>{' '}
            {'>'} User Settings. They must generate and redeem it for themselves or send it to someone else.
          </Text>
        </Stack>

        <FormControl as={Stack}>
          <SmallHeading>Player</SmallHeading>
          <Input
            {...methods.register('player', {required: true})}
            placeholder="Gamertag or XUID"
            rounded="md"
            size="sm"
          />
        </FormControl>

        <FormControl as={Stack}>
          <SmallHeading>Item</SmallHeading>
          <Select<ProductPlanOption, false, GroupBase<ProductPlanOption>>
            className="react-select"
            classNamePrefix="react-select"
            isRequired
            menuPortalTarget={document.body}
            onChange={value => methods.setValue('productPlanId', value?.value ?? '')}
            options={productPlans}
            selectedOptionStyle="check"
            size="sm"
            styles={{menuPortal: provided => ({...provided, zIndex: 9999})}}
            value={productPlans.find(option => option.value === methods.watch('productPlanId')) ?? null}
          />
        </FormControl>

        {selectedPlan?.value === 'subscription_credit' && (
          <FormControl as={Stack}>
            <SmallHeading>Subscription Credit Days</SmallHeading>
            <NumberInput
              defaultValue={30}
              min={1}
              onChange={value => methods.setValue('subscriptionCreditDays', Number(value))}
              size="sm"
            >
              <NumberInputField rounded="md" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        )}

        <FormControl as={Stack}>
          <SmallHeading>Geo-Lock Currency</SmallHeading>
          <Select<CurrencyOption, false, GroupBase<CurrencyOption>>
            className="react-select"
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            onChange={value => methods.setValue('geoLockCurrency', value?.value ?? null)}
            options={currencyOptions}
            selectedOptionStyle="check"
            isClearable
            size="sm"
            styles={{menuPortal: provided => ({...provided, zIndex: 9999})}}
            value={currencyOptions.find(option => option.value === methods.watch('geoLockCurrency')) ?? null}
          />
          <FormHelperText>
            If set, the entitlement will only be redeemable in similar income countries to said currency. Leave it blank
            for no lock.
          </FormHelperText>
        </FormControl>

        <FormControl as={Stack}>
          <SmallHeading>Restrictions</SmallHeading>
          <Checkbox defaultChecked={false} onChange={event => methods.setValue('selfRedeemOnly', event.target.checked)}>
            Self-Redeem Only
          </Checkbox>
          <FormHelperText>
            If checked, the recipient will not be able to send this entitlement to someone else. Leave it unchecked for
            no restrictions.
          </FormHelperText>
        </FormControl>

        <FormControl as={Stack}>
          <SmallHeading>Quantity</SmallHeading>
          <NumberInput
            defaultValue={1}
            max={100}
            min={1}
            onChange={value => methods.setValue('quantity', Number(value))}
            size="sm"
          >
            <NumberInputField rounded="md" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormHelperText>
            Quantity does not affect most items (only Extra Friend Slots and Skyblock Mini-Helpers). Leave it at one and
            create multiple entitlements if you need more.
          </FormHelperText>
        </FormControl>
      </ModalBody>
    </Modal>
  )
}
