import {PlayerFlags, useCurrentUser, usePlayer} from '@app/common/api'
import {Period} from '@app/common/constants'
import {hasFlag} from '@app/common/utils'
import {
  PlayerBadges,
  PlayerBio,
  PlayerHeader,
  PlayerHighlights,
  PlayerHighlightsExtra,
  PlayerLeaderboardSection,
  PlayerStatus,
} from '@app/components/Player/Player'
import {Scroller} from '@app/components/Scroller/Scroller'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Stack} from '@chakra-ui/react'
import * as HoverCard from '@radix-ui/react-hover-card'
import {m as motion} from 'motion/react'
import React from 'react'

export function PlayerHoverCard({
  username,
  children,
}: {username: string; children: React.JSX.Element}): React.JSX.Element | null {
  const [isOpen, setIsOpen] = React.useState(false)
  const {data: player, status} = usePlayer(username, {enabled: isOpen})
  const {data: user} = useCurrentUser()
  const shouldDisableStats = Boolean(
    player && (user?.xuid === player.xuid ? false : hasFlag(player.flags, PlayerFlags.DISABLE_STATS)),
  )

  React.useEffect(() => {
    if (!isOpen) return
    for (const el of document.querySelectorAll('[data-radix-hover-card-root]'))
      el.dispatchEvent(new Event('mouseleave', {bubbles: true}))
  }, [isOpen])

  return (
    <HoverCard.Root onOpenChange={open => setIsOpen(open)} openDelay={750}>
      <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>

      <HoverCard.Portal>
        <HoverCard.Content asChild side="right" sideOffset={8} style={{zIndex: 2}}>
          <motion.div
            animate={{opacity: 1, scale: 1}}
            exit={{opacity: 0, scale: 0.5, transition: {duration: 0.2}}}
            initial={{opacity: 0, scale: 0.3}}
          >
            <Stack
              align="center"
              as={Scroller}
              bg="gray.900"
              borderWidth={1}
              direction="column"
              gap={1}
              h="32rem"
              overflow="scroll"
              p={4}
              position="relative"
              rounded="2xl"
              w="320px"
            >
              {status === 'pending' && <Spinner />}
              {player && (
                <>
                  <PlayerHeader avatarTopGutter={0} player={player} />
                  <PlayerBadges player={player} />
                  <PlayerStatus player={player} />

                  <Stack align="center" gap={2} h="full" w="full">
                    <PlayerBio player={player} />
                    <PlayerHighlights player={player} shouldDisableStats={shouldDisableStats} />
                    <PlayerHighlightsExtra player={player} shouldDisableStats={shouldDisableStats} />
                    <PlayerLeaderboardSection
                      bottomGutter
                      period={Period.GLOBAL}
                      player={player}
                      shouldDisableStats={shouldDisableStats}
                    />
                  </Stack>
                </>
              )}
            </Stack>
          </motion.div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  )
}
