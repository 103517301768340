import type {APIUser} from '@app/common/api'
import {DMStatus, PlayerFlags} from '@app/common/api'
import {getErrorMessage, hasFlag} from '@app/common/utils'
import {LogoutModal} from '@app/components/Modal/LogoutModal'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Textarea} from '@app/components/UI/Textarea'
import {api} from '@app/hooks/useApi'
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  useDisclosure,
} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import type {Control} from 'react-hook-form'
import {Controller, useForm} from 'react-hook-form'
import {toast} from 'react-hot-toast'

type ModalProps = {
  buttonRef?: any
  isOpen: boolean
  onClose(): void
  user: APIUser
}

type UserPayloadToggles = {
  announcementsDisabled: boolean
  anonymizeLeaderboardDisabled: boolean
  discordDisabled: boolean
  fpsModeDisabled: boolean
  friendRequestsDisabled: boolean
  guildInvitesDisabled: boolean
  guildTagDisabled: boolean
  invisibleModeDisabled: boolean
  locationDisabled: boolean
  otherPlayersDisabled: boolean
  rankDisabled: boolean
  rankedChatDisabled: boolean
  skinDisabled: boolean
  statsDisabled: boolean
  winStreaksDisabled: boolean
}

type UserPayload = UserPayloadToggles & {
  bio: string
  dmStatus: DMStatus
}

function getUserPayload(user: APIUser): UserPayload {
  return {
    bio: user.bio,
    dmStatus: user.dmStatus,
    announcementsDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_ANNOUNCEMENTS),
    anonymizeLeaderboardDisabled: !hasFlag(user.flags, PlayerFlags.ANONYMIZE_LEADERBOARD),
    discordDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_DISCORD),
    fpsModeDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_FPS_MODE),
    friendRequestsDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_FRIEND_REQUESTS),
    guildInvitesDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_GUILD_INVITES),
    guildTagDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_GUILD_TAG),
    invisibleModeDisabled: !hasFlag(user.flags, PlayerFlags.INVISIBLE_MODE),
    locationDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_LOCATION),
    otherPlayersDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_OTHER_PLAYERS),
    rankDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_RANK),
    rankedChatDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_RANKED_CHAT),
    skinDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_SKIN),
    statsDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_STATS),
    winStreaksDisabled: hasFlag(user.flags, PlayerFlags.DISABLE_WIN_STREAKS),
  }
}

const DMStatusToString: Record<DMStatus, string> = {
  [DMStatus.EVERYONE]: 'everyone',
  [DMStatus.FRIENDS]: 'friends',
  [DMStatus.BLOCKED]: 'blocked',
}

const StringToDMStatus: Record<string, DMStatus> = {
  everyone: DMStatus.EVERYONE,
  friends: DMStatus.FRIENDS,
  blocked: DMStatus.BLOCKED,
}

function getFlagsFromPayload(input: UserPayload): number {
  let flags = 0
  if (input.announcementsDisabled) flags |= PlayerFlags.DISABLE_ANNOUNCEMENTS
  else flags &= ~PlayerFlags.DISABLE_ANNOUNCEMENTS
  if (input.anonymizeLeaderboardDisabled) flags &= ~PlayerFlags.ANONYMIZE_LEADERBOARD
  else flags |= PlayerFlags.ANONYMIZE_LEADERBOARD
  if (input.discordDisabled) flags |= PlayerFlags.DISABLE_DISCORD
  else flags &= ~PlayerFlags.DISABLE_DISCORD
  if (input.fpsModeDisabled) flags |= PlayerFlags.DISABLE_FPS_MODE
  else flags &= ~PlayerFlags.DISABLE_FPS_MODE
  if (input.friendRequestsDisabled) flags |= PlayerFlags.DISABLE_FRIEND_REQUESTS
  else flags &= ~PlayerFlags.DISABLE_FRIEND_REQUESTS
  if (input.guildInvitesDisabled) flags |= PlayerFlags.DISABLE_GUILD_INVITES
  else flags &= ~PlayerFlags.DISABLE_GUILD_INVITES
  if (input.guildTagDisabled) flags |= PlayerFlags.DISABLE_GUILD_TAG
  else flags &= ~PlayerFlags.DISABLE_GUILD_TAG
  if (input.invisibleModeDisabled) flags &= ~PlayerFlags.INVISIBLE_MODE
  else flags |= PlayerFlags.INVISIBLE_MODE
  if (input.locationDisabled) flags |= PlayerFlags.DISABLE_LOCATION
  else flags &= ~PlayerFlags.DISABLE_LOCATION
  if (input.otherPlayersDisabled) flags |= PlayerFlags.DISABLE_OTHER_PLAYERS
  else flags &= ~PlayerFlags.DISABLE_OTHER_PLAYERS
  if (input.rankDisabled) flags |= PlayerFlags.DISABLE_RANK
  else flags &= ~PlayerFlags.DISABLE_RANK
  if (input.rankedChatDisabled) flags |= PlayerFlags.DISABLE_RANKED_CHAT
  else flags &= ~PlayerFlags.DISABLE_RANKED_CHAT
  if (input.skinDisabled) flags |= PlayerFlags.DISABLE_SKIN
  else flags &= ~PlayerFlags.DISABLE_SKIN
  if (input.statsDisabled) flags |= PlayerFlags.DISABLE_STATS
  else flags &= ~PlayerFlags.DISABLE_STATS
  if (input.winStreaksDisabled) flags |= PlayerFlags.DISABLE_WIN_STREAKS
  else flags &= ~PlayerFlags.DISABLE_WIN_STREAKS
  return flags
}

export function AccountUpdateModal(props: ModalProps): React.JSX.Element {
  const queryClient = useQueryClient()
  const logoutModal = useDisclosure()
  const [characterCount, setCharacterCount] = React.useState(0)
  const {control, formState, handleSubmit, register, reset, setError, watch} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: React.useMemo(() => getUserPayload(props.user), [props.user]),
  })

  React.useEffect(() => reset(props.user), [props.user, reset])
  React.useEffect(() => {
    if (!props.isOpen) reset(getUserPayload(props.user))
  }, [props.isOpen, props.user, reset])
  React.useEffect(() => setCharacterCount(props.user.bio?.length ?? 0), [props.user])
  React.useEffect(() => {
    const subscription = watch(value => setCharacterCount(value.bio?.length ?? 0))
    return () => subscription.unsubscribe()
  }, [watch])

  async function onSubmit(input: UserPayload): Promise<void> {
    try {
      await api.patch<Partial<APIUser>>('users/@me', {
        bio: input.bio,
        flags: getFlagsFromPayload(input),
        dmStatus: input.dmStatus,
      })
      await queryClient.invalidateQueries({queryKey: ['user']})
      await queryClient.invalidateQueries({queryKey: ['player', props.user.name]})
      await queryClient.refetchQueries()
      toast.success('Updated your profile', {id: 'profile_updated'})
      props.onClose()
    } catch (error) {
      setError('bio', {type: 'validate', message: getErrorMessage(error, 'bio')})
    }
  }

  return (
    <Modal
      finalFocusRef={props.buttonRef}
      footer={
        <ModalFooter>
          <Button isLoading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </ModalFooter>
      }
      header="Edit Profile"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <LogoutModal {...logoutModal} />

      <ModalBody fontSize="md">
        <FormControl isDisabled>
          <FormLabel fontWeight="semibold" htmlFor="name" letterSpacing="tight">
            Email
          </FormLabel>
          <Input isReadOnly value={props.user.email} />
          <FormHelperText>
            <Link color="orange.300" href="https://go.microsoft.com/fwlink/?LinkId=529489" isExternal>
              Click here
            </Link>{' '}
            to change your email address, and then{' '}
            <Link color="orange.300" onClick={() => logoutModal.onOpen()}>
              log out
            </Link>
            .
          </FormHelperText>
        </FormControl>

        <FormControl isInvalid={Boolean(formState.errors.bio?.message)} mt={4}>
          <FormLabel fontWeight="semibold" htmlFor="bio" letterSpacing="tight">
            About Me
          </FormLabel>
          <Textarea
            autoFocus
            characterCount={characterCount}
            id="bio"
            maxLength={512}
            placeholder="Let the community know who you are!"
            {...register('bio')}
          />
          <FormErrorMessage>{formState.errors.bio?.message}</FormErrorMessage>
        </FormControl>

        <Stack gap={2} mt={4}>
          <FormControl as="fieldset">
            <FormLabel as="legend" fontWeight="semibold" letterSpacing="tight">
              DM Settings
            </FormLabel>
            <Controller
              control={control}
              name="dmStatus"
              render={({field}) => (
                <RadioGroup
                  as={Stack}
                  onChange={value => field.onChange(StringToDMStatus[value]!)}
                  spacing="1"
                  value={DMStatusToString[field.value]}
                >
                  <Radio size="sm" value="everyone">
                    Allow DMs from everyone
                  </Radio>
                  <Radio size="sm" value="friends">
                    Allow DMs from friends only
                  </Radio>
                  <Radio size="sm" value="blocked">
                    Block DMs from everyone
                  </Radio>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>

        <Stack gap={2} mt={4}>
          <Divider />

          <Stack>
            <SmallHeading>Portal Privacy Settings</SmallHeading>
            <Stack gap={2}>
              <FlagToggle
                control={control}
                description="Display your Discord account to everyone."
                label="Discord Visibility"
                name="discordDisabled"
              />

              <FlagToggle
                control={control}
                description="Display your server location to everyone."
                label="Location Visibility"
                name="locationDisabled"
              />

              <FlagToggle
                control={control}
                description="Display your player skin to everyone."
                label="Skin Visibility"
                name="skinDisabled"
              />

              <FlagToggle
                control={control}
                description="Display your portal profile stats (does not affect the API)."
                label="Stats Visibility"
                name="statsDisabled"
              />

              <FlagToggle
                control={control}
                description="Display your win streaks to everyone."
                label="Win Streak Visibility"
                name="winStreaksDisabled"
              />

              <FlagToggle
                control={control}
                description="Allow incoming guild invites in the portal."
                label="Guild Invites"
                name="guildInvitesDisabled"
              />

              <FlagToggle
                control={control}
                description="Mask your identity as 'Anonymous User' on leaderboards."
                label="Anonymize Leaderboard"
                name="anonymizeLeaderboardDisabled"
              />

              <FlagToggle
                control={control}
                description="Hide your online status in the portal."
                label="Invisible Mode"
                name="invisibleModeDisabled"
              />
            </Stack>
          </Stack>

          <Divider />

          <Stack>
            <SmallHeading>Game Privacy Settings</SmallHeading>
            <Stack gap={2}>
              <FlagToggle
                control={control}
                description="Allow incoming friend requests from anyone."
                label="Friend Requests"
                name="friendRequestsDisabled"
              />

              <FlagToggle
                control={control}
                description="Display your guild tag to everyone."
                label="Guild Tag Visibility"
                name="guildTagDisabled"
              />

              <FlagToggle
                control={control}
                description="Display your highest rank to everyone."
                label="Rank Visibility"
                name="rankDisabled"
              />
            </Stack>
          </Stack>

          <Divider />

          <Stack>
            <SmallHeading>Game Display Settings</SmallHeading>
            <Stack gap={2}>
              <FlagToggle
                control={control}
                description="Enable displaying other players in-game."
                label="Player Visibility"
                name="otherPlayersDisabled"
              />

              <FlagToggle
                control={control}
                description="Enable server announcements in the chat."
                label="Announcements"
                name="announcementsDisabled"
              />

              <FlagToggle
                control={control}
                description="Enable dedicated chat for players with a rank."
                label="Ranked Chat"
                name="rankedChatDisabled"
              />

              <FlagToggle
                control={control}
                description="Disable fancy graphics and animations in-game."
                label="Low FPS Mode"
                name="fpsModeDisabled"
              />
            </Stack>
          </Stack>
        </Stack>
      </ModalBody>
    </Modal>
  )
}

function FlagToggle({
  description,
  name,
  label,
  control,
}: {
  description: string
  name: keyof UserPayloadToggles
  label: string
  control: Control<UserPayload>
}): React.JSX.Element {
  return (
    <FormControl w="full">
      <Stack direction="column" spacing="1">
        <Stack align="center" direction="row">
          <FormLabel flex="1" fontWeight="semibold" htmlFor={name} letterSpacing="tight" mb={0}>
            {label}
          </FormLabel>
          <Controller
            control={control}
            name={name}
            render={({field}) => (
              <Switch id={name} isChecked={!field.value} onChange={event => field.onChange(!event.target.checked)} />
            )}
          />
        </Stack>
        <FormHelperText letterSpacing="tight">{description}</FormHelperText>
      </Stack>
    </FormControl>
  )
}
