import type {APIApplication} from '@app/common/api'
import {useApplications} from '@app/common/api'
import {dateFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import styles from '@app/components/App.module.css'
import {ApplicationCreateModal} from '@app/components/Application/ApplicationCreateModal'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import ErrorScreen from '@app/components/UI/ErrorScreen'
import {MetaTags} from '@app/components/UI/MetaTags'
import {ExternalLinkIcon} from '@chakra-ui/icons'
import {Box, Button, Heading, IconButton, Link, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {PencilSquareIcon, PlusIcon} from '@heroicons/react/24/solid'
import React from 'react'

function PlayerApplication({application}: {application: APIApplication}): React.JSX.Element {
  const setApplicationId = useOverlayStore(state => state.setApplicationId)
  return (
    <Stack
      align="center"
      bgColor="gray.900"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
    >
      <Stack align="center" direction="row" flex="1" spacing="16px">
        <Stack spacing="1">
          <Text fontWeight="semibold" textTransform="uppercase">
            {application.name}
          </Text>

          <Text fontWeight="light">
            {dateFormatter.format(new Date(application.lastActiveAt ?? application.createdAt))}
          </Text>
        </Stack>
      </Stack>

      <Stack spacing="1">
        <Tooltip label="Edit Application">
          <IconButton
            aria-label="Edit Application"
            colorScheme="orange"
            icon={<PencilSquareIcon height={16} width={16} />}
            onClick={() => setApplicationId(application.id)}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default function Applications(): React.JSX.Element | null {
  const {status, data, error} = useApplications()
  const createModal = useDisclosure()
  const setSplashScreenOpen = useOverlayStore(state => state.setSplashScreenOpen)
  React.useEffect(() => {
    setSplashScreenOpen(status === 'pending')
  }, [setSplashScreenOpen, status])

  if (status === 'error') return <ErrorScreen payload={error} />
  if (!data) return null

  return (
    <>
      <MetaTags title="API Integrations" />
      <ApplicationCreateModal {...createModal} />

      <Stack borderWidth={1} className={styles.backdrop} gap="8px" p={8} rounded="2xl">
        <Box textAlign="center">
          <Heading fontSize="2xl">API Integrations</Heading>
          <Text fontSize="md" mt={2}>
            Manage your API integrations.{' '}
            <Link color="orange.300" href="https://docs.nethergames.org" isExternal>
              Read the docs
              <ExternalLinkIcon color="orange.300" mb={1} ml={1} />
            </Link>
          </Text>
        </Box>

        <Stack gap="8px">
          {data.map(application => (
            <PlayerApplication application={application} key={application.id} />
          ))}

          {data.length < 10 && (
            <Button
              colorScheme="orange"
              leftIcon={<PlusIcon height={16} width={16} />}
              onClick={() => createModal.onOpen()}
              size="md"
              variant="outline"
            >
              Create New
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  )
}
