import type {APIPunishment} from '@app/common/api'
import {PunishmentType} from '@app/common/api'
import {dateFormatter} from '@app/common/constants'
import {Indicator} from '@app/components/Indicator/Indicator'
import {PunishmentModal} from '@app/components/Modal/PunishmentModal'
import {Flex, Heading, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/solid'
import type React from 'react'
import {FaGavel, FaVolumeMute} from 'react-icons/fa'

export function Punishment({punishment}: {punishment: APIPunishment}): React.JSX.Element {
  const LeftIcon = punishment.type === PunishmentType.BAN ? FaGavel : FaVolumeMute
  const punishmentModal = useDisclosure()
  return (
    <Stack
      align="center"
      bgColor={punishment.active ? 'red.500' : 'green.500'}
      boxShadow="lg"
      cursor="pointer"
      direction="row"
      justify="space-between"
      onClick={() => punishmentModal.onOpen()}
      p={4}
      role="button"
      rounded="lg"
      tabIndex={0}
      transition="ease-in-out 200ms"
      w="full"
    >
      <PunishmentModal punishment={punishment} {...punishmentModal} />
      <Stack align="center" direction="row" spacing="12px">
        <Flex flex="1 0 auto">
          <LeftIcon height={24} size={24} width={24} />
        </Flex>
        <Stack spacing="0.5">
          <Stack align="center" direction="row" spacing="1.5">
            <Heading isTruncated size="sm">
              {punishment.type === PunishmentType.BAN ? 'Ban' : 'Mute'}
            </Heading>
            <Indicator variant="inverted">{punishment.active ? 'Active' : 'Expired'}</Indicator>
          </Stack>
          <Stack spacing="0">
            <Text color="whiteAlpha.800" fontSize="sm">
              {punishment.permanent ? 'Permanent' : dateFormatter.format(new Date(punishment.validUntil * 1000))}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <ArrowTopRightOnSquareIcon height={24} width={24} />
    </Stack>
  )
}
