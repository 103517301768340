import type {APIGuild} from '@app/common/api'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {useNavigate} from 'react-router-dom'

type ModalProps = {
  guild: APIGuild
  buttonRef: any
  isOpen: boolean
  onClose(): void
}

export function GuildDeleteModal(props: ModalProps): React.JSX.Element {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)

  async function onSubmit(event: any): Promise<void> {
    event.preventDefault()
    setIsLoading(true)
    await api.delete(`guilds/${props.guild.name}`)
    navigate('/')
    setIsLoading(false)
    queryClient.invalidateQueries({queryKey: ['guilds', props.guild.name]})
    props.onClose()
  }

  return (
    <Modal
      finalFocusRef={props.buttonRef}
      footer={
        <ModalFooter>
          <Button colorScheme="gray" onClick={props.onClose} variant="ghost">
            Cancel
          </Button>
          <Button colorScheme="red" isLoading={isLoading} ml={3} onClick={async event => onSubmit(event)}>
            Delete
          </Button>
        </ModalFooter>
      }
      header="Delete Guild"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody fontSize="md">
        Are you sure you want to delete '<strong>{props.guild.name}</strong>'? This cannot be undone!
      </ModalBody>
    </Modal>
  )
}
