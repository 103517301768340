import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Button} from '@chakra-ui/react'
import type React from 'react'

type AlertDialogProps = {
  title: React.ReactNode
  content: React.ReactNode
  primary: React.ReactNode
  isOpen: boolean
  isLoading: boolean
  onClose(): void
  onPrimary(): void
}

export function AlertDialog(props: AlertDialogProps): React.JSX.Element {
  return (
    <Modal
      footer={
        <ModalFooter gap="8px">
          <Button colorScheme="gray" onClick={props.onClose} variant="ghost">
            Cancel
          </Button>
          <Button colorScheme="red" isLoading={props.isLoading} ml={3} onClick={props.onPrimary}>
            {props.primary}
          </Button>
        </ModalFooter>
      }
      header={props.title}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody fontSize="md">{props.content}</ModalBody>
    </Modal>
  )
}
