import styles from '@app/components/Spinner/Spinner.module.css'
import clsx from 'clsx'
import type React from 'react'

export function Spinner(): React.JSX.Element {
  return (
    <div className={styles.spinner}>
      <div className={styles.spinnerInner}>
        <svg className={styles.circular} viewBox="25 25 50 50">
          <title>Spinner</title>
          <circle className={clsx(styles.path, styles.path3)} cx={50} cy={50} r={20} />
          <circle className={clsx(styles.path, styles.path2)} cx={50} cy={50} r={20} />
          <circle className={styles.path} cx={50} cy={50} r={20} />
        </svg>
      </div>
    </div>
  )
}
