import {useCurrentUser} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import styles from '@app/components/App.module.css'
import {AuthButton} from '@app/components/Auth/AuthButton'
import ErrorScreen from '@app/components/UI/ErrorScreen'
import {Box, Heading, Link, Text} from '@chakra-ui/react'
import React from 'react'
import {Outlet} from 'react-router-dom'

export default function Auth(): React.JSX.Element | null {
  const {error, isLoading, status} = useCurrentUser()
  const setSplashScreenOpen = useOverlayStore(state => state.setSplashScreenOpen)
  React.useEffect(() => {
    setSplashScreenOpen(status === 'pending')
  }, [setSplashScreenOpen, status])

  if (isLoading) return null
  if (status === 'error')
    return error?.response?.status === 401 ? (
      <Box borderWidth={1} className={styles.backdrop} mx={4} p={8} rounded="2xl" textAlign="center">
        <Box mb={5}>
          <Heading fontSize="4xl">Login Required</Heading>
          <Text fontSize="lg" letterSpacing="tight" mt={4}>
            Please authenticate with Xbox to continue.
          </Text>
          <Text fontSize="lg" letterSpacing="tight">
            Wrong account? Log out from{' '}
            <Link color="orange.300" fontWeight="semibold" href="https://microsoft.com" isExternal>
              microsoft.com
            </Link>{' '}
            and try again.
          </Text>
        </Box>
        <AuthButton />
      </Box>
    ) : (
      <ErrorScreen payload={error} />
    )

  return <Outlet />
}
