import styles from '@app/components/Dropdown/Dropdown.module.css'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {ChevronRightIcon, DotFilledIcon} from '@radix-ui/react-icons'
import clsx from 'clsx'
import React from 'react'
import {useNavigate} from 'react-router-dom'

type IBaseMenuItem = {
  label: string
  danger?: boolean
  tag?: 'Beta' | 'New'
}

export type IMenuItem = IBaseMenuItem & {url: string}
export type IMenuItemWithHandler = IBaseMenuItem & {onSelect(): void}
export type IMenuItemWithChildren = IBaseMenuItem & {children: TMenuItem[]}
export type TMenuItem = IMenuItem | IMenuItemWithChildren | IMenuItemWithHandler

type DropdownProps = {
  children: React.ReactNode
  menuItems: TMenuItem[]
  isRadioList?: boolean
  checkedItem?: string
}

function DropdownMenuItem({item}: {item: TMenuItem}): React.JSX.Element {
  const navigate = useNavigate()

  if ('children' in item)
    return (
      <DropdownMenu.Sub key={item.label}>
        <DropdownMenu.SubTrigger className={clsx(styles.item, styles.trigger)}>
          {item.label}
          <div className={styles.right}>
            {item.tag && <span className={styles.newIndicator}>{item.tag}</span>}
            <div className={styles.right}>
              <ChevronRightIcon />
            </div>
          </div>
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent alignOffset={-5} className={styles.content} sideOffset={18}>
            {item.children.map(item => (
              <DropdownMenuItem item={item} key={item.label} />
            ))}
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    )

  return (
    <DropdownMenu.Item
      className={clsx(styles.item, {[styles.danger as string]: item.danger})}
      key={item.label}
      onSelect={'url' in item ? () => navigate(item.url) : 'onSelect' in item ? item.onSelect : () => {}}
    >
      {item.label}
      {item.tag && <span className={styles.newIndicator}>{item.tag}</span>}
    </DropdownMenu.Item>
  )
}

export function Dropdown(props: DropdownProps): React.JSX.Element {
  const navigate = useNavigate()
  const [checkedItem, setCheckedItem] = React.useState(props.checkedItem)
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{props.children}</DropdownMenu.Trigger>
      <DropdownMenu.Content alignOffset={16} className={styles.content} sideOffset={16}>
        {props.isRadioList && checkedItem ? (
          <DropdownMenu.RadioGroup onValueChange={setCheckedItem} value={checkedItem}>
            {(props.menuItems as IMenuItem[]).map(item => (
              <DropdownMenu.RadioItem
                className={clsx(styles.item, {[styles.danger as string]: item.danger})}
                key={item.url}
                onSelect={() => navigate(item.url)}
                value={item.url}
              >
                <DropdownMenu.ItemIndicator className={styles.indicator}>
                  <DotFilledIcon />
                </DropdownMenu.ItemIndicator>
                {item.label}
                {item.tag && <span className={styles.newIndicator}>{item.tag}</span>}
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        ) : (
          props.menuItems.map(item => <DropdownMenuItem item={item} key={item.label} />)
        )}
        <DropdownMenu.Arrow className={styles.arrow} />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
