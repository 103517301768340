import {FactionRole, useCurrentUser, useGuild, usePunishmentsBulk} from '@app/common/api'
import {GUILD_INACTIVE_MESSAGE, GuildFlags, numberFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {getAvatarUrl, hasFlag, isAdmin} from '@app/common/utils'
import appStyles from '@app/components/App.module.css'
import {GuildInvitesModal} from '@app/components/Guild/GuildInvitesModal'
import {GuildUpdateModal} from '@app/components/Guild/GuildUpdateModal'
import {MemberList} from '@app/components/Member/MemberList'
import playerStyles from '@app/components/Player/Player.module.css'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import ErrorScreen from '@app/components/UI/ErrorScreen'
import {MetaTags} from '@app/components/UI/MetaTags'
import {api} from '@app/hooks/useApi'
import {EditIcon, ExternalLinkIcon, LinkIcon} from '@chakra-ui/icons'
import {Badge, Button, Divider, Heading, IconButton, Link, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {ArrowLeftOnRectangleIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import clsx from 'clsx'
import React from 'react'
import {toast} from 'react-hot-toast'
import {Link as RouterLink, useParams} from 'react-router-dom'

export default function Guild(): React.JSX.Element | null {
  const {guild} = useParams()
  const {status, data, error} = useGuild(guild!)
  const {data: user} = useCurrentUser()
  const {data: punishments} = usePunishmentsBulk(data?.xuids ?? [])
  const [isLeaving, setIsLeaving] = React.useState(false)
  const [isRandomizing, setIsRandomizing] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const invitesModal = useDisclosure()
  const invitesRef = React.useRef()
  const updateModal = useDisclosure()
  const updateRef = React.useRef()
  const queryClient = useQueryClient()
  const setSplashScreenOpen = useOverlayStore(state => state.setSplashScreenOpen)
  React.useEffect(() => {
    setSplashScreenOpen(status === 'pending')
  }, [setSplashScreenOpen, status])

  if (status === 'error') return <ErrorScreen payload={error} />
  if (!data) return null

  function getCurrentRole(xuid: string): FactionRole | null {
    if (!data) return null
    if (data.leader && data.leader.xuid === xuid) return FactionRole.LEADER
    if (data.officers.some(value => value.xuid === xuid)) return FactionRole.OFFICER
    if (data.members.some(value => value.xuid === xuid)) return FactionRole.MEMBER
    return null
  }

  const currentRole = user ? getCurrentRole(user.xuid) : null
  const shouldAllowUpdate = import.meta.env.DEV || currentRole === FactionRole.LEADER
  const isGuildActive = hasFlag(data.flags, GuildFlags.ACTIVE)

  return (
    <>
      <MetaTags image={getAvatarUrl(data.leader.skinHash)} title={data.name} />

      <GuildUpdateModal buttonRef={updateRef} guild={data} {...updateModal} />
      <GuildInvitesModal buttonRef={invitesRef} guild={data} {...invitesModal} />

      <Stack
        align="center"
        borderWidth={1}
        className={clsx(appStyles.card, appStyles.backdrop)}
        direction="column"
        my={4}
        overflow="visible"
        p={4}
        rounded="2xl"
        spacing={2}
      >
        <Stack align="center" direction="column">
          <Stack align="center" direction={['column', 'row']}>
            <Heading fontSize="2xl" fontWeight="bold">
              {data.name}
            </Heading>

            <Stack align="center" direction={['column', 'row']}>
              {data.tag && data.tagColor && (
                <Badge bg={data.tagColor} className={playerStyles.badge} px={2} rounded="lg">
                  {data.tag}
                </Badge>
              )}

              {!isGuildActive && (
                <Tooltip label={GUILD_INACTIVE_MESSAGE} padding={16}>
                  <Badge bg="red.600" className={playerStyles.badge} px={2} rounded="lg">
                    Inactive
                  </Badge>
                </Tooltip>
              )}
            </Stack>
          </Stack>

          <Stack direction="row">
            {shouldAllowUpdate && (
              <Button
                colorScheme="gray"
                leftIcon={<EditIcon />}
                onClick={() => updateModal.onOpen()}
                ref={updateRef as any}
                size={['xs', 'sm']}
              >
                Manage Guild
              </Button>
            )}

            {currentRole != null && currentRole !== FactionRole.LEADER && (
              <Button
                colorScheme="red"
                isLoading={isLeaving}
                leftIcon={<ArrowLeftOnRectangleIcon height={16} width={16} />}
                onClick={async () => {
                  try {
                    setIsLeaving(true)
                    await api.delete(`/guilds/${data.name}/members/@me`)
                    await queryClient.invalidateQueries({queryKey: ['guilds', data.name]})
                  } finally {
                    setIsLeaving(false)
                  }
                }}
                size={['xs', 'sm']}
              >
                Leave Guild
              </Button>
            )}

            {currentRole && (
              <Button
                colorScheme="gray"
                leftIcon={<LinkIcon />}
                onClick={() => invitesModal.onOpen()}
                ref={invitesRef as any}
                size={['xs', 'sm']}
              >
                Manage Invites
              </Button>
            )}
          </Stack>
        </Stack>

        {isAdmin(user) && (
          <Stack direction="row">
            <Button
              colorScheme="red"
              isLoading={isDeleting}
              onClick={async () => {
                try {
                  setIsDeleting(true)
                  await api.delete(`/admin/guilds/${data.name}`)
                  await queryClient.invalidateQueries({queryKey: ['guilds', data.name]})
                } catch {
                  toast.error('Something went wrong while deleting the guild. Please try again later.')
                } finally {
                  setIsDeleting(false)
                }
              }}
              size={['xs', 'sm']}
            >
              Delete Guild (Admin Only)
            </Button>

            <Button
              colorScheme="red"
              isLoading={isRandomizing}
              onClick={async () => {
                try {
                  setIsRandomizing(true)
                  await api.post(`/admin/guilds/${data.name}/randomize-name`)
                  await queryClient.invalidateQueries({queryKey: ['guilds', data.name]})
                } catch {
                  toast.error('Something went wrong while randomizing the guild name. Please try again later.')
                } finally {
                  setIsRandomizing(false)
                }
              }}
              size={['xs', 'sm']}
            >
              Randomize Name (Admin Only)
            </Button>
          </Stack>
        )}

        <Stack align="center" direction={['column', 'row']} fontSize="16px" gap={['0px', '8px']} justify="center">
          <div>
            Level: <strong>{numberFormatter.format(data.level)}</strong>
          </div>

          <div>
            Members: <strong>{data.memberCount}</strong>/<strong>{data.maxSize}</strong>
          </div>

          <Stack align="center" direction="row" gap="4px" justify="center">
            <div>
              <strong>{numberFormatter.format(data.xp)}</strong> XP{' '}
              {isGuildActive && (
                <>
                  (#<strong>{numberFormatter.format(data.position)}</strong>)
                </>
              )}
            </div>
            <IconButton
              aria-label="Leaderboard"
              as={RouterLink}
              colorScheme="gray"
              icon={<ExternalLinkIcon />}
              ml={1}
              size="xs"
              to={`/leaderboard/guilds?active=${isGuildActive}`}
            />
          </Stack>
        </Stack>

        {data.discordInvite && (
          <Text>
            <strong>Discord</strong>:{' '}
            <Link color="orange.300" href={`https://discord.gg/${data.discordInvite}`} isExternal userSelect="text">
              discord.gg/{data.discordInvite}
              <ExternalLinkIcon color="orange.300" mb={1} ml={1} />
            </Link>
          </Text>
        )}

        <Divider mb="0.5rem!important" mt="1rem!important" />
        <Stack align="center" direction="column" justify="center" mb="1.25rem!important" px={1} spacing={2} w="full">
          <Heading fontSize="lg">MOTD</Heading>
          {data.motd ? (
            <Text className={playerStyles.bio} fontSize="md">
              {data.motd}
            </Text>
          ) : (
            <Text color="gray.400" fontSize="md">
              {data.name} does not have a MOTD yet.{' '}
              {shouldAllowUpdate && data.level >= 100 ? (
                <Link color="orange.300" fontWeight="semibold" onClick={() => updateModal.onOpen()}>
                  Create one now!
                </Link>
              ) : null}
            </Text>
          )}
        </Stack>

        {data.leader && (
          <>
            <Divider mb="0.5rem!important" mt="1rem!important" />
            <Stack
              align="center"
              direction="column"
              justify="center"
              mb="1.25rem!important"
              px={1}
              spacing={2}
              w="full"
            >
              <Heading fontSize="lg">Leader</Heading>
              <MemberList
                currentRole={null}
                entity={data}
                members={[data.leader]}
                punishments={punishments ?? {}}
                role={FactionRole.LEADER}
                type="guild"
              />
            </Stack>
          </>
        )}

        {data.officers.length > 0 && (
          <>
            <Divider mb="0.5rem!important" mt="1rem!important" />
            <Stack
              align="center"
              direction="column"
              justify="center"
              mb="1.25rem!important"
              px={1}
              spacing={2}
              w="full"
            >
              <Heading fontSize="lg">Officers — {data.officers.length}</Heading>
              <MemberList
                currentRole={currentRole}
                entity={data}
                members={data.officers}
                punishments={punishments ?? {}}
                role={FactionRole.OFFICER}
                type="guild"
              />
            </Stack>
          </>
        )}

        {data.members.length > 0 && (
          <>
            <Divider mb="0.5rem!important" mt="1rem!important" />
            <Stack
              align="center"
              direction="column"
              justify="center"
              mb="1.25rem!important"
              px={1}
              spacing={2}
              w="full"
            >
              <Heading fontSize="lg">Members — {data.members.length}</Heading>
              <MemberList
                currentRole={currentRole}
                entity={data}
                members={data.members}
                punishments={punishments ?? {}}
                role={FactionRole.MEMBER}
                type="guild"
              />
            </Stack>
          </>
        )}
      </Stack>
    </>
  )
}
