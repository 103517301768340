import type {TextareaProps as ChakraTextareaProps} from '@chakra-ui/react'
import {Box, Textarea as ChakraTextarea, Flex} from '@chakra-ui/react'
import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

type TextareaProps = ChakraTextareaProps & {
  maxLength: number
  characterCount: number
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const {characterCount, ...rest} = props
  return (
    <Flex flexDir="column">
      <Box pos="relative">
        <ChakraTextarea
          as={TextareaAutosize}
          letterSpacing="tight"
          maxH="128px"
          minH="unset"
          minRows={1}
          ref={ref}
          resize="none"
          w="100%"
          {...rest}
        />
        <Box
          bottom="12px"
          color="gray.500"
          fontFamily="mono"
          fontSize="12px"
          pointerEvents="none"
          pos="absolute"
          right="14px"
        >
          {props.maxLength - characterCount}
        </Box>
      </Box>
    </Flex>
  )
})
