import {Text} from '@chakra-ui/react'
import type React from 'react'

type LabelValueProps = {
  label: string
  value: React.ReactNode
}

export function LabelValue(props: LabelValueProps): React.JSX.Element {
  return (
    <Text fontSize="md">
      <strong>{props.label}</strong>: {props.value}
    </Text>
  )
}
