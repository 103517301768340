import {NOOP} from '@app/common/constants'
import {Scroller} from '@app/components/Scroller/Scroller'
import type {ComponentWithAs, ModalBodyProps, ModalFooterProps} from '@chakra-ui/react'
import {
  Modal as ChakraModal,
  ModalBody as ChakraModalBody,
  ModalFooter as ChakraModalFooter,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
} from '@chakra-ui/react'
import type React from 'react'

export function Modal({
  children,
  footer,
  header,
  isOpen,
  onClose,
  finalFocusRef,
  fullscreen,
  forceOpen = false,
}: {
  children: React.ReactNode
  footer?: React.ReactNode
  header?: React.ReactNode
  isOpen: boolean
  onClose(): void
  finalFocusRef?: any
  fullscreen?: boolean
  forceOpen?: boolean
}): React.JSX.Element {
  const isMobile = useMediaQuery('(max-width: 768px)')
  return (
    <ChakraModal
      blockScrollOnMount={!isMobile}
      finalFocusRef={finalFocusRef}
      isCentered
      isOpen={isOpen}
      onClose={forceOpen ? NOOP : onClose}
      onOverlayClick={forceOpen ? NOOP : onClose}
    >
      <ModalOverlay {...(forceOpen ? {bg: 'gray.800'} : {})} />
      <ModalContent
        borderRadius={['unset', '2xl']}
        boxShadow="none"
        h={['100%', fullscreen ? '75vh' : 'unset']}
        maxH={['unset', '85vh']}
        maxW="unset"
        my={['unset', '3.75rem']}
        overflow="hidden"
        w={['100%', fullscreen ? '75vw' : '490px']}
      >
        {!forceOpen && <ModalCloseButton />}
        {header && (
          <ModalHeader borderBottomWidth="1px" borderColor="gray.600" fontSize="md">
            {header}
          </ModalHeader>
        )}
        <Scroller>{children}</Scroller>
        {footer}
      </ModalContent>
    </ChakraModal>
  )
}

export const ModalFooter: ComponentWithAs<'footer', ModalFooterProps> = (
  props: ModalFooterProps,
): React.JSX.Element => {
  return <ChakraModalFooter {...props} bgColor="gray.700" borderColor="gray.600" borderTopWidth="1px" width="full" />
}

export const ModalBody: ComponentWithAs<'div', ModalBodyProps> = (props: ModalBodyProps): React.JSX.Element => {
  return <ChakraModalBody {...props} minH="full" px={2} py={3} />
}
