import type {APIPlayer} from '@app/common/api'
import {Period, numberFormatter} from '@app/common/constants'
import type {ExtraNested} from '@app/common/types'
import {getKdr, getPeriodOptions} from '@app/common/utils'
import appStyles from '@app/components/App.module.css'
import playerStyles from '@app/components/Player/Player.module.css'
import {
  Badge,
  Box,
  Tab as ChakraTab,
  Flex,
  Heading,
  Link,
  Select,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, makeStyles} from '@material-ui/core'
import clsx from 'clsx'
import {getWeek} from 'date-fns'
import type React from 'react'
import Countdown from 'react-countdown'
import {FaCircle} from 'react-icons/fa'
import {Link as RouterLink} from 'react-router-dom'
import {useMediaQuery} from 'usehooks-ts'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    borderRadius: '16px',
    boxShadow: 'none',
    width: '100%',
  },
  tableWrapper: {overflow: 'auto'},
}))

function Tab(props: {children: React.ReactNode}): React.JSX.Element {
  return (
    <ChakraTab
      _hover={{color: 'white'}}
      _selected={{backgroundColor: 'gray.800', color: 'white'}}
      color="var(--chakra-colors-gray-400)"
      flex="0 0 auto"
      rounded="full"
    >
      {props.children}
    </ChakraTab>
  )
}

function FormattedTableCell({children}: {children: React.ReactNode}): React.JSX.Element {
  return (
    <TableCell>
      <Box as="span" color="gray.400">
        {typeof children === 'number' ? numberFormatter.format(children) : children}
      </Box>
    </TableCell>
  )
}

export function PlayerStats({
  player,
  period,
  setPeriod,
  shouldDisableStats,
}: {
  player: APIPlayer
  period: Period
  setPeriod(period: Period): void
  shouldDisableStats: boolean
}): React.JSX.Element | null {
  const styles = useStyles()
  const isMobile = useMediaQuery('(max-width: 640px)')
  if (shouldDisableStats) return null

  const wins = [
    {key: 'bw', label: 'Bedwars', value: player.winsData?.BW ?? 0, color: 'var(--chakra-colors-blue-400)'},
    {key: 'cq', label: 'Conquests', value: player.winsData?.CQ ?? 0, color: 'var(--chakra-colors-cyan-700)'},
    {key: 'duels', label: 'Duels', value: player.winsData?.Duels ?? 0, color: 'var(--chakra-colors-green-600)'},
    {key: 'md', label: 'Meltdown', value: player.winsData?.MD ?? 0, color: 'var(--chakra-colors-blue-500)'},
    {key: 'mm', label: 'Murder Mystery', value: player.winsData?.MM ?? 0, color: 'var(--chakra-colors-orange-500)'},
    {key: 'ms', label: 'Momma Says', value: player.winsData?.MS ?? 0, color: 'var(--chakra-colors-orange-300)'},
    {key: 'sc', label: 'Soccer', value: player.winsData?.SC ?? 0, color: 'var(--chakra-colors-pink-400)'},
    {key: 'sg', label: 'Survival Games', value: player.winsData?.SG ?? 0, color: 'var(--chakra-colors-purple-500)'},
    {key: 'sw', label: 'Skywars', value: player.winsData?.SW ?? 0, color: 'var(--chakra-colors-purple-300)'},
    {key: 'tb', label: 'The Bridge', value: player.winsData?.TB ?? 0, color: 'var(--chakra-colors-red-500)'},
    {key: 'uhc', label: 'UHC', value: player.winsData?.UHC ?? 0, color: 'var(--chakra-colors-blue-200)'},
  ].filter(props => props.value)
  const winsSum = wins.map(value => value.value).reduce<number>((a, b) => a + b, 0)
  const winsPresentable = wins
    .map(item => ({...item, value: (item.value / winsSum) * 100}))
    .filter(value => value.value > 0.1)

  const stats: ExtraNested = player.extraNested as ExtraNested
  const arcade = [
    {label: 'Momma Says Successes', value: stats?.ms.successes},
    {label: 'Momma Says Failures', value: stats?.ms.fails},
    {label: 'Soccer Goals', value: stats?.sc.goals},
  ].filter(value => value.value)

  const bedwarsFooter = [
    {label: 'Iron Collected', value: stats?.bw.iron.collected},
    {label: 'Gold Collected', value: stats?.bw.gold.collected},
    {label: 'Diamonds Collected', value: stats?.bw.diamonds.collected},
    {label: 'Emeralds Collected', value: stats?.bw.emeralds.collected},
  ].filter(value => value.value)

  const conquests = [
    {label: 'Kills', value: stats?.cq.kills},
    {label: 'Deaths', value: stats?.cq.deaths},
    {label: 'Wins', value: stats?.cq.wins},
    {label: 'Diamonds Collected', value: stats?.cq.diamonds.collected},
    {label: 'Emeralds Collected', value: stats?.cq.emeralds.collected},
    {label: 'Flags Captured', value: stats?.cq.flags.captured},
    {label: 'Flags Returned', value: stats?.cq.flags.returned},
    {label: 'Gold Collected', value: stats?.cq.gold.collected},
    {label: 'Iron Collected', value: stats?.cq.iron.collected},
  ].filter(value => value.value)

  const isBedwarsTableNonEmpty = [
    stats?.bw.kills,
    stats?.bw.deaths,
    stats?.bw.beds.broken,
    stats?.bw.final.kills,
    stats?.bw.wins,
    stats?.bw.solo.kills,
    stats?.bw.solo.deaths,
    stats?.bw.solo.beds.broken,
    stats?.bw.solo.final.kills,
    stats?.bw.solo.wins,
    stats?.bw.doubles.kills,
    stats?.bw.doubles.deaths,
    stats?.bw.doubles.beds.broken,
    stats?.bw.doubles.final.kills,
    stats?.bw.doubles.wins,
    stats?.bw.squads.kills,
    stats?.bw.squads.deaths,
    stats?.bw.squads.beds.broken,
    stats?.bw.squads.final.kills,
    stats?.bw.squads.wins,
    stats?.bw['1v1'].kills,
    stats?.bw['1v1'].deaths,
    stats?.bw['1v1'].beds.broken,
    stats?.bw['1v1'].final.kills,
    stats?.bw['1v1'].wins,
    stats?.bw['2v2'].kills,
    stats?.bw['2v2'].deaths,
    stats?.bw['2v2'].beds.broken,
    stats?.bw['2v2'].final.kills,
    stats?.bw['2v2'].wins,
  ].some(Boolean)
  const renderBedwarsTable = isBedwarsTableNonEmpty || bedwarsFooter.length > 0

  const duels = [
    {label: 'Kills', value: stats?.duels.kills},
    {label: 'Deaths', value: stats?.duels.deaths},
    {label: 'K/D', value: stats?.duels == null ? 0 : getKdr(stats.duels.kills, stats.duels.deaths)},
    {label: 'Wins', value: stats?.duels.wins},
    {label: 'Losses', value: stats?.duels.losses},
    {label: 'W/L', value: stats?.duels == null ? 0 : getKdr(stats.duels.wins, stats.duels.losses)},
  ].filter(value => value.value)

  const meltdown = [
    {label: 'Kills', value: stats?.md.kills},
    {label: 'Deaths', value: stats?.md.deaths},
    {label: 'K/D', value: stats?.md == null ? 0 : getKdr(stats.md.kills, stats.md.deaths)},
    {label: 'Powerups Collected', value: stats?.md.powerups.collected},
    {label: 'Wins', value: stats?.md.wins},
    {label: 'Losses', value: stats?.md.losses},
    {label: 'W/L', value: stats?.md == null ? 0 : getKdr(stats.md.wins, stats.md.losses)},
  ].filter(value => value.value)

  const murderMysteryFooter = [
    {label: 'Bow Kills', value: stats?.mm.bow.kills},
    {label: 'Knife Kills', value: stats?.mm.knife.kills},
    {label: 'Throw Knife Kills', value: stats?.mm.throw.knife.kills},
  ].filter(value => value.value)

  const isMurderMysteryTableNonEmpty = [
    stats?.mm.classic.kills,
    stats?.mm.classic.deaths,
    stats?.mm.classic.wins,
    stats?.mm.infection.kills,
    stats?.mm.infection.deaths,
    stats?.mm.infection.wins,
  ].some(Boolean)
  const renderMurderMysteryTable = isMurderMysteryTableNonEmpty || murderMysteryFooter.length > 0

  const skywarsFooter = [
    {label: 'Blocks Broken', value: stats?.sw.blocks.broken},
    {label: 'Blocks Placed', value: stats?.sw.blocks.placed},
    {label: 'Eggs Thrown', value: stats?.sw.eggs.thrown},
    {label: 'Ender Pearls Thrown', value: stats?.sw.epearls.thrown},
  ].filter(value => value.value)
  const isSkywarsTableNonEmpty = [
    stats?.sw.solo.normal.kills,
    stats?.sw.solo.normal.deaths,
    stats?.sw.solo.insane.kills,
    stats?.sw.solo.insane.deaths,
    stats?.sw.solo.wins,
    stats?.sw.solo.losses,
    stats?.sw.doubles.normal.kills,
    stats?.sw.doubles.normal.deaths,
    stats?.sw.doubles.insane.kills,
    stats?.sw.doubles.insane.deaths,
    stats?.sw.doubles.wins,
    stats?.sw.doubles.losses,
  ].some(Boolean)
  const renderSkywarsTable = isSkywarsTableNonEmpty || skywarsFooter.length > 0

  const theBridge = [
    {label: 'Kills', value: stats?.tb.kills},
    {label: 'Deaths', value: stats?.tb.deaths},
    {label: 'K/D', value: stats?.tb == null ? 0 : getKdr(stats.tb.kills, stats.tb.deaths)},
    {label: 'Goals', value: stats?.tb.goals},
    {label: 'Wins', value: stats?.tb.wins},
    {label: 'Losses', value: stats?.tb.losses},
    {label: 'W/L', value: stats?.tb == null ? 0 : getKdr(stats.tb.wins, stats.tb.losses)},
  ].filter(value => value.value)

  const tbDoubles = [
    {label: 'Doubles Goals', value: stats?.tb.doubles.goals},
    {label: 'Doubles Kills', value: stats?.tb.doubles.kills},
    {label: 'Doubles Wins', value: stats?.tb.doubles.wins},
  ].filter(value => value.value)

  const tbSolo = [
    {label: 'Solo Goals', value: stats?.tb.solo.goals},
    {label: 'Solo Kills', value: stats?.tb.solo.kills},
    {label: 'Solo Wins', value: stats?.tb.solo.wins},
  ].filter(value => value.value)

  const factions = [
    {label: 'Best Streak', value: player.factionData?.bestStreak},
    {label: 'Bounty', value: player.factionData?.bounty},
    {label: 'Coins', value: player.factionData?.coins},
    {label: 'Joined', value: player.factionData?.registerDate},
    {label: 'Kills', value: player.factionData?.kills},
    {label: 'Streak', value: player.factionData?.streak},
    {label: 'Tags', value: player.factionData?.tags},
    {label: 'XP', value: player.factionData?.xp},
  ].filter(value => value.value)

  const hasNoStats = [
    !renderBedwarsTable,
    !renderMurderMysteryTable,
    !renderSkywarsTable,
    arcade.length === 0,
    conquests.length === 0,
    duels.length === 0,
    factions.length === 0,
    theBridge.length === 0,
    meltdown.length === 0,
    tbDoubles.length === 0,
    tbSolo.length === 0,
  ].every(Boolean)

  function getCountdown(): React.JSX.Element {
    const now = new Date()
    switch (period) {
      case Period.DAILY: {
        const nextMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
        return <Countdown date={nextMidnight} />
      }

      case Period.WEEKLY: {
        const nextMonday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + ((8 - now.getDay()) % 7))
        return <Countdown date={nextMonday} />
      }

      case Period.BIWEEKLY: {
        const nextMonday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + ((8 - now.getDay()) % 7))
        const nextEvenMonday = new Date(
          nextMonday.getFullYear(),
          nextMonday.getMonth(),
          nextMonday.getDate() + 7 * (1 - (getWeek(nextMonday) % 2)),
        )
        return <Countdown date={nextEvenMonday} />
      }

      case Period.MONTHLY: {
        const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
        return <Countdown date={nextMonth} />
      }

      case Period.YEARLY: {
        const nextYear = new Date(now.getFullYear() + 1, 0, 1)
        return <Countdown date={nextYear} />
      }

      default: {
        throw new Error('Invalid period')
      }
    }
  }

  return (
    <Stack align="flex-start" direction="column" spacing={4}>
      <Stack
        borderWidth={1}
        className={clsx(appStyles.backdrop, playerStyles.stats)}
        direction="column"
        p={4}
        rounded="2xl"
      >
        <Stack direction="row" justify="space-between">
          <Stack align={isMobile ? 'left' : 'center'} direction={isMobile ? 'column' : 'row'}>
            <Heading fontSize="2xl" fontWeight="bold">
              Statistics
            </Heading>
            {period !== Period.GLOBAL && (
              <Badge bg="red.500" color="white" px={2} rounded="lg">
                {period} — resets in {getCountdown()}
              </Badge>
            )}
          </Stack>
          <Select
            maxW="100px"
            onChange={event => setPeriod(event.target.value as Period)}
            rounded="full"
            size="sm"
            value={period}
          >
            {getPeriodOptions()}
          </Select>
        </Stack>

        {hasNoStats && (
          <Stack align="center" h="full" justify="center">
            <Stack align="center" justify="center" textAlign="center">
              <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={128} width={128} />
              <Heading fontSize="3xl" fontWeight="bold">
                *cricket noises*
              </Heading>
              <Text color="gray.400" fontSize="lg">
                <strong>{player.name}</strong> has no stats available for the selected period (<strong>{period}</strong>
                ).
              </Text>
              <Text color="gray.400" fontSize="lg">
                Try selecting a different period.
              </Text>
            </Stack>
          </Stack>
        )}

        <Tabs h="full" size="sm" variant="unstyled">
          <TabList className={appStyles.scroller}>
            {renderBedwarsTable && <Tab>Bedwars</Tab>}
            {conquests.length > 0 && <Tab>Conquests</Tab>}
            {duels.length > 0 && <Tab>Duels</Tab>}
            {meltdown.length > 0 && <Tab>Meltdown</Tab>}
            {renderMurderMysteryTable && <Tab>Murder Mystery</Tab>}
            {renderSkywarsTable && <Tab>Skywars</Tab>}
            {theBridge.length > 0 && <Tab>The Bridge</Tab>}
            {arcade.length > 0 && <Tab>Arcade</Tab>}
            {factions.length > 0 && <Tab>Factions</Tab>}
          </TabList>

          <TabPanels mb={4} mt={2}>
            {renderBedwarsTable && (
              <TabPanel fontSize="sm" p={0}>
                <Paper className={styles.root}>
                  {isBedwarsTableNonEmpty && (
                    <div className={clsx(styles.tableWrapper, appStyles.scroller)}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Kills</TableCell>
                            <TableCell>Deaths</TableCell>
                            <TableCell>K/D</TableCell>
                            <TableCell>Beds Broken</TableCell>
                            <TableCell>BB/D</TableCell>
                            <TableCell>Final Kills</TableCell>
                            <TableCell>FK/D</TableCell>
                            <TableCell>Wins</TableCell>
                            <TableCell>W/D</TableCell>
                          </TableRow>
                          {['1v1', '2v2', 'solo', 'doubles', 'squads'].map(type => {
                            const data = stats.bw[
                              type as '1v1' | '2v2' | 'solo' | 'doubles' | 'squads'
                            ] as ExtraNested['bw']['1v1']
                            return (
                              <TableRow key={type}>
                                <TableCell>{type}</TableCell>
                                <FormattedTableCell>{data.kills}</FormattedTableCell>
                                <FormattedTableCell>{data.deaths}</FormattedTableCell>
                                <FormattedTableCell>{getKdr(data.kills, data.deaths)}</FormattedTableCell>
                                <FormattedTableCell>{data.beds.broken}</FormattedTableCell>
                                <FormattedTableCell>{getKdr(data.beds.broken, data.deaths)}</FormattedTableCell>
                                <FormattedTableCell>{data.final.kills}</FormattedTableCell>
                                <FormattedTableCell>{getKdr(data.final.kills, data.deaths)}</FormattedTableCell>
                                <FormattedTableCell>{data.wins}</FormattedTableCell>
                                <FormattedTableCell>{getKdr(data.wins, data.deaths)}</FormattedTableCell>
                              </TableRow>
                            )
                          })}
                          <TableRow>
                            <TableCell>Total</TableCell>
                            <FormattedTableCell>{stats?.bw.kills}</FormattedTableCell>
                            <FormattedTableCell>{stats?.bw.deaths}</FormattedTableCell>
                            <FormattedTableCell>
                              {stats?.bw == null ? 0 : getKdr(stats.bw.kills, stats.bw.deaths)}
                            </FormattedTableCell>
                            <FormattedTableCell>{stats?.bw.beds.broken}</FormattedTableCell>
                            <FormattedTableCell>
                              {stats?.bw == null ? 0 : getKdr(stats.bw.beds.broken, stats.bw.deaths)}
                            </FormattedTableCell>
                            <FormattedTableCell>{stats?.bw.final.kills}</FormattedTableCell>
                            <FormattedTableCell>
                              {stats?.bw == null ? 0 : getKdr(stats.bw.final.kills, stats.bw.deaths)}
                            </FormattedTableCell>
                            <FormattedTableCell>{stats?.bw.wins}</FormattedTableCell>
                            <FormattedTableCell>
                              {stats?.bw == null ? 0 : getKdr(stats.bw.wins, stats.bw.deaths)}
                            </FormattedTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  )}

                  {bedwarsFooter.length > 0 && (
                    <Text p={4}>
                      {bedwarsFooter.map(item => (
                        <span key={item.label}>
                          <strong>{item.label}</strong>:{' '}
                          <Box as="span" color="gray.400">
                            {numberFormatter.format(item.value!)}
                          </Box>
                          <br />
                        </span>
                      ))}
                    </Text>
                  )}
                </Paper>
              </TabPanel>
            )}

            {conquests.length > 0 && (
              <TabPanel fontSize="xl" p={0}>
                <Stack direction="column" justify="center">
                  {conquests.map(item => (
                    <Stack direction="row" justify="space-between" key={item.label}>
                      <Text fontWeight="bold">{item.label}</Text>
                      <Text color="gray.400">{numberFormatter.format(item.value!)}</Text>
                    </Stack>
                  ))}
                </Stack>
              </TabPanel>
            )}

            {duels.length > 0 && (
              <TabPanel fontSize="xl" p={0}>
                <Stack direction="column" justify="center">
                  {duels.map(item => (
                    <Stack direction="row" justify="space-between" key={item.label}>
                      <Text fontWeight="bold">{item.label}</Text>
                      <Text color="gray.400">{numberFormatter.format(item.value!)}</Text>
                    </Stack>
                  ))}
                </Stack>
              </TabPanel>
            )}

            {meltdown.length > 0 && (
              <TabPanel fontSize="xl" p={0}>
                <Stack direction="column" justify="center">
                  {meltdown.map(item => (
                    <Stack direction="row" justify="space-between" key={item.label}>
                      <Text fontWeight="bold">{item.label}</Text>
                      <Text color="gray.400">{numberFormatter.format(item.value!)}</Text>
                    </Stack>
                  ))}
                </Stack>
              </TabPanel>
            )}

            {renderMurderMysteryTable && (
              <TabPanel fontSize="sm" p={0}>
                <Paper className={styles.root}>
                  {isMurderMysteryTableNonEmpty && (
                    <div className={clsx(styles.tableWrapper, appStyles.scroller)}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Kills</TableCell>
                            <TableCell>Deaths</TableCell>
                            <TableCell>K/D</TableCell>
                            <TableCell>Wins</TableCell>
                          </TableRow>
                          {['classic', 'infection'].map(type => {
                            const data = stats.mm[type as 'classic' | 'infection'] as
                              | ExtraNested['mm']['classic']
                              | ExtraNested['mm']['infection']
                            return (
                              <TableRow key={type}>
                                <TableCell>{type}</TableCell>
                                <FormattedTableCell>{data.kills}</FormattedTableCell>
                                <FormattedTableCell>{data.deaths}</FormattedTableCell>
                                <FormattedTableCell>{getKdr(data.kills, data.deaths)}</FormattedTableCell>
                                <FormattedTableCell>{data.wins}</FormattedTableCell>
                              </TableRow>
                            )
                          })}
                          <TableRow>
                            <TableCell>Total</TableCell>
                            <FormattedTableCell>{stats?.mm.kills}</FormattedTableCell>
                            <FormattedTableCell>{stats?.mm.deaths}</FormattedTableCell>
                            <FormattedTableCell>
                              {stats?.mm == null ? 0 : getKdr(stats.mm.kills, stats.mm.deaths)}
                            </FormattedTableCell>
                            <FormattedTableCell>{stats?.mm.wins}</FormattedTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  )}

                  {murderMysteryFooter.length > 0 && (
                    <Text p={4}>
                      {murderMysteryFooter.map(item => (
                        <span key={item.label}>
                          <strong>{item.label}</strong>:{' '}
                          <Box as="span" color="gray.400">
                            {numberFormatter.format(item.value!)}
                          </Box>
                          <br />
                        </span>
                      ))}
                    </Text>
                  )}
                </Paper>
              </TabPanel>
            )}

            {renderSkywarsTable && (
              <TabPanel fontSize="sm" p={0}>
                <Paper className={styles.root}>
                  {isSkywarsTableNonEmpty && (
                    <div className={clsx(styles.tableWrapper, appStyles.scroller)}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={1} />
                            <TableCell colSpan={3}>Normal</TableCell>
                            <TableCell colSpan={6}>Insane</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Kills</TableCell>
                            <TableCell>Deaths</TableCell>
                            <TableCell>K/D</TableCell>
                            <TableCell>Kills</TableCell>
                            <TableCell>Deaths</TableCell>
                            <TableCell>K/D</TableCell>
                            <TableCell>Wins</TableCell>
                            <TableCell>Losses</TableCell>
                            <TableCell>W/L</TableCell>
                          </TableRow>
                          {['solo', 'doubles'].map(type => {
                            const data = stats.sw[type as 'solo' | 'doubles'] as
                              | ExtraNested['sw']['solo']
                              | ExtraNested['sw']['doubles']
                            return (
                              <TableRow key={type}>
                                <TableCell>{type}</TableCell>
                                <FormattedTableCell>{data.normal.kills}</FormattedTableCell>
                                <FormattedTableCell>{data.normal.deaths}</FormattedTableCell>
                                <FormattedTableCell>{getKdr(data.normal.kills, data.normal.deaths)}</FormattedTableCell>
                                <FormattedTableCell>{data.insane.kills}</FormattedTableCell>
                                <FormattedTableCell>{data.insane.deaths}</FormattedTableCell>
                                <FormattedTableCell>{getKdr(data.insane.kills, data.insane.deaths)}</FormattedTableCell>
                                <FormattedTableCell>{data.wins}</FormattedTableCell>
                                <FormattedTableCell>{data.losses}</FormattedTableCell>
                                <FormattedTableCell>{getKdr(data.wins, data.losses)}</FormattedTableCell>
                              </TableRow>
                            )
                          })}
                          <TableRow>
                            <TableCell>Total</TableCell>
                            <FormattedTableCell>{stats?.sw.kills}</FormattedTableCell>
                            <FormattedTableCell>{stats?.sw.deaths}</FormattedTableCell>
                            <FormattedTableCell>
                              {stats?.sw == null ? 0 : getKdr(stats.sw.kills, stats.sw.deaths)}
                            </FormattedTableCell>
                            <FormattedTableCell>
                              {stats?.sw.solo.insane.kills + stats?.sw.doubles.insane.kills}
                            </FormattedTableCell>
                            <FormattedTableCell>
                              {stats?.sw.solo.insane.deaths + stats?.sw.doubles.insane.deaths}
                            </FormattedTableCell>
                            <FormattedTableCell>
                              {getKdr(
                                stats?.sw.solo.insane.kills + stats?.sw.doubles.insane.kills,
                                stats?.sw.solo.insane.deaths + stats?.sw.doubles.insane.deaths,
                              )}
                            </FormattedTableCell>
                            <FormattedTableCell>{stats?.sw.wins}</FormattedTableCell>
                            <FormattedTableCell>{stats?.sw.losses}</FormattedTableCell>
                            <FormattedTableCell>
                              {stats?.sw == null ? 0 : getKdr(stats.sw.wins, stats.sw.losses)}
                            </FormattedTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  )}

                  {skywarsFooter.length > 0 && (
                    <Text p={4}>
                      {skywarsFooter.map(item => (
                        <span key={item.label}>
                          <strong>{item.label}</strong>:{' '}
                          <Box as="span" color="gray.400">
                            {numberFormatter.format(item.value!)}
                          </Box>
                          <br />
                        </span>
                      ))}
                    </Text>
                  )}
                </Paper>
              </TabPanel>
            )}

            {theBridge.length > 0 && (
              <TabPanel fontSize="xl" p={0}>
                <Stack direction="column" justify="center">
                  {theBridge.map(item => (
                    <Stack direction="row" justify="space-between" key={item.label}>
                      <Text fontWeight="bold">{item.label}</Text>
                      <Text color="gray.400">{numberFormatter.format(item.value!)}</Text>
                    </Stack>
                  ))}
                  {tbDoubles.map(item => (
                    <Stack direction="row" justify="space-between" key={item.label}>
                      <Text fontWeight="bold">{item.label}</Text>
                      <Text color="gray.400">{numberFormatter.format(item.value!)}</Text>
                    </Stack>
                  ))}
                  {tbSolo.map(item => (
                    <Stack direction="row" justify="space-between" key={item.label}>
                      <Text fontWeight="bold">{item.label}</Text>
                      <Text color="gray.400">{numberFormatter.format(item.value!)}</Text>
                    </Stack>
                  ))}
                </Stack>
              </TabPanel>
            )}

            {arcade.length > 0 && (
              <TabPanel fontSize="xl" p={0}>
                <Stack direction="column" justify="center">
                  {arcade.map(item => (
                    <Stack direction="row" justify="space-between" key={item.label}>
                      <Text fontWeight="bold">{item.label}</Text>
                      <Text color="gray.400">{numberFormatter.format(item.value!)}</Text>
                    </Stack>
                  ))}
                </Stack>
              </TabPanel>
            )}

            {factions.length > 0 && (
              <TabPanel fontSize="xl" p={0}>
                <Stack direction="column" justify="center">
                  {player.factionData?.faction != null && (
                    <Stack direction="row" justify="space-between">
                      <Text fontWeight="bold">Faction</Text>
                      <Link
                        as={RouterLink}
                        color="orange.300"
                        fontWeight="bold"
                        textDecor="underline"
                        to={`/faction/${player.factionData?.faction.name}`}
                      >
                        {player.factionData?.faction.name}
                      </Link>
                    </Stack>
                  )}
                  {factions.map(item => (
                    <Stack direction="row" justify="space-between" key={item.label}>
                      <Text fontWeight="bold">{item.label}</Text>
                      <Text color="gray.400">
                        {typeof item.value === 'number' ? numberFormatter.format(item.value) : item.value}
                      </Text>
                    </Stack>
                  ))}
                </Stack>
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>

        {winsPresentable.some(Boolean) && (
          <Stack direction="column" gap={2}>
            <Heading size="xs">Wins Summary</Heading>

            <Flex as="span" bg="rgb(110 118 129 / 40%)" borderRadius={6} height={2} overflow="hidden">
              {winsPresentable.map(item => (
                <Box
                  aria-label={`${item.label} ${item.value.toFixed(1)}`}
                  as="span"
                  bg={item.color}
                  key={item.key}
                  width={`${item.value.toFixed(1)}%`}
                />
              ))}
            </Flex>

            <Box as="ul" listStyleType="none">
              {winsPresentable.map(item => (
                <Box as="li" display="inline" key={item.key}>
                  <Link
                    _hover={{color: 'orange.200'}}
                    alignItems="center"
                    as={RouterLink}
                    color="gray.400"
                    display="inline-flex"
                    flexWrap="nowrap"
                    fontSize="12px"
                    mr="16px"
                    to={`/leaderboard/wins/${item.key}`}
                  >
                    <FaCircle style={{color: item.color, marginRight: 8}} />
                    <Box as="span" color="gray.50" fontWeight={600} mr="4px">
                      {item.label}
                    </Box>
                    <Box as="span">{item.value.toFixed(1)}%</Box>
                  </Link>
                </Box>
              ))}
            </Box>
          </Stack>
        )}
      </Stack>

      {player.winStreaks && player.winStreaks.length > 0 && (
        <Stack
          borderWidth={1}
          className={clsx(appStyles.backdrop, playerStyles.stats)}
          direction="column"
          p={4}
          rounded="2xl"
          w="full"
        >
          <Heading fontSize="2xl" fontWeight="bold">
            Win Streaks
          </Heading>

          <Text color="gray.400" fontSize="lg" letterSpacing="tight">
            The current win streaks for <strong>{player.name}</strong>, displayed as <strong>current/best</strong>.
          </Text>

          <Stack direction="column" justify="center">
            {player.winStreaks.map(item => (
              <Stack direction="row" justify="space-between" key={item.gameKey}>
                <Text fontWeight="bold">{item.gameKeyFriendly}</Text>
                <Text color="gray.400">
                  {numberFormatter.format(item.current)}/{numberFormatter.format(item.best)}
                </Text>
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
