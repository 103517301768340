import type {APIChatLogEntry} from '@app/common/api'
import {dateFormatter} from '@app/common/constants'
import {Indicator} from '@app/components/Indicator/Indicator'
import {Flex, Heading, Stack, Text} from '@chakra-ui/react'
import type React from 'react'
import {
  FaCampground,
  FaCrown,
  FaGavel,
  FaMessage,
  FaPeopleGroup,
  FaQuestion,
  FaShield,
  FaUserShield,
  FaUsersRays,
  FaComments,
} from 'react-icons/fa6'

export function ChatlogEntry({chatLogEntry}: {chatLogEntry: APIChatLogEntry}): React.JSX.Element {
  let LeftIcon = FaQuestion

  switch (chatLogEntry.context) {
    case 'global': {
      LeftIcon = FaMessage
      break
    }
    case 'party': {
      LeftIcon = FaPeopleGroup
      break
    }
    case 'guild': {
      LeftIcon = FaShield
      break
    }
    case 'staff': {
      LeftIcon = FaUserShield
      break
    }
    case 'moderation': {
      LeftIcon = FaGavel
      break
    }
    case 'faction': {
      LeftIcon = FaCampground
      break
    }
    case 'faction_ally': {
      LeftIcon = FaUsersRays
      break
    }
    case 'trainee': {
      LeftIcon = FaUserShield
      break
    }
    case 'admin': {
      LeftIcon = FaUserShield
      break
    }
    case 'ranked': {
      LeftIcon = FaCrown
      break
    }
    case 'whisper': {
      LeftIcon = FaComments
      break
    }
  }
  return (
    <Stack
      align="center"
      bgColor={'green.500'}
      boxShadow="lg"
      cursor="pointer"
      direction="row"
      justify="space-between"
      p={4}
      role="button"
      rounded="lg"
      tabIndex={0}
      transition="ease-in-out 200ms"
      w="full"
    >
      <Stack align="center" direction="row" spacing="12px">
        <Flex flex="1 0 auto">
          <LeftIcon height={24} size={24} width={24} />
        </Flex>
        <Stack spacing="0.5">
          <Stack align="center" direction="row" spacing="1.5">
            <Heading isTruncated size="sm">
              {dateFormatter.format(new Date(chatLogEntry.timestamp))}
            </Heading>
            <Indicator variant="inverted">{chatLogEntry.context}</Indicator>
          </Stack>
          <Stack spacing="0">
            <Text color="whiteAlpha.800" fontSize="sm">
              {chatLogEntry.content}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
