import {Provider, useOAuthCallback} from '@app/common/api'
import {MICROSOFT_PARENTAL_CONSENT, XBOX_REDIRECT_URI} from '@app/common/constants'
import styles from '@app/components/Callback/Callback.module.css'
import {Spinner} from '@app/components/Spinner/Spinner'
import {ArrowTopRightOnSquareIcon, ExclamationTriangleIcon} from '@heroicons/react/24/solid'
import clsx from 'clsx'
import React from 'react'
import {useParams, useSearchParams} from 'react-router-dom'

const ProviderToString: Record<Provider, string> = {
  [Provider.DISCORD]: 'Discord',
  [Provider.XBOX]: 'Xbox',
  [Provider.YOUTUBE]: 'YouTube',
}

const ProviderToLogo: Record<Provider, string> = {
  [Provider.DISCORD]: '/assets/c3c8213405e3c513452dfdb68aa70ce3.svg',
  [Provider.XBOX]: '/assets/3c2f72412ec816bcd4c26ea8fe87f57c.svg',
  [Provider.YOUTUBE]: '/assets/2da7041141b738d90e6e806cbf3e5760.svg',
}

const ERROR_MESSAGE = "We're sorry, there was a problem connecting. You can close this window and try again."

export default function Callback(): React.JSX.Element | null {
  const params = useParams() as {provider: Provider}
  const [query] = useSearchParams()
  const {data, error, status} = useOAuthCallback({
    provider: params.provider,
    code: query.get('code') ?? '',
    state: query.get('state') ?? '',
    redirectUri: XBOX_REDIRECT_URI,
  })

  React.useEffect(() => {
    if (status !== 'success') return
    if (typeof data === 'object' && 'token' in data) {
      window.localStorage.setItem('token', data.token)
      window.localStorage.setItem('mfa', data.mfa)
      window.opener?.location.reload()
    }

    window.close()
  }, [data, status])

  if (!Object.values(Provider).includes(params.provider)) return null
  return (
    <div className={styles.wrapper}>
      <div className={styles.verifyConnectedAccount}>
        <div>
          <div className={styles.logos}>
            <div className={clsx(styles.logo, styles.logoNg)} />
            <div className={styles.logosDivider} />
            <div className={styles.logo} style={{backgroundImage: `url(${ProviderToLogo[params.provider]})`}} />
          </div>
          <div className={clsx(styles.message, status === 'error' && styles.error)}>
            {status === 'error' ? 'Failed to connect' : 'Connecting'} your{' '}
            <strong>{ProviderToString[params.provider]}</strong> account to <strong>NetherGames</strong>
          </div>
          <div className={clsx(styles.message, styles.details)}>{status === 'error' ? ERROR_MESSAGE : <Spinner />}</div>
          {error && (
            <div className={clsx(styles.message, styles.details, styles.error, styles.errorDetails)}>
              {error.response?.data?.message ?? error.message}
            </div>
          )}
          {error?.response?.data?.code === MICROSOFT_PARENTAL_CONSENT && (
            <div className={styles.underageWarning}>
              <div className={styles.underageWarningTitleWrapper}>
                <ExclamationTriangleIcon height={24} width={24} />
                <div className={styles.underageWarningTitle}>Underage Warning</div>
              </div>
              <div className={styles.underageWarningMessage}>
                Your Microsoft account is underage. A parent or guardian (a Microsoft account above 18) must add your
                account to a Microsoft Family. NetherGames does not provide support for this.
              </div>
              <a
                className={styles.button}
                href="https://support.microsoft.com/en-us/account-billing/b6280c9d-38d7-82ff-0e4f-a6cb7e659344"
                rel="noreferrer"
                target="_blank"
              >
                Microsoft support page
                <ArrowTopRightOnSquareIcon height={16} width={16} />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
