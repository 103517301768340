import {queryClient, useHealthCheck} from '@app/common/api'
import {useExperimentStore} from '@app/common/experiments'
import {useCountryCodeStore, useDelayedFormsStore, useOverlayStore, useSettingsStore} from '@app/common/stores'
import Account from '@app/components/Account/Account'
import MFABackupCodes from '@app/components/Account/MFA/MFABackupCodes'
import MFAEnable from '@app/components/Account/MFA/MFAEnable'
import MFAVerify from '@app/components/Account/MFA/MFAVerify'
import MFAVerifyOpener from '@app/components/Account/MFA/MFAVerifyOpener'
import AdminApplicationModal from '@app/components/Admin/AdminApplicationModal'
import AdminAuditLogModal from '@app/components/Admin/AdminAuditLogModal'
import AdminAuditLogModalOpener from '@app/components/Admin/AdminAuditLogModalOpener'
import AdminExperimentsModal from '@app/components/Admin/AdminExperimentsModal'
import AdminPlayerGameIPsModal from '@app/components/Admin/AdminPlayerGameIPsModal'
import AdminPlayerModal from '@app/components/Admin/AdminPlayerModal'
import AdminPlayerWebIPsModal from '@app/components/Admin/AdminPlayerWebIPsModal'
import AdminPromosModal from '@app/components/Admin/AdminPromosModal'
import AdminPunishmentReasonsModal from '@app/components/Admin/AdminPunishmentReasonsModal'
import AdminServerAnnouncementsModal from '@app/components/Admin/AdminServerAnnouncementsModal'
import AdminServerSettingsModal from '@app/components/Admin/AdminServerSettingsModal'
import AdminServerUpdatesModal from '@app/components/Admin/AdminServerUpdatesModal'
import AdminSystemMessageModal from '@app/components/Admin/AdminSystemMessageModal'
import AdminTournamentModal from '@app/components/Admin/AdminTournamentModal'
import StaffDirectoryModal from '@app/components/Admin/StaffDirectoryModal'
import StaffDirectoryModalOpener from '@app/components/Admin/StaffDirectoryModalOpener'
import YoutubeDirectoryModal from '@app/components/Admin/YoutubeDirectoryModal'
import ApplicationModal from '@app/components/Application/ApplicationModal'
import Applications from '@app/components/Application/Applications'
import Auth from '@app/components/Auth/Auth'
import Callback from '@app/components/Callback/Callback'
import {CrashScreen} from '@app/components/CrashScreen/CrashScreen'
import ProxyListModal from '@app/components/Developer/ProxyListModal'
import Faction from '@app/components/Faction/Faction'
import Guild from '@app/components/Guild/Guild'
import Home from '@app/components/Home/Home'
import {Layout} from '@app/components/Home/Layout'
import Leaderboard from '@app/components/Leaderboard/Leaderboard'
import {Loading} from '@app/components/Loading/Loading'
import AdminPunishmentsModal from '@app/components/Modal/AdminPunishmentsModal'
import AdminRequestsModal from '@app/components/Modal/AdminRequestsModal'
import AdminRequestsModalOpener from '@app/components/Modal/AdminRequestsModalOpener'
import AppSettingsModal from '@app/components/Modal/AppSettingsModal'
import LinkWarningModal from '@app/components/Modal/LinkWarningModal'
import PlayerAltsModal from '@app/components/Modal/PlayerAltsModal'
import PlayerPunishmentsModal from '@app/components/Modal/PlayerPunishmentsModal'
import TierSummaryModal from '@app/components/Modal/RankTierSummaryModal'
import RequestReviewModal from '@app/components/Modal/RequestReviewModal'
import RequestReviewModalOpener from '@app/components/Modal/RequestReviewModalOpener'
import RequestsModal from '@app/components/Modal/RequestsModal'
import RequestsModalOpener from '@app/components/Modal/RequestsModalOpener'
import StaffChatLogsModal from '@app/components/Modal/StaffChatLogsModal'
import TournamentDetails from '@app/components/Modal/TournamentDetails'
import Player from '@app/components/Player/Player'
import SearchOverlay from '@app/components/Search/SearchOverlay'
import SearchResults from '@app/components/Search/SearchResults'
import ErrorScreen from '@app/components/UI/ErrorScreen'
import KeybindManager from '@app/components/UI/KeybindManager'
import Vote from '@app/components/Vote/Vote'
import VotePlayer from '@app/components/Vote/VotePlayer'
import XPCalculator from '@app/components/XPCalculator/XPCalculator'
import {useKeyboardMode} from '@app/hooks/useKeyboardMode'
import {ChakraProvider, Link, extendTheme, withDefaultColorScheme} from '@chakra-ui/react'
import {ThemeProvider, createTheme} from '@material-ui/core'
import * as Sentry from '@sentry/react'
import {QueryClientProvider} from '@tanstack/react-query'
import {LazyMotion, MotionConfig, domAnimation} from 'motion/react'
import React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {Toaster} from 'react-hot-toast'
import {Route, Routes} from 'react-router-dom'
import {Provider as WrapBalancerProvider} from 'react-wrap-balancer'
import {useMediaQuery} from 'usehooks-ts'

export function App(): React.JSX.Element {
  useKeyboardMode()

  const loadCountryCode = useCountryCodeStore(state => state.loadCountryCode)
  const loadDelayedForms = useDelayedFormsStore(state => state.loadDelayedForms)
  const loadExperiments = useExperimentStore(state => state.loadExperiments)
  const setMfaVerifyOpen = useOverlayStore(state => state.setMfaVerifyOpen)

  React.useEffect(() => {
    loadCountryCode()
    loadDelayedForms()
    loadExperiments()
  }, [loadCountryCode, loadDelayedForms, loadExperiments])

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  React.useEffect(() => {
    if (window.localStorage.getItem('mfa') === 'true') {
      setMfaVerifyOpen(true)
    }
  }, [])

  const {data: healthy} = useHealthCheck()
  if (!healthy)
    return (
      <CrashScreen
        note={<>We are currently undergoing maintenance.</>}
        note2={
          <>
            Follow{' '}
            <Link color="orange.300" href="https://twitter.com/NetherGamesMC" isExternal>
              @NetherGamesMC
            </Link>{' '}
            on Twitter for updates.
          </>
        }
        showReload={false}
        title="Maintenance Mode"
      />
    )

  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<Home />} index />
          <Route element={<Auth />} path="auth">
            <Route element={<Account />} path="account" />
            <Route element={<Applications />} path="applications" />
          </Route>
          <Route element={<AdminAuditLogModalOpener />} path="admin/audit-log" />
          <Route element={<AdminRequestsModalOpener />} path="admin/requests" />
          <Route element={<AdminRequestsModalOpener />} path="pending-requests" />
          <Route element={<Faction />} path="faction/:faction" />
          <Route element={<Guild />} path="guild/:guild" />
          <Route element={<Leaderboard />} path="leaderboard/:type" />
          <Route element={<Leaderboard />} path="leaderboard/:type/:game" />
          <Route element={<MFAVerifyOpener />} path="handoff" />
          <Route element={<Player />} path="player/:player" />
          <Route element={<RequestReviewModalOpener />} path="admin/requests/:submissionId" />
          <Route element={<RequestReviewModalOpener />} path="request-review/:submissionId" />
          <Route element={<RequestsModalOpener />} path="request" />
          <Route element={<RequestsModalOpener />} path="request/:formType" />
          <Route element={<SearchResults />} path="results/:name" />
          <Route element={<StaffDirectoryModalOpener />} path="staff-directory" />
          <Route element={<Vote />} path="vote" />
          <Route element={<VotePlayer />} path="vote/:player" />
          <Route element={<XPCalculator />} path="tools/xp-calculator" />
          <Route element={<ErrorScreen payload={{status: 404, message: '404'}} />} path="*" />
        </Route>
        <Route element={<Callback />} path="oauth/:provider/callback" />
      </Routes>

      <AdminApplicationModal />
      <AdminAuditLogModal />
      <AdminExperimentsModal />
      <AdminPlayerGameIPsModal />
      <AdminPlayerModal />
      <AdminPlayerWebIPsModal />
      <AdminPromosModal />
      <AdminPunishmentsModal />
      <StaffChatLogsModal />
      <AdminPunishmentReasonsModal />
      <AdminRequestsModal />
      <AdminServerAnnouncementsModal />
      <AdminServerSettingsModal />
      <AdminServerUpdatesModal />
      <AdminSystemMessageModal />
      <AdminTournamentModal />
      <ApplicationModal />
      <AppSettingsModal />
      <KeybindManager />
      <LinkWarningModal />
      <Loading />
      <MFABackupCodes />
      <MFAEnable />
      <MFAVerify />
      <PlayerAltsModal />
      <PlayerPunishmentsModal />
      <TierSummaryModal />
      <RequestReviewModal />
      <RequestsModal />
      <SearchOverlay />
      <StaffDirectoryModal />
      <TournamentDetails />
      <YoutubeDirectoryModal />
      <ProxyListModal />
    </>
  )
}

export function AppSkeleton(): React.JSX.Element {
  const settings = useSettingsStore(state => ({
    reducedMotion: state.reducedMotion,
    setReducedMotion: state.setReducedMotion,
    syncReducedMotion: state.syncReducedMotion,
  }))
  const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)')
  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  React.useEffect(() => {
    if (settings.syncReducedMotion) settings.setReducedMotion(prefersReducedMotion)
  }, [settings.syncReducedMotion, prefersReducedMotion])

  return (
    <Sentry.ErrorBoundary
      fallback={<CrashScreen />}
      onError={() => !import.meta.env.DEV && window.localStorage.clear()}
    >
      <HelmetProvider>
        <svg
          aria-hidden="true"
          style={{position: 'absolute', pointerEvents: 'none', top: -1, left: -1, width: 1, height: 1}}
          viewBox="0 0 1 1"
        >
          <mask id="svg-mask-avatar-status-none" maskContentUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" fill="white" r="0.5" />
          </mask>
          <mask id="svg-mask-avatar-status-round-32" maskContentUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" fill="white" r="0.5" />
            <circle cx="0.84375" cy="0.84375" fill="black" r="0.25" />
          </mask>
          <mask id="svg-mask-avatar-status-round-80" maskContentUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" fill="white" r="0.5" />
            <circle cx="0.85" cy="0.85" fill="black" r="0.175" />
          </mask>
          <mask id="svg-mask-status-online" maskContentUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" fill="white" r="0.5" />
          </mask>
          <mask id="svg-mask-status-offline" maskContentUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" fill="white" r="0.5" />
            <circle cx="0.5" cy="0.5" fill="black" r="0.25" />
          </mask>
        </svg>

        <QueryClientProvider client={queryClient}>
          <Toaster toastOptions={{duration: 7500, style: {fontSize: 16}}} />
          <MotionConfig reducedMotion={settings.reducedMotion ? 'always' : 'never'}>
            <ThemeProvider
              theme={createTheme({
                palette: {type: 'dark', primary: {main: 'rgba(0, 0, 0, 0.65)'}, secondary: {main: '#dd6b20'}},
                typography: {fontFamily: 'var(--font-sans)'},
              })}
            >
              <ChakraProvider
                theme={extendTheme(withDefaultColorScheme({colorScheme: 'orange'}), {
                  config: {initialColorMode: 'dark', useSystemColorMode: false},
                  fonts: {body: 'var(--font-sans)', heading: 'var(--font-sans)'},
                })}
              >
                <LazyMotion features={domAnimation}>
                  <WrapBalancerProvider>
                    <App />
                  </WrapBalancerProvider>
                </LazyMotion>
              </ChakraProvider>
            </ThemeProvider>
          </MotionConfig>
        </QueryClientProvider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  )
}
