import {useCurrentUser} from '@app/common/api'
import {createPopup} from '@app/common/utils'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button} from '@chakra-ui/react'
import type React from 'react'

type ModalProps = {
  isOpen: boolean
  onClose(): void
}

export function AccountDiscordModal(props: ModalProps): React.JSX.Element | null {
  const {data: user} = useCurrentUser()
  if (!user) return null

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            onClick={async () =>
              createPopup(async () => {
                const {data} = await api.post<{url: string}>('/oauth/discord/authorize')
                return data.url
              })
            }
          >
            Authorize
          </Button>
        </ModalFooter>
      }
      header="Link Discord Account"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody fontSize="md">
        By linking your Discord account, you will sync Discord roles for ranks and tiers. Your Discord ID will also be
        publicly available (opt-out in Account {'>'} Portal Privacy Settings).
      </ModalBody>
    </Modal>
  )
}
