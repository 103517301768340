import type {APIGuild} from '@app/common/api'
import {useGuildToken} from '@app/common/api'
import {GuildFlags, MinecraftColor, MinecraftColorToString} from '@app/common/constants'
import {getErrorMessage, getValidColorsForGuildLevel, hasFlag} from '@app/common/utils'
import {GuildDeleteModal} from '@app/components/Guild/GuildDeleteModal'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {CheckIcon} from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  ListItem,
  Stack,
  Switch,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import {ClipboardDocumentCheckIcon, ClipboardDocumentIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {toast} from 'react-hot-toast'
import {useCopyToClipboard} from 'usehooks-ts'

function Color({
  color,
  isChecked,
  onClick,
}: {color: MinecraftColor; isChecked: boolean; onClick(): void}): React.JSX.Element {
  return (
    <Tooltip label={MinecraftColorToString[color]}>
      <Flex
        align="center"
        backgroundColor={color}
        boxSize="32px"
        color="black"
        cursor="pointer"
        justify="center"
        onClick={onClick}
        rounded="full"
      >
        {isChecked && <CheckIcon />}
      </Flex>
    </Tooltip>
  )
}

function computeGuild(
  guild: APIGuild,
): APIGuild & {autoKickOfficers: boolean; autoKickBanned: boolean; autoKickTempBanned: boolean} {
  return {
    ...guild,
    autoKickOfficers: hasFlag(guild.flags, GuildFlags.AUTO_KICK_OFFICERS),
    autoKickBanned: hasFlag(guild.flags, GuildFlags.AUTO_KICK_BANNED),
    autoKickTempBanned: hasFlag(guild.flags, GuildFlags.AUTO_KICK_TEMP_BANNED),
  }
}

type ModalProps = {
  buttonRef: any
  isOpen: boolean
  onClose(): void
  guild: APIGuild
}

export function GuildUpdateModal(props: ModalProps): React.JSX.Element {
  const {data: token} = useGuildToken(props.guild.name)
  const [copiedValue, copy] = useCopyToClipboard()
  const [hasAgreedToTokenDisclaimer, setHasAgreedToTokenDisclaimer] = React.useState(false)
  const [isResettingToken, setIsResettingToken] = React.useState(false)
  const [showToken, setShowToken] = React.useState(false)
  const queryClient = useQueryClient()
  const deleteModal = useDisclosure()
  const deleteRef = React.useRef()
  const {formState, handleSubmit, register, setError, setValue, watch, reset, control} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: React.useMemo(() => computeGuild(props.guild), [props.guild]),
  })

  React.useEffect(() => reset(computeGuild(props.guild)), [props.guild, reset])
  React.useEffect(() => {
    if (!props.isOpen) reset(computeGuild(props.guild))
  }, [props.isOpen, props.guild, reset])

  async function onSubmit(data: {
    name: string
    motd: string
    tag: string | null
    tagColor: string | null
    discordInvite: string | null
    autoKickDays: number
    autoKickOfficers: boolean
    autoKickBanned: boolean
    autoKickTempBanned: boolean
  }): Promise<void> {
    try {
      const {data: guild} = await api.patch<APIGuild>(`guilds/${props.guild.name}`, {
        name: data.name,
        motd: data.motd,
        tag: data.tag ?? null,
        tagColor: data.tagColor ?? null,
        discordInvite: data.discordInvite ?? null,
        autoKickDays: Number(data.autoKickDays),
        autoKickOfficers: data.autoKickOfficers,
        autoKickBanned: data.autoKickOfficers,
        autoKickTempBanned: data.autoKickOfficers,
      })
      await queryClient.invalidateQueries({queryKey: ['guilds', guild.name]})
      toast.success(`Updated the ${guild.name} guild`, {id: 'guild_updated'})
      props.onClose()
    } catch (error) {
      setError('name', {type: 'validate', message: getErrorMessage(error, 'name')})
      setError('motd', {type: 'validate', message: getErrorMessage(error, 'motd')})
      setError('tag', {type: 'validate', message: getErrorMessage(error, 'tag')})
      setError('tagColor', {type: 'validate', message: getErrorMessage(error, 'tagColor')})
      setError('discordInvite', {type: 'validate', message: getErrorMessage(error, 'discordInvite')})
      setError('autoKickDays', {type: 'validate', message: getErrorMessage(error, 'autoKickDays')})
    }
  }

  return (
    <>
      <GuildDeleteModal {...deleteModal} buttonRef={deleteRef} guild={props.guild} />
      <Modal
        finalFocusRef={props.buttonRef}
        footer={
          <ModalFooter justifyContent="space-between">
            <Button colorScheme="red" onClick={() => deleteModal.onOpen()} ref={deleteRef as any} variant="outline">
              Delete
            </Button>
            <Button isLoading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </ModalFooter>
        }
        header={`Update '${props.guild.name}'`}
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <ModalBody fontSize="md">
          <FormControl isInvalid={Boolean(formState.errors.name?.message)}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input autoFocus id="name" maxLength={16} minLength={1} rounded="md" size="sm" {...register('name')} />
            <FormErrorMessage>{formState.errors.name?.message}</FormErrorMessage>
          </FormControl>

          {props.guild.level >= 25 && (
            <>
              <FormControl isInvalid={Boolean(formState.errors.tag?.message)} mt={4}>
                <FormLabel htmlFor="tag">Tag</FormLabel>
                <Input id="tag" maxLength={8} rounded="md" size="sm" {...register('tag')} />
                <FormErrorMessage>{formState.errors.tag?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(formState.errors.tagColor?.message)} mt={4}>
                <FormLabel htmlFor="tag">Tag Color</FormLabel>
                <Flex align="center" gap={3}>
                  {props.guild.leader.titan && (
                    <Color
                      color={MinecraftColor.RED}
                      isChecked={watch('tagColor') === MinecraftColor.RED}
                      onClick={() => setValue('tagColor', MinecraftColor.RED)}
                    />
                  )}
                  {getValidColorsForGuildLevel(props.guild.level).map(color => (
                    <Color
                      color={color}
                      isChecked={watch('tagColor') === color}
                      key={color}
                      onClick={() => setValue('tagColor', color)}
                    />
                  ))}
                </Flex>
                <FormErrorMessage>{formState.errors.tagColor?.message}</FormErrorMessage>
              </FormControl>
            </>
          )}

          {props.guild.level >= 100 && (
            <FormControl isInvalid={Boolean(formState.errors.motd?.message)} mt={4}>
              <FormLabel htmlFor="motd">MOTD</FormLabel>
              <Input
                id="motd"
                maxLength={50}
                minLength={0}
                placeholder="Displayed to all members when they join"
                rounded="md"
                size="sm"
                {...register('motd', {required: false})}
              />
              <FormErrorMessage>{formState.errors.motd?.message}</FormErrorMessage>
            </FormControl>
          )}

          <FormControl isInvalid={Boolean(formState.errors.discordInvite?.message)} mt={4}>
            <FormLabel htmlFor="discordInvite">Discord Invite</FormLabel>
            <Input
              id="discordInvite"
              maxLength={255}
              minLength={2}
              placeholder="Discord invite code or URL"
              rounded="md"
              size="sm"
              {...register('discordInvite', {required: false})}
            />
            <FormErrorMessage>{formState.errors.discordInvite?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={Boolean(formState.errors.autoKickDays?.message)} mt={4}>
            <FormLabel htmlFor="autoKickDays">Auto-Kick Days</FormLabel>
            <Input
              id="autoKickDays"
              max={365}
              min={0}
              rounded="md"
              size="sm"
              type="number"
              {...register('autoKickDays', {required: false})}
            />
            <FormHelperText>Auto-kick members if inactive for this many days (disabled if zero).</FormHelperText>
            <FormErrorMessage>{formState.errors.autoKickDays?.message}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} w="full">
            <Stack direction="column" spacing="1">
              <Stack align="center" direction="row">
                <FormLabel flex="1" fontWeight="semibold" htmlFor="autoKickOfficers" mb={0}>
                  Auto-Kick Officers
                </FormLabel>
                <Controller
                  control={control}
                  name="autoKickOfficers"
                  render={({field}) => (
                    <Switch
                      id="autoKickOfficers"
                      isChecked={field.value}
                      onChange={event => field.onChange(event.target.checked)}
                    />
                  )}
                />
              </Stack>
              <FormHelperText>When enabled, officers will also be auto-kicked.</FormHelperText>
            </Stack>
          </FormControl>

          <FormControl mt={4} w="full">
            <Stack direction="column" spacing="1">
              <Stack align="center" direction="row">
                <FormLabel flex="1" fontWeight="semibold" htmlFor="autoKickBanned" mb={0}>
                  Auto-Kick Banned Members
                </FormLabel>
                <Controller
                  control={control}
                  name="autoKickBanned"
                  render={({field}) => (
                    <Switch
                      id="autoKickBanned"
                      isChecked={field.value}
                      onChange={event => field.onChange(event.target.checked)}
                    />
                  )}
                />
              </Stack>
              <FormHelperText>Independent of the auto-kick days setting.</FormHelperText>
            </Stack>
          </FormControl>

          <FormControl isDisabled={!watch('autoKickBanned')} mt={4} w="full">
            <Stack direction="column" spacing="1">
              <Controller
                control={control}
                name="autoKickTempBanned"
                render={({field}) => (
                  <Checkbox
                    id="autoKickTempBanned"
                    isChecked={field.value}
                    onChange={event => field.onChange(event.target.checked)}
                  >
                    Also auto-kick temporarily banned members
                  </Checkbox>
                )}
              />
            </Stack>
          </FormControl>

          {token && (
            <Accordion mt={4}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      API Automation
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <FormControl as={Stack}>
                    <SmallHeading>Token</SmallHeading>

                    <Stack color="red.300" fontSize="sm" fontWeight="semibold" gap={2} letterSpacing="tight" pb={2}>
                      <Text>
                        <strong>Danger, Will Robinson!</strong> This token is intended solely for automating guild
                        management in third-party applications. The permitted API actions with this token include:
                      </Text>

                      <UnorderedList pl={4} spacing={1}>
                        <ListItem>Creating, deleting, and listing guild invites.</ListItem>
                        <ListItem>
                          Updating guild members (including role changes, but excluding leadership transfers).
                        </ListItem>
                        <ListItem>Removing guild members.</ListItem>
                        <ListItem>Updating guild settings.</ListItem>
                      </UnorderedList>

                      <Text color="red.300" fontSize="sm" fontWeight="semibold" letterSpacing="tight">
                        <strong>Do not share this token with anyone you do not trust.</strong> In the event that you
                        suspect this token has been compromised, <strong>reset it immediately</strong>. NetherGames
                        disclaims responsibility for any consequences arising from a compromised token.
                      </Text>
                    </Stack>

                    {!hasAgreedToTokenDisclaimer && (
                      <Button colorScheme="orange" onClick={() => setHasAgreedToTokenDisclaimer(true)} size="sm">
                        I understand the risks, show me the token
                      </Button>
                    )}

                    {hasAgreedToTokenDisclaimer && (
                      <>
                        <InputGroup size="md">
                          <Input
                            isReadOnly
                            name="token"
                            pr="3rem"
                            value={showToken ? token : '●'.repeat(token.length)}
                          />
                          <InputRightElement w="3rem">
                            <IconButton
                              aria-label="Copy token"
                              h="1.75rem"
                              icon={
                                copiedValue ? (
                                  <ClipboardDocumentCheckIcon height={16} width={16} />
                                ) : (
                                  <ClipboardDocumentIcon height={16} width={16} />
                                )
                              }
                              onClick={async () => copy(token)}
                              size="sm"
                            />
                          </InputRightElement>
                        </InputGroup>

                        <FormHelperText>
                          <Link color="orange.300" onClick={() => setShowToken(!showToken)}>
                            {showToken ? 'Hide' : 'Reveal'} this field
                          </Link>
                        </FormHelperText>
                      </>
                    )}
                  </FormControl>

                  {hasAgreedToTokenDisclaimer && (
                    <Stack direction="row" mt={4} spacing={4}>
                      <Button
                        colorScheme="orange"
                        isLoading={isResettingToken}
                        onClick={async () => {
                          setIsResettingToken(true)
                          try {
                            await api.delete(`/guilds/${props.guild.name}/token`)
                            await queryClient.invalidateQueries({queryKey: ['guilds', props.guild.name]})
                            toast.success('Reset guild token')
                          } catch {
                            toast.error('Failed to reset guild token')
                          }

                          setIsResettingToken(false)
                        }}
                        variant="outline"
                      >
                        Reset Token
                      </Button>
                    </Stack>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}
